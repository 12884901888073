import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import List from "../../components/list/ListWallet";
import "./wallet.css";
import { useStore } from "../../context/storeContext";
import { useLocalDB } from "../../context/LocalDB";
import { useWallet } from "../../context/walletContext";
import { Link } from "react-router-dom";
import language from "../../assets/language/text";
import sounds from "../../assets/sounds";
import BuyEq9 from "./buyEq9";
import { CircularProgress, Dialog } from "@material-ui/core";
import PlayerModal from "./PlayerModal";
import api from "../../services/api";
import Stepper from "./stepper";
import AddWallet from "./newWallet";
import DepositEq9 from "./depositEq9";
import WithdrawEq9 from "./withdrawEq9";
import Suggestion from "../../components/Suggestion";
import ConfirmDeposit from "./confirmDeposit";

const coin = sounds.coin2;

function Index() {
  const { user } = useStore();
  const { lang } = useLocalDB();
  const { eq9, setEq9, getTransfer, account, status, setDefaultWallet, getDeposit, getBalanceOf } = useWallet();
  const [openTransfer, setOpenTransfer] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [modalBuy, setModalBuy] = useState(false);
  const { setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const [addWalletOpen, setAddWalletOpen] = useState(false);
  const [modalDeposit, setModalDeposit] = useState(false);
  const [modalWithdraw, setModalWithdraw] = useState(false);
  const [attBalance, setAttBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageList, setPageList] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [confirmDepositModal, setConfirmDepositModal] = useState(false);
  const pageVariants = {
    initial: { opacity: 0 },
    in: { opacity: 1 },
    out: { opacity: 0 },
  };
  const text = language[lang].wallet_page;
  const textStepper = language[lang].login_page;

  const [openGuide, setOpenGuide] = useState(false);

  const getTransactions = async (page) => {
    setPageList(page);
    setHistorico([]);
    const transact = await api.get(`payments/all`, { params: { page: page, size: 10 } }).catch(console.log);
    if (transact) {
      setHistorico(transact.data);
      if (page > lastPage) {
        setLastPage(true);
      } else {
        setLastPage(false);
      }
    }
  };

  useEffect(() => {
    getTransactions(0);
  }, []);

  const fetchAllTransactions = async (page, size, setLoading) => {
    const getHistorico = await api.get(`payments/all`, { params: { page: page ? page : 0, size: size ? size : 20 } }).catch(console.log);
    setHistorico([...historico, ...getHistorico.data]);
    setLoading && setLoading(false);
  };

  function TransferToken() {
    const [transferValue, setTransferValue] = useState();
    const [forTrasnferWallet, setForTrasnferWallet] = useState();
    const [playerModalOpen, setPlayerWalletModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const openModal = () => {
      setPlayerWalletModalOpen(true);
    };

    const closeModal = () => {
      if (!loading) {
        setPlayerWalletModalOpen(false);
      }
    };

    if (openTransfer && !user.wallets) return <AddWallet Address={account} setDefault={setDefaultWallet} />;

    return (
      <Dialog
        open={openTransfer}
        onClose={() => (!loading ? setOpenTransfer(false) : null)}
        PaperProps={{ style: { background: "rgba(0,0,0,0.9)", padding: "1rem", borderRadius: "15px" } }}
        BackdropProps={{ style: { backdropFilter: "blur(5px)" } }}
      >
        <h1 style={{ textAlign: "center" }}>{text.wallet_transfer[3]}</h1>
        <div
          style={{
            width: "450px",
            backdropFilter: "blur(15px)",
            background: "rgba(0,0,0,0.4)",
            zIndex: "100",
            borderRadius: "10px",
            zIndex: "300",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <button
              className="default-input"
              style={{
                padding: "10px",
                fontSize: "1rem",
                textAlign: "center",
                color: "var(--pink)",
                marginBottom: "10px",
              }}
              onClick={openModal}
            >
              {text.wallet_transfer[4]}
            </button>
            <input
              className="default-input"
              value={forTrasnferWallet}
              style={{
                padding: "10px",
                fontSize: "0.9rem",
                textAlign: "center",
                color: "var(--pink)",
                border: "none",
                borderBottom: "1px solid rgba(255,30,255,0.5)",
                borderRadius: "0",
                width: "95%",
              }}
              placeholder={text.wallet_transfer[0]}
              onChange={(e) => {
                setForTrasnferWallet(e.target.value);
              }}
            />
            <div
              style={{ display: "flex", width: "95%", alignItems: "center", borderBottom: "0.5px solid rgba(200,0,200,0.5)", height: "50px", margin: "2rem 0" }}
            >
              <input
                placeholder={text.wallet_transfer[1]}
                className="default-input"
                style={{
                  fontSize: "1rem",
                  textAlign: "right",
                  border: "none",
                  borderRadius: "0px",
                  width: "95%",
                }}
                value={transferValue}
                onChange={(e) => {
                  setTransferValue(e.target.value);
                }}
              />
              <p style={{ color: "var(--pink)" }}>EQ9</p>
            </div>
            <button
              className="default-input"
              style={{ background: "transparent" }}
              onClick={async () => {
                if (!loading) {
                  setLoading(true);
                  const transfer = await getTransfer(forTrasnferWallet, transferValue);
                  if (transfer) {
                    setPlayerWalletModalOpen(false);
                    setLoading(false);
                    setOpenTransfer(false);
                  }
                }
              }}
            >
              {!loading ? text.buttons[1] : <CircularProgress />}
            </button>
          </div>
        </div>
        <PlayerModal open={playerModalOpen} setOpen={closeModal} setPlayerWallet={setForTrasnferWallet} />
      </Dialog>
    );
  }

  const handleCloseGuide = () => {
    if (!loading) {
      setOpenGuide(false);
    } else {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "CONCLUA OS PASSOS PARA CONTINUAR" : "FINISH THE STEPS TO CONTINUE");
    }
  };

  const getWithdraw = async (value) => {
    const withdraw = await api.post(`/withdraws/request`, { amount: value });
    return withdraw;
  };

  const pageTransition = { type: "tween", easy: "circInOut", duration: 0.4 };
  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="walletPage">
      <div className="walletContainerLeft">
        <div className="NavBarWallet">
          <div className="coinsContainer">
            <i class="fas fa-coins fa-3x"></i>
            <div className="infoWallet">
              <div className="saldoWallet">{text.balance[0]}</div>
              <div className="coinsWallet">
                {/* {user.wallet.value} EQ9 */}
                {eq9} EQ9
              </div>
            </div>
            <i
              className="fas fa-sync-alt fa-2x"
              style={{ color: "var(--pink)", padding: "1rem", cursor: "pointer", transform: `rotate(${attBalance}deg)`, transition: "transform ease 0.6s" }}
              onClick={() => {
                setEq9(0);
                getBalanceOf();
                setAttBalance(attBalance === 180 ? 0 : 180);
                getTransactions(0);
              }}
            />
          </div>
          <div className="walletBtnContainer" style={{ minWidth: "500px" }}>
            {status === "connected" && user.wallets?.default?.address ? (
              <p style={{ color: "var(--pink)", padding: "20px" }}>{user.wallets?.default?.address}</p>
            ) : !user.wallets?.default?.address ? (
              <button
                className="walletNavBtn"
                onClick={() => {
                  setOpenGuide(true);
                }}
                style={{ backgroundColor: "transparent", fontSize: "1.2rem" }}
              >
                {text.buttons[5]}
              </button>
            ) : null}
            <button
              className="walletNavBtn"
              onClick={() => {
                setModalBuy(true);
              }}
              style={{ fontSize: "1.2rem" }}
            >
              {text.buttons[0]}
              <Suggestion
                value={lang === "pt" ? "A EQ9 será depositada na sua conta na EqualsSport." : "Your EQ9 will be deposited on your account on EqualsSport"}
              />
            </button>
            <button
              className="walletNavBtn"
              style={{ fontSize: "1.2rem" }}
              onClick={() => {
                const StringAccount = account;
                const defaultStringAccount = user.wallets.default?.address;
                if (StringAccount != defaultStringAccount) {
                  return (
                    setAlert(true),
                    setAlertMsg(
                      lang === "pt"
                        ? "Sua carteira é diferente da carteira registrada na EqualsSport"
                        : "Your wallet metamask wallet is different than your EqualsSport default wallet"
                    ),
                    setSeverity("error")
                  );
                }
                setModalDeposit(true);
              }}
            >
              {lang === "pt" ? "DEPOSITAR NA PLATAFORMA" : "DEPOSIT ON PLATFORM"}
              <Suggestion
                value={
                  lang === "pt" ? "Você pode depositar EQ9 direto de sua carteira da Metamask." : "Here, you can deposit your EQ9 from your Metamask Wallet"
                }
              />
            </button>

            <button
              className="walletNavBtn"
              onClick={() => {
                setModalWithdraw(true);
              }}
              style={{ fontSize: "1.2rem" }}
            >
              {lang === "pt" ? "RETIRAR EQ9" : "WITHDRAW EQ9"}
              <Suggestion value={lang === "pt" ? "A EQ9 será depositada na sua carteira da Metamask." : "Your EQ9 will be deposited on your Metamask wallet"} />
            </button>
            <button
              className="walletNavBtn"
              onClick={() => {
                setOpenTransfer(!openTransfer);
              }}
              style={{ fontSize: "1.2rem" }}
            >
              {text.buttons[1]}
              <Suggestion
                value={
                  lang === "pt"
                    ? "A EQ9 será transferida da carteira Metamask, se quiser utilizar suas moedas da plataforma faça uma retirada antes."
                    : "Your transfer will be from your Metamask wallet, if you want to transfer from your balance on platform, make a withdraw before"
                }
              />
            </button>
            <button
              className="walletNavBtn"
              onClick={() => {
                setOpenGuide(true);
              }}
              style={{ backgroundColor: "transparent", fontSize: "1.2rem" }}
            >
              {lang == "pt" ? "RECONECTAR HARMONY" : "RECONNECT HARMONY"}
              <Suggestion
                value={
                  lang === "pt"
                    ? "Se precisar, você pode reconectar a rede da harmony e adicionar a Token EQ9 a sua carteira da Metamask"
                    : "If you need, you can reconnect the harmony network here and add your EQ9 asset to your wallet."
                }
              />
            </button>
          </div>
        </div>
      </div>
      <div className="walletContainerRight">
        <span className="walletContainerRight-title">{text.list[0]}</span>
        <div className="walletContainerRight-trc">
          <List data={historico} fetchAllTransactions={fetchAllTransactions} />
        </div>
      </div>
      <Stepper open={openGuide} setOpen={handleCloseGuide} text={textStepper} setLoading={setLoading} canClose={true} />
      <BuyEq9 open={modalBuy} setOpen={setModalBuy} />
      <WithdrawEq9 open={modalWithdraw} setOpen={setModalWithdraw} getWithdraw={getWithdraw} />
      <DepositEq9 open={modalDeposit} setOpen={setModalDeposit} getDeposit={getDeposit} setConfirmDepositModal={setConfirmDepositModal} />
      <TransferToken />
      <ConfirmDeposit open={confirmDepositModal} handleClose={() => setConfirmDepositModal(false)} lang={lang} />
    </motion.div>
  );
}

export default Index;
