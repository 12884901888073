import { useEffect, useState } from "react";
import { useStore } from "../../context/storeContext";
import ProfileHalfPage from "../../pages/profileHalfPage/";
import List from "../../components/list/List";
import { motion } from "framer-motion";
import LoadingPage from "../../components/pageloading/pageloading";
import { Link } from "react-router-dom";
import AddDeck from "../../components/cardInport";
import api from "../../services/api";
import language from "../../assets/language/text";
import sound from "../../assets/sounds";
import { useLocalDB } from "../../context/LocalDB";
import BtnContinuo from "../../components/buttons/BtnContinuo";
import Profile from "../../components/profile/Profile";

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};
const pageTransition = { type: "tween", easy: "anticipate", duration: 0.4 };

function Player() {
  const [userTournaments, setUserTournaments] = useState([]);
  const { setUserLocalData } = useLocalDB();
  const { user, setUser } = useStore();

  const fetchUser = async () => {
    const fetch = await api.get(`users/${user._id}`).catch(console.log);
    return setUser(fetch.data);
  };

  useEffect(() => {
    const getchTournament = async () => {
      const fetchTournaments = await api
        .get(`/tournaments/find-by-user/${user._id}`)
        .catch(console.log);
      setUserTournaments(fetchTournaments.data);
    };
    setUserLocalData({ access: 1 });
    if (!user) {
      fetchUser();
    }
    getchTournament();
  }, []);

  return (
    <motion.div
      exit="out"
      animate="in"
      initial="initial"
      variants={pageVariants}
      transition={pageTransition}
      className="page-player"
    >
      <div className="page-player-profile-mobile">
        <Profile />
      </div>
      <div className="page-player-list-mobile">
        <List evento={userTournaments} />
      </div>
    </motion.div>
  );
}

export default Player;
