import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useLocalDB } from "../../context/LocalDB";
import "./alert.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertMsg() {
  const { alert, setAlert, alertMsg, severity } = useLocalDB();

  const color = (color) => {
    if (color === "success") {
      return "rgb(0, 180,160)";
    } else {
      return "rgb(197, 20, 200)";
    }
  };

  return (
    <div>
      <Snackbar className="topCenter" open={alert} autoHideDuration={3000} onClose={() => setAlert(false)}>
        <Alert className="alert" onClose={() => setAlert(false)} style={{ background: color(severity) }} severity={severity}>
          <h1>{alertMsg}</h1>
        </Alert>
      </Snackbar>
    </div>
  );
}
