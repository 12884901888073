import React, { useState, useEffect } from "react";
import { useLocalDB } from "../../context/LocalDB";
import language from "../../assets/language/text";
import sounds from "../../assets/sounds";
import images from "../../assets/img";
import RankingProfile from "./rankingProfile";
import ModalStake from "./modalStake/stake";
import ModalUnstake from "./modalStake/unstake";
import { useStore } from "../../context/storeContext";
import api from "../../services/api";
import NumberFromWei from "../../utils/fromWei";
import stakeService from "../../services/stakeService";
import Pop from "../PopOver";
import { CircularProgress } from "@material-ui/core";

const slide = sounds.slideCard;
const click = sounds.btn;
const coin2 = sounds.coin2;
const unstake = sounds.unstake;

function Index({ col, data, getStakedPlayers, sx, fetchAllUser, searchStakePlayers, modal }) {
  const [dataTable, setDataTable] = useState([]);
  const [column, setColumn] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [modalPlayerProfile, setModalPlayerProfile] = useState(false);
  const [player, setPlayer] = useState(false);
  const { stakersTable, lang, setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const [unstakeconfirm, setUnstakeConfirm] = useState(false);
  const [valueModal, setValueModal] = useState(false);
  const { stakeOnPlayer, unstakeOnPlayer } = stakeService;
  const { user, setUser } = useStore();
  const [timer, setTimer] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(30);
  const text = language[lang].table;
  const textModal = language[lang].rankingProfile;

  const [loading, setLoading] = useState(false);

  // const fetchUser = async () => {
  //   const getUser = await api.get(`users/${user._id}`).catch(console.log);
  //   setUser(getUser.data);
  // };

  useEffect(() => {
    setDataTable(data);
    setColumn(col);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => setDataTable(data), [data]);

  useEffect(
    () =>
      setTimeout(() => {
        searchDatateste();
        setTimer();
      }, timer),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timer]
  );

  const searchDatateste = (e) => {
    // e.preventDefault();
    // eslint-disable-next-line array-callback-return
    if (!timer) {
      fetchAllUser(searchValue, page);
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(0);
    if (searchStakePlayers) {
      searchStakePlayers(e.target.value);
    } else {
      setLoading(true);
      setSearchValue(e.target.value.toLowerCase());
      setTimer(800);
    }
  };

  const handleInfiniteScroll = (e) => {
    if (!searchStakePlayers) {
      if (e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight) {
        if (!loading) {
          fetchAllUser(searchValue, page + 1, size, true, setLoading);
          setLoading(true);
          e.target.scrollTop = e.target.scrollTop - 10;
          setTimeout(() => {
            setPage(page + 1);
          }, 400);
        }
      }
    }
  };

  const alternateStyle = (props) => {
    if (props % 2)
      return {
        backgroundColor: "rgba(0,0,0,0.25)",
      };
    else return { backgroundColor: "transparent" };
  };

  return (
    <div className="container-table-staker" style={sx ? sx : { position: "relative", height: "40vh", overflow: "hidden" }}>
      {/* <div style={{ position: "absolute", top: "2rem", right: "2rem", cursor: "pointer" }} onClick={handleAttStakers}>
        <i className="fas fa-sync-alt" style={{ color: "var(--pink)" }}></i>
      </div> */}
      <div className={`default-input-i ${sx ? null : "search-box"}`}>
        <input required id="search" autoComplete="off" placeholder={text[1]} onChange={handleSearch} />
        <label for="search">
          <i className="fas fa-search fa-lg"></i>
        </label>
        {/* <i for="search" class="fas fa-search fa-lg"></i> */}
      </div>
      <div className="table-staker-scroll" onScroll={handleInfiniteScroll} style={sx ? null : { height: "30vh" }}>
        <table className="table-staker">
          {column ? (
            column.map((item) => {
              return (
                <th
                  className="table-staker-header"
                  style={{
                    width: item.wd,
                    background: "black",
                  }}
                >
                  {item.col}
                </th>
              );
            })
          ) : (
            <th>{text[0]}</th>
          )}
          {dataTable.length > 0 ? (
            dataTable.map((item, index) => {
              return (
                <>
                  <tr
                    className="table-staker-items"
                    key={item.id}
                    style={alternateStyle(data.indexOf(item))}
                    onClick={() => {
                      // setAlert(true);
                      // setAlertMsg("Coming Soon!");
                      // setSeverity("error");
                      click.currentTime = 0;
                      click.play();
                    }}
                    onMouseEnter={() => {
                      slide.currentTime = 0;
                      slide.play();
                    }}
                    // onMouseOver={() => sound.cardPass()}
                  >
                    <td
                      className="table-staker-items-avatar"
                      onClick={() => {
                        setPlayer(item.user ? item.user : item);
                        setModalPlayerProfile(true);
                      }}
                    >
                      <div className="table-staker-items-avatar-flex">
                        {item.user ? (
                          item.user.img ? (
                            <img src={item.user.img} alt="player-img" />
                          ) : (
                            <img src={images.unknown} alt="player-img" />
                          )
                        ) : item.img ? (
                          <img src={item.img} alt="player-img" />
                        ) : (
                          <img src={images.unknown} alt="player-img" />
                        )}
                        <Pop cutValue={16}>{item.user ? item.user.name : item.name}</Pop>
                      </div>
                    </td>
                    <td
                      className="table-staker-items-nickname"
                      onClick={() => {
                        setPlayer(item.user ? item.user : item);
                        setModalPlayerProfile(true);
                      }}
                    >
                      <b className="gradient-txt">{item.user ? item.user.nickname : item.nickname}</b>
                    </td>
                    <td className="table-staker-items-staked">{item.amount && NumberFromWei(item.amount)}</td>
                    {item.user && <td className="table-staker-items-rewarded">{item.rewards ? NumberFromWei(item.rewards) : 0}</td>}
                    <td
                      style={{ zIndex: 1000 }}
                      onClick={() => {
                        setPlayer(item.user ? item.user : item);
                        setValueModal(true);
                      }}
                    >
                      <i className="far fa-hand-rock fa-lg" style={{ color: "var(--lightblue)", zIndex: "3000" }} />
                    </td>
                    {item.user && (
                      <td
                        onClick={() => {
                          setPlayer(item.user ? item.user : item);
                          setUnstakeConfirm(true);
                        }}
                      >
                        <i className="far fa-hand-paper fa-lg" style={{ color: "var(--pink)", zIndex: "3000" }} />
                      </td>
                    )}
                  </tr>
                  {loading && index === dataTable.length - 1 ? <CircularProgress /> : null}
                </>
              );
            })
          ) : (
            <tr>
              <td colspan="6" style={{ textAlign: "center", fontSize: "2rem", color: "var(--pink)" }}>
                {text[0]}
              </td>
            </tr>
          )}
        </table>
      </div>
      <ModalStake
        setValueModal={setValueModal}
        setLoading={setLoading}
        player={player}
        text={textModal}
        unstake={unstake}
        valueModal={valueModal}
        stakeOnPlayer={stakeOnPlayer}
        coin2={coin2}
        loading={loading}
        getStakedPlayers={getStakedPlayers}
      />
      <ModalUnstake
        setUnstakeConfirm={setUnstakeConfirm}
        unstakeOnPlayer={unstakeOnPlayer}
        player={player}
        text={textModal}
        coin2={coin2}
        unstake={unstake}
        fetchUser={fetchAllUser}
        unstakeconfirm={unstakeconfirm}
        loading={loading}
        setLoading={setLoading}
        getStakedPlayers={getStakedPlayers}
      />
      <RankingProfile
        modalPlayerProfile={modalPlayerProfile}
        setModalPlayerProfile={setModalPlayerProfile}
        player={player}
        getStakedPlayers={getStakedPlayers}
      />
    </div>
  );
}

export default Index;
