import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { useStore } from "../../context/storeContext";
import SwiperCore, { Keyboard, EffectCoverflow, Pagination, Navigation } from "swiper";
import sound from "../../assets/sounds/index";
import Banlist from "../../components/deck/options/banlist";
import { useLocalDB } from "../../context/LocalDB";
import VisualSuggestion from "../Suggestion/visualSuggestion";
import Card from "./Card";

SwiperCore.use([Keyboard, EffectCoverflow, Pagination, Navigation]);
const slideCard = sound.slideCard;

function Carousel({ data, addCard, format }) {
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  // const { user } = useStore();

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#2ac1d8",
          "--swiper-pagination-color": "#2ac1d8",
        }}
        keyboard={{ enabled: true }}
        effect={"coverflow"}
        centeredSlides={true}
        slidesPerView={"auto"}
        loop={false}
        coverflowEffect={{
          rotate: 5,
          stretch: 0,
          depth: 150,
          modifier: 1,
          slideShadows: true,
        }}
        navigation={true}
        className="swiper-cards"
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide key={`Slider${item}${index}`} className="swiper-cards-item">
              {item.card?.imageUrl ? (
                <img
                  key={index}
                  className="swipper-slide-img-deck"
                  src={item.card.imageUrl}
                  onClick={() => {
                    if (Banlist[format.name]?.banlist?.includes(item.card.name.trim())) {
                      setAlert(true);
                      setAlertMsg(
                        lang === "pt" ? `Esta carta esta banida desse formato (${format.name})` : `This card is banished for this format (${format.name})`
                      );
                      setSeverity();
                    } else if (Banlist[format.name]?.suspenso?.includes(item.card.name.trim())) {
                      setAlert(true);
                      setAlertMsg(
                        lang === "pt" ? `Esta carta esta suspensa deste formato (${format.name})` : `This card is suspended for this format (${format.name})`
                      );
                      setSeverity();
                    } else {
                      addCard(item);
                    }
                  }}
                  onMouseEnter={() => {
                    slideCard.currentTime = 0.04;
                    slideCard.play();
                  }}
                  alt="card-deck"
                />
              ) : (
                // <VisualSuggestion value={`${item.card.name}`}>
                <Card
                  item={item}
                  color="rgb(10,30,180)"
                  onClick={() => {
                    if (Banlist[format.name]?.banlist?.includes(item.card.name.trim())) {
                      setAlert(true);
                      setAlertMsg(
                        lang === "pt" ? `Esta carta esta banida desse formato (${format.name})` : `This card is banished for this format (${format.name})`
                      );
                      setSeverity();
                    } else if (Banlist[format.name]?.suspenso?.includes(item.card.name.trim())) {
                      setAlert(true);
                      setAlertMsg(
                        lang === "pt" ? `Esta carta esta suspensa deste formato (${format.name})` : `This card is suspended for this format (${format.name})`
                      );
                      setSeverity();
                    } else {
                      addCard(item);
                    }
                  }}
                />
                // </VisualSuggestion>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default Carousel;
