import { useState, useEffect, useRef } from "react";
import "./torneyAdm.scss";
import { useParams, useHistory } from "react-router-dom";
import LoadingPage from "../../components/pageloading/pageloading";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import api from "../../services/api";
import MessageDrawer from "./messageDrawer";
import { useSocket } from "../../context/webSocket";
import { setTimeout } from "timers";
import twoDecimals from "../../utils/twoDecimals";
import { Link } from "@material-ui/core";
import Pageloading from "../../components/pageloading/pageloading";
import sounds from "../../assets/sounds";
import Timer from "./timers/Timer";
import Pop from "../../components/PopOver";
import CommunityChat from "./messageCommunityDrawer";

function TourneyAdmin() {
  let { id } = useParams();
  const [data, setData] = useState();
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const [openChat, setOpenChat] = useState(false);
  const [matchId, setMatchId] = useState();
  const [player, setPlayer] = useState();
  const [tourney, setTourney] = useState();
  const [matches, setMatches] = useState([]);
  const [activeMatches, setActiveMatches] = useState([]);
  const [standings, setStandings] = useState([]);
  const { socket, socketUpdate } = useSocket();
  const { user, evo } = useStore();
  const chatInputRef = useRef();
  const history = useHistory();
  const [players, setPlayers] = useState([]);
  const [roundsBtn, setRoundsBtn] = useState(1);
  const [tourneyData, setTourneyData] = useState();
  const [roundFilter, setRoundFilter] = useState(0);
  const [notifications, setNotifications] = useState();
  const [canExtraTime, setCanExtraTime] = useState(false);
  const [extraTimeRunning, setExtraTimeRunning] = useState(false);
  const [openCommunityChat, setOpenCommunityChat] = useState(false);
  const timeForRound = 50;
  const extraTimer = 5;
  const timeForCheckIn = 3;
  const minPlayers = 4;

  const fetchData = async () => {
    const getTournament = await api.get(`tournaments/${id}`).catch(console.log);
    if (getTournament) {
      setData(getTournament.data);
    }
  };

  const getTourney = async () => {
    const fetchTourney = await api.get(`tourneys/${id}`).catch(console.log);
    if (fetchTourney) {
      setTourneyData(fetchTourney.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
      fetchPlayers();
      getTourney();
    }
  }, [id]);

  const SocketInitiate = () => {
    socket.off("tourneyData").on("tourneyData", setTourney);
    socket.off("exception").on("exception", (e) => {
      console.log(e);
    });
    socket.off(`notifications/${user._id}`).on(`notifications/${user._id}`, (e) => {
      setNotifications(e);
      sounds.msg2.play();
    });
    socket.emit("getNotifications");
    socket.emit("tourneyData", { tournamentId: id });
  };

  useEffect(() => {
    console.log("Reconectando os listeners do EVO.");
    SocketInitiate();
  }, [socketUpdate]);

  useEffect(() => {
    if (data?.status === "STARTED") {
      SocketInitiate();
    }
  }, [data]);

  useEffect(() => {
    if (tourney) {
      setStandings(tourney.lastStandings);
      setMatches(tourney.matches);
      setActiveMatches(tourney.activeMatches);
    }
  }, [tourney]);

  const fetchPlayers = async () => {
    if (id) {
      const getPlayers = await api.get(`tournaments/all-players/${id}`).catch(console.log);
      if (getPlayers) {
        setPlayers(getPlayers.data);
      }
    }
  };

  const handleDropPlayer = async (PLAYER) => {
    const confirm = await window.confirm(lang == "pt" ? `Você deseja remover o jogador?` : `Are you shure to remove the player?`);
    if (confirm) {
      const getPlayerDrop = await api.delete(`tournaments/drop/${id}`, { data: { playerId: PLAYER } }).catch(() => {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "NÃO FOI POSSÍVEL RETIRAR O PLAYER DO TORNEIO" : "WAS NOT POSSIBLE REMOVE PLAYER FROM TOURNAMENT");
        setSeverity("error");
      });
      if (getPlayerDrop) {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "PLAYER REMOVIDO COM SUCESSO" : "PLAYER REMOVED FROM TOURNAMENT");
        setSeverity("success");
        fetchData();
        fetchPlayers();
      }
    }
  };

  const Players = () => {
    if (!data) return <Pageloading />;
    return (
      <>
        {players &&
          players.map((item, index) => {
            const { player } = item;
            return (
              <tr key={player.nickname} style={{ minHeight: "70px" }}>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <button className="default-input" style={{ height: "20px", width: "100px" }} onClick={() => handleDropPlayer(player._id)}>
                    Drop
                  </button>
                </td>
                <td>
                  {player.img ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                      <img style={{ width: "40px", height: "40px", borderRadius: "50%" }} src={player.img} alt="imgSrc" />
                      <span>
                        <Pop cutValue={16}>{player.name}</Pop>
                      </span>
                    </div>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                      <div style={{ width: "40px", height: "40px", borderRadius: "50%", background: "grey" }} />
                      <span>
                        <Pop cutValue={16}>{player.name}</Pop>
                      </span>
                    </div>
                  )}
                </td>
                {/* <td></td> */}
                <td style={{ textAlign: "center" }}>
                  <Pop cutValue={10}>{player.nickname}</Pop>
                </td>
                <td style={{ textAlign: "center" }}>{data.game.typeOf === "ARENA" && player.game.magic.arenaAlias}</td>
                <td style={{ textAlign: "center" }}>
                  <Link href={`${process.env.REACT_APP_ENDPOINT}editDeck/${data?.players[index]?.deck?._id}`} target="blank">
                    Deck
                  </Link>
                </td>
              </tr>
            );
          })}
      </>
    );
  };

  const handleOpenCommunityChat = () => {
    setOpenCommunityChat(true);
  };

  const handleCreateEvent = async () => {
    const confirm = await window.confirm(lang == "pt" ? `Você deseja iniciar o torneio?` : `Are you shure to initiate tournament?`);
    if (confirm) {
      if (data.players.length >= minPlayers) {
        const createEvent = await api.post(`tourneys/${id}`).catch((error) => {
          if (error.response.data) {
            const message = error.response.data.message ? error.response.data.message : "error";
            setAlert(true);
            setAlertMsg(message);
            setSeverity("error");
          }
        });
        if (createEvent) {
          fetchData();
          getTourney();
          fetchPlayers();
          SocketInitiate();
          setAlert(true);
          setAlertMsg("Torneio Aberto");
          setSeverity("success");
          // window.location.reload();
        }
      } else {
        setAlert(true);
        setAlertMsg(`Torneio precisa de ${minPlayers} jogadores para continuar.`);
        setSeverity("error");
      }
    }
  };

  const handleFinishEvent = async () => {
    const finishEvent = await api.post(`tourneys/end-tournament/${id}`).catch(console.log);
    if (finishEvent) {
      setAlert(true);
      setAlertMsg("TOURNAMENT FINISHED!");
      setSeverity("success");
      setTimeout(() => {
        history.push(`/badges/${id}`);
      }, 1000);
    }
  };

  const handleNextRound = async (lastRound) => {
    if (!lastRound) {
      const confirm = await window.confirm(lang == "pt" ? `Você deseja iniciar o próximo round?` : `Are you shure to initiate the next round?`);
      if (confirm) {
        const nextround = await api.post(`tourneys/next-round/${id}`).catch((error) => {
          setAlert(true);
          setAlertMsg("NÃO FOI POSSÍVEL DAR INICIO A PRÓXIMA RODADA, SUBMETA TODOS OS RESULTADOS PARA CONTINUAR");
          setSeverity("error");
        });
        if (nextround) {
          setAlert(true);
          setAlertMsg("Next Round");
          setSeverity("success");
          getTourney();
          setExtraTimeRunning(false);
          // window.location.reload();
        }
        return nextround;
      }
    } else {
      const confirm = await window.confirm(lang == "pt" ? `Você deseja finalizar este torneio?` : `Are you shure to finish this tournament`);
      if (confirm) {
        handleFinishEvent();
      }
    }
  };

  const handleSubmitMessage = (e, playerid) => {
    e.preventDefault();
    if (chatInputRef.current?.value) {
      socket.emit("talkTo", {
        userId: playerid,
        message: { author: user._id, body: chatInputRef.current?.value, to: playerid },
      });
      chatInputRef.current.value = "";
    }
  };

  const handleExtraTime = async () => {
    setExtraTimeRunning(true);
    const getExtraTime = await api.put(`/tourneys/extra-time/${id}`, { minutes: 5, seconds: 0 }).catch(() => {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "Erro ao adicionar o tempo" : "error on add time");
      setSeverity("error");
    });
    if (getExtraTime) {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "TEMPO EXTRA ADICIONADO" : "EXTRA TIME SUCCESSFULLY ADDED");
      setSeverity("success");
    }
  };

  function Card({ classStyle, children, header, description, styled }) {
    return (
      <div className={`t-adm-console ${classStyle}`} style={styled}>
        <div className="console-description">
          <h1>{header}</h1>
          <p>{description}</p>
        </div>
        <div className="console-body">{children}</div>
      </div>
    );
  }

  const anteriorRounds = (round) => {
    let roundArray = [];
    for (let i = 1; i < round; i++) {
      roundArray.push("vazia");
    }
    return roundArray.map((item, index) => {
      if (index + 1 < round) {
        return (
          <button
            className="default-input"
            onClick={() => {
              setRoundsBtn(2);
              setRoundFilter(index + 1);
            }}
            style={{ fontFamily: "gilroy-bold", fontSize: "1.2rem", width: "350px", height: "40px" }}
          >
            Round {index + 1}
          </button>
        );
      }
    });
  };

  const handleClose = () => {
    setOpenChat(false);
  };

  if (!data) return <LoadingPage />;
  return (
    <section>
      <div className="t-adm-page" style={{ zIndex: 5000 }}>
        <Card classStyle="eventControls" header={data.name}>
          <div className="btn-controls">
            {data && data.status === "WAITING" ? (
              <button onClick={handleCreateEvent}>
                <i className="fas fa-play btn-controls-icon fa-2x"></i>
                {lang === "pt" ? "INICIAR EVENTO" : "START EVENT"}
              </button>
            ) : tourney?.currentRound < tourney?.numberOfRounds ? (
              <button onClick={() => handleNextRound(false)}>
                <i className="fas fa-forward btn-controls-icon fa-2x"></i>
                {lang === "pt" ? "PRÓXIMO ROUND" : "NEXT ROUND"}
              </button>
            ) : (
              <button
                onClick={() => {
                  handleNextRound(true);
                }}
              >
                <i className="fas fa-times btn-controls-icon fa-2x"></i>
                {lang === "pt" ? "FINALIZAR EVENTO" : "FINISH EVENT"}
              </button>
            )}
            <span className="btn-separate" />
            {!extraTimeRunning && canExtraTime ? (
              <>
                <span className="btn-separate" />
                <button onClick={handleExtraTime}>
                  <i className="fas fa-forward btn-controls-icon fa-2x"></i>
                  {lang === "pt" ? "TEMPO EXTRA" : "EXTRA TIME"}
                </button>
              </>
            ) : null}
            {data?.status === "STARTED" && (
              <button onClick={handleOpenCommunityChat}>
                <i className="fas fa-comments btn-controls-icon fa-2x"></i>
                {lang === "pt" ? "CHAT" : "CHAT"}
              </button>
            )}
          </div>
          <div className="active-control">
            <div>
              <h1>ROUND</h1>
              <h1>{tourneyData?.currentRound}</h1>
            </div>
            <div>
              <h1>PLAYERS</h1>
              <h1>{data?.players?.length}</h1>
            </div>
          </div>
        </Card>
        <Card classStyle="flex1" header="STANDINGS" styled={{ maxHeight: "400px", overflow: "auto", padding: "10px" }}>
          <table style={{ width: "100%", maxHeight: "100px", overflow: "auto" }}>
            <thead>
              {standings.length > 0 ? (
                <>
                  <tr style={{ background: "rgba(0,0,0,0.4)", height: "10px" }}>
                    <td style={{ textAlign: "center" }}>RANK</td>
                    <td style={{ textAlign: "center" }}>PLAYER</td>
                    <td style={{ textAlign: "center" }}>POINTS</td>
                    <td style={{ textAlign: "center" }}>OMW|PGW|OGW</td>
                    <td style={{ textAlign: "center" }}>DECK</td>
                  </tr>
                </>
              ) : (
                <>
                  <tr style={{ background: "rgba(0,0,0,0.4)", height: "10px" }}>
                    <td style={{ textAlign: "center" }}>DROP</td>
                    <td style={{ textAlign: "center" }}>PLAYER</td>
                    <td style={{ textAlign: "center" }}>NICKNAME</td>
                    <td style={{ textAlign: "center" }}>GAME ALIAS</td>
                    <td style={{ textAlign: "center" }}>DECK</td>
                  </tr>
                </>
              )}
            </thead>
            <tbody>
              {standings.length > 0 ? (
                standings.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{index + 1} º</td>
                      <td style={{ textAlign: "center" }}>
                        <Pop cutValue={20} sx={{ textDecoration: !item.active && "line-through", color: !item.active && "grey" }}>
                          {item.name ? item.name : item.nickname}
                        </Pop>
                      </td>
                      <td style={{ textAlign: "center" }}>{item.tiebreakers.matchPoints}</td>
                      <td style={{ textAlign: "center" }}>
                        {twoDecimals(item.tiebreakers.omwp * 100) > 100
                          ? 100
                          : twoDecimals(item.tiebreakers.omwp * 100) > 0
                          ? twoDecimals(item.tiebreakers.omwp * 100)
                          : 0}
                        % |{" "}
                        {twoDecimals(item.tiebreakers.gwp * 100) > 100
                          ? 100
                          : twoDecimals(item.tiebreakers.gwp * 100) > 0
                          ? twoDecimals(item.tiebreakers.gwp * 100)
                          : 0}
                        % |{" "}
                        {twoDecimals(item.tiebreakers.ogwp * 100) > 100
                          ? 100
                          : twoDecimals(item.tiebreakers.ogwp * 100) > 0
                          ? twoDecimals(item.tiebreakers.ogwp * 100)
                          : 0}
                        %
                      </td>
                      {item?.etc?.deckId ? (
                        <td style={{ textAlign: "center" }}>
                          <Link href={`${process.env.REACT_APP_ENDPOINT}editDeck/${item.etc.deckId}`} target="blank">
                            Deck
                          </Link>
                        </td>
                      ) : null}
                    </tr>
                  );
                })
              ) : (
                <Players id={data._id} />
              )}
            </tbody>
          </table>
        </Card>
      </div>
      {activeMatches?.length > 0 && (
        <div
          className="t-adm-page"
          style={{
            alignItems: "center",
            background: "rgba(50,0,70)",
            height: "50%",
            flexDirection: "column",
            border: "rgba(50,0,70) solid 1rem",
            borderRadius: "15px",
          }}
        >
          <label
            style={{
              fontFamily: "gilroy-bold",
              fontSize: "1.5rem",
              height: "30px",
              marginTop: "-10px",
              width: "100%",
              textAlign: "center",
              background: "rgba(50,0,70)",
            }}
          >
            Ativas
          </label>
          <table style={{ width: "100%", background: "rgba(100,0,130,0.8)", borderRadius: "15px" }}>
            <thead>
              <tr>
                <td style={{ width: "5%" }}>ROUND</td>
                <td style={{ width: "5%" }}>TABLE</td>
                <td style={{ width: "5%" }}>STATUS</td>
                <td style={{ width: "15%" }}></td>
                <td style={{ width: "15%", textAlign: "right" }}>PLAYER1</td>
                <td style={{ width: "10%" }}></td>
                <td style={{ width: "10%" }}></td>
                <td style={{ width: "10%" }}></td>
                <td style={{ width: "15%" }}>PLAYER2</td>
                <td style={{ width: "15%" }}></td>
                <td style={{ width: "5%" }}>EDITAR</td>
              </tr>
            </thead>
            <tbody>
              {activeMatches.map((item, index) => {
                // if (item.round === index + 1) {
                return (
                  <CardMatches
                    key={item.id}
                    player1={item.playerOne ? item.playerOne : "Bye"}
                    player2={item.playerTwo ? item.playerTwo : "Bye"}
                    onClick={() => {
                      setMatchId(item.id);
                    }}
                    setChatOpen={setOpenChat}
                    setPlayer={setPlayer}
                    setMatchId={setMatchId}
                    roundNumber={item.round}
                    match={item.matchNumber}
                    round={item}
                    id={id}
                    notifications={notifications}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {matches?.length - activeMatches.length > 0 && (
        <div className="t-adm-page" style={{ alignItems: "center", background: "rgba(50,0,70,0.8)", height: "50%", flexDirection: "column", padding: "1rem" }}>
          <div style={{ display: "flex", maxWidth: "100%", overflow: "auto" }}>
            {anteriorRounds(tourneyData?.currentRound)}
            <button
              className="default-input"
              onClick={() => setRoundsBtn(1)}
              style={{ fontFamily: "gilroy-bold", fontSize: "1.5rem", width: "150px", height: "40px" }}
            >
              Finalizadas
            </button>
          </div>
          <table style={{ width: "95vw" }}>
            <thead>
              <tr>
                <td style={{ width: "5%" }}>ROUND</td>
                <td style={{ width: "5%" }}>TABLE</td>
                <td style={{ width: "5%" }}>STATUS</td>
                <td style={{ width: "15%" }}></td>
                <td style={{ width: "15%", textAlign: "right" }}>PLAYER1</td>
                <td style={{ width: "10%" }}></td>
                <td style={{ width: "10%" }}></td>
                <td style={{ width: "10%" }}></td>
                <td style={{ width: "15%" }}>PLAYER2</td>
                <td style={{ width: "15%" }}></td>
                <td style={{ width: "5%" }}>EDITAR</td>
              </tr>
            </thead>
            <tbody>
              {matches.map((item, index) => {
                if (roundsBtn === 1 ? !item.active && item.round == tourneyData?.currentRound : !item.active && item.round === roundFilter) {
                  return (
                    <CardMatches
                      key={item.id}
                      player1={item.playerOne ? item.playerOne : "Bye"}
                      player2={item.playerTwo ? item.playerTwo : "Bye"}
                      onClick={() => {
                        setMatchId(item.id);
                      }}
                      setChatOpen={setOpenChat}
                      setPlayer={setPlayer}
                      setMatchId={setMatchId}
                      roundNumber={item.round}
                      match={item.matchNumber}
                      round={item}
                      id={id}
                      roundsBtn={roundsBtn}
                      notifications={notifications}
                    />
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      )}
      {openChat && (
        <div className="t-adm-page" style={{ justifyContent: "center" }}>
          <MessageDrawer
            tournamentId={id}
            chatInputRef={chatInputRef}
            handleSubmitMsg={handleSubmitMessage}
            user={evo}
            player={player}
            open={openChat}
            handleClose={handleClose}
          />
        </div>
      )}
      <CommunityChat tournamentId={id} user={evo} open={openCommunityChat} handleClose={() => setOpenCommunityChat(false)} />
      {notifications && <MessageBoxes notifications={notifications} standings={standings} setPlayer={setPlayer} setChatOpen={setOpenChat} />}
      <Timer
        id={id}
        timeForRound={timeForRound}
        tourneyData={tourney}
        extraTimer={extraTimer}
        timeForCheckIn={timeForCheckIn}
        canExtraTime={canExtraTime}
        setCanExtraTime={setCanExtraTime}
        extraTimeRunning={extraTimeRunning}
      />
      {/* {CheckInTimeLeft()}
      {ExtraTimeLeft()} */}
    </section>
  );
}

function CardMatches({ player1, player2, roundNumber, match, setPlayer, setChatOpen, round, id, setMatchId, onClick, roundsBtn, notifications }) {
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const [resultadoAnterior, setResultadoAnterior] = useState({ p1wRes: 0, p2wRes: 0, draws: 0 });
  const [edit, setEdit] = useState(false);
  const valueP1 = useRef();
  const valueP2 = useRef();
  const valueDraws = useRef();
  const bestOf = 3;
  const { result } = round;

  const handleEdit = async (p1wRes, p2wRes, draws) => {
    if (!edit) {
      setResultadoAnterior({ p1wRes: p1wRes ? +p1wRes : 0, p2wRes: p2wRes ? +p2wRes : 0, draws: +draws ? draws : 0 });
    } else {
      if (+p1wRes + +p2wRes + +draws <= bestOf && +p1wRes < bestOf && +p2wRes < bestOf && +draws < bestOf) {
        const confirm = await window.confirm(lang == "pt" ? `Você deseja confirmar edição?` : `Are you shure to confirm edited result`);
        if (confirm) {
          if (+resultadoAnterior.p1wRes == +p1wRes && +resultadoAnterior.p2wRes == +p2wRes && +resultadoAnterior.draws == +draws) {
            setResultadoAnterior({ p1wRes: 0, p2wRes: 0, draws: 0 });
          } else {
            const getNewResults = async () => {
              const setResults = await api
                .put(`tourneys/overwrite-result/${id}`, {
                  matchNumber: round.matchNumber,
                  playerOneWins: +p1wRes,
                  playerTwoWins: +p2wRes,
                  draws: +draws,
                })
                .catch(() => {
                  setAlert(true);
                  setAlertMsg("Partida com Bye!");
                  setSeverity("error");
                });
              if (setResults) {
                setAlert(true);
                setAlertMsg("Resultado atualizado com sucesso!");
                setSeverity("success");
              }
            };
            getNewResults();
            setResultadoAnterior({ p1wRes: 0, p2wRes: 0, draws: 0 });
          }
        }
      } else {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "O resultado é inválido" : "Invalid result");
        setSeverity("error");
      }
    }
  };
  return (
    <tr onClick={onClick} key={match + round.id}>
      <td style={{ width: "5%", textAlign: "center" }}>{roundNumber}</td>
      <td style={{ width: "5%", textAlign: "center" }}>{match}</td>
      <td style={{ textAlign: "center" }}>
        <div
          style={{
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            background: round.active ? "red" : "green",
          }}
        />
      </td>
      <td style={{ textAlign: "right" }}>{player1 && player1.name}</td>
      <td style={{ textAlign: "right", display: "flex", justifyContent: "flex-end" }}>
        <h1
          onClick={() => {
            setPlayer({ side: "playerOne", player: player1 });
            setMatchId(round.id);
            setChatOpen(true);
          }}
          style={{ textDecoration: player1.active === false ? "line-through" : "" }}
        >
          {player1 && player1.nickname}
        </h1>
        {notifications && player2
          ? notifications[player1.id] && <div style={{ maxWidth: "10px", maxHeight: "10px", borderRadius: "50%", padding: "5px", background: "red" }} />
          : null}
      </td>
      <td style={{ textAlign: "center" }}>
        {edit ? (
          <input
            className="default-input"
            type="number"
            style={{ height: "35px", width: "50px", textAlign: "center" }}
            defaultValue={result ? result.p1 : 0}
            ref={valueP1}
          />
        ) : (
          <h1>{result ? result.p1 : 0}</h1>
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        {edit ? (
          <input
            style={{ width: "50px", height: "40px", textAlign: "center" }}
            type="number"
            ref={valueDraws}
            placeholder="Empate"
            defaultValue={result ? result.d : 0}
            className="default-input"
          />
        ) : (
          <h1 style={{ fontSize: "2rem" }}>vs</h1>
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        {edit ? (
          <input
            className="default-input"
            type="number"
            style={{ height: "35px", width: "50px", textAlign: "center" }}
            defaultValue={result ? result.p2 : 0}
            ref={valueP2}
          />
        ) : (
          <h1>{result ? result.p2 : 0}</h1>
        )}
      </td>
      <td style={{ display: "flex" }}>
        {notifications && player2
          ? notifications[player2.id] && <div style={{ maxWidth: "10px", maxHeight: "10px", borderRadius: "50%", padding: "5px", background: "red" }} />
          : null}
        <h1
          onClick={() => {
            setPlayer({ side: "playerTwo", player: player2 });
            setMatchId(round.id);
            setChatOpen(true);
          }}
          style={{ textDecoration: player2.active === false ? "line-through" : "" }}
        >
          {player2 && player2.nickname ? player2.nickname : "BYE"}
        </h1>
      </td>
      <td style={{ textAlign: "left" }}>{player2 && player2.name ? player2.name : null}</td>
      <td>
        <div style={{ display: "flex", justifyContent: "center", width: "100px" }}>
          {roundsBtn === 1 && !round.active ? (
            <input
              type="checkbox"
              className="default-input"
              style={{ width: "30px", height: "30px" }}
              onChange={(e) => {
                setEdit(e.target.checked);
                handleEdit(+valueP1.current?.value, +valueP2.current?.value, +valueDraws.current?.value);
              }}
              defaultChecked={false}
            />
          ) : null}
          {round.active && (
            <input
              type="checkbox"
              className="default-input"
              style={{ width: "30px", height: "30px" }}
              onChange={(e) => {
                setEdit(e.target.checked);
                handleEdit(+valueP1.current?.value, +valueP2.current?.value, +valueDraws.current?.value);
              }}
              defaultChecked={false}
            />
          )}
        </div>
      </td>
    </tr>
  );
}

const MessageBoxes = ({ notifications, standings, setPlayer, setChatOpen }) => {
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    setBoxes(notifications);
  }, [notifications]);

  const getBoxes = (item) => {
    if (standings) {
      return standings.map((p) => {
        if (p.id === item) {
          return (
            <div
              key={p.id}
              style={{ position: "relative", cursor: "pointer" }}
              onClick={() => {
                setPlayer({ side: "playerTwo", player: p });
                setChatOpen(true);
              }}
            >
              {notifications[p.id] && (
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    background: "red",
                    border: "2px solid black",
                  }}
                />
              )}
              <div
                className="default-input"
                style={{
                  background: "black",
                  width: "140px",
                  height: "40px",
                  margin: "10px",
                  textAlign: "center",
                  fontFamily: "gilroy-bold",
                  fontSize: "1em",
                }}
              >
                <Pop cutValue={9}>{p.name ? p.name : p.nickname}</Pop>
              </div>
            </div>
          );
        }
      });
    }
  };

  return (
    <div style={{ position: "fixed", bottom: 0, right: 0, display: "flex" }}>
      {Object.keys(boxes).map((item) => {
        return getBoxes(item);
      })}
    </div>
  );
};

export default TourneyAdmin;
