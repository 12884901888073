export const GetDate = (date, lang) => {
  if (date) {
    const getDateString = date.split("T", 1);
    const getRealDateSplited = getDateString[0].split("-");
    const getDateServer = new Date(getRealDateSplited[1] + "/" + getRealDateSplited[2] + "/" + getRealDateSplited[0]);
    const getServer = process.env.REACT_APP_APP_ENV;
    return lang === "pt"
      ? getServer === "mainet"
        ? getDateServer.toLocaleDateString("pt-br")
        : getDateServer.toLocaleDateString("pt-br")
      : getServer === "mainet"
      ? getDateServer.toLocaleDateString("en-us")
      : getDateServer.toLocaleDateString("en-us");
  }
};
