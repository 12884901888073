const deckTypes = {
  monoW: ["w"],
  monoR: ["r"],
  monoU: ["u"],
  monoG: ["g"],
  monoB: ["b"],
  selesnya: ["w", "g"],
  borus: ["r", "w"],
  izzet: ["r", "u"],
  azorius: ["w", "u"],
  orzhov: ["w", "b"],
  gruul: ["r", "g"],
  rakdos: ["r", "b"],
  simic: ["g", "u"],
  golgari: ["g", "b"],
  dimir: ["u", "b"],
  bant: ["w", "g", "u"],
  esper: ["u", "w", "b"],
  grixis: ["b", "u", "r"],
  jund: ["r", "g", "b"],
  naya: ["g", "r", "w"],
  abzan: ["w", "b", "g"],
  jeskai: ["u", "r", "w"],
  sultai: ["b", "g", "u"],
  mardu: ["r", "w", "b"],
  temur: ["g", "u", "r"],
  yoreTiller: ["w", "u", "b", "r"],
  glintEye: ["u", "b", "r", "g"],
  duneBrood: ["w", "b", "r", "g"],
  inkTreader: ["w", "u", "r", "g"],
  witchMaw: ["w", "u", "b", "g"],
  fullCollor: ["w", "r", "g", "b", "u"],
  colorless: [],
};
export default deckTypes;
