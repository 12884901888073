import React from "react";
import { useParams } from "react-router-dom";

function Social({ social }) {
  const { id } = useParams();
  window.location.href = `${social}/${id}`;
  return null;
}

export default Social;
