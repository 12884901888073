import { Dialog } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";

function ConfirmDeposit({ open, handleClose, lang }) {
  const { setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const [res, setRes] = useState();
  const [loading, setLoading] = useState(false);
  const txId = useRef();

  const CloseModal = () => {
    setRes();
    handleClose();
  };
  const handleConfirm = async () => {
    if (txId.current?.value?.length > 0) {
      setLoading(true);
      const confirm = await api.post(`deposits/validate`, { transactionHash: txId.current.value }).catch(() => {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "Transação não pôde ser validada" : "Transaction cannot be validated");
        setSeverity("error");
        setLoading(false);
      });
      console.log(confirm);
      if (confirm) {
        setLoading(false);
        setAlert(true);
        setAlertMsg(lang === "pt" ? "concluído com sucesso" : "successfully completed");
        setSeverity("success");
        CloseModal();
        // setRes("teve uma resposta aqui");
      }
    } else {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "Submeta um ID para continuar" : "Submit a ID to continue");
      setSeverity("error");
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { padding: "5px", maxWidth: "500px", borderRadius: "15px", background: "none" } }}
      BackdropProps={{ style: { backdropFilter: "blur(10px)" } }}
    >
      <section
        style={{
          width: "450px",
          height: "250px",
          display: open ? "block" : "none",
          background: "rgba(0,0,0,0.7)",
          zIndex: "100",
          borderRadius: "10px",
          zIndex: "300",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: "350px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : res ? (
            <h1 style={{ margin: "10px", color: "var(--pink)" }}>{res}</h1>
          ) : (
            <>
              <h1 style={{ margin: "10px", color: "var(--pink)" }}>{lang === "pt" ? "CONFIRMAR DEPÓSITO" : "CONFIRM DEPOSIT"}</h1>
              <p style={{ margin: "10px" }}>{lang === "pt" ? "Cole aqui o id da transação da metamask" : "Paste your metamask transactional ID here"}</p>

              <input
                className="default-input"
                ref={txId}
                placeholder={lang === "pt" ? "Cole aqui o id da transação da metamask" : "Paste your metamask transactional ID here"}
                style={{ width: "400px", height: "50px", fontSize: "1em" }}
              />
            </>
          )}
        </div>
        <button
          className="default-input"
          style={{ background: "transparent", width: "85%", cursor: "pointer" }}
          onClick={() => {
            res ? CloseModal() : handleConfirm();
          }}
        >
          {res
            ? lang === "pt"
              ? "Fechar"
              : "close"
            : lang === "pt"
            ? loading
              ? "Validando..."
              : "Validar Transação"
            : loading
            ? "Validating..."
            : "Validate Transaction"}
        </button>
      </section>
    </Dialog>
  );
}

export default ConfirmDeposit;
