import React from "react";

function PlayerCard({ data, children }) {
  return (
    <>
      {data && (
        <div className="room-card-player">
          <div className="front">
            <div className="backCardIMG">
              {data.img && data.img.profile && (
                <img
                  style={{ borderRadius: "50%" }}
                  src={data.img.profile}
                  alt="profileImg"
                />
              )}
            </div>

            {/* <div className="details-avatar-front"></div> */}
            <div className="social-iconsCard">
              <a href="http://facebook.com.br/" target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="http://twitch.tv/" target="_blank">
                <i className="fab fa-twitch"></i>
              </a>
              <a href="http://youtube.com.br/" target="_blank">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="http://instagram.com.br/" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <div className="backContent">
              <h1
                className="gradientNickBack"
                style={{
                  marginTop: "-60px",
                  marginLeft: "-110px",
                  fontSize: "2.5rem",
                }}
              >
                {data.nickname.toUpperCase()}
              </h1>
              {/* <p className="textScore">{`${data.score.w}/${data.score.l}`}</p> */}
            </div>
            {/* <img src={MagicBack} alt='magicBack' /> */}
          </div>
          <div className="back">
            <div className="details">
              <div className="children">{children}</div>
              <div className="cardLogo" />
              {data.img && data.img.profile && (
                <img src={data.img.profile} alt="profileImg" />
              )}
              {/* <img src={Logo} alt='logo' /> */}
              <div className="textDetails">
                <h1 className="gradientNickFront">
                  {data.nickname.toUpperCase()}
                </h1>
                <p className="textType">Human player</p>
                <p className="textName">{data.name}</p>
                <p className="textDescription">
                  "Jogador disputando o titulo no campeonato!"
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PlayerCard;
