import images from "../../assets/img";
import { Link } from "@material-ui/core";
import { useStore } from "../../context/storeContext";
import { useWallet } from "../../context/walletContext";
import { useEffect } from "react";
import { useLocalDB } from "../../context/LocalDB";
import { calculateAge } from "../../utils/calculateAge";

function StakerProfile({ rewards, ClaimReward }) {
  const { user } = useStore();
  const { eq9 } = useWallet();
  const { setAlert, setAlertMsg, setSeverity } = useLocalDB();

  return (
    <div className="c-staker-profile">
      <div className="c-staker-profile-avatar">
        <div className="c-staker-profile-avatar-dropzone">
          <i class="fas fa-camera"></i>
        </div>
        <div className="c-staker-profile-avatar-photo">
          <img src={user.img} alt="Profile Photo" />
        </div>
        <img src={images.bordaAvatar3} alt="avatar border" />
      </div>
      <div className="c-staker-profile-infos">
        <p>
          {user.name} ( {calculateAge(user.birthDate)} )
        </p>
        <h1 className="gradient-txt">{user.nickname}</h1>
        <div className="c-staker-profile-infos-rewards">
          {/* <i
            className="fas fa-award"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (rewards > 0) {
                ClaimReward(true);
              } else {
                setAlert(true);
                setAlertMsg("Sem valor suficiente para dar Claim!");
                setSeverity("error");
              }
            }}
          >
            <p>{rewards > 0 ? rewards : 0} EQ9</p>
          </i> */}
          {/* <h3>My rewards: 1.390,23</h3> */}
          {/* <i class="fas fa-users"></i> */}
        </div>
        {/* <div style={{ marginLeft: "-2rem" }}>Claim Clicking Here</div> */}
        <div className="c-staker-profile-infos-social">
          {/* {!user.social && edit === true ? (
            <input className="default-input" defaultValue="www.twitch.tv/" />
          ) : ( */}
          {/* <Link>
            <i class="fab fa-twitch"></i>
          </Link> */}
          {/* )} */}
          {/* <Link>
            <i class="fab fa-youtube"></i>
          </Link>
          <Link>
            <i class="fab fa-instagram"></i>
          </Link>
          <Link style={{ marginLeft: "2.5vw" }}>
            <i class="fas fa-share-alt"></i>
          </Link> */}
        </div>
      </div>
    </div>
  );
}

export default StakerProfile;
