import { Drawer } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import { useSocket } from "../../context/webSocket";
import decode from "jwt-decode";
import api from "../../services/api";
import ProfilePlayer from "./Profile";
import BtnContinuo from "../../components/buttons/BtnContinuo";

function Chat({ tournamentId, chatInputRef, handleSubmitMsg, player, open, handleClose }) {
  const chatscrollref = useRef(null);
  const { user } = useStore();
  const [chatList, setChatList] = useState([]);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const { socket } = useSocket();

  useEffect(() => {
    if (open && player) {
      socket.off(`chat/${user._id}/${player.player.id}`).on(`chat/${user._id}/${player.player.id}`, (e) => {
        if (e?.messages) {
          setChatList(e.messages);
        } else {
          setChatList([]);
        }
      });
      socket.emit(`joinChatRoom`, { userId: player.player.id });
      socket.emit(`wipe`, { userId: player.player.id });
    } else {
      socket.off(`chat/${user._id}/${player.player.id}`);
    }
  }, [open, player]);

  useEffect(() => {
    if (chatList) {
      if (chatscrollref.current?.scrollHeight > chatscrollref.current?.offsetHeight) {
        chatscrollref.current.scrollTop = chatscrollref.current.scrollHeight - chatscrollref.current.offsetHeight;
      }
      socket.emit(`wipe`, { userId: player.player.id });
    }
  }, [chatList]);

  const handleDropPlayer = async () => {
    const confirm = await window.confirm(
      lang == "pt" ? `Você deseja retirar ${player.player.alias} deste torneio?` : `Are you shure to remove ${player.player.alias} of this tournament`
    );
    if (confirm) {
      const dropPlayer = await api.delete(`/tourneys/remove-player/${tournamentId}`, { data: { playerId: player.player.id } }).catch(() => {
        setAlert(true);
        setAlertMsg(`Não foi possível retirar ${player.player.alias} do torneio`);
        setSeverity("error");
      });
      if (dropPlayer) {
        setAlert(true);
        setAlertMsg(
          player.player.active
            ? `Player ${player.player.alias} retirado do torneio com sucesso!`
            : `Player ${player.player.alias} inserido novamente ao torneio!`
        );
        setSeverity("success");
        handleClose();
      }
    }
  };

  if (!chatList) return null;

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: {
          background: "transparent",
          // backdropFilter: "blur(15px)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          width: "70vw",
          height: "90%",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
      BackdropProps={{ style: { backdropFilter: "blur(20px)" } }}
    >
      <section style={{ position: "relative", minHeight: "30%" }}>
        <ProfilePlayer playerId={player.player.id} />
      </section>
      <section style={{ position: "relative" }}>
        <BtnContinuo
          // className="default-input"
          onClick={handleClose}
          style={{ position: "absolute", width: "100px", height: "50px", top: "-4rem", right: "1rem", zIndex: 1000, cursor: "pointer" }}
        >
          CLOSE
        </BtnContinuo>
        <div style={{ width: "60vw", height: "60vh" }}>
          <h1>{player.player.alias}</h1>
          <div className="room-page_chat-container-messages" style={{ backdropFilter: "none", backgroundColor: "rgba(0,0,0,0.95)" }} ref={chatscrollref}>
            {chatList.map((item) => {
              if (!item.author) return null;
              return (
                <div key={item.body}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      paddingRight: "5px",
                      flexDirection: "row",
                      justifyContent: item.author === user._id ? "flex-start" : "flex-end",
                    }}
                  >
                    {item.author !== user._id ? (
                      <div className="chat-box-vs animate__animated animate__fadeInRight animate__faster">
                        <div style={{ color: "rgb(200,0,200)" }}>{player.player.alias}</div>
                        <div style={{ whiteSpace: "wrap", wordWrap: "break-word", userSelect: "text" }}>{item.body}</div>
                      </div>
                    ) : (
                      <div className="chat-box-author animate__animated animate__fadeInLeft animate__faster">
                        <div
                          style={{
                            whiteSpace: "wrap",
                            wordWrap: "break-word",
                            userSelect: "text",
                          }}
                        >
                          {item.body}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <form
            onSubmit={(e) => {
              handleSubmitMsg(e, player?.player?.id);
            }}
          >
            <input
              type="text"
              placeholder="Digite aqui..."
              style={{ height: "50px", background: "black" }}
              className="room-page_chat-container-input"
              ref={chatInputRef}
            />
          </form>
          <button
            className="default-button"
            style={{ position: "absolute", top: 0, left: "50%", height: "30px", transform: "translateX(-50%)" }}
            onClick={() => handleDropPlayer()}
          >
            {player.player.active ? "DROP " + player.player.nickname : lang === "pt" ? "VOLTAR DROP " : "UNDO DROP " + player.player.nickname}
          </button>
        </div>
      </section>
    </Drawer>
  );
}

export default Chat;
