import React, { useState, useEffect, useCallback } from "react";
import LineGraphic from "../../components/graphic/Graphic";
import PizzaGraphic from "../../components/graphic/PizzaGraphic";
import Table from "../../components/table/meusStakersTable";
import { useStore } from "../../context/storeContext";
import api from "../../services/api";
import "./meusstakers.scss";

const coldata = [
  { col: "STAKER", wd: "40%" },
  { col: "NICKNAME", wd: "20%" },
  { col: "GAMETAG", wd: "30%" },
  { col: "STAKED", wd: "5%" },
];

function Index() {
  const { user } = useStore();
  const [stakerMap, setStakerMap] = useState([]);

  // useEffect(() => DataTable(), []);

  return (
    <div className="meusStakersPage">
      <div className="meusStakersTop">{/* <LineGraphic stakers={user.stakers} wallet={user.wallet} data={user} mark={1} /> */}</div>
      <div className="meusStakersBotton">
        <div className="meusStakersBottonLeft">
          <div className="pista">
            {/* <PizzaGraphic
              stake={user.stakers.value}
              eq9={user.wallet.value}
              width="350"
              height="350"
            /> */}
          </div>
        </div>
        <div className="meusStakersBottonRight">
          <div
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "100%",
              maxHeight: "500px",
              display: "relative",
            }}
          >
            {/* <Table col={coldata} data={stakerMap} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
