import { Drawer } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import { useSocket } from "../../context/webSocket";
import decode from "jwt-decode";
import api from "../../services/api";
import ProfilePlayer from "./Profile";
import BtnContinuo from "../../components/buttons/BtnContinuo";

function CommunityChat({ tournamentId, open, handleClose }) {
  const chatscrollref = useRef(null);
  const { user } = useStore();
  const [chatList, setChatList] = useState([]);
  const { lang } = useLocalDB();
  const { socket } = useSocket();
  const msgInputValue = useRef(null);

  useEffect(() => {
    if (open) {
      socket.off("tournamentChatData").on("tournamentChatData", (e) => {
        setChatList(e.messages);
      });
      socket.emit("joinTournamentChatRoom", { tournamentId: tournamentId });
    } else if (!open) {
      socket.off("tournamentChatData");
    }
  }, [open]);

  useEffect(() => {
    if (chatList) {
      if (chatscrollref.current?.scrollHeight > chatscrollref.current?.offsetHeight) {
        chatscrollref.current.scrollTop = chatscrollref.current.scrollHeight - chatscrollref.current.offsetHeight;
      }
    }
  }, [chatList]);

  const handleSubmitMsg = (e) => {
    e.preventDefault();
    if (msgInputValue.current?.value !== "") {
      // firebaseApi.sendWaitRoomMessage(id, user.nickname, msgInputValue);
      socket.emit("sendMessageTournament", {
        tournamentId: tournamentId,
        message: { author: user.nickname, body: msgInputValue.current.value, to: "ALL" },
      });
      msgInputValue.current.value = "";
    }
  };

  if (!chatList) return null;

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: {
          background: "transparent",
          // backdropFilter: "blur(15px)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          width: "70vw",
          height: "90%",
          position: "absolute",
          marginLeft: "15%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
      BackdropProps={{ style: { backdropFilter: "blur(20px)" } }}
    >
      <section style={{ position: "relative" }}>
        <BtnContinuo
          // className="default-input"
          onClick={handleClose}
          style={{ position: "absolute", width: "100px", height: "50px", top: "-4rem", right: "1rem", zIndex: 1000, cursor: "pointer" }}
        >
          CLOSE
        </BtnContinuo>
        <div style={{ width: "60vw", height: "60vh" }}>
          <h1>{lang === "pt" ? "Chat da Comunidade" : "Comunity Chat"}</h1>
          <div className="room-page_chat-container-messages" style={{ backdropFilter: "none", backgroundColor: "rgba(0,0,0,0.95)" }} ref={chatscrollref}>
            {chatList.map((item) => {
              if (!item.author) return null;
              return (
                <div key={item.body}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      paddingRight: "5px",
                      flexDirection: "row",
                      justifyContent: item.author === user.nickname ? "flex-start" : "flex-end",
                    }}
                  >
                    {item.author !== user.nickname ? (
                      <div className="chat-box-vs animate__animated animate__fadeInRight animate__faster">
                        <div style={{ color: "rgb(200,0,200)" }}>{item.author}</div>
                        <div style={{ whiteSpace: "wrap", wordWrap: "break-word", userSelect: "text" }}>{item.body}</div>
                      </div>
                    ) : (
                      <div className="chat-box-author animate__animated animate__fadeInLeft animate__faster">
                        <div style={{ color: "rgb(0,200,200)" }}>{item.author}</div>
                        <div
                          style={{
                            whiteSpace: "wrap",
                            wordWrap: "break-word",
                            userSelect: "text",
                          }}
                        >
                          {item.body}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <form
            onSubmit={(e) => {
              handleSubmitMsg(e);
            }}
          >
            <input
              type="text"
              placeholder="Digite aqui..."
              style={{ height: "50px", background: "black" }}
              className="room-page_chat-container-input"
              ref={msgInputValue}
            />
          </form>
        </div>
      </section>
    </Drawer>
  );
}

export default CommunityChat;
