import { useEffect, useState, useRef } from "react";
import { Drawer } from "@material-ui/core";
import BtnContinuo from "../../components/buttons/BtnContinuo";
import sound from "../../assets/sounds";
import "animate.css";
import { useStore } from "../../context/storeContext";
import CarouselMatches from "../../components/slider/CarouselMatches";
import api from "../../services/api";
import { useSocket } from "../../context/webSocket";
import Pop from "../../components/PopOver";

const DrawerStandings = ({ id, tournament, winner }) => {
  const [open, setOpen] = useState(false);
  const { user } = useStore();
  const [standings, setStandings] = useState([]);
  const [tourney, setTourney] = useState();
  const [messages, setMessages] = useState([]);
  const [matches, setMatches] = useState([]);
  const msgInputValue = useRef(null);
  const chatscrollref = useRef(null);
  const { socket } = useSocket();

  const slide = sound.slide;

  useEffect(() => {
    if (id) {
      socket.on("tournamentChatData", (e) => {
        setMessages(e.messages);
      });
      socket.emit("joinTournamentChatRoom", { tournamentId: id });
    }
  }, [id]);

  useEffect(() => {
    if (tourney) {
      if (tournament) {
        const getRounds = tournament.matches.filter((item) => {
          if (item.round === tourney.currentRound) {
            return true;
          }
        });
        setMatches(getRounds);
      }
    }
  }, [tourney]);

  useEffect(() => {
    if (tourney) {
      slide.currentTime = 0;
      slide.play();
    }
  }, [open]);

  useEffect(() => {
    if (chatscrollref.current !== null) {
      if (chatscrollref.current.scrollHeight) {
        if (chatscrollref.current.scrollHeight > chatscrollref.current.offsetHeight) {
          chatscrollref.current.scrollTop = chatscrollref.current.scrollHeight - chatscrollref.current.offsetHeight;
        }
      }
    }
  }, [messages]);

  const Card = ({ classStyle, children, header, description }) => {
    return (
      <div className={`t-adm-console ${classStyle}`} style={{ display: "flex", justifyContent: "center", width: "97%" }}>
        <div className="console-description">
          <h1>{header}</h1>
          <p>{description}</p>
        </div>
        <div className="console-body" style={{ width: "100%" }}>
          {children}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (open) {
      if (id) {
        setStandings(tournament.standings);
        const GetTourney = async () => {
          const Tourney = await api.get(`tourneys/${id}`).catch(console.log);
          setTourney(Tourney.data);
        };
        return GetTourney();
      }
    }
  }, [open]);

  const handleSubmitMsg = (e) => {
    e.preventDefault();
    if (msgInputValue.current?.value !== "") {
      // firebaseApi.sendWaitRoomMessage(id, user.nickname, msgInputValue);
      socket.emit("sendMessageTournament", {
        tournamentId: id,
        message: { author: user.nickname, body: msgInputValue.current.value, to: "ALL" },
      });
      msgInputValue.current.value = "";
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BtnContinuo classe="default-button" onClick={toggleDrawer}>
          Standings
        </BtnContinuo>
        <Drawer
          anchor={"right"}
          open={open}
          PaperProps={{
            style: {
              background: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(15px)",
              width: "45%",
            },
          }}
          onClose={toggleDrawer}
        >
          <Card>
            <h1>STANDINGS</h1>
            <table style={{ width: "100%" }}>
              <thead>
                <tr style={{ background: "rgba(0,0,0,0.4)", height: "10px" }}>
                  <td style={{ textAlign: "center" }}>POSITION</td>
                  <td style={{ textAlign: "center" }}>PLAYER</td>
                  <td style={{ textAlign: "center" }}>DECK</td>
                  <td style={{ textAlign: "center" }}>POINTS</td>
                  {/* <td style={{ textAlign: "center" }}>MATCH POINTS</td> */}
                  <td style={{ textAlign: "center" }}>OMW | PGW | OGW</td>
                </tr>
              </thead>
              <tbody>
                {standings &&
                  standings.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{index + 1} º</td>
                        <td style={{ textAlign: "center" }}>
                          <Pop cutValue={20}>{item.etc?.name ? item.etc?.name : item.alias}</Pop>
                        </td>
                        <td style={{ textAlign: "center" }}>deckName</td>
                        {/* <td style={{ textAlign: "center" }}>{item.gamePoints}</td> */}
                        <td style={{ textAlign: "center" }}>{item.matchPoints}</td>
                        <td style={{ textAlign: "center" }}>
                          {item.tiebreakers.oppMatchWinPctM * 100 > 100 ? 100 : item.tiebreakers.oppMatchWinPctM * 100}% |{" "}
                          {item.tiebreakers.gameWinPct * 100 > 100 ? 100 : item.tiebreakers.gameWinPct * 100}% |{" "}
                          {item.tiebreakers.oppGameWinPct * 100 > 100 ? 100 : item.tiebreakers.oppGameWinPct * 100}%
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Card>
          {matches && (
            <Card>
              <CarouselMatches data={matches} tourney={tourney} winner={winner} />
            </Card>
          )}
          <div className="room-page_chat-container" style={{ width: "100%", padding: "0 10px", marginBottom: "-5px" }}>
            <div className="room-page_chat-container-messages" ref={chatscrollref}>
              {messages.map((item, key) => {
                return (
                  <div key={key}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        paddingRight: "5px",
                        flexDirection: "row-reverse",
                        justifyContent: item.author === user.nickname ? "flex-start" : "flex-end",
                      }}
                    >
                      {item.author !== user.nickname ? (
                        <div
                          className="chat-box animate__animated animate__fadeInLeft animate__faster"
                          style={{
                            background: "rgba(200,0,150,0.1)",
                            borderRadius: "8px",
                            maxWidth: "78%",
                            padding: "10px",
                            margin: "5px",
                          }}
                        >
                          <div style={{ color: "rgb(200,0,200)" }}>{item.author}</div>
                          <div
                            style={{
                              whiteSpace: "wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {item.body}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="chat-box animate__animated animate__fadeInRight animate__faster"
                          style={{
                            background: "rgba(0,200,150,0.1)",
                            borderRadius: "8px",
                            maxWidth: "78%",
                            textAlign: "right",
                            padding: "10px",
                            margin: "5px",
                          }}
                        >
                          {/* <div
                        style={{
                          color: "rgb(200,0,200)",
                          fontWeight: "bold",
                        }}
                      >
                        {item.author}
                      </div> */}
                          <div
                            style={{
                              whiteSpace: "wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {item.body}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <form onSubmit={handleSubmitMsg}>
              <input type="text" placeholder="Digite aqui..." className="room-page_chat-container-input" ref={msgInputValue} />
            </form>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default DrawerStandings;
