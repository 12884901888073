import { Bar } from "react-chartjs-2";

function DoughnutComponent({ name, dataProp }) {
  const labels = dataProp && Object.keys(dataProp);
  const getDataValues = labels.map((item) => {
    return dataProp[item];
  });
  const data = {
    labels: labels,
    datasets: [
      {
        // label: "CardsTypes",
        data: getDataValues,
        barPercentage: 0.5,
        barThickness: 10,
        maxBarThickness: 20,
        minBarLength: 2,
        borderColor: "rgb(122, 67, 219)",
        borderWidth: 0.5,
      },
    ],
  };

  const config = {
    type: "Colors",
    data: data,
    layout: { autoPadding: true },
    plugins: {
      title: {
        align: "center",
        display: true,
        text: name,
        fullSize: true,
        font: { weight: "bold" },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
        align: "left",
        position: "bottom",
        labels: {
          boxWidth: 7,
          boxHeight: 7,
        },
      },
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
  return (
    <Bar
      style={{ maxHeight: "250px", maxWidth: "530px" }}
      height="300px"
      // width={width}
      data={data}
      options={config}
    />
  );
}

export default DoughnutComponent;
