import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Keyboard, Pagination, Navigation } from "swiper";
import language from "../../assets/language/text";

import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import Pop from "../PopOver";
// import { useLocalDB } from "../../context/LocalDB";

SwiperCore.use([Keyboard, Pagination, Navigation]);

const BadgesSlider = ({ data }) => {
  const { user } = useStore();
  const { lang, setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const text = language[lang].badges_page;
  if (!data) return null;
  return (
    <>
      <div className="container-badgesSlider" style={{ width: "100%" }}>
        <Swiper
          // style={{
          //   "--swiper-navigation-color": "#2ac1d8",
          //   "--swiper-pagination-bullet": "",
          // }}
          keyboard={{ enabled: true }}
          spaceBetween={10}
          slidesPerView={"auto"}
          centeredSlides={false}
          navigation={true}
          id="badges"
          className="swiper-badge"
        >
          {data.length > 0 ? (
            data.map((item, index) => {
              return (
                <SwiperSlide className="swiper-badge-item" style={{ padding: "0 0rem" }} key={`/badges/${item._id}_${index}`}>
                  <Link to={`/badges/${item.tournament}`}>
                    <div className="badge-box">
                      <div className="badge-box-name">
                        <Pop cutValue={8}>{item.name}</Pop>
                      </div>
                      <div className="badge-box-position">
                        <p>{item.position}#</p>
                      </div>
                      <div className="badge-box-reward">{item.reward ? item.reward + " EQ9" : null}</div>
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })
          ) : (
            <div className="badges-new-account-banner">
              <i class="fas fa-dungeon" />
              <p>{data._id === user._id ? text[1] : "PLAYER NÃO POSSUI RECOMPENSAS AINDA"}</p>
            </div>
          )}
        </Swiper>
      </div>
    </>
  );
};

export default BadgesSlider;
