import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import "./badgespage.scss";
import { useFetch } from "../../hooks/useFetch";
import Pageloading from "../../components/pageloading/pageloading";
import Table from "./table";
import TopPlayers from "../../components/topPlayers";
import { motion } from "framer-motion";
import api from "../../services/api";
import language from "../../assets/language/text";
import { useLocalDB } from "../../context/LocalDB";
import Calc from "../eventos/Calc";
import { useStore } from "../../context/storeContext";
import NumberFromWei from "../../utils/fromWei";

const coldata = [
  { col: "POS", wd: "5%" },
  { col: "NICKNAME", wd: "20%" },
  { col: "GAMETAG", wd: "30%" },
  // { col: "STAKERS", wd: "5%" },
];

function BadgesPages({ idEvent }) {
  let { id } = useParams();
  const { data, error, mutate } = useFetch(`tournaments/${id}`);
  const [ranking, setRanking] = useState();
  const [rewardAmount, setRewardAmount] = useState(0);
  const [playerWinners, setPlayerWinners] = useState([]);
  const [stakerAmount, setStakerAmount] = useState([]);
  const [stakersTimeAmout, setStakersTimeAmout] = useState([]);
  const { user } = useStore();
  const { lang } = useLocalDB();
  const text = language[lang].events_page;

  useEffect(() => {
    if (data) {
      const getData = async () => {
        const FetchUser = async (item) => {
          const getUser = await api.get(`users/${item.id}`).catch(console.log);
          return { ...item, user: getUser.data };
        };
        if (data?.standings) {
          const getAllListed = await Promise.all(data.standings.map(FetchUser)).catch(console.log);
          setRanking(getAllListed);
        }
      };
      getData();
    }
    if (data?.reward?.amount) {
      Calc(NumberFromWei(data.reward.amount.toString()), 4, setPlayerWinners, setStakerAmount, setStakersTimeAmout, setRewardAmount);
    }
  }, [data]);

  useEffect(() => {
    if (idEvent) {
      const tournamentUpdate = async () => {
        const getTournament = await api.get(`tournaments/${idEvent}`).catch(console.log);
        if (getTournament) {
          const FetchUser = async (item) => {
            const getUser = await api.get(`users/${item.id}`).catch(console.log);
            return { ...item, user: getUser.data };
          };
          const getAllListed = await Promise.all(getTournament.data.standings.map(FetchUser)).catch(console.log);
          setRanking(getAllListed);
        }
      };
      tournamentUpdate();
    }
  }, [idEvent]);

  const pageVariants = {
    initial: { opacity: 0 },
    in: { opacity: 1 },
    out: { opacity: 0 },
  };
  const pageTransition = { type: "tween", easy: "circInOut", duration: 0.4 };
  if (!data) return <Pageloading />;
  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="rankingPage">
      <div className="containerRankingLeft">
        <div className="rankingBanner" style={{ height: "30%", marginTop: "-3rem", padding: "1rem" }}>
          <div className="banner" style={{ minHeight: "80px" }}>
            <h1>{data.name}</h1>
          </div>
          <textarea
            className="descriptionBanner"
            readOnly
            disabled
            style={{
              width: "100%",
              height: "90%",
              // maxHeight: "5vh",
              background: "transparent",
              resize: "none",
              fontFamily: "gilroy-thin",
              fontSize: "1em",
              border: "none",
            }}
          >
            {data.description.trim()}
          </textarea>
        </div>
        <div className="rankingProfile" style={{ padding: "1rem", height: "50%", transform: "scale(.8)" }}>
          {/* <div className="rankingProfile" style={{ paddingTop: "5rem" }}> */}
          <div className="rankingProfileContent">
            <TopPlayers data={ranking} />
          </div>
        </div>
      </div>
      <div className="containerRankingRight" style={{ flexDirection: "column" }}>
        <div style={{ width: "100%", height: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Table col={coldata} data={ranking} />
        </div>
        <div style={{ width: "100%", height: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          {data.reward.typeOf === "EQ9" ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", height: "100%" }}>
              <h1 style={{ color: "var(--pink)" }}>
                {text[34]} <span style={{ color: "var(--yellow)" }}>{rewardAmount.toString()} EQ9 </span>
              </h1>
              <table style={{ width: "90%" }}>
                <thead>
                  <tr>
                    <td></td>
                    <td>{text[24]}</td>
                    <td>{text[25]}</td>
                    {/* <td>{text[25]}</td> */}
                  </tr>
                  <tr>
                    <td></td>
                    <td>{text[26]}</td>
                    <td>{lang === "pt" ? "RECOMPENSA" : "REWARD"}</td>
                    {/* <td>{text[28]}</td> */}
                  </tr>
                </thead>
                <tbody>
                  {playerWinners?.map((item, index) => {
                    return (
                      <tr key={user._id + index}>
                        <td>
                          <span>{index + 1}</span> {text[29]}:
                        </td>
                        <td>
                          <span style={{ color: "var(--yellow)" }}>{playerWinners[index]}</span> EQ9
                        </td>
                        <td>
                          <span style={{ color: "var(--yellow)" }}>{stakerAmount[index]}</span> EQ9
                        </td>
                        {/* <td>
                          <span style={{ color: "var(--yellow)" }}>{stakersTimeAmout[index]}</span> EQ9
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </motion.div>
  );
}

export default BadgesPages;
