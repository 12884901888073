import { createContext, useContext, useState, useEffect } from "react";
import { useMetaMask } from "metamask-react";
import eq9Config from "../services/eq9Config";
import * as Web3 from "web3";
import TournamentABI from "../contracts/Tournament.json";
import EQ9 from "../contracts/EQ9";
import StakerAbi from "../contracts/Staker.json";
import VirtualContractABI from "../contracts/virtualContract.json";
import { useLocalDB } from "./LocalDB";
import { useStore } from "./storeContext";
import api from "../services/api";
import toBigNumber from "../utils/toBigNumber";
import NumberFromWei from "../utils/fromWei";
import NumberToWei from "../utils/toWei";

const WalletDBContext = createContext();
const tokenAddress = eq9Config.trc20ContractAddress;
const initialApproving = { pt: "Aprovando...", en: "Approving..." };

export default function WalletDBProvider({ children }) {
  const [eq9, setEq9] = useState(0);
  const { user } = useStore();
  const [staked, setStaked] = useState(0);
  const [address, setAddress] = useState();
  const [connected, setConnected] = useState(false);
  const [installed, setInstalled] = useState(false);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const [playersDistrib, setPlayersDistrib] = useState();
  const [stakersDistrib, setStakersDistrib] = useState();
  const { account, chainId, status, connect } = useMetaMask();
  const [eq9Contract, setEq9Contract] = useState();
  const [stakerContract, setStakerContract] = useState();
  const [stepApprove, setStepApprove] = useState(initialApproving);
  const [virtualContract, setVirtualContract] = useState();
  const [web3, setWeb3] = useState();

  useEffect(() => {
    if (user && user.wallets?.default?.address && status === "connected") {
      handleSyncMetamaskWeb3();
      getBalanceOf();
    }
  }, [user, status]);

  useEffect(() => {
    if (user && user.wallets?.default?.address) {
      getBalanceOf();
    }
  }, [user]);

  const handleSyncMetamaskWeb3 = async () => {
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      window.ethereum.enable();
      setWeb3(window.web3);
      const eq9ContractAt = await new window.web3.eth.Contract(EQ9.abi, tokenAddress);
      setEq9Contract(eq9ContractAt);
      const stakerContractAt = await new window.web3.eth.Contract(StakerAbi.abi, eq9Config.stakerContract);
      setStakerContract(stakerContractAt);
      const virtualContractAt = await new window.web3.eth.Contract(VirtualContractABI.abi, process.env.REACT_APP_VIRTUAL_CONTRACT);
      setVirtualContract(virtualContractAt);
      return true;
    }
    return false;
  };

  const getBalanceOf = async () => {
    // if (status === "connected") {
    //   if (account && web3) {
    //     const eq9Contract = await new web3.eth.Contract(EQ9.abi, tokenAddress);
    //     const balance = await eq9Contract.methods.balanceOf(account).call();
    if (user) {
      const getUserBalance = await api.get(`users/${user._id}`).catch(console.log);
      if (getUserBalance) {
        setEq9(NumberFromWei(getUserBalance.data.wallets?.default?.balance));
      }
      // const balance = user.wallets?.default?.balance;
    }
    // }
  };

  const checkPlayerPayment = async (playerAddress, contractAddress) => {
    const tournamentContract = await new web3.eth.Contract(TournamentABI.abi, contractAddress);
    const paid = await tournamentContract.methods.checkPayment(playerAddress).call();
    return paid;
  };

  useEffect(() => {
    if (status === "connected") {
      getBalanceOf();
    }
  }, [user]);

  const getSignIn = () => {
    // connect();
  };

  const getTransfer = async (address, value) => {
    if (account && web3) {
      if (address && value) {
        const transfer = await eq9Contract.methods.transfer(address, NumberToWei(value)).send({
          from: account,
          gasPrice: "30000000000",
          gas: await eq9Contract.methods.transfer(address, NumberToWei(value)).estimateGas({ from: account }),
        });
        if (transfer) {
          setAlert(true);
          setAlertMsg(`YOU HAVE TRANSFER ${value}EQ9, SUCCESSFULLY.`);
          setSeverity("success");
          return transfer;
        }
      } else {
        setAlert(true);
        setAlertMsg("NEED A VALID ADDRESS OR VALUE");
        setSeverity("error");
      }
    }
  };

  const stakeOnPlayer = async (bockChainId, amount) => {
    setStepApprove(initialApproving);
    const getApprove = await eq9Contract.methods.approve(eq9Config.stakerContract, amount).send({ from: account, gas: "3000000" }).catch(console.log);
    if (getApprove) {
      setStepApprove({ pt: "Aprovado! Efetuando o Stake...", en: "Approved! Staking..." });
      const getStakeOn = await stakerContract.methods.stake(amount, bockChainId).send({ from: account, gas: "3000000" });
      return getStakeOn;
    } else {
      setAlert(true);
      setAlertMsg("Confirmação necessária");
      setSeverity("error");
    }
  };

  const unstakeOnPlayer = async (bockChainId, amount) => {
    setStepApprove(initialApproving);
    setStepApprove({ pt: "Efetuando o Unstake...", en: "Unstaking..." });
    const getStakeOn = await stakerContract.methods.unstake(amount, bockChainId).send({ from: account, gas: "3000000" });
    return getStakeOn;
  };

  const setDefaultWallet = async (address) => {
    const setWallet = await api.patch(`/users/wallet`, { wallet: address });
    return setWallet;
  };

  const getJoin = async (value, contract, setLoading, userWallet) => {
    const getApprove = await eq9Contract.methods
      .approve(contract.contractAddress, value)
      .send({
        from: userWallet,
        gasPrice: "30000000000",
        gas: await eq9Contract.methods.approve(contract, value).estimateGas({}),
      })
      .catch((error) => {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "Erro, não foi aprovado..." : "Error, not approved...");
        setSeverity("error");
        setLoading(false);
        return error;
      });
    if (getApprove) {
      const TournamentContract = await new window.web3.eth.Contract(TournamentABI.abi, contract);
      const getJoin = await TournamentContract.methods
        .join(account, value.toString())
        .send({ from: account, gas: "3000000" })
        .catch((error) => {
          setAlert(true);
          setAlertMsg(lang === "pt" ? "Erro, não foi concluido a inscrição..." : "Error, User did not accept the registration...");
          setSeverity("error");
          setLoading(false);
          return error;
        });
      return getJoin;
    }
  };

  const getTransfers = () => {};

  const getRewards = async (wallet) => {
    if (stakerContract) {
      return await stakerContract.methods.claims(wallet).call();
    }
  };

  const getDeposit = async (value) => {
    if (virtualContract) {
      const virtualAddress = process.env.REACT_APP_VIRTUAL_CONTRACT;
      const getAmountApproved = await eq9Contract.methods.allowance(user.wallets.default.address, virtualAddress).call();
      const signer = user.wallets.default.address;
      if (+NumberFromWei(getAmountApproved) >= +value) {
        const tx = await virtualContract.methods.deposit(value).send({
          from: signer,
          gas: await virtualContract.methods.deposit(value).estimateGas({
            from: signer,
          }),
          gasPrice: "30000000000",
        });
        return tx;
      } else {
        const getApprove = await eq9Contract.methods.approve(virtualAddress, value).send({
          from: signer,
          gasPrice: "30000000000",
          gas: await eq9Contract.methods.approve(virtualAddress, value).estimateGas({ from: signer }),
        });
        if (getApprove) {
          const tx = await virtualContract.methods.deposit(value).send({
            from: signer,
            gas: await virtualContract.methods.deposit(value).estimateGas({
              from: signer,
            }),
            gasPrice: "30000000000",
          });
          return tx;
        }
      }
    }
  };

  const getWithdraw = async (value) => {
    const withdraw = await api.post(``, { value: value }).catch((error) => {
      const response = error.response;
    });
    if (withdraw) {
      return withdraw;
    }
  };

  const claimRewards = async (value) => {
    if (stakerContract) {
      return await stakerContract.methods.claim(toBigNumber(value)).send({ from: user.wallets.default.address, gas: "3000000" });
    }
  };

  return (
    <WalletDBContext.Provider
      value={{
        account,
        status,
        connect,
        chainId,
        eq9,
        setEq9,
        staked,
        setStaked,
        address,
        setAddress,
        connected,
        setConnected,
        installed,
        setInstalled,
        playersDistrib,
        setPlayersDistrib,
        stakersDistrib,
        setStakersDistrib,
        getBalanceOf,
        getTransfer,
        getSignIn,
        getTransfers,
        stakeOnPlayer,
        unstakeOnPlayer,
        stepApprove,
        getJoin,
        setDefaultWallet,
        getRewards,
        claimRewards,
        checkPlayerPayment,
        getDeposit,
        getWithdraw,
      }}
    >
      {children}
    </WalletDBContext.Provider>
  );
}

export function useWallet() {
  const context = useContext(WalletDBContext);
  if (!context) throw new Error("useImport precisa de um Arquivo importado");
  const {
    account,
    chainId,
    status,
    eq9,
    connect,
    setEq9,
    staked,
    setStaked,
    address,
    setAddress,
    connected,
    setConnected,
    installed,
    setInstalled,
    playersDistrib,
    setPlayersDistrib,
    stakersDistrib,
    setStakersDistrib,
    getBalanceOf,
    getTransfer,
    getSignIn,
    getTransfers,
    stakeOnPlayer,
    unstakeOnPlayer,
    stepApprove,
    getJoin,
    setDefaultWallet,
    getRewards,
    claimRewards,
    checkPlayerPayment,
    getDeposit,
    getWithdraw,
  } = context;
  return {
    account,
    chainId,
    status,
    connect,
    eq9,
    setEq9,
    staked,
    setStaked,
    address,
    setAddress,
    connected,
    setConnected,
    installed,
    setInstalled,
    playersDistrib,
    setPlayersDistrib,
    stakersDistrib,
    setStakersDistrib,
    getBalanceOf,
    getTransfer,
    getSignIn,
    getTransfers,
    stakeOnPlayer,
    unstakeOnPlayer,
    stepApprove,
    getJoin,
    setDefaultWallet,
    getRewards,
    claimRewards,
    checkPlayerPayment,
    getDeposit,
    getWithdraw,
  };
}
