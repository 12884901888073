import React, { useEffect, useRef, useState } from "react";
import sound from "../../assets/sounds";
import { useStore } from "../../context/storeContext";
import { useLocalDB } from "../../context/LocalDB";
import language from "../../assets/language/text";
import api from "../../services/api";
import sounds from "../../assets/sounds";
// const soundAmbient = new Audio(sound.StadiumSound);
// soundAmbient.loop = true;
// soundAmbient.play();

const Controller = () => {
  const [slider, setSlider] = useState("");
  // const [volume, setVolume] = useState(1);
  const { lang, setLang, setAlert, setAlertMsg, setSeverity, volume, setVolume } = useLocalDB();
  const { user, setUser } = useStore();
  const divControl = useRef();

  const text = language[lang].config;

  useEffect(() => {
    if (user) {
      setLang(user?.webConfig?.language);
      setVolume(user?.webConfig?.volume);
      Object.keys(sounds).map((item) => {
        sounds[item].volume = user?.webConfig?.volume;
      });
    }
  }, []);

  // useEffect(() => {
  //   soundAmbient.volume = volume;
  // }, [volume]);

  const handleSlider = () => {
    if (slider === "") {
      sound.slide.pause();
      setSlider("slider-active");
      sound.slide.currentTime = 0;
      sound.slide.play();
    } else {
      setSlider("");
      sound.slide.pause();
      sound.slide.currentTime = 0;
      sound.slide.play();
    }
  };

  async function handleVolumeUpdate(e) {
    const volume = +e.target.value;
    if (user) {
      const updateVolume = await api.patch(`users`, { webConfig: { ...user.webConfig, volume: volume } }).catch(() => {
        setAlert(true);
        setAlertMsg("Não foi possível atualizar as configurações");
        setSeverity("error");
      });
    }
  }

  async function handleUpdateLanguage(e) {
    setLang(e.target.value);
    if (user) {
      const updateLanguage = await api
        .patch(`users`, {
          webConfig: { ...user.webConfig, language: e.target.value },
        })
        .catch(console.log);
    }
  }

  async function handleChangeVolume(e) {
    setVolume(e.target.value);
    Object.keys(sounds).map((item) => {
      sounds[item].volume = e.target.value;
    });
  }

  window.addEventListener("click", (e) => {
    if (slider === "slider-active") {
      if (divControl.current && !divControl.current.contains(e.target)) {
        setSlider("");
      }
    }
  });
  // const handlePause = () => {
  //   soundAmbient.pause();
  // };

  if (!user) {
    return (
      <div className="modal-settings" ref={divControl}>
        <div className="modal-settings-slider">
          <div className={`slider-content ${slider}`}>
            <div className="volume-content">
              {/* <p>{text[0]}</p> */}
              <div className="volume-content-control">
                <i class="fas fa-volume-down fa-lg"></i>
                <input
                  type="range"
                  id="volume-id"
                  className="volume"
                  style={{
                    background: `linear-gradient(90deg, var(--pink) ${volume * 100}%, rgba(238, 238, 238, 0.4) ${volume * 100}%)`,
                  }}
                  // defaultValue={user.webConfig.volume}
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={handleChangeVolume}
                  onMouseUp={handleVolumeUpdate}
                />
                <i class="fas fa-volume-up fa-lg"></i>
              </div>
            </div>
            <div className="language-content">
              <p>{text[1]}:</p>
              <select
                type="select"
                className="select-language"
                // defaultValue={user.webConfig.language}
                // onClick={handleLanguage}
                onChange={handleUpdateLanguage}
              >
                {Object.keys(language).map((item, index) => {
                  return <option key={item + "language" + index}>{item}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="button-settings" onClick={handleSlider}>
            <i class={`fas fa-cog fa-2x ${slider ? "active" : "activeBack"}`}></i>
          </div>
          {/* <button onClick={PutVolumAll}>test</button> */}
        </div>
      </div>
    );
  } else if (user) {
    return (
      <div className="modal-settings" ref={divControl}>
        <div className="modal-settings-slider">
          <div className={`slider-content ${slider}`}>
            <div className="volume-content">
              {/* <p>{text[0]}</p> */}
              <div className="volume-content-control">
                <i class="fas fa-volume-down fa-lg"></i>
                <input
                  type="range"
                  id="volume-id"
                  className="volume"
                  style={{
                    background: `linear-gradient(90deg, var(--pink) ${volume * 100}%, rgba(238, 238, 238, 0.4) ${volume * 100}%)`,
                  }}
                  defaultValue={user.webConfig.volume}
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={handleChangeVolume}
                  onMouseUp={handleVolumeUpdate}
                />
                <i class="fas fa-volume-up fa-lg"></i>
              </div>
            </div>
            <div className="language-content">
              <p>{text[1]}:</p>
              <select
                type="select"
                className="select-language"
                defaultValue={user.webConfig.language}
                // onClick={handleLanguage}
                onChange={handleUpdateLanguage}
              >
                {Object.keys(language).map((item, index) => {
                  return <option key={item + "language" + index}>{item}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="button-settings" onClick={handleSlider}>
            <i class={`fas fa-cog fa-2x ${slider ? "active" : "activeBack"}`}></i>
          </div>
          {/* <button onClick={PutVolumAll}>test</button> */}
        </div>
      </div>
    );
  }
};

export default Controller;
