import React, { useState, useEffect, useRef } from "react";
import { ButtonBase, Dialog } from "@material-ui/core";
import { useFetch } from "../../../hooks/useFetch";
import { useStore } from "../../../context/storeContext";
import { useLocalDB } from "../../../context/LocalDB";
import firebaseApi from "../../../services/firebaseApi";
import ChatIcon from "@material-ui/icons/Chat";
import CloseIcon from "@material-ui/icons/Close";
import AddCommentIcon from "@material-ui/icons/AddComment";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import api from "../../../services/api";
import "./friendmsg.css";
import language from "../../../assets/language/text";

const initialAtivo = "f-Msg-Component";
const initialSelected = {
  chatId: "",
  lastMessage: "",
  lastMessageDate: "",
  title: "",
  with: "",
};

function FriendMsg() {
  const [ativo, setAtivo] = useState(initialAtivo);
  const [addPlayer, setAddPlayer] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [requests, setRequests] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [friendSelectedData, setFriendSelectedData] = useState();
  const [msgBox, setMsgBox] = useState(false);
  const [friendMsg, setFriendMsg] = useState("");
  const [selected, setSelected] = useState(initialSelected);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const { user } = useStore();
  const { data, mutate } = useFetch(`users/${user._id}`);
  const text = language[lang].friendMsg;

  useEffect(() => {
    if (user !== null) {
      let unsub = firebaseApi.onChatList(user._id, setChatList);
      return unsub;
    }
  }, []);

  const handleActive = () => {
    ativo === initialAtivo ? setAtivo("f-Msg-Component-Ativo") : setAtivo(initialAtivo);
  };

  const status = (status) => {
    if (status === "OFFLINE") return "red";
    else if (status === "ONLINE") return "green";
    else if (status === "AWAY") return "orange";
  };

  const ChatBox = ({ isOn, chat, friend }) => {
    const msgInput = useRef(null);
    const chatscrollref = useRef(null);
    const [list, setList] = useState([]);
    const [users, setUsers] = useState();

    useEffect(() => {
      let unsub = firebaseApi.onChatContent(chat, setList, setUsers);
      return unsub;
    }, [chat]);

    useEffect(() => {
      msgInput.current.focus();
    }, []);
    useEffect(() => {
      if (chatscrollref.current.scrollHeight > chatscrollref.current.offsetHeight) {
        chatscrollref.current.scrollTop = chatscrollref.current.scrollHeight - chatscrollref.current.offsetHeight;
      }
    }, [list]);

    const handleSubmitMsg = (e) => {
      e.preventDefault();
      const InputMsg = msgInput.current;
      if (msgInput.current.value !== "") {
        firebaseApi.sendMessage(chat, user.nickname, "text", InputMsg.value, users);
      }
      InputMsg.value = "";
    };

    return (
      <div className="chatComponent">
        <div>{friend.nickname}</div>
        <div className="chatPointer" />
        <div className={`ChatBox ${isOn}`}>
          <div
            className="chatTexts"
            ref={chatscrollref}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "240px",
              width: "100%",
              marginRight: "-5px",
            }}
          >
            {list.map((item, key) => {
              return (
                <div key={key}>
                  <div
                    style={{
                      display: "flex",
                      width: "370px",
                      paddingRight: "5px",
                      justifyContent: item.author === user.nickname ? "flex-end" : "flex-start",
                    }}
                  >
                    {item.author !== user.nickname ? (
                      <div
                        style={{
                          background: "rgba(200,0,150,0.1)",
                          borderRadius: "8px",
                          maxWidth: "78%",
                          padding: "10px",
                          margin: "5px",
                        }}
                      >
                        <div style={{ color: "rgb(200,0,200)" }}>{item.author}: </div>
                        <div style={{ whiteSpace: "wrap", wordWrap: "break-word" }}>{item.body}</div>
                      </div>
                    ) : (
                      <div
                        style={{
                          background: "rgba(0,200,150,0.1)",
                          borderRadius: "8px",
                          maxWidth: "78%",
                          textAlign: "right",
                          padding: "10px",
                          margin: "5px",
                        }}
                      >
                        <div style={{ color: "rgb(200,0,200)", marginLeft: "5px" }}>{item.author}</div>
                        <div
                          style={{
                            whiteSpace: "wrap",
                            wordWrap: "break-word",
                            textAlign: "right",
                          }}
                        >
                          {item.body}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <form onSubmit={handleSubmitMsg}>
          <input type="text" ref={msgInput} />
        </form>
      </div>
    );
  };

  const handleRequestSearch = () => {
    if (data)
      if (data.friendRequests) {
        let countFriend = [];
        data.friendRequests.map((item) => {
          api
            .get(`users/${item._id}`)
            .then((res) => {
              countFriend.push(res.data);
              return countFriend;
            })
            .then((res) => setPedidos([...pedidos, ...res]))
            .catch(console.log);
        });
        return setPedidos(countFriend);
      }
  };

  const Friend = ({ item }) => {
    const [friendData, setFriendData] = useState();
    const { data } = useFetch(`users/${item.with}`);

    useEffect(() => setFriendData(data), [data]);
    useEffect(() => setFriendSelectedData(data), [data]);
    return (
      <>
        <div
          className="friend-box"
          onClick={() => {
            setFriendMsg((chat) => (chat === "MsgAberto" ? "" : "MsgAberto"));
            setMsgBox((msgbox) => (msgbox === true ? false : true));
            setSelected((selected) => (selected === initialSelected ? item : initialSelected));
          }}
        >
          {friendData ? (
            <div className="friendItems">
              <img className="f-Chat-Header-FriendsIMG" src={friendData.img.profile} alt="friendIMG" />
              <div className="friend-items-description">
                <h2>{item.title}</h2>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="status-ball" style={{ background: status(friendData.status) }} />
                  <p>{friendData.status}</p>
                </div>
                {selected.with !== item.with && <p style={{ color: "#aaa" }}>{item.lastMessage && item.lastMessage}</p>}
              </div>
            </div>
          ) : null}
        </div>
        {msgBox && selected.with === item.with && <ChatBox isOn={friendMsg} chat={item.chatId} friend={item} />}
      </>
    );
  };

  const AddRequest = () => {
    const handleConfirmRequest = (e, item) => {
      e.preventDefault();
      const indexRequest = pedidos.indexOf(item);
      const retirar = pedidos;
      retirar.splice(indexRequest, 1);
      setPedidos(retirar);
      setRequests(false);
      const dataFriends = data.friends ? data.friends : null;
      const friendDataFriends = item.friends ? item.friends : null;
      const AcceptRequest = {
        nickname: item.nickname,
        description: "",
        _id: item._id,
        msg: {
          origin: "SISTEMA",
          msg: `${item.nickname} adicionado com sucesso!`,
        },
      };
      const AcceptedRequest = {
        nickname: user.nickname,
        description: "",
        _id: user._id,
        msg: {
          origin: "SISTEMA",
          msg: `${user.nickname} adicionado com sucesso!`,
        },
      };

      api
        .put(`users/${user._id}`, {
          user: {
            friendRequests: retirar,
            friends: [...dataFriends, AcceptRequest],
          },
        })
        .then(
          () =>
            mutate({
              ...user,
              friendRequests: retirar,
              friends: [...dataFriends, AcceptRequest],
            }),
          false
        )
        .then(() => {
          setAlert(true);
          setAlertMsg(`${item.nickname} adicionado com sucesso!`);
          setSeverity("success");
          firebaseApi.addUser(user).then(() => {
            firebaseApi.addUser(item).then(() => {
              setTimeout(() => {
                firebaseApi.addNewChat(user, item);
              }, 5000);
            });
          });
        })
        .catch(console.log);
      api
        .put(`users/${item._id}`, {
          user: {
            friends: [...friendDataFriends, AcceptedRequest],
          },
        })
        .catch(console.log);
    };
    return (
      <Dialog
        open={requests}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        onClose={() => {
          setRequests(false);
          setPedidos([]);
        }}
      >
        <div
          style={{
            width: "300px",
            height: "200px",
            background: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            padding: "10px",
            justifyContent: "flex-start",
            overflow: "hidden",
          }}
        >
          <label style={{ textAlign: "center" }}>{text[0]}</label>
          <ul style={{ overflowY: "scroll" }}>
            {pedidos.map((item) => {
              return (
                <li
                  key={item._id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "3px",
                  }}
                >
                  {item.nickname} <ButtonBase onClick={(e) => handleConfirmRequest(e, item)}>Adicionar</ButtonBase>
                </li>
              );
            })}
          </ul>
        </div>
      </Dialog>
    );
  };

  const AddPlayer = () => {
    const [value, setValue] = useState("");
    const [search, setSearch] = useState([]);
    const [timer, setTimer] = useState(300);
    const { data } = useFetch(`user`);
    useEffect(() => {
      setTimeout(() => {
        handleSearch();
        setTimer(0);
      }, timer);
    }, [timer]);

    const handleSearch = () => {
      if (data) {
        const mapSearch = data.filter((item) => {
          if (value) {
            if (item.nickname.toLowerCase().includes(value)) return true;
          }
        });
        return setSearch(mapSearch);
      }
    };
    const handleAddPlayer = (e) => {
      setAddPlayer(false);

      api
        .put(`users/${e._id}`, { user: { friendRequests: { _id: user._id } } })
        .then(() => {
          setAlert(true);
          setAlertMsg(`Pedido de amizade encaminhado para ${e.nickname}`);
          setSeverity("success");
        })
        .catch(console.log);
    };

    return (
      <Dialog
        open={addPlayer}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        onClose={() => setAddPlayer(false)}
      >
        <div
          style={{
            width: "300px",
            height: "200px",
            background: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            padding: "10px",
            justifyContent: "flex-start",
            overflow: "hidden",
          }}
        >
          <label style={{ textAlign: "center" }}>{text[1]}</label>
          <input
            style={{
              color: "black",
              border: "none",
              height: "30px",
              fontSize: "1.5rem",
            }}
            value={value}
            onChange={(e) => {
              setTimer(300);
              setValue(e.target.value);
            }}
          />
          <ul style={{ overflowY: "scroll" }}>
            {search.length > 0
              ? search.map((item) => {
                  return (
                    <li
                      key={item._id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "3px",
                      }}
                    >
                      {item.nickname} <ButtonBase onClick={() => handleAddPlayer(item)}>Adicionar</ButtonBase>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      </Dialog>
    );
  };

  const Chat = () => {
    const { user, setUser } = useStore();
    const { data, mutate } = useFetch(`users/${user._id}`);

    // useEffect(() => {
    //   if (data) setUser(data);
    // }, [data]);

    const handleStatusChange = (e) => {
      const { outerText } = e.target;
      const userChange = { ...user, status: outerText };
      api
        .put(`users/${user._id}`, { user: { status: outerText } })
        .then(() => {
          mutate(userChange, false);
        })
        .catch(console.log);
    };

    return (
      <>
        {user ? (
          <div className="f-Chat-Component">
            <div className="f-Chat-Func-Btn">
              <ButtonBase className="f-Active-Button-Close" style={{ borderRadius: "50%" }} onClick={() => handleActive()}>
                <CloseIcon
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "5px",
                  }}
                />
              </ButtonBase>
              <ButtonBase
                className="f-Active-Button-Add"
                style={{ borderRadius: "50%" }}
                onClick={() => {
                  setRequests(true);
                  handleRequestSearch();
                }}
              >
                {user.friendRequests ? (
                  user.friendRequests.length > 0 ? (
                    <div
                      style={{
                        position: "absolute",
                        width: "15px",
                        height: "15px",
                        top: 0,
                        left: 0,
                        borderRadius: "50%",
                        background: "red",
                        zIndex: 1001,
                      }}
                    >
                      {user.friendRequests.length}
                    </div>
                  ) : null
                ) : null}
                <AddCommentIcon
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "5px",
                  }}
                />
              </ButtonBase>
              <ButtonBase className="f-Active-Button-Requests" style={{ borderRadius: "50%" }} onClick={() => setAddPlayer(true)}>
                <PersonAddIcon
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "5px",
                  }}
                />
              </ButtonBase>
            </div>
            <div className="f-Chat-Header">
              <img className="f-Chat-Header-UserIMG" src={user.img ? user.img.profile : null} alt="profileIMG" />
              <div className="f-Chat-Header-User">
                <h1 className="user-Gradient-Nick">{user.nickname}</h1>
                <div className="user-Status" style={{ display: "flex", alignItems: "center" }}>
                  <div className="status-ball" style={{ background: status(user.status) }} />
                  <p>{user.status}</p>
                  <div className="dropdown-Status">
                    {user.status !== "ONLINE" ? (
                      <div className="dropdown-Status-item" name="online" onClick={handleStatusChange}>
                        <div className="status-ball" style={{ background: "green" }} />
                        {text[2]}
                      </div>
                    ) : null}
                    {user.status !== "OFFLINE" ? (
                      <div className="dropdown-Status-item" name="ausente" onClick={handleStatusChange}>
                        <div className="status-ball" style={{ background: "orange" }} />
                        {text[4]}
                      </div>
                    ) : null}
                    {user.status !== "AWAY" ? (
                      <div className="dropdown-Status-item" onClick={handleStatusChange} name="offline">
                        <div className="status-ball" style={{ background: "red" }} />
                        {text[3]}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                opacity: 0.2,
                boxShadow: "1px 10px 10px 1px black",
                width: "100%",
                height: "1px",
                background: "white",
                pointerEvents: "none",
              }}
            />
            <div className="f-box-list">
              {chatList.map((item) => (
                <Friend key={item.id} item={item} />
              ))}
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <section className={`f-section ${ativo}`}>
      <AddPlayer />
      <AddRequest />
      {ativo === "f-Msg-Component" ? (
        <ButtonBase className="f-Active-Button" style={{ borderRadius: "50%" }} onClick={() => handleActive()}>
          <ChatIcon />
        </ButtonBase>
      ) : null}
      {ativo === "f-Msg-Component-Ativo" ? <Chat /> : null}
    </section>
  );
}

export default FriendMsg;
