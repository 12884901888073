import React, { useState, useEffect, useRef } from "react";
import { useStore } from "../../context/storeContext";
import PlayerCard from "./playerCard";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
import firebaseApi from "../../services/firebaseApi";
import language from "../../assets/language/text";
import { Link } from "react-router-dom";
import sound from "../../assets/sounds";
import "animate.css";
import BtnContinuo from "../../components/buttons/BtnContinuo";
import DrawerStandings from "./DrawerStandings";
import Timer from "../../components/timer";
import apiWebsocket from "../../services/apiWebsocket";
import Chat from "./chat";
import LoadingPage from "../../components/pageloading/pageloading";
import { useSocket } from "../../context/webSocket";

const newPairing = sound.newPairing2;
const msgSound = sound.NewMessage;
const initialResults = { r1: 0, r2: 0, r3: 0 };
const initialVisualResults = { player1: 0, player2: 0 };
const initialMachData = {
  isTimerActive: false,
  playerOne: { id: "", check: false },
  playerTwo: { id: "", check: false },
  selectedWins: [0, 0, 0, 0, 0],
  timer: 20,
};
const EndPoint = process.env.REACT_APP_API_HOST;

function Room() {
  const chatInputRef = useRef(null);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const { id } = useParams();
  const { user, token } = useStore();
  const [data, setData] = useState();
  const [activeMatches, setActiveMatches] = useState([]);
  const [yourMatch, setYourMatch] = useState();
  const [matchData, setMatchData] = useState();
  const [exception, setException] = useState();
  const [timer, setTimer] = useState();
  const [results, setResults] = useState(initialResults);
  const [visualResults, setVisualResults] = useState(initialVisualResults);
  const [yourSeat, setYourSeat] = useState();
  const [confirm, setConfirm] = useState(false);
  const [userDeck, setUserDeck] = useState();
  const [player2, setPlayer2] = useState();
  const [preview, setPreview] = useState();
  const [confirmed, setConfirmed] = useState({
    playerOne: false,
    playerTwo: false,
  });
  const [chatList, setChatList] = useState([]);
  const [chatEVOList, setChatEVOList] = useState([]);
  const [player1Deck, setPlayer1Deck] = useState();
  const [player2Deck, setPlayer2Deck] = useState();
  const [winner, setWinner] = useState();
  const [thirdRound, setThirdRound] = useState(false);
  const [tourneyData, setTourneyData] = useState();
  const [connected, setConnected] = useState({
    playerOne: false,
    playerTwo: false,
  });
  const [chatData, setChatData] = useState();
  const [tournament, setTournament] = useState();
  const [round, setRound] = useState(1);
  const [messageTo, setMessageTo] = useState("ALL");
  const { socket } = useSocket();

  const text = language[lang].room_page;

  useEffect(() => {
    const getTourneyData = async () => {
      const getTourney = await api.get(`tourneys/${id}`).catch(console.log);
      if (getTourney) {
        return setData(getTourney.data);
      }
    };
    return getTourneyData();
  }, []);

  const fetchTournament = async () => {
    if (id) {
      const getTournament = await api.get(`tournaments/${id}`).catch(console.log);
      setTournament(getTournament.data);
    }
  };

  const SocketInitiate = () => {
    socket.on("connect", () => {});
    socket.on("disconnect", (e) => {
      // console.log(e);
    });
    socket.on("exception", setException);
    socket.on("matchData", setMatchData);
    socket.on("matchChatData", setChatData);
    socket.on("findAllActiveMatches", setActiveMatches);
    socket.on("timer", setTimer);
    socket.on("tourneyData", setTourneyData);
    socket.emit("joinMatchRoom", { tournamentId: id });
    socket.emit("tourneyData", { tournamentId: id });
    socket.emit("findAllActiveMatches", { tournamentId: id });
  };

  // ANCHOR  HandShake Socket & Player Enter Room

  useEffect(() => {
    if (id) {
      SocketInitiate();
      fetchTournament();
    }
  }, [id]);

  const GetOpponent = async () => {
    if (yourSeat && !player2) {
      const p1 = matchData.playerOne.id;
      const p2 = matchData.playerTwo.id;
      const getPlayer2 = await api.get(`users/${p1 === user._id ? p2 : p1}`).catch(console.log);
      if (getPlayer2) {
        setPlayer2(getPlayer2.data);
        newPairing.play();
      }
    }
  };

  //ANCHOR ATUALIZA SEMPRE QUE MODIFICA VALOR DO TOURNEY

  useEffect(() => {
    if (yourMatch) {
      socket.emit("joinMatchChatRoom", {
        tournamentId: id,
        matchId: yourMatch.id,
      });
      GetOpponent();
    }
  }, [yourMatch]);

  useEffect(() => {
    if (tourneyData) {
      const yourMatch = tourneyData.activeMatches.filter((item) => {
        if (item.playerOne != null || item.playerOne != null || item.playerOne.id === user._id || item.playerTwo.id != user._id) return true;
      });
      if (yourMatch.length > 0) {
        setYourMatch(yourMatch[0]);
      }
    }
  }, [tourneyData]);

  useEffect(() => {
    if (activeMatches) {
      const getConnectedMatch = activeMatches.filter((item) => {
        if (item.playerOne != null || item.playerOne != null || item.playerOne.id === user._id || item.playerTwo.id != user._id) return true;
      });
      if (getConnectedMatch.length > 0) {
        if (getConnectedMatch[0]?.round > round) {
          setRound(getConnectedMatch[0].round);
          GetOpponent();
        }
      }
    }
  }, [activeMatches]);

  useEffect(() => {
    if (chatData) {
      setChatList(chatData.messages);
      yourSeat === 1 ? setChatEVOList(chatData.playerOneSup) : setChatEVOList(chatData.playerTwoSup);
    }
  }, [chatData]);

  //ANCHOR Sempre que tiver atualização pelo Websocket, sobre sua match

  useEffect(() => {
    if (matchData) {
      if (!yourSeat) {
        matchData.playerOne.id === user._id ? setYourSeat(1) : setYourSeat(2);
      }
      setConnected({
        playerOne: matchData.playerOne && matchData.playerOne.inRoom,
        playerTwo: matchData.playerTwo && matchData.playerTwo.inRoom,
      });
      setResults(matchData.rounds);
    }
    if (!player2) {
      GetOpponent();
    }
  }, [matchData]);

  useEffect(() => {
    GetOpponent();
  }, [yourSeat]);

  useEffect(() => {
    if (results.r1 > 0 && results.r2 > 0 && results.r1 !== results.r2) {
      return setThirdRound(true);
    } else {
      return setThirdRound(false);
    }
  }, [results]);

  useEffect(() => {
    if (exception) {
      console.log("exception", exception);
    }
  }, [exception]);

  useEffect(() => {
    if (player2 && tournament?.game?.needsDeck) {
      getPlayersDeck();
    }
    if (!player2 && tournament) {
      GetOpponent();
    }
  }, [player2]);

  const getPlayersDeck = () => {
    const playerDeck = async (deck, setPlayerDeck, player) => {
      const getDeck = await api.get(`decks/${deck}`).catch(console.log);
      if (getDeck) {
        setPlayerDeck(getDeck.data);
      }
    };
    const getDeckName = (player, setPlayerDeck) =>
      tournament.players.filter((item) => {
        if (item.player === player._id) {
          return playerDeck(item.deck, setPlayerDeck, player);
        }
      });
    getDeckName(player2, setPlayer2Deck);
    getDeckName(user, setPlayer1Deck);
  };

  function getResults(round, result) {
    if (results.r3 > 0) {
      socket.emit("setMatchData", {
        ...matchData,
        tournamentId: id,
        rounds: {
          ...results,
          [round]: result,
          r3: 0,
        },
        results: {
          playerOneWins: visualResults.player1,
          playerTwoWins: visualResults.player2,
          draws: 0,
        },
        check: false,
      });
      setResults({
        ...results,
        [round]: result,
        r3: 0,
      });
    } else {
      socket.emit("setMatchData", {
        ...matchData,
        tournamentId: id,
        rounds: {
          ...results,
          [round]: result,
        },
        results: {
          playerOneWins: visualResults.player1,
          playerTwoWins: visualResults.player2,
          draws: 0,
        },
        check: false,
      });
      setResults({
        ...results,
        [round]: result,
      });
    }
  }

  // ANCHOR Visual Results
  useEffect(() => {
    const getResult = {
      player1: () => {
        const total = Object.keys(results).filter((item) => {
          if (results[item] === 1) return true;
        });
        return total.length;
      },
      player2: () => {
        const total = Object.keys(results).filter((item) => {
          if (results[item] === 2) return true;
        });
        return total.length;
      },
    };
    const playerResults = {
      player1: getResult.player1(),
      player2: getResult.player2(),
    };
    setVisualResults(playerResults);
  }, [results]);

  const handleSubmitMsg = (e) => {
    e.preventDefault();
    if (chatInputRef.current?.value.length > 0) {
      if (messageTo !== "EVO") {
        socket.emit("sendMessageMatch", {
          matchId: yourMatch.id,
          tournamentId: id,
          message: {
            author: user.nickname,
            body: chatInputRef.current?.value,
            to: messageTo,
          },
        });
      } else if (messageTo === "EVO") {
        socket.emit("sendSupportMessage", {
          matchId: yourMatch.id,
          tournamentId: id,
          message: {
            author: user.nickname,
            body: chatInputRef.current?.value,
            to: yourSeat == 1 ? "playerOneSup" : "playerTwoSup",
          },
        });
      }
      chatInputRef.current.value = "";
    }
  };

  const handleConfirmResult = () => {
    setConfirm(!confirm);
    socket.emit("setMatchData", {
      tournamentId: id,
      check: !confirm,
      results: {
        playerOneWins: visualResults.player1,
        playerTwoWins: visualResults.player2,
        draws: 0,
      },
      rounds: results,
    });
  };

  if (!data) return <h1>Pairing new opponent...</h1>;

  // if (!player2) return <h1>Pairing new opponent...</h1>;

  return (
    <div className="room-page-mobile">
      <div className="room-page-mobile-box1">
        <div className="room-page-mobile-box1__left">
          <h1 className="gradient-txt">round {yourMatch && yourMatch.round}</h1>
          {(results && !thirdRound && results.r1 > 0 && results.r2 > 0) || (thirdRound && results.r3 > 0) ? (
            <BtnContinuo
              classe="animate__animated animate__backInDown"
              style={{
                background: confirm ? "#ff28f1" : "transparent",
              }}
              handleConfirm={handleConfirmResult}
            >
              {confirm ? text[1] : text[0]}
            </BtnContinuo>
          ) : null}
        </div>
        <div className="room-page-mobile-box1__right">
          <div className="card-box">
            {player2 ? (
              <PlayerCard
                data={player2}
                deck={player2Deck}
                setPreview={setPreview}
                seat={yourSeat === 1 ? 2 : 1}
                side={2}
                connected={yourSeat === 2 ? connected.playerOne : connected.playerTwo}
                confirm={yourSeat === 1 ? matchData.playerTwo?.check : matchData.playerOne?.check}
                winner={winner}
                connected={yourSeat === 1 ? connected.playerTwo : connected.playerOne}
              />
            ) : (
              <PlayerCard />
            )}
          </div>
        </div>
      </div>

      <div className="room-page-mobile-box2">
        <div className="score-btns-box">
          {[1, 2].map((item) => {
            return (
              <div
                className="button-round-mobile"
                style={{
                  background: `${results[`r${item}`] === 1 ? "#ff28f1" : "transparent"}`,
                  zIndex: 3200,
                }}
                key={item}
                onClick={() => {
                  getResults(`r${item}`, 1);
                }}
              />
            );
          })}
          {results.r1 > 0 && results.r2 > 0 && results.r1 !== results.r2 ? (
            <button
              className="button-round-mobile"
              style={{
                background: `${results.r3 === 1 ? "#ff28f1" : "transparent"}`,
                zIndex: 3200,
              }}
              onClick={() => {
                getResults("r3", 1);
              }}
            />
          ) : null}
        </div>
        {visualResults && (
          <div
            className="room-page-mobile-box2__score"
            style={{
              display: "flex",
              flexDirection: yourSeat === 1 ? "row" : "row-reverse",
            }}
          >
            <b>{visualResults.player1}</b>
            <div className="room-page-mobile-box2__score-vs">
              <p>v</p>
              <p>s</p>
            </div>
            <b>{visualResults.player2}</b>
          </div>
        )}
        <div className="score-btns-box">
          {[1, 2].map((item) => {
            return (
              <div
                className="button-round-mobile"
                style={{
                  background: `${results[`r${item}`] === 2 ? "#ff28f1" : "transparent"}`,
                  zIndex: 3200,
                }}
                key={item}
                onClick={() => {
                  getResults(`r${item}`, 2);
                }}
              />
            );
          })}
          {results.r1 > 0 && results.r2 > 0 && results.r1 !== results.r2 ? (
            <div
              className="button-round-mobile"
              style={{
                background: `${results.r3 === 2 ? "#ff28f1" : "transparent"}`,
                zIndex: 3200,
              }}
              onClick={() => {
                getResults("r3", 2);
              }}
            />
          ) : null}
        </div>
      </div>

      <div className="room-page-mobile-box3">
        <div className="room-page-mobile-box3__top">
          <div className="card-box">
            <PlayerCard
              data={user}
              deck={player1Deck}
              style={{ zIndex: 1 }}
              setPreview={setPreview}
              seat={yourSeat}
              connected={yourSeat === 1 ? connected.playerOne : connected.playerTwo}
              side={1}
              confirm={yourSeat === 1 ? matchData?.playerOne?.check : matchData?.playerTwo?.check}
              winner={winner}
              connected={yourSeat === 1 ? connected?.playerOne : connected?.playerTwo}
            />
          </div>
        </div>
        <div className="room-page-mobile-box3__bottom">
          {/* <div className="room-page-mobile-box3__bottom-group"> */}
          <BtnContinuo>chat</BtnContinuo>
          <DrawerStandings id={id} tournament={tourneyData} match={yourMatch} winner={winner} />
          <BtnContinuo>drop</BtnContinuo>
        </div>
      </div>
      <div className="room-page-mobile-chat">
        {/* <Chat
          chatList={messageTo === "ALL" ? chatList : chatEVOList}
          chatInputRef={chatInputRef}
          handleSubmitMsg={handleSubmitMsg}
          user={user}
        /> */}
      </div>
      {preview && (
        <img
          style={{
            position: "absolute",
            width: "350px",
            borderRadius: "20px",
            top: "23%",
          }}
          src={preview}
          alt="Preview"
        />
      )}
    </div>
  );
}

export default Room;
