import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import language from "../../assets/language/text";
import { useLocalDB } from "../../context/LocalDB";
import BtnContinuo from "../buttons/BtnContinuo";
import sounds from "../../assets/sounds/";
import Counter from "./counter";
import NumberFromWei from "../../utils/fromWei";

const dateNow = new Date().toLocaleDateString();

const click = sounds.btn;

const List = ({ evento }) => {
  const { lang, mobile } = useLocalDB();
  const text = language[lang].player_page;
  const listEvents = useRef(null);

  const GetDate = (date) => {
    if (date) {
      const getDateString = date.split("T", 1);
      const getRealDateSplited = getDateString[0].split("-");
      const getDateServer = new Date(getRealDateSplited[1] + "/" + getRealDateSplited[2] + "/" + getRealDateSplited[0]);
      // const getDateLocal = new Date(getRealDateSplited[2] + "/" + getRealDateSplited[1] + "/" + getRealDateSplited[0]);
      const getServer = process.env.REACT_APP_APP_ENV;
      return lang === "pt"
        ? getServer === "mainet"
          ? getDateServer.toLocaleDateString("pt-br")
          : getDateServer.toLocaleDateString("pt-br")
        : getServer === "mainet"
        ? getDateServer.toLocaleDateString("en-us")
        : getDateServer.toLocaleDateString("en-us");
    }
  };

  useEffect(() => {
    if (evento) {
      if (listEvents.current?.scrollHeight > listEvents.current?.offsetHeight) {
        listEvents.current.scrollTop = listEvents.current.offsetHeight - listEvents.current.scrollHeight;
      }
    }
  }, [evento]);

  const ListEvent = ({ item, index }) => {
    const [dateEvent, setDateEvent] = useState({
      dateString: null,
      dateEvent: null,
    });
    const [counterDate, setCounterDate] = useState({ d: "", m: "", a: "" });
    const [difference, setDifference] = useState(false);
    const minimalDaysToShowCounter = 7;

    // const getPlayerActived = () => {
    //   console.log(item);
    // };

    function calculateAge(date, hour) {
      const data = new Date(date);
      const brDate = data.toLocaleDateString();
      const eventDate = new Date(`${brDate} ${hour}`);
      const DateConfirm = brDate.split("/");
      const DateNowConfirm = dateNow.split("/");
      const differenceDate = DateConfirm[1] - DateNowConfirm[1] <= minimalDaysToShowCounter;
      const getDiferenceNegativeDate = new Date(dateNow) <= eventDate;
      const getDiference = getDiferenceNegativeDate && differenceDate;
      setDifference(getDiference);
      setCounterDate({
        d: DateConfirm[1],
        m: DateConfirm[0],
        a: DateConfirm[2],
      });
      setDateEvent({ dateString: brDate, dateEvent: eventDate });
    }

    useEffect(() => {
      calculateAge(item.startDate, item.hour);
      // getPlayerActived();
    }, []);

    return (
      <div
        className="list-item"
        key={item.id}
        style={{
          position: "relative",
          // pointerEvents: item.status === "ENDED" ? "none" : "all",
          opacity: item.status === "ENDED" ? 0.6 : 1,
          height: "90%",
        }}
      >
        {/* {item.status === "WAITING" && difference && (
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <Counter date={counterDate} hour={item.hour} />
          </div>
        )} */}
        <Link
          className="list-item__link"
          style={{ textDecoration: "none" }}
          to={item.status === "ENDED" ? `/badges/${item._id}` : item.status === "STARTED" ? `/room/${item._id}` : `/event/${item._id}`}
          onClick={() => {
            click.currentTime = 0;
            click.play();
          }}
        >
          <div className="list-item__link__header">
            <span>{GetDate(item.startDate)}</span>
            <span>{item.hour}</span>
            <span>- {item.organizer.name}</span>
          </div>
          {item.status === "STARTED" ? (
            <Link
              className="button-enter-tournament"
              style={{
                height: "80px",
                fontSize: lang == "pt" ? "1.4rem" : "1.6rem",
                inlineSize: "150px",
                overflowWrap: "break-word",
                letterSpacing: "2px",
                fontFamily: "gilroy-bold",
                padding: "11px",
              }}
              to={mobile ? `/mobile/room/${item._id}` : `/room/${item._id}`}
            >
              {lang == "pt" ? "PAINEL DO JOGADOR" : "PLAYER PANEL"}
            </Link>
          ) : null}
          <div className="list-item__link__title">
            <div className="dot" />
            <span>{item.name}</span>
          </div>
          <div className="list-item__link__footer">
            <span className="list-item__link__footer__sub">
              <i className="fas fa-clipboard-check"></i> inscrito
            </span>
            <span className="list-item__link__footer__trophy">
              <div className="trophyIcon" />
              {NumberFromWei(item.reward.amount.toString())}
            </span>
            <span className="list-item__link__footer__users">
              <i className="fas fa-users"></i>
              {item.playerCount ? item.playerCount : 0}
            </span>
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div className="listGrid" style={{ marginTop: mobile ? "-2rem" : "" }}>
      <div className="listDiv">
        {!mobile && (
          <div className="tourments-button-box">
            <BtnContinuo component={Link} to="/tournaments" classe="default-button list-button">
              {text[0]}
            </BtnContinuo>
          </div>
        )}

        <ul className="listUL" ref={listEvents} style={{ flexDirection: "column" }}>
          <div className="linhaList" />
          {evento ? (
            evento.length > 0 ? (
              evento.map((item, index) => {
                return <ListEvent key={item._id} item={item} index={index} />;
              })
            ) : (
              <div style={{ marginLeft: "4vw" }}>{lang === "pt" ? "Carregando Torneios..." : "Loading Tournaments..."}</div>
            )
          ) : (
            <p style={{ marginLeft: "4vw" }}>{text[5]}</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default List;
