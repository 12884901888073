import React, { useState, useEffect } from "react";
import "./messages.scss";
import { Link } from "react-router-dom";
import { useStore } from "../../context/storeContext";
import { useFetch } from "../../hooks/useFetch";
import language from "../../assets/language/text";
import { useLocalDB } from "../../context/LocalDB";
import LoadingPage from "../pageloading/pageloading";
import { Snackbar } from "@material-ui/core";

const initialState = {
  height: "80px",
  degrade: "linear-gradient(black, transparent 80%)",
};
function Index(props) {
  const [degrade, setDegrade] = useState(initialState.degrade);
  const { lang, messageAlert, setMessageAlert } = useLocalDB();
  const [height, setHeight] = useState(initialState.height);
  const [opacity, setOpacity] = useState(1);
  const [open, setOpen] = useState(false);
  const [cursor, setCursor] = useState();
  const text = language[lang].messages;
  const { user } = useStore();

  useEffect(() => {
    setMessageAlert(true);
  }, []);

  useEffect(() => {
    handleOpen();
    setOpen(true);
    setHeight("70px");
    setCursor();
    setOpacity(0.4);
  }, [messageAlert]);

  const handleOpen = () => {
    if (!open) {
      setOpen(true);
      setHeight("200px");
      setCursor();
      setOpacity(0.4);
      setDegrade("linear-gradient(black, transparent 90%)");
    } else {
      setOpen(false);
      setHeight(initialState.height);
      setCursor("pointer");
      setDegrade(initialState.degrade);
      setOpacity(1);
    }
  };

  return (
    <Snackbar className="topCenter" open={messageAlert} autoHideDuration={3000} onClose={() => setMessageAlert(false)}>
      <div className="pageMessage" style={{ height: height, cursor: cursor }} onClick={handleOpen}>
        <span
          className="degradeMsg"
          style={{
            opacity: opacity,
            background: degrade,
            pointerEvents: "none",
          }}
        />
        <div className="msgBlock">
          <div
            style={{
              height: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            {user && user.notifications?.length > 0 ? (
              user.notifications.map((item) => {
                return (
                  <p>
                    {open ? (
                      <Link to={item.path}>
                        <span>{item.origin}</span> <span>{item.msg}</span>
                        <span> {text[0]}</span>
                      </Link>
                    ) : (
                      <p>
                        <span>{item.origin}:</span> <span>{item.msg}</span>
                      </p>
                    )}
                  </p>
                );
              })
            ) : (
              <p>{text[1]}</p>
            )}
          </div>
        </div>
      </div>
    </Snackbar>
  );
}

export default Index;
