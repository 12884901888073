export function calculateAge(date) {
  if (date) {
    const Idade = new Date(date).toISOString().slice(0, 10);
    const getIdadeSplit = Idade.split("-");
    const birthDate = { year: getIdadeSplit[0], month: getIdadeSplit[1], day: getIdadeSplit[2] };
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    let calculatedAge = currentYear - birthDate.year;

    if (currentMonth < birthDate.month - 1) {
      calculatedAge--;
    }
    if (birthDate.month - 1 == currentMonth && currentDay < birthDate.day) {
      calculatedAge--;
    }
    return calculatedAge;
  }
}
