import React, { useState, useEffect } from "react";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
import Card from "./card";
import LoadingPage from "../../components/pageloading/pageloading";
import { useFetch } from "../../hooks/useFetch";

function Index() {
  const [cartModal, setCartModal] = useState();
  const { cartItems } = useLocalDB();
  const [search, setSearch] = useState();
  const { data } = useFetch(`store`);

  const handleCartModal = () => {
    cartModal === "cartModalOpen" ? setCartModal("") : setCartModal("cartModalOpen");
  };
  const CartItems = () => {
    return <div className={`CartModal ${cartModal}`}></div>;
  };
  if (!data) return <LoadingPage size={100} />;
  return (
    <div className="storePage">
      <div className="HeaderStore">
        <div className="default-input-i">
          <input placeholder="FILTRAR" value={search} onChange={(e) => setSearch(e.target.value)} />
          <i className="fas fa-search fa-lg"></i>
        </div>
        <div className="cartBox">
          <div className={`cartItemCount ${cartItems.length > 0 ? "countAtivo" : null}`}>{cartItems.length}</div>
          <i className="fas fa-shopping-cart fa-2x" onClick={handleCartModal}></i>
          <CartItems />
        </div>
      </div>
      <div className="bodyStore">
        <div className="bodyStore_items">
          {data.map((item) => {
            return <Card key={item._id} data={item} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Index;
