const resultBolls = (
  results,
  confirmed,
  active,
  winner,
  checkIn,
  socket,
  matchData,
  id,
  visualResults,
  setAlert,
  setAlertMsg,
  setSeverity,
  lang,
  setResults
) => {
  const confirmBlock = !confirmed.playerOne || !confirmed.playerOne;

  function getResults(round, result) {
    if (active) {
      if (winner) {
        return setAlert(true), setAlertMsg(lang == "pt" ? "Essa partida já foi submetida." : "This match was already submitted"), setSeverity("error");
      }
      if (!checkIn.player1 || !checkIn.player2) {
        return (
          setAlert(true),
          setAlertMsg(lang == "pt" ? "Os dois precisam fazer o check-in para submeter os resultados." : "Both players need to check-in to submit the results"),
          setSeverity("error")
        );
      }
      if (confirmBlock) {
        if (results.r3 > 0) {
          socket.emit("setMatchData", {
            ...matchData,
            tournamentId: id,
            rounds: {
              ...results,
              [round]: result,
              r3: 0,
            },
            checkIn: true,
            results: {
              playerOneWins: visualResults.player1,
              playerTwoWins: visualResults.player2,
              draws: 0,
            },
            check: false,
          });
          setResults({
            ...results,
            [round]: result,
            r3: 0,
          });
        } else {
          socket.emit("setMatchData", {
            ...matchData,
            tournamentId: id,
            rounds: {
              ...results,
              [round]: result,
            },
            results: {
              playerOneWins: visualResults.player1,
              playerTwoWins: visualResults.player2,
              draws: 0,
            },
            check: false,
            checkIn: true,
          });
          setResults({
            ...results,
            [round]: result,
          });
        }
      }
    }
  }
  return (
    <>
      <div className="markBox">
        {[1, 2].map((item, index) => {
          return (
            <div
              className="button-round"
              style={{
                background: `${results[`r${item}`] === 1 ? "#ff28f1" : "transparent"}`,
                zIndex: 3200,
              }}
              key={`btnResults${index}`}
              onClick={() => {
                getResults(`r${item}`, 1);
              }}
            />
          );
        })}
        {results.r1 > 0 && results.r2 > 0 && results.r1 !== results.r2 ? (
          <button
            className="button-round"
            style={{
              background: `${results.r3 === 1 ? "#ff28f1" : "transparent"}`,
              zIndex: 3200,
            }}
            onClick={() => {
              getResults("r3", 1);
            }}
          />
        ) : null}
      </div>
      <div className="markBox">
        {[1, 2].map((item) => {
          return (
            <div
              className="button-round"
              style={{
                background: `${results[`r${item}`] === 2 ? "#ff28f1" : "transparent"}`,
                zIndex: 3200,
              }}
              key={item}
              onClick={() => {
                getResults(`r${item}`, 2);
              }}
            />
          );
        })}
        {results.r1 > 0 && results.r2 > 0 && results.r1 !== results.r2 ? (
          <div
            className="button-round"
            style={{
              background: `${results.r3 === 2 ? "#ff28f1" : "transparent"}`,
              zIndex: 3200,
            }}
            onClick={() => {
              getResults("r3", 2);
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default resultBolls;
