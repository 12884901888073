import React, { useState, useEffect, useRef } from "react";
import * as mtg from "mtgsdk";
import "./card.scss";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import language from "../../assets/language/text";
import DeckOptions from "../deck/options/deckAddOptions";
import sound from "../../assets/sounds";
import deckTypes from "../../components/deck/options/deckTypes";
import cardListBan from "../deck/options/banlist";
import { Dialog } from "@material-ui/core";

const format = DeckOptions.format;

const ImportCards = ({ open, setOpen, fetchUser, value, handleClose, searchFormat }) => {
  const [pastedText, setPastedText] = useState();
  const [tourneyOptions, setTourneyOptions] = useState(format.MODERN);
  const [commanderCard, setCommanderCard] = useState([]);
  const [companionCard, setCompanionCard] = useState([]);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const [CardPreview, setCardPreview] = useState();
  const [deckName, setDeckName] = useState();
  const [deckCards, setDeckCards] = useState([]);
  const [sideCards, setSideCards] = useState([]);
  const [deckType, setDeckType] = useState([]);
  const [canHaveCommander, setCanHaveCommander] = useState(false);
  const [canHaveCompanion, setCanHaveCompanion] = useState(false);
  const [companionListCards, setCompanionListCards] = useState([]);
  const [commanderListCards, setCommanderListCards] = useState([]);
  const [banCard, setBanCard] = useState([]);
  const text = language[lang].addDeck;

  useEffect(() => {
    if (searchFormat) {
      setTourneyOptions(format[searchFormat.toUpperCase()]);
    }
  }, [searchFormat]);

  useEffect(() => {
    const getColors = deckCards.reduce((a, b) => {
      if (b.card) {
        if (b.card.colors) return a + b.card.colors;
      }
    }, []);
    const getSideColors = sideCards.reduce((a, b) => {
      if (b.card) {
        if (b.card.colors) return a + b.card.colors;
      }
    }, []);
    if (getColors) {
      const black = getColors.includes("Black");
      const white = getColors.includes("White");
      const blue = getColors.includes("Blue");
      const green = getColors.includes("Green");
      const red = getColors.includes("Red");
      const colors = { b: black, w: white, u: blue, g: green, r: red };
      const deckTypeReturn = Object.keys(colors).filter((item) => {
        if (colors[item] === true) return item;
      });
      const containsOnly = (ar1, ar2) => {
        return ar2.every((item) => ar1.includes(item));
      };
      const decktypefilter = Object.keys(deckTypes).filter((item) => {
        if (containsOnly(deckTypeReturn, deckTypes[item])) return true;
      });
      if (decktypefilter[decktypefilter.length - 2] !== undefined) {
        return setDeckType(decktypefilter[decktypefilter.length - 2]);
      } else {
        return setDeckType("colorless");
      }
    }
  }, [deckCards, sideCards]);

  const getDeckCards = (deck) => {
    if (deck) {
      const filteredDeck = deck.filter((item) => item != "");
      const mapReturn = filteredDeck
        .map((item, index) => {
          if (item.includes("(")) {
            const splitItem = item.split("(", [1]);
            const splitCount = item.split(" ", [1]);
            const getSet = item.split("(", 2);
            const getSetandNbr = getSet[1].split(")");
            const set = getSetandNbr[0];
            const nbrSet = getSetandNbr[1];
            const cardName = splitItem.map((item) => {
              return item.slice(2, item.length);
            });
            return {
              count: parseInt(splitCount[0]),
              card: cardName[0],
              set: set,
              nbr: parseInt(nbrSet.split("\r", [1])[0]),
            };
          } else {
            const cardCount = item.substring(0, 2);
            const cardName = item.substring(2);
            return {
              count: +cardCount.trim(),
              card: cardName,
              set: null,
              nbr: null,
            };
          }
        })
        .filter((item) => {
          if (item !== undefined) {
            return true;
          }
        });
      return mapReturn;
    }
  };

  useEffect(() => {
    const cardGet = () => {
      const pastetext = value;
      if (pastetext.includes("Deck")) {
        let commanderIndex = null;
        let companionIndex = null;
        let deckIndex = null;
        let sideIndex = null;
        const arrayDeck = pastetext.toString().split("\n");
        setPastedText(arrayDeck);
        arrayDeck.map((item, index) => {
          if (item.toString() == "Commander") commanderIndex = index;
          if (item.toString() == "Companion") companionIndex = index;
          if (item.toString() == "Deck") deckIndex = index;
          if (item.toString() == "Sideboard") sideIndex = index;
        });
        const commander = arrayDeck.filter((item, index) => commanderIndex != null && commanderIndex + 1 === index);
        const companion = arrayDeck.filter((item, index) => companionIndex != null && companionIndex + 1 === index);
        const deck = arrayDeck.filter((item, index) => (!sideIndex ? index > deckIndex : index > deckIndex && index < sideIndex));
        const side = arrayDeck.filter((item, index) => sideIndex && index > sideIndex);
        const deckCards = getDeckCards(deck);
        const sideCards = getDeckCards(side);
        const getCommander = getDeckCards(commander);
        const getCompanion = getDeckCards(companion);
        if (getCommander.length > 0) fetchDeck(getCommander, setCommanderCard);
        if (getCompanion.length > 0) fetchDeck(getCompanion, setCompanionCard);
        fetchDeck(deckCards, setDeckCards);
        fetchDeck(sideCards, setSideCards);
      } else if (
        pastetext.includes("Island") ||
        pastetext.includes("Swamp") ||
        pastetext.includes("Mountain") ||
        pastetext.includes("Forest") ||
        pastetext.includes("1")
      ) {
        const getSideBoardTxtOut = pastetext.toString().replace("Sideboard", "");
        const arrayDeck = getSideBoardTxtOut.toString().split("\n");
        setPastedText(arrayDeck);
        let diffIndex = null;
        arrayDeck.map((item, index) => {
          if (item === "" && diffIndex === null) {
            return (diffIndex = index);
          }
        });
        if (diffIndex) {
          const deck = arrayDeck.filter((item, index) => index < diffIndex);
          const side = arrayDeck.filter((item, index) => index > diffIndex);
          const deckReturnCards = getDeckCards(deck);
          const sideReturnCards = getDeckCards(side);
          fetchDeck(deckReturnCards, setDeckCards);
          fetchDeck(sideReturnCards, setSideCards);
        }
      } else {
        setAlert(true);
        setAlertMsg("Formado Invalido, copie a lista diretamente do Magic Arena");
        setSeverity("error");
        handleClose();
      }
    };
    cardGet();
  }, []);

  useEffect(() => {
    if (deckCards.length <= 60) {
      setCanHaveCompanion(true);
    }
    if (deckCards.length > 60 && sideCards.includes("Yorion, Sky Nomad")) {
      setCanHaveCompanion(true);
    }
    if (deckCards.length > 60 && !sideCards.includes("Yorion, Sky Nomad")) {
      setCanHaveCommander(true);
    }
    if (canHaveCompanion && sideCards.length > 0) {
      let companions = [];
      sideCards.filter((item) => {
        const getWhiteList = DeckOptions.companions.filter((companion) => item.name === companion);
        if (getWhiteList.length > 0) {
          companions.push(item);
        }
      });
      if (companions.length > 0) {
        setCompanionListCards(companions);
        setCompanionCard([companions[0]]);
      }
    }
    if (canHaveCommander && deckCards.length > 0 && sideCards.length === 0) {
      let commanders = [];
      const getPossibleCommanders = deckCards.filter((item) => item.card?.supertypes?.includes("Legendary") && item.card?.types?.includes("Creature"));
      if (getPossibleCommanders.length > 0) {
        commanders = getPossibleCommanders;
      }
      if (commanders.length > 0) {
        setCommanderListCards(commanders);
        setCommanderCard([commanders[0]]);
      }
    }
  }, [canHaveCommander, canHaveCompanion, deckCards, sideCards]);

  const fetchDeck = async (itemMap, setItem) => {
    if (itemMap !== undefined) {
      const deckCard = async (card) => {
        setItem((cards) => [...cards, card]);
      };
      const banListAdd = (card) => {
        setBanCard((cards) => [...cards, card]);
      };
      const getMapped = itemMap.map(async (item) => {
        const objectRequestWithLegality = {
          // gameFormat: searchFormat === "Alchemy" ? "Standard" : searchFormat.toLowerCase().charAt(0).toUpperCase() + this.substr(1),
          gameFormat: searchFormat,
          legality: "Legal",
          page: 1,
          pageSize: 30,
        };
        const cardsplit = item.card.split("/", [1]);
        const getCard = await mtg.card.where({
          name: cardsplit[0].trim(),
          ...objectRequestWithLegality,
          // contains: "imageUrl",
        });
        if (getCard.length > 0) {
          const filterCard = getCard.filter((card) => {
            if (card?.name?.trim().includes(cardsplit[0].trim()) && card.imageUrl) {
              return true;
            }
          });
          if (filterCard.length > 0) {
            const getSameSet = filterCard.filter((card) => card.set.includes(item.set));
            return deckCard({
              count: item.count,
              card: getSameSet.length > 0 ? getSameSet[0] : filterCard[0],
              name: item.card,
            });
          } else {
            return deckCard({
              count: item.count,
              card: getCard[0],
              name: item.card,
            });
          }
        } else {
          return banListAdd(item.card);
        }
        // }
      });
      return getMapped;
    }
  };

  function handleSelectFormat(e) {
    setTourneyOptions(format[e.target.value]);
  }

  const handleAddDeck = async () => {
    if (deckName && deckName.length > 3) {
      const deckAdd = {
        deckName: deckName,
        deckCards: deckCards,
        sideBoard: sideCards,
        commander: commanderCard.length > 0 && commanderCard[0],
        companion: companionCard.length > 0 && companionCard[0],
        format: tourneyOptions.name,
        deckType: deckType,
      };
      if (deckName !== undefined) {
        const putDeck = await api.post(`decks`, deckAdd).catch(console.log);
        if (putDeck) {
          setAlert(true);
          setAlertMsg(text[2]);
          setSeverity("success");
          fetchUser();
          handleClose();
          // setTimeout(() => {
          //   history.push("/");
          // }, 800);
        } else {
          setAlert(true);
          setAlertMsg(text[3]);
          setSeverity("error");
        }
      }
    } else {
      setAlert(true);
      setAlertMsg("Deck precisa de um nome com mais de 3 caracteres para continuar.");
      setSeverity("error");
    }
  };

  const handleDeckName = (e) => {
    setDeckName(e.target.value.toUpperCase());
  };

  return (
    <Dialog
      open={open}
      style={{ padding: "10px" }}
      onClose={handleClose}
      PaperProps={{ style: { background: "transparent", maxWidth: "90vw", maxHeight: "90vh" } }}
      BackdropProps={{ style: { background: "rgba(0,0,0,0.9)" } }}
    >
      <div
        style={{
          height: "60px",
          display: "flex",
          paddingTop: "30px",
          alignItems: "center",
          justifyContent: "space-between",
          // margin: "30px",
        }}
      >
        {!pastedText ? (
          <></>
        ) : (
          <>
            <input className="inputNickname" value={deckName} onChange={handleDeckName} placeholder="NOME DO DECK" />
            <div classNAme="default-input" style={{ color: "var(--pink)" }}>
              {searchFormat?.toUpperCase()}
            </div>
            {/* <select
              ref={selectBar}
              style={{
                fontSize: "1.1rem",
                padding: "9px",
                width: "150px",
                height: "50px",
              }}
              className="default-input"
              onChange={handleSelectFormat}
              name="tourneyFormat"
            >
              {Object.keys(DeckOptions.format).map((item, index) => {
                const format = DeckOptions.format[item];
                return (
                  <option key={item + "deckOptions" + index} selected={item === searchFormat?.toUpperCase() && true} value={format.name}>
                    {format.name}
                  </option>
                );
              })}
            </select> */}
            <button className="btnInport" onClick={handleAddDeck}>
              {text[0]}
            </button>
          </>
        )}
      </div>
      <>
        {deckCards && (
          <div
            style={{
              height: "90%",
              maxWidth: "900px",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div>
              <table
                className="tableCards"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  background: "rgba(0,0,0,0.5)",
                  backdropFilter: "blur(15px)",
                  padding: "20px",
                  margin: "20px",
                  // height: "600px",
                  width: "90vw",
                  height: "90vh",
                  overflow: "auto",
                  width: "400px",
                }}
              >
                <label style={{ fontSize: "2rem" }}>DECK</label>
                {deckCards &&
                  deckCards.map((item, index) => {
                    if (item.card?.name) {
                      return (
                        <tr
                          style={{ minHeight: "28px", maxHeight: "50px" }}
                          key={item.card.name + index}
                          onMouseOver={(e) => {
                            // setMousePosition({ x: e.screenX, y: e.screenY });
                            setCardPreview(item.card.imageUrl);
                          }}
                          onMouseOut={() => setCardPreview()}
                        >
                          <td>{item.count}</td>
                          <td>{item.card.name}</td>
                        </tr>
                      );
                    }
                  })}
              </table>
            </div>
            <table
              className="tableCards"
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
                background: "rgba(0,0,0,0.5)",
                backdropFilter: "blur(15px)",
                padding: "20px",
                margin: "20px",
                width: "300px",
              }}
            >
              {companionCard.length > 0 && <label style={{ fontSize: "2rem" }}>Companion</label>}
              {companionCard.length > 0 &&
                companionCard.map((item, index) => {
                  return (
                    <tr
                      style={{ height: "28px" }}
                      key={item.card.name + index + "sidecard"}
                      onMouseOver={(e) => {
                        // console.log(e);
                        setCardPreview(item.card.imageUrl);
                      }}
                      onMouseOut={() => setCardPreview()}
                    >
                      <td>{item.count}</td>
                      <td>{item.card.name}</td>
                    </tr>
                  );
                })}
              {commanderCard.length > 0 && <label style={{ fontSize: "2rem" }}>Commander</label>}
              {commanderCard.length > 0 &&
                commanderCard.map((item, index) => {
                  return (
                    <tr
                      key={item.card.name + index + "sidecard" + index}
                      onMouseOver={(e) => {
                        setCardPreview(item.card.imageUrl);
                      }}
                      onMouseOut={() => setCardPreview()}
                    >
                      <td style={{ height: "38px", inlineSize: "200px", overflow: "hidden", wordWrap: "noWrap", padding: "5px" }}>{item.card.name}</td>
                      <td
                        style={{ width: "50px", maxHeight: "20px", padding: "3px", borderRadius: "50%", textAlign: "center" }}
                        onClick={() => {
                          let commander = commanderCard;
                          commander.splice(index, 1);
                          setCommanderCard(commander);
                        }}
                      >
                        X
                      </td>
                    </tr>
                  );
                })}
              {sideCards.length > 0 && (
                <>
                  <label style={{ fontSize: "2rem", marginTop: "1.5rem" }}>{text[1]}</label>
                  {sideCards.map((item, index) => {
                    return (
                      <tr
                        style={{ minHeight: "28px", maxHeight: "50px" }}
                        key={item.card.name + index + "sidecard"}
                        onMouseOver={(e) => {
                          setCardPreview(item.card.imageUrl);
                        }}
                        onMouseOut={() => setCardPreview()}
                      >
                        <td>{item.count}</td>
                        <td>{item.card.name}</td>
                      </tr>
                    );
                  })}
                </>
              )}
              {canHaveCommander ? (
                <div style={{ padding: "1rem" }}>
                  <label>{lang === "pt" ? "Qual é seu Commander" : "Who's your Commander"}</label>
                  <select
                    className="default-input"
                    onClick={() => commanderCard.length === 0 && setCommanderCard([commanderListCards[0]])}
                    onChange={(e) => {
                      if (commanderCard.length < 2) setCommanderCard([...commanderCard, commanderListCards[e.target.value]]);
                    }}
                  >
                    {commanderListCards.map((item, index) => {
                      return (
                        <option key={item.name} value={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : canHaveCompanion ? (
                <div style={{ padding: "1rem" }}>
                  <label>{lang === "pt" ? "Qual é seu Companion" : "Who's your Companion"}</label>
                  <select
                    className="default-input"
                    onChange={(e) => {
                      setCompanionCard([companionListCards[e.target.value]]);
                    }}
                  >
                    {companionListCards.map((item, index) => {
                      return (
                        <option key={item.name} value={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}
            </table>
          </div>
        )}
      </>
      <div style={{ position: "absolute", right: 0, top: 0 }} onClick={() => setOpen(false)}>
        <i class="far fa-times-circle fa-2x"></i>
      </div>
      {CardPreview && CardPreview !== undefined && (
        <img
          style={{
            position: "absolute",
            width: "200px",
            bottom: "2rem",
            right: "2rem",
            // top: mousePosition.y - 150,
            // left: mousePosition.x - 330,
            pointerEvents: "none",
          }}
          src={CardPreview}
          alt="cardPreview"
        />
      )}
      {banCard && <BanList list={banCard} setBanCard={setBanCard} />}
    </Dialog>
  );
};

const BanList = ({ list, setBanCard }) => {
  const [open, setOpen] = useState(false);
  const { lang } = useLocalDB();
  useEffect(() => {
    if (list.length > 0) {
      setOpen(true);
    }
  }, [list]);
  return (
    <Dialog open={open} PaperProps={{ style: { background: "rgba(0,0,0,0.9)" } }}>
      <div style={{ padding: "1rem", borderRadius: "15px" }}>
        <h3>{lang === "pt" ? "Essas cartas são inválidas ou banidas para esse formato." : "These cards are invalid or banished for this format"}</h3>
        <div style={{ maxHeight: "500px", overflow: "auto", padding: "1rem" }}>
          <table>
            {list?.map((item) => {
              return (
                <tr>
                  <td>{item}</td>
                </tr>
              );
            })}
          </table>
        </div>
        <button
          className="default-input"
          onClick={() => {
            setBanCard([]);
            setOpen(false);
          }}
        >
          OK
        </button>
      </div>
    </Dialog>
  );
};

export default ImportCards;
