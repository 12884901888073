import React from "react";

const UIButton = ({ children, className, component: Component, theme, ...restProps }) => {
  return (
    <Component className={`ui-button ui-button--${theme} ${className}`} {...restProps}>
      {children}
    </Component>
  );
};

UIButton.defaultProps = {
  component: "a",
  className: "",
  children: "button",
  theme: "bordered-white",
};

export default UIButton;
