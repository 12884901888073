import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { useStore } from "../../context/storeContext";
import SwiperCore, { Keyboard, EffectCoverflow, Pagination, Navigation } from "swiper";
import sound from "../../assets/sounds/index";
import Pop from "../PopOver";

SwiperCore.use([Keyboard, EffectCoverflow, Pagination, Navigation]);
const slideCard = sound.slideCard;

function Carousel({ data, tourney }) {
  const [matches, setMatches] = useState([]);

  // const { user } = useStore();

  const getWinner = (player1, player2, item) => {
    if (player1 > player2) return item.playerOne.nickname;
    if (player1 < player2) return item.playerTwo.nickname;
  };

  useEffect(() => {
    if (data) {
      setMatches(data);
    }
  }, [data]);

  return (
    <>
      <div style={{ height: "23vh" }}>
        <Swiper
          style={{
            "--swiper-navigation-color": "#2ac1d8",
            "--swiper-pagination-color": "#2ac1d8",
          }}
          keyboard={{ enabled: true }}
          spaceBetween={10}
          effect={"coverflow"}
          centeredSlides={true}
          slidesPerView={"auto"}
          slidesPerGroupSkip={4}
          coverflowEffect={{
            rotate: 5,
            stretch: 0,
            depth: 150,
            modifier: 1,
            slideShadows: true,
          }}
          navigation={true}
        >
          {matches.length > 0 &&
            matches.map((item) => {
              return (
                <SwiperSlide
                  style={{
                    width: "300px",
                    height: "15vh",
                    display: "flex",
                  }}
                  key={item}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      opacity: "0.8",
                      backgroundColor: item.active ? "var(--pink)" : "var(--lightgreen)",
                      borderRadius: "15px",
                    }}
                  />
                  <div style={{ width: "100%", height: "100%", padding: "10px" }}>
                    <div style={{ textAlign: "center", fontFamily: "gilroy-bold" }}>Match {item.matchNumber}</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        height: "8vh",
                      }}
                    >
                      <h2 style={{ fontSize: ".9rem", textAlign: "center", inlineSize: "80px" }}>
                        <Pop cutValue={10}>{item.playerOne.name ? item.playerOne.name : item.playerOne.nickname}</Pop>
                      </h2>
                      <h4>{item.result ? item.result.p1 : 0}</h4>
                      <h4>VS</h4>
                      <h4>{item.result ? item.result.p2 : 0}</h4>
                      <h2 style={{ fontSize: "0.9rem", textAlign: "center", inlineSize: "80px" }}>
                        {!item.playerTwo.bye ? <Pop cutValue={10}>{item.playerTwo.name ? item.playerTwo.name : item.playerTwo.nickname}</Pop> : "BYE"}
                      </h2>
                    </div>
                    <div style={{ textAlign: "center" }}>{item.active ? "Playing..." : `${getWinner(item.result.p1, item.result.p2, item)} WON`}</div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
}

export default Carousel;
