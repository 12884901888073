import { useEffect, useState, useRef } from "react";
import { useLocalDB } from "../../context/LocalDB";
import language from "../../assets/language/text";
import api from "../../services/api";
import BtnContinuo from "../buttons/BtnContinuo";
import { Link } from "react-router-dom";
import { useStore } from "../../context/storeContext";
import Pop from "../PopOver";

const PlayerDeck = ({ user, setBuildOpen, buildOpen, addDeck, setAddDeck }) => {
  const { lang, setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const [btnPreview, setBtnPreview] = useState();
  const { setUser } = useStore();
  const [deleteDeck, setDeleteDeck] = useState(false);
  const text = language[lang].player_page;
  const [data, setData] = useState();
  const [mouseIndex, setMouseIndex] = useState(0);
  const deckAddModal = useRef();
  const myDecks = useRef(null);

  useEffect(() => {
    setData(user);
  }, []);

  const fetchUser = async () => {
    const getUser = await api.get(`users/${user._id}`).catch(console.log);
    if (getUser) {
      setData(getUser.data);
    }
  };

  async function handleDeleteDeck(item) {
    const deleteDeck = await api.delete(`decks/${item._id}`).catch(console.log);
    if (deleteDeck) {
      setAlert(true);
      setAlertMsg(`${item.deckName} deletado com sucesso!`);
      setSeverity("success");
      fetchUser();
    } else {
      setAlert(true);
      setAlertMsg(`Erro ao deletar ${item.deckName}, aguarde alguns instantes e tente novamente!`);
      setSeverity("success");
    }
  }

  const handleMove = (move) => {
    const currentScroll = myDecks.current.scrollLeft;
    const scrollValue = myDecks.current?.scrollWidth / data.game.magic.deck.length;
    if (move === "left") {
      //scrollWidth: 730
      //scrollHeight: 130
      //scrollLeft: 0
      myDecks.current.scrollLeft = currentScroll - scrollValue;
    } else {
      myDecks.current.scrollLeft = currentScroll + scrollValue;
    }
  };

  if (!data) return null;
  return (
    <>
      <div className="page-player-right-deck" style={{ padding: "1rem" }}>
        {/* <p
          className="deck-build-title"
          style={{
            fontFamily: "gilroy-bold",
            fontSize: "clamp(.8rem, 1.5vw, 1.4rem)",
            marginBottom: "-20px",
            marginTop: "15px",
          }}
        >
          DECK BUILDER
        </p> */}
        <div
          style={{
            display: "flex",
            width: "45vw",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ textAlign: "center", width: "30px", height: "30px", borderRadius: "50%", background: "radial-gradient(grey,black)", padding: "7px" }}
            onClick={() => handleMove("left")}
          >
            <i className="fas fa-arrow-left" />
          </div>
          <div style={{ width: "70%", overflowX: "hidden", padding: "10px", alignItems: "center", scrollBehavior: "smooth" }} ref={myDecks}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {data.game
                ? data.game.magic.deck.map((item, index) => {
                    return (
                      <div key={item._id}>
                        <div
                          className="page-player-right-deck-buttons"
                          style={{
                            width: "120px",
                            height: "120px",
                            overflowY: "hidden",
                            overflowX: "auto",
                            padding: "1rem",
                            marginBottom: "-10px",
                            position: "relative",
                          }}
                        >
                          <button
                            className="button-delete-deck"
                            style={{
                              display: deleteDeck ? "block" : "none",
                              position: "absolute",
                              top: "15px",
                              right: "15px",
                              opacity: mouseIndex === index ? 1 : 0,
                            }}
                            onMouseOver={() => setDeleteDeck(true)}
                            onMouseLeave={() => setDeleteDeck(false)}
                            onClick={() => handleDeleteDeck(item, index)}
                          >
                            x
                          </button>
                          <BtnContinuo
                            component={Link}
                            to={`/editDeck/${item._id}`}
                            style={{ width: "120px" }}
                            // className="default-button button-deck"
                            onMouseOut={() => {
                              setDeleteDeck(false);
                            }}
                            onMouseOver={() => {
                              // sound.btn.start();
                              setMouseIndex(index);
                              setDeleteDeck(true);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: ".6em",
                              }}
                            >
                              <Pop cutValue={6}>{item.deckName}</Pop>
                              <p>{item.format}</p>
                            </div>
                          </BtnContinuo>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div
            style={{ textAlign: "center", width: "30px", height: "30px", borderRadius: "50%", background: "radial-gradient(grey,black)", padding: "7px" }}
            onClick={() => handleMove("right")}
          >
            <i className="fas fa-arrow-right" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "20%",
              height: "100%",
            }}
          >
            <BtnContinuo component={Link} to="/addDeck" classe="button-deck-add" onClick={() => setBuildOpen(!buildOpen)}>
              <i style={{ pointerEvents: "none" }} className="fas fa-scroll" />
              {text[1]} Deck
            </BtnContinuo>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayerDeck;
