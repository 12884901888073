import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocalDB } from "../../context/LocalDB";
import language from "../../assets/language/text";
import NumberFromWei from "../../utils/fromWei";
import { Button } from "@material-ui/core";
import { Dialog } from "@mui/material";
import deckOptions from "../../components/deck/options/deckAddOptions";
import api from "../../services/api";
import { useStore } from "../../context/storeContext";
import Pop from "../PopOver";
import Pageloading from "../pageloading/pageloading";
import VisualSuggestion from "../Suggestion/visualSuggestion";
import NumberToWei from "../../utils/toWei";

const List = ({ evento, fetchEvents, fetchAllEvents }) => {
  const [event, setEvent] = useState([]);
  const { setTournamentID, lang, setAlert, setSeverity, setAlertMsg } = useLocalDB();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dup, setDup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const text = language[lang].evo_page;
  const listEvents = useRef();

  useEffect(() => {
    setEvent(evento);
  }, [evento]);

  useEffect(() => {
    if (evento) {
      if (listEvents.current?.scrollHeight > listEvents.current?.offsetHeight) {
        listEvents.current.scrollTop = listEvents.current.offsetHeight - listEvents.current.scrollHeight;
      }
    }
  }, [evento]);

  const handleEditModal = async (item, itemDup) => {
    const fetchTournamentData = await api.get(`tournaments/${item._id}`).catch(console.log);
    if (fetchTournamentData) {
      itemDup && setDup(true);
      setSelectedEvent(fetchTournamentData.data);
      setEditModalOpen(true);
    }
  };

  const getDate = (date) => {
    if (date) {
      const getDateString = date.split("T", 1);
      const getRealDateSplited = getDateString[0].split("-");
      const getDateServer = new Date(getRealDateSplited[1] + "/" + getRealDateSplited[2] + "/" + getRealDateSplited[0]);
      // const getDateLocal = new Date(getRealDateSplited[2] + "/" + getRealDateSplited[1] + "/" + getRealDateSplited[0]);
      const getServer = process.env.REACT_APP_APP_ENV;
      return lang === "pt"
        ? getServer === "mainet"
          ? getDateServer.toLocaleDateString("pt-br")
          : getDateServer.toLocaleDateString("pt-br")
        : getServer === "mainet"
        ? getDateServer.toLocaleDateString("en-us")
        : getDateServer.toLocaleDateString("en-us");
    }
  };

  async function handleAddTournament(e, data) {
    e.preventDefault();
    const getNewDate = new Date(`${data.startDate} ${data.hour}`);
    // setLoading(true);
    const tournamentUp = {
      ...data,
      startDate: getNewDate.toLocaleString(),
      subscription: NumberToWei(data.subscription),
      reward: {
        typeOf: "EQ9",
      },
      game: { typeOf: data.game.typeOf, format: data.game.format, name: data.game.name, needsDeck: data.game.needsDeck },
    };
    const postTournament = await api.post("tournaments", tournamentUp).catch((error) => {
      if (error.response.status === 405)
        return setAlert(true), setAlertMsg(`405: "ERRO AO CRIAR O TORNEIO, ENTRE EM CONTATO COM O SUPPORTE."`), setSeverity("error");
      setAlert(true);
      setAlertMsg(error.response.data.message[0]);
      setSeverity("error");
    });
    if (postTournament) {
      setAlert(true);
      setAlertMsg(text[0]);
      setSeverity("success");
      fetchEvents();
      setEditModalOpen(false);
    }
  }
  const handleInfiniteScroll = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight) {
      if (!loading) {
        fetchAllEvents(page + 1, size, setLoading);
        setLoading(true);
        e.target.scrollTop = e.target.scrollTop - 10;
        setTimeout(() => {
          setPage(page + 1);
        }, 400);
      }
    }
  };

  const handleModalClose = () => {
    setEditModalOpen(false);
    setSelectedEvent(null);
  };

  const ListEvent = ({ item }) => {
    return (
      <div
        className="list-item"
        style={{
          textDecoration: "none",
          opacity: item.status === "ENDED" ? 0.4 : 1,
          cursor: "pointer",
          // pointerEvents: item.status === "ENDED" ? "none" : "all",
        }}
        onClick={() => {
          if (item.finished === false) {
            setTournamentID(item._id);
          }
        }}
      >
        <div className="list-item__link" key={item.id} style={{ zIndex: 100 }}>
          <Link to={item.status === "ENDED" ? `/badges/${item._id}` : `/tourneyAdm/${item._id}`}>
            <div className="list-item__link__header">
              <span>{getDate(item.startDate)}</span>
              <span>{item.hour}</span>
              <span>- {item.organizer.name}</span>
            </div>
            <div className="list-item__link__title">
              <div className="dot" />
              <span>{item.name}</span>
            </div>
            <div className="list-item__link__footer">
              <span className="list-item__link__footer__sub">
                <i class="fas fa-clipboard-check"></i>
                {item.subscription ? NumberFromWei(item.subscription) : 0} EQ9
              </span>
              <span className="list-item__link__footer__trophy">
                <div className="trophyIcon" />
                {item.reward.typeOf === "EQ9" ? (item.reward.amount ? NumberFromWei(item.reward.amount) : 0) : 0} EQ9
              </span>
              <span className="list-item__link__footer__users">
                <i class="fas fa-users"></i> {item.playerCount ? item.playerCount : 0}
              </span>
              <span>{item.datediference}</span>
            </div>
          </Link>
          {item.status === "WAITING" && (
            <Button
              style={{ position: "absolute", top: "1rem", right: "1rem", zIndex: 5000 }}
              onClick={() => {
                setDup(false);
                handleEditModal(item);
              }}
            >
              EDITAR
            </Button>
          )}
          <Button
            style={{ zIndex: 6000, position: "absolute", top: "48%", left: "-3.5rem", transform: "translateY(-50%)" }}
            onClick={() => {
              handleEditModal(item, true);
            }}
          >
            <VisualSuggestion value={lang === "pt" ? "Duplicar esse torneio" : "Duplicate this tournament"}>
              <i className="fas fa-copy" style={{ color: "var(--pink)" }} />
            </VisualSuggestion>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="listGrid">
        <div className="listDiv">
          <ul className="listUL" ref={listEvents} style={{ flexDirection: "column" }} onScroll={handleInfiniteScroll}>
            <div className="linhaList" style={{ height: "87%", paddingTop: "16px" }} />
            {event ? (
              event.length > 0 ? (
                event.map((item) => {
                  return <ListEvent item={item} />;
                })
              ) : (
                <p>{text[0]}</p>
              )
            ) : (
              <p>{text[0]}</p>
            )}
          </ul>
        </div>
      </div>
      <AttEvent
        open={editModalOpen}
        handleClose={handleModalClose}
        selectedEvent={selectedEvent}
        fetchEvents={fetchEvents}
        handleDuplicateEvent={handleAddTournament}
        dup={dup}
      />
    </>
  );
};

const AttEvent = ({ open, handleClose, selectedEvent, fetchEvents, dup, handleDuplicateEvent }) => {
  const getDub = dup ? { subscription: "" } : null;
  const initialEventData = {
    name: "",
    startDate: "",
    img: "",
    rules: "",
    mail: "",
    ranking: null,
    description: "",
    ...getDub,
    hour: "",
    game: { typeOf: "", format: "", name: "", needsDeck: false },
    tourneyConfig: { maxRound: null },
  };
  const [eventData, setEventData] = useState(initialEventData);
  const { lang, setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const { evo } = useStore();
  const [fixedRounds, setFixedRounds] = useState(false);
  const [rulesSelected, setRulesSelected] = useState([]);
  const text = language[lang].addTournament;
  const checkDeck = useRef(null);
  const format = useRef(null);
  const gameType = useRef(null);
  const rankingSelected = useRef(null);
  const [rankings, setRankings] = useState([]);
  const [ranking, setRanking] = useState(false);

  const swissPlayerLimit = [
    { min: 2, max: 2 },
    { min: 3, max: 4 },
    { min: 5, max: 8 },
    { min: 9, max: 16 },
    { min: 17, max: 32 },
    { min: 33, max: 64 },
    { min: 65, max: 128 },
    { min: 129, max: 212 },
    { min: 213, max: 384 },
    { min: 385, max: 672 },
    { min: 673, max: 1248 },
    { min: 1249, max: 2272 },
    { min: 2273, max: "inf" },
  ];

  const getDate = (date) => {
    if (date) {
      const getDateString = date.split("T", 1);
      const getRealDateSplited = getDateString[0].split("-");
      const getDateServer = getRealDateSplited[0] + "-" + getRealDateSplited[1] + "-" + getRealDateSplited[2];
      const getDateLocal = getRealDateSplited[0] + "-" + getRealDateSplited[2] + "-" + getRealDateSplited[1];
      const getServer = process.env.REACT_APP_APP_ENV;
      return getServer === "mainet" ? getDateServer : getDateLocal;
    }
  };

  useEffect(() => {
    if (open && selectedEvent) {
      fetchRankings();
      let getEvent = eventData;
      Object.keys(getEvent).map((item) => {
        if (item === "startDate") getEvent = { ...getEvent, [item]: getDate(selectedEvent[item]) };
        else if (item === "subscription") getEvent = { ...getEvent, [item]: NumberFromWei(selectedEvent.subscription) };
        else getEvent = { ...getEvent, [item]: selectedEvent[item] };
      });
      if (selectedEvent.tourneyConfig.maxRounds) {
        setFixedRounds(true);
      }
      if (getEvent.ranking) {
        setRanking(true);
      }
      setRulesSelected(getEvent.rules && getEvent.rules);
      setEventData(getEvent);
      console.log(getEvent);
    }
  }, [open, selectedEvent]);

  const handleChangeTournament = (event) => {
    const { value, name } = event.target;
    setEventData({
      ...eventData,
      [name]: value.toUpperCase(),
    });
  };
  const fetchRankings = async () => {
    const getRankings = await api.get(`rankings`, { params: { evoId: evo._id, page: 0, size: 30 } }).catch(console.log);
    if (getRankings) {
      setRankings(getRankings.data);
    }
  };

  const handleEventEdit = (e) => {
    e.preventDefault();
    let eventReturn;
    Object.keys(initialEventData).map((item) => {
      if (item === "game") {
        eventReturn = {
          ...eventReturn,
          rules: rulesSelected,
          ranking: ranking ? rankingSelected.current?.value : null,
          game: { typeOf: eventData.game.typeOf, format: eventData.game.format, name: eventData.game.name, needsDeck: eventData.game.needsDeck },
        };
      } else {
        eventReturn = { ...eventReturn, [item]: eventData[item] };
      }
    });
    const UpdateTournament = api.patch(`tournaments/update/${selectedEvent._id}`, eventReturn).catch(() => {
      setAlert(true);
      setAlertMsg("Erro ao Atualizar, tente novamente mais tarde...");
      setSeverity("error");
    });
    if (UpdateTournament) {
      fetchEvents();
      setAlert(true);
      setAlertMsg("Torneio Atualizado com sucesso!");
      setSeverity("success");
      handleClose();
    }
  };

  const handleModalClose = () => {
    setEventData(initialEventData);
    setRulesSelected();
    setRanking(false);
    setFixedRounds(false);
    handleClose();
  };

  const handleDeleteTournament = (e) => {
    e.preventDefault();
    const UpdateTournament = api.delete(`tournaments/${selectedEvent._id}`).catch(() => {
      setAlert(true);
      setAlertMsg("Erro ao Deletar, tente novamente mais tarde...");
      setSeverity("error");
    });
    if (UpdateTournament) {
      fetchEvents();
      setAlert(true);
      setAlertMsg("Torneio Deletado com sucesso!");
      setSeverity("success");
      handleClose();
    }
  };

  if (!open) return null;
  if (!eventData.name) return <Pageloading />;

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      PaperProps={{ style: { background: "transparent", maxWidth: "100%" } }}
      BackdropProps={{ style: { background: "rgba(0,0,0,0.9)" } }}
    >
      <form onSubmit={(e) => (dup ? handleDuplicateEvent(e, eventData) : handleEventEdit(e))} style={{ display: "flex", height: "100%", width: "60vw" }}>
        <section style={{ display: "flex", flexDirection: "column", margin: "1rem", width: "50%", height: "80vh" }}>
          <h1 style={{ textAlign: "center", color: "var(--pink)" }}>{dup ? "Criar Evento" : "Editar Evento"}</h1>
          <div className="modal-add-tournament-form" style={{ height: "350px" }}>
            <div className="default-input-label">
              <input
                required
                type="text"
                id="input-name-event"
                placeholder="Type here..."
                autoComplete="off"
                value={eventData.name}
                name="name"
                onChange={handleChangeTournament}
                className="default-input-label-content inputTornament"
              />
              <label className="input-label" for="input-name-event">
                {text[2]}
              </label>
              <span className="input-label-line" for="input-name-event" style={{ width: "55%" }} />
            </div>
            <div className="modal-add-tournament-form-date">
              <div className="default-input-label width-2">
                <input
                  required
                  id="input-date-event"
                  type="date"
                  value={eventData.startDate}
                  name="startDate"
                  max="2050-12-31"
                  onChange={handleChangeTournament}
                  className="default-input-label-content inputTornament"
                />
                <label className="input-label" for="input-date-event">
                  {text[3]}
                </label>
                <span className="input-label-line" for="input-date-event" style={{ width: "75%" }} />
              </div>
              <div className="default-input-label width-2">
                <input
                  required
                  id="input-hour-event"
                  type="time"
                  value={eventData.hour}
                  name="hour"
                  onChange={handleChangeTournament}
                  className="default-input-label-content inputTornament"
                />
                <label className="input-label" for="input-hour-event">
                  {text[8]}
                </label>
                <span className="input-label-line" for="input-hour-event" style={{ width: "82%" }} />
              </div>
            </div>
            <section style={{ display: "flex", height: "65px", alignItems: "center", justifyContent: "space-between" }}>
              <div className="default-input-label" style={{ width: "20%", padding: "10px", marginRight: "10px", marginTop: "10px" }}>
                <input
                  type="checkbox"
                  className="default-input-label-content inputTornament"
                  style={{ width: "22px", height: "22px", borderRadius: "15px", padding: "5px", marginTop: "7px" }}
                  checked={fixedRounds}
                  onChange={() => setFixedRounds(!fixedRounds)}
                />
                <label className="input-label" for="input-numberOfRounds-event">
                  {lang === "pt" ? "Rodadas Fixas?" : "Fixed Rounds?"}
                </label>
              </div>
              {fixedRounds && (
                <div className="default-input-label" style={{ width: "65%", display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <input
                      required
                      id="input-numberOfRounds-event"
                      type="number"
                      style={{ textAlign: "center" }}
                      value={eventData.tourneyConfig?.maxRounds}
                      name="hour"
                      onChange={(e) => {
                        setEventData({ ...eventData, tourneyConfig: { ...eventData.tourneyConfig, maxRounds: e.target.value } });
                      }}
                      className="default-input-label-content inputTornament"
                    />
                    <label className="input-label" for="input-numberOfRounds-event">
                      {lang === "pt" ? "Rodadas" : "Rounds"}
                    </label>
                    <span className="input-label-line" for="input-numberOfRounds-event" style={{ width: "80%" }} />
                  </div>
                  <div style={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "-10px" }}>
                    <p style={{ fontFamily: "gilroy-bold", textAlign: "center", color: "var(--pink)" }}>{lang === "pt" ? "Jogadores" : "Players"}</p>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                      <p style={{ color: "var(--pink)", minWidth: "50%" }}>Min</p>{" "}
                      <p style={{ color: "var(--pink)", minWidth: "50%" }}>
                        {eventData.tourneyConfig.maxRounds
                          ? swissPlayerLimit[
                              eventData.tourneyConfig.maxRounds < swissPlayerLimit.length ? eventData.tourneyConfig.maxRounds - 1 : swissPlayerLimit.length - 1
                            ]?.min
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </section>
            <select
              className="default-input inputTornament"
              onChange={(e) => setEventData({ ...eventData, game: { ...eventData.game, typeOf: e.target.value } })}
              ref={gameType}
            >
              {deckOptions.gameType.map((item) => {
                if (item === eventData.game.typeOf) {
                  return (
                    <option style={{ background: "black" }} value={item} selected key={item}>
                      {item}
                    </option>
                  );
                } else {
                  return (
                    <option style={{ background: "black" }} value={item} key={item}>
                      {item}
                    </option>
                  );
                }
              })}
            </select>
            <select
              className="default-input inputTornament"
              onChange={(e) => setEventData({ ...eventData, game: { ...eventData.game, format: e.target.value } })}
              ref={format}
            >
              {Object.keys(deckOptions.format).map((item) => {
                if (item === eventData.game.format) {
                  return (
                    <option style={{ background: "black" }} value={item} selected key={item.name}>
                      {deckOptions.format[item].name}
                    </option>
                  );
                } else {
                  return (
                    <option style={{ background: "black" }} value={item} key={item.name}>
                      {deckOptions.format[item].name}
                    </option>
                  );
                }
              })}
            </select>
            <div className="checkbox-div">
              <input
                id="check-deck"
                type="checkbox"
                checked={eventData.game.needsDeck}
                className="checkbox-div-input"
                onChange={() => setEventData({ ...eventData, game: { ...eventData.game, needsDeck: !eventData.game.needsDeck } })}
                ref={checkDeck}
              />
              <label for="check-deck" className="checkbox-div-label">
                <i class="fas fa-check fa-lg"></i>
              </label>
              <label for="check-deck" className="checkbox-div-text">
                Deck List
              </label>
            </div>
            {dup && (
              <div className="default-input-label">
                <input
                  required
                  id="input-sub-event"
                  type="number"
                  placeholder={lang === "pt" ? "VALOR EM EQ9" : "EQ9 VALUE"}
                  value={eventData.subscription}
                  name="subscription"
                  onChange={handleChangeTournament}
                  className="default-input-label-content inputTornament"
                />
                <label className="input-label" for="input-sub-event">
                  {text[6]}
                </label>
                <span className="input-label-line" for="input-sub-event" style={{ width: "60%" }} />
              </div>
            )}
            <span className="input-label-line" for="input-sub-event" style={{ width: "60%" }} />
            <div className="default-input-label">
              <textarea
                col="30"
                row="5"
                required
                id="input-desc-event"
                type="text"
                placeholder="Type here..."
                value={eventData.description}
                name="description"
                onChange={handleChangeTournament}
                className="default-input-label-content inputTornament"
                style={{ height: "9rem", wordWrap: "break-word" }}
              />
              <label className="input-label" for="input-desc-event">
                {text[5]}
              </label>
              <span className="input-label-line" for="input-desc-event" style={{ width: "45%" }} />
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-around", padding: "1rem" }}>
              <button
                className="default-button"
                onClick={async (e) => {
                  if (dup) {
                    handleClose();
                  } else {
                    const confirm = await window.confirm(
                      lang == "pt" ? "Você deletará este torneio, deseja confirmar?" : "You will delete this tournament, are u shure?"
                    );
                    if (confirm) {
                      handleDeleteTournament(e);
                    }
                  }
                }}
              >
                {dup ? "Cancelar" : "Deletar"}
              </button>
              <button type="submit" className="default-button">
                {dup ? "Criar" : "Confirmar"}
              </button>
            </div>
          </div>
        </section>
        <section style={{ minHeight: "80vh", width: "40%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <div className="default-input inputTornament" style={{ height: "250px", border: "2px solid rgb(0,190,210)" }}>
            <h3 style={{ color: "rgb(170,30,160)" }}>{lang === "pt" ? "REGRAS" : "RULES"}</h3>
            {evo?.rules?.map((item) => {
              return (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "left", height: "40px" }} key={item}>
                  <input
                    type="checkbox"
                    style={{ margin: "5px" }}
                    defaultChecked={eventData?.rules?.includes(item) ? true : false}
                    onClick={(e) => {
                      if (e.target.checked) {
                        return setRulesSelected((e) => [...e, item]);
                      } else {
                        let ruleIndice;
                        rulesSelected.filter((rule, index) => {
                          if (rule === item) {
                            ruleIndice = index;
                          }
                        });
                        let ruleSplice = rulesSelected;
                        ruleSplice.splice(ruleIndice, 1);
                        return setRulesSelected(ruleSplice);
                      }
                    }}
                  />
                  <div style={{ background: "black", fontSize: "0.8em" }}>
                    <Pop cutValue={65}>{item}</Pop>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: "10px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h3 style={{ color: "rgb(170,30,160)" }}>{lang === "pt" ? "RANKINGS" : "RANKINGS"}</h3>
              <input
                type="checkbox"
                style={{ width: "30px", height: "30px", margin: "10px" }}
                checked={ranking}
                onChange={() => {
                  setRanking(!ranking);
                }}
              />
            </div>
            {ranking && (
              <select className="default-input inputTornament" ref={rankingSelected}>
                {rankings.map((item) => {
                  return (
                    <option value={item._id} selected={eventData.ranking?._id === item._id ? true : false} style={{ background: "black" }} key={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
        </section>
      </form>
    </Dialog>
  );
};

export default List;
