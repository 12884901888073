import React, { useEffect, useState } from "react";
import { useStore } from "../../context/storeContext";
import ProfileHalfPage from "../profileHalfPage/";
import List from "../../components/list/List";
import { motion } from "framer-motion";
import LoadingPage from "../../components/pageloading/pageloading";
import { Link } from "react-router-dom";
import AddDeck from "../../components/cardInport";
import api from "../../services/api";
import language from "../../assets/language/text";
import sound from "../../assets/sounds";
import { useLocalDB } from "../../context/LocalDB";
import BtnContinuo from "../../components/buttons/BtnContinuo";
import PlayerDeck from "../../components/deck/index";
import ParticlesPage from "../../components/Particles";
import Pageloading from "../../components/pageloading/pageloading";
const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};
const pageTransition = { type: "tween", easy: "anticipate", duration: 0.4 };

function Player() {
  const { user, setUser } = useStore();
  const { lang, setUserLocalData, tournamentStartNotifications } = useLocalDB();
  const [addDeck, setAddDeck] = useState(false);
  const [buildOpen, setBuildOpen] = useState(false);
  const [userTournaments, setUserTournaments] = useState([]);
  const text = language[lang].player_page;

  const fetchUser = async () => {
    const fetch = await api.get(`users/${user._id}`).catch(console.log);
    return setUser(fetch.data);
  };

  const fetchTournament = async () => {
    const fetchTournaments = await api.get(`/tournaments/find-by-user/${user._id}`, { params: { page: 0, size: 7 } }).catch(console.log);
    setUserTournaments(fetchTournaments.data);
  };

  useEffect(() => {
    setUserLocalData({ access: 1 });
    fetchTournament();
    fetchUser();
  }, []);

  useEffect(() => {
    if (tournamentStartNotifications && tournamentStartNotifications.length > 0) {
      fetchTournament();
    }
  }, [tournamentStartNotifications]);

  if (!user) return <Pageloading />;

  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="page-player">
      <span className="backgroundPlayer" />
      <div className="page-player-left">
        <ProfileHalfPage data={user} />
      </div>
      <div className="page-player-right">
        <div className="page-player-right-list">
          <List evento={userTournaments} />
        </div>
        <PlayerDeck user={user} fetchUser={fetchUser} buildOpen={buildOpen} setBuildOpen={setBuildOpen} addDeck={addDeck} setAddDeck={setAddDeck} />
      </div>
    </motion.div>
  );
}

export default Player;
