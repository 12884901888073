function TimerRound({ timeForRound, tourneyData, extraTimer, serverTimer, lang, canExtraTime, setCanExtraTime, extraTimeRunning }) {
  if (serverTimer) {
    if (!tourneyData?.extraTime && (serverTimer?.minutes > 0 || serverTimer?.seconds > 0)) {
      if (canExtraTime) {
        setCanExtraTime(false);
      }
      return (
        <div style={{ position: "absolute", top: "1.8rem", left: "50%", transform: "translateX(-50%)", textAlign: "center" }}>
          <p style={{ color: "var(--pink)" }}>{lang === "pt" ? "TEMPO PARA FINALIZAR O ROUND" : "TIME LEFT TO END THE ROUND"}</p>
          <h1
            style={{
              color: "var(--lightblue)",
              fontSize: "clamp(1.4rem, 1.8vw, 2.6rem)",
            }}
          >
            {" "}
            {serverTimer.minutes}:{serverTimer.seconds < 10 ? `0${serverTimer.seconds}` : serverTimer.seconds}
          </h1>
        </div>
      );
    } else if (tourneyData?.extraTime) {
      if (serverTimer.minutes > 0 || serverTimer.seconds > 0) {
        return (
          <section>
            <div
              style={{
                position: "absolute",
                top: "2rem",
                left: "70%",
                transform: "translateX(-50%)",
                textAlign: "center",
              }}
            >
              <p style={{ color: "var(--pink)" }}>{lang === "pt" ? "TEMPO EXTRA" : "EXTRA TIME"}</p>
              <h1
                style={{
                  color: "var(--lightblue)",
                  fontSize: "clamp(1.4rem, 1.8vw, 2.6rem)",
                }}
              >
                {" "}
                {serverTimer.minutes}:{serverTimer.seconds < 10 ? `0${serverTimer.seconds}` : serverTimer.seconds}
              </h1>
            </div>
          </section>
        );
      } else {
        return (
          <div style={{ position: "absolute", top: "2rem", left: "70%", transform: "translateX(-50%)", textAlign: "center" }}>
            <p style={{ color: "var(--pink)" }}>{lang === "pt" ? "TEMPO EXTRA DO ROUND FINALIZADO" : "THE ROUND EXTRA TIME HAS ENDED"}</p>
          </div>
        );
      }
    } else {
      if (!canExtraTime && !extraTimeRunning) {
        setCanExtraTime(true);
      }
      return (
        <div style={{ position: "absolute", top: "2rem", left: "50%", transform: "translateX(-50%)", textAlign: "center" }}>
          <p style={{ color: "var(--pink)" }}>{lang === "pt" ? "TEMPO DO ROUND FINALIZADO" : "THE ROUND TIME HAS ENDED"}</p>
        </div>
      );
    }
  }
  return <></>;
}

export default TimerRound;
