import React, { useState, useEffect, useRef } from "react";
import { useStore } from "../../context/storeContext";
import PlayerCard from "./playerCard";
import { useParams } from "react-router-dom";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
import language from "../../assets/language/text";
import sound from "../../assets/sounds";
import "animate.css";
import DrawerStandings from "./DrawerStandings";
import Chat from "./chat";
import { useSocket } from "../../context/webSocket";
import BadgesPages from "../badges";
import Pageloading from "../../components/pageloading/pageloading";
import Timer from "./timers/Timer";
import resultBolls from "./roundElements/resultBolls";
import confirmButton from "./roundElements/confirmButton";

const newPairing = sound.newPairing;
const initialResults = { r1: 0, r2: 0, r3: 0 };
const initialVisualResults = { player1: 0, player2: 0 };
const initialCheckIn = { player1: false, player2: false };
const initialConfirmed = { playerOne: false, playerTwo: false };
const initialConnected = { playerOne: false, playerTwo: false };

function Room() {
  const chatInputRef = useRef(null);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const { id } = useParams();
  const { user } = useStore();
  const [matchData, setMatchData] = useState();
  const [results, setResults] = useState(initialResults);
  const [visualResults, setVisualResults] = useState(initialVisualResults);
  const [yourSeat, setYourSeat] = useState();
  const [confirm, setConfirm] = useState(false);
  const [player2, setPlayer2] = useState();
  const [preview, setPreview] = useState();
  const [confirmed, setConfirmed] = useState(initialConfirmed);
  const [chatList, setChatList] = useState([]);
  const [player1Deck, setPlayer1Deck] = useState();
  const [player2Deck, setPlayer2Deck] = useState();
  const [winner, setWinner] = useState();
  const [thirdRound, setThirdRound] = useState(false);
  const [tourneyData, setTourneyData] = useState();
  const [connected, setConnected] = useState({
    playerOne: false,
    playerTwo: false,
  });
  const [tournamentWinner, setTournamentWinner] = useState();
  const [tournament, setTournament] = useState();
  const [messageTo, setMessageTo] = useState("ALL");
  const [active, setActive] = useState(true);
  const text = language[lang].room_page;
  const { socket, socketUpdate } = useSocket();
  const [player2Bye, setPlayer2Bye] = useState(false);
  const [checkIn, setCheckIn] = useState(initialCheckIn);
  const [newPairingConfirm, setNewPairingConfirm] = useState(false);
  const [matchUpdate, setMatchUpdate] = useState(null);
  const [chatEvoDataUpdated, setChatEvoDataUpdated] = useState();
  const [notifications, setNotifications] = useState();
  const [roundData, setRoundData] = useState();
  const timeForRound = 50;
  const extraTimer = 5;
  const timeForCheckIn = 3;

  const resetState = () => {
    setWinner();
    setPlayer1Deck();
    setPlayer2Deck();
    setPlayer2(null);
    setConfirm(false);
    setResults(initialResults);
    setVisualResults(initialVisualResults);
    setConnected(initialConnected);
    setCheckIn(initialCheckIn);
    setNewPairingConfirm(false);
    setChatList([]);
    setChatEvoDataUpdated([]);
    setConfirmed({
      playerOne: false,
      playerTwo: false,
    });
  };
  const resetSocket = () => {
    socket.off("matchData");
    socket.off("matchChatData");
  };

  useEffect(() => {
    SocketInitiate();
    console.log("Reconectando os listeners do Player.");
  }, [socketUpdate]);

  useEffect(() => {
    if (id) {
      fetchTournament();
      SocketInitiate();
    }
  }, [id]);

  const fetchTournament = async () => {
    const getTournament = await api.get(`tournaments/${id}`).catch(console.log);
    if (getTournament) {
      socket.off(`chat/${user._id}/${getTournament.data?.organizer?.owner}`).on(`chat/${user._id}/${getTournament.data?.organizer?.owner}`, (e) => {
        if (e?.messages) {
          setChatEvoDataUpdated(e.messages);
        } else {
          setChatEvoDataUpdated([]);
        }
      });
      socket.off(`notifications/${user._id}`).on(`notifications/${user._id}`, (e) => {
        setNotifications(e);
        sound.msg2.play();
      });
      setTournament(getTournament.data);
    }
  };

  const handleCheckIn = () => {
    if (matchData) {
      socket.emit("setMatchData", {
        ...matchData,
        tournamentId: id,
        rounds: matchData.rounds,
        results: { playerOneWins: 0, playerTwoWins: 0, draws: 0 },
        check: false,
        checkIn: true,
      });
    }
  };

  // ANCHOR  HandShake Socket & Player Enter Room

  const SocketInitiate = () => {
    socket.off("tourneyData").on("tourneyData", (tourney) => {
      // console.log(tourney);
      getTourneyDataUpdate(tourney);
      setTourneyData(tourney);
    });
    socket.off("roundData").on("roundData", (e) => {
      setRoundData(e);
      changeRoundData(e);
    });
    socket.off("matchData").on("matchData", (e) => {
      setUpdateMatchData(e);
    });
    socket.off("exception").on("exception", (e) => {
      if (e) {
        setAlert(true);
        setAlertMsg(e.message);
        setSeverity("error");
      }
    });
    socket.emit(`roundTimer`, () => {});
    socket.emit("tourneyData", { tournamentId: id });
    socket.emit("joinMatchRoom", { tournamentId: id });
    socket.emit("findAllActiveMatches", { tournamentId: id });
  };

  const changeRoundData = (e) => {
    if (e) {
      let getPlayerSeat = e.playerOne.id === user._id ? 1 : 2;
      let getOpponentSeat = e.playerOne.id === user._id ? 2 : 1;
      let getStringSide = getPlayerSeat === 1 ? "playerOne" : "playerTwo";
      let getOpponentStringSide = getPlayerSeat === 1 ? "playerTwo" : "playerOne";
      let playerTwo = e[getOpponentStringSide];
      setChatList([]);
      socket.off(`chat/${user._id}/${e[getOpponentStringSide].id}`).on(`chat/${user._id}/${e[getOpponentStringSide].id}`, (e) => {
        getChatDataUpdated(e);
      });
      setMatchUpdate({
        ...matchUpdate,
        yourSide: getPlayerSeat,
        OpponentSide: getOpponentSeat,
        yourSeatStringSide: getStringSide,
        OpponentStringSide: getOpponentStringSide,
        Opponent: playerTwo,
        currentRound: e.currentRound,
        matchId: e.matchId,
      });
      setYourSeat(getPlayerSeat);
      if (!e[getOpponentStringSide].bye) {
        setWinner();
        GetOpponent(e[getOpponentStringSide].id);
        setMessageTo("ALL");
        setPlayer2Bye(false);
      } else {
        setPlayer2Bye(true);
        setMessageTo("EVO");
        setPlayer2();
        setResults({ r1: 1, r2: 1, r3: 0 });
        setVisualResults({ player1: 2, player2: 0 });
        setConfirmed({ playerOne: true, playerOne: true });
        setConfirm(true);
        setWinner(1);
      }
      setActive(true);
    }
  };

  //ANCHOR Sempre que tiver atualização pelo Websocket, sobre sua match

  const setUpdateMatchData = (e) => {
    if (e) {
      let getPlayerSeat = e.playerOne.id === user._id ? 1 : 2;
      let getOpponentSeat = e.playerOne.id === user._id ? 2 : 1;
      let getStringSide = getPlayerSeat === 1 ? "playerOne" : "playerTwo";
      let getOpponentStringSide = getOpponentSeat === 1 ? "playerOne" : "playerTwo";
      let playerTwo = e[getOpponentStringSide];
      setMatchData(e);
      setCheckIn(e.checkIn ? e.checkIn : initialCheckIn);
      setResults(e.rounds);
      setVisualResults({ playerOne: e.results.playerOneWins, playerTwo: e.results.playerTwoWins });
      setConnected({
        playerOne: e.playerOne && e.playerOne.inRoom,
        playerTwo: !e.playerTwo.bye && e.playerTwo.inRoom,
      });
      if (e.winner) {
        setWinner(e.winner);
      }
      const getConfirmed = getPlayerSeat === 1 ? { ...confirmed, playerOne: e?.playerOne.check } : { ...confirmed, playerTwo: e?.playerTwo.check };
      setConfirmed({ ...confirmed, ...getConfirmed });
      setConfirm(getConfirmed[getStringSide]);
    }
  };

  const GetOpponent = async (seat) => {
    if (seat) {
      const getPlayer2 = await api.get(`users/${seat}`).catch(console.log);
      if (getPlayer2) {
        setPlayer2Bye(false);
        setPlayer2(getPlayer2.data);
      }
    }
  };

  //ANCHOR ATUALIZA SEMPRE QUE MODIFICA VALOR DO TOURNEY

  const getTourneyDataUpdate = (tourney) => {
    if (tourney.finished === true) return setTournamentWinner(true), setActive(false);
  };

  const getChatDataUpdated = (e) => {
    if (e) {
      setChatList(e.messages);
    }
  };

  useEffect(() => {
    if (player2Bye) {
      setResults({ r1: 1, r2: 1, r3: 0 });
      setVisualResults({ player1: 2, player2: 0 });
      setConfirmed({ playerOne: true, playerOne: true });
    }
  }, [player2Bye]);

  useEffect(() => {
    if (results.r1 > 0 && results.r2 > 0 && results.r1 !== results.r2) {
      return setThirdRound(true);
    } else {
      return setThirdRound(false);
    }
  }, [results]);

  useEffect(() => {
    if (player2) {
      if (!matchData?.winner) {
        if (!newPairingConfirm) {
          newPairing.play();
          setNewPairingConfirm(true);
        }
      }
      if (tournament?.game?.needsDeck) {
        getPlayersDeck();
      }
    }
  }, [player2]);

  const getPlayersDeck = () => {
    const playerDeck = async (deck, setPlayerDeck, player) => {
      if (deck) {
        const getDeck = await api.get(`decks/${deck}`).catch(console.log);
        if (getDeck) {
          setPlayerDeck(getDeck.data);
        }
      }
    };
    const getDeckName = (player, setPlayerDeck) => {
      tournament.players.filter((item) => {
        if (item.player === player._id) {
          return playerDeck(item.deck?._id, setPlayerDeck, player);
        }
      });
    };
    getDeckName(player2, setPlayer2Deck);
    getDeckName(user, setPlayer1Deck);
  };

  // ANCHOR Visual Results
  useEffect(() => {
    if (results) {
      const getResult = {
        player1: () => {
          const total = Object.keys(results).filter((item) => {
            if (results[item] === 1) return true;
          });
          return total?.length;
        },
        player2: () => {
          const total = Object.keys(results).filter((item) => {
            if (results[item] === 2) return true;
          });
          return total?.length;
        },
      };
      const playerResults = {
        player1: getResult.player1(),
        player2: getResult.player2(),
      };
      setVisualResults(playerResults);
    }
  }, [results]);

  const handleSubmitMsg = (e) => {
    e.preventDefault();
    if (chatInputRef.current?.value?.length > 0) {
      if (messageTo === "ALL" && player2) {
        socket.emit(`talkTo`, {
          userId: player2._id,
          message: {
            author: user._id,
            body: chatInputRef.current?.value,
            to: player2._id,
          },
        });
      }
      if (messageTo === "EVO") {
        socket.emit("talkTo", {
          userId: tournament?.organizer?.owner,
          message: {
            author: user._id,
            body: chatInputRef.current?.value,
            to: tournament.organizer.owner,
          },
        });
      }
      chatInputRef.current.value = "";
    }
  };

  const handleRefresh = () => {
    resetState();
    resetSocket();
    SocketInitiate();
  };

  const youAreIn = tournament?.players.filter((item) => item.player === user._id);

  if (tournament?.status === "ENDED") return <BadgesPages idEvent={id} />;

  if (tournamentWinner) return <BadgesPages idEvent={id} />;

  if (!tournament) return <Pageloading />;

  if (tournament && youAreIn.length === 0) return (window.location.href = `${process.env.REACT_APP_ENDPOINT}event/${id}`);

  if (tournament && youAreIn.length === 1 && youAreIn[0].drop) return (window.location.href = `${process.env.REACT_APP_ENDPOINT}event/${id}`);

  if (matchData && matchData[yourSeat === 1 ? "playerOne" : "playerTwo"]?.active === false)
    return (
      setAlert(true),
      setSeverity("error"),
      setAlertMsg(lang === "pt" ? "VOCÊ FOI REMOVIDO DO TORNEIO" : "YOU HAVE BEEN REMOVED FROM THE TOURNAMENT"),
      setTimeout(() => (window.location.href = `${process.env.REACT_APP_ENDPOINT}event/${id}`), 6000)
    );

  return (
    <div className="room-page">
      <div className="room-page-container1">
        <div className="room-page-container1-middle">
          {confirmButton(
            results,
            thirdRound,
            matchData,
            player2Bye,
            winner,
            confirm,
            text,
            socket,
            checkIn,
            confirmed,
            id,
            visualResults,
            setAlert,
            setAlertMsg,
            setSeverity,
            lang
          )}
          <span>
            {lang === "pt" ? "RODADA" : "ROUND"} {matchUpdate && matchUpdate.currentRound}
          </span>
        </div>
      </div>
      <div className="room-page-container2" style={{ zIndex: 100 }}>
        <div className="room-page-container2-playerBox">
          <PlayerCard
            id={id}
            data={user}
            deck={player1Deck}
            style={{ zIndex: 1 }}
            setPreview={setPreview}
            seat={yourSeat}
            yourMatch={matchData}
            tournament={tournament}
            connected={yourSeat === 1 ? connected.playerOne : connected.playerTwo}
            side={1}
            confirm={yourSeat === 1 ? matchData?.playerOne?.check : matchData?.playerTwo?.check}
            winner={winner}
            connected={yourSeat === 1 ? connected?.playerOne : connected?.playerTwo}
            player2={player2}
            player2Bye={player2Bye}
            you={true}
            handleCheckIn={handleCheckIn}
            checkIn={yourSeat === 1 ? checkIn.player1 : checkIn.player2}
            roundData={roundData}
          />
        </div>
        <h1>{yourSeat === 1 ? visualResults.player1 : visualResults.player2}</h1>
        <div className="room-page-container2-middle">
          <div className="room-page-container2-middle-vs">
            <p>v</p>
            <p>s</p>
          </div>
          <div className="room-page-container2-middle-marks" style={{ flexDirection: yourSeat === 1 ? "row" : "row-reverse" }}>
            {resultBolls(
              results,
              confirmed,
              active,
              winner,
              checkIn,
              socket,
              matchData,
              id,
              visualResults,
              setAlert,
              setAlertMsg,
              setSeverity,
              lang,
              setResults
            )}
          </div>
        </div>
        <h1>{yourSeat === 1 ? visualResults.player2 : visualResults.player1}</h1>
        <div className="room-page-container2-playerBox">
          {player2 ? (
            <PlayerCard
              data={player2}
              deck={player2Deck}
              setPreview={setPreview}
              seat={yourSeat === 1 ? 2 : 1}
              side={2}
              yourMatch={matchData}
              tournament={tournament}
              connected={yourSeat === 2 ? connected?.playerOne && connected?.playerOne : connected.playerTwo}
              confirm={yourSeat === 1 ? matchData?.playerTwo?.check : matchData.playerOne?.check}
              winner={winner}
              connected={yourSeat === 1 ? connected?.playerTwo && connected?.playerTwo : connected.playerOne}
              you={false}
              handleCheckIn={handleCheckIn}
              checkIn={yourSeat === 1 ? checkIn?.player2 : checkIn.player1}
              roundData={roundData}
              player2Active={matchData[yourSeat === 1 ? "playerTwo" : "playerOne"].active}
            />
          ) : (
            <PlayerCard bye={player2Bye} you={false} />
          )}
        </div>
      </div>
      <div className="room-page-container3" style={{ zIndex: 0 }}>
        <button className="room-page-container3-drop">drop</button>
        <div className="room-page-container3-chat">
          <Chat
            id={id}
            messageTo={messageTo}
            chatList={chatList}
            evoChatList={chatEvoDataUpdated}
            chatInputRef={chatInputRef}
            handleSubmitMsg={handleSubmitMsg}
            user={user}
            player2={player2}
            player2Bye={player2Bye}
            tournament={tournament}
            notifications={notifications}
          />
          <div className="room-page-container3-chat-tabs" style={{ zIndex: 200, marginTop: "20px" }}>
            {!player2Bye && (
              <button
                className="room-page-container3-chat-tabs-button"
                style={{
                  height: messageTo === "EVO" ? "20px" : "25px",
                  color: messageTo === "EVO" ? "grey" : "white",
                  background: messageTo !== "EVO" ? "black" : "rgb(50,50,50)",
                  cursor: messageTo === "EVO" && "pointer",
                  borderBottomLeftRadius: "15px",
                  borderTopLeftRadius: "15px",
                  position: "relative",
                }}
                onClick={() => {
                  setMessageTo("ALL");
                }}
              >
                {notifications && player2
                  ? notifications[player2._id] && (
                      <span style={{ position: "absolute", top: 0, right: 0, width: "10px", height: "10px", background: "red", borderRadius: "50%" }} />
                    )
                  : null}
                {player2?.nickname?.toUpperCase()}
              </button>
            )}
            <button
              className="room-page-container3-chat-tabs-button"
              style={{
                height: messageTo === "EVO" ? "25px" : "20px",
                color: messageTo === "EVO" ? "white" : "grey",
                cursor: messageTo === "ALL" && "pointer",
                background: messageTo === "EVO" ? "black" : "rgb(50,50,50)",
                borderBottomRightRadius: "15px",
                borderTopRightRadius: "15px",
                position: "relative",
              }}
              onClick={() => {
                setMessageTo("EVO");
              }}
            >
              {notifications && tournament?.organizer?.owner
                ? notifications[tournament?.organizer?.owner] && (
                    <span style={{ position: "absolute", top: 0, right: 0, width: "10px", height: "10px", background: "red", borderRadius: "50%" }} />
                  )
                : null}
              {tournament?.organizer?.name?.toUpperCase()}
            </button>
          </div>
        </div>
        <div className="room-page-container3-standings">
          <DrawerStandings id={id} tournament={tourneyData} winner={winner} />
        </div>
      </div>
      {preview && (
        <img
          style={{
            position: "absolute",
            width: "350px",
            borderRadius: "20px",
            top: "23%",
            left: "50%",
            pointerEvents: "none",
            transform: "translateX(-50%)",
          }}
          src={preview}
          alt="Preview"
        />
      )}
      <h1
        style={{
          position: "absolute",
          top: "3%",
          left: "50%",
          transform: "translate(-50%)",
          fontFamily: "gilroy-bold",
          fontSize: "clamp(1.6rem, 2.4vw, 3rem)",
          color: "var(--pink)",
        }}
      >
        {lang == "pt" ? "PAINEL DO JOGADOR" : "PLAYER PANEL"}
      </h1>
      <Timer
        id={id}
        timeForRound={timeForRound}
        tourneyData={tourneyData}
        extraTimer={extraTimer}
        player2Bye={player2Bye}
        checkIn={checkIn}
        timeForCheckIn={timeForCheckIn}
      />
      <button
        style={{
          position: "absolute",
          top: "2.4vh",
          left: "15vw",
          background: "transparent",
          width: "50px",
          height: "50px",
          border: "none",
        }}
        onClick={handleRefresh}
      >
        <i className="fas fa-sync-alt fa-2x" style={{ color: "var(--pink)" }} />
      </button>
    </div>
  );
}

export default Room;
