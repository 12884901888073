import { useEffect } from "react";
import Routes from "./Routes/Routes";
import LocalDBProvider from "../context/LocalDB";
import WalletProvider from "../context/walletContext";
import UserProvider from "../context/userContext";
import { BrowserRouter } from "react-router-dom";
import StoreProvider from "../context/storeContext";
import SocketProvider from "../context/webSocket";
import { QueryClientProvider, QueryClient } from "react-query";

function App() {
  const queryClient = new QueryClient();

  return (
    <SocketProvider>
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <LocalDBProvider>
            <WalletProvider>
              <UserProvider>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </UserProvider>
            </WalletProvider>
          </LocalDBProvider>
        </StoreProvider>
      </QueryClientProvider>
    </SocketProvider>
  );
}

export default App;
