import React, { useEffect, useRef, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import { useLocalDB } from "../../context/LocalDB";
import { Link } from "react-router-dom";
import { useStore } from "../../context/storeContext";
import GraphicPlayer from "../graphic/GraphicPlayer";
import useStorage from "../../utils/useStorage";
import eq9Config from "../../services/eq9Config";
import language from "../../assets/language/text";
import PageMessage from "../messages/index";
import BtnSide from "../buttons/BtnSide";
import EvoList from "../list/EvoList";
import api from "../../services/api";
import sound from "../../assets/sounds";
import { useWallet } from "../../context/walletContext";
import Pop from "../PopOver";

const continuousSound = sound.btnContinuous;
const click = sound.btn;
const screenPass = sound.screenPass;

export default function TemporaryDrawer() {
  const { btnSideNav, setBtnSideNav, userLocalData, setUserLocalData, setAddTournament, carteira, lang, setAlert, setAlertMsg, setSeverity, mobile } =
    useLocalDB();
  const { user, setUser, evo } = useStore();
  const { eq9, status } = useWallet();
  const chatscrollref = useRef(null);
  const [messages, setMessages] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [token, setToken, remove] = useStorage("token");
  const [accessBtn, setAccessBtn] = useState(false);
  const text = language[lang].sideNav;
  const textAccess = language[lang].w_page;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setUserLocalData({ access: 3 }), []);

  useEffect(() => {
    if (messages) {
      if (chatscrollref.current !== null) {
        if (chatscrollref.current.scrollHeight > chatscrollref.current.offsetHeight) {
          chatscrollref.current.scrollTop = chatscrollref.current.scrollHeight - chatscrollref.current.offsetHeight;
        }
      }
    }
  }, [chatscrollref]);

  useEffect(() => {
    if (user.message) {
      setMessages(user.message);
    }
  }, [btnSideNav]);

  const handleClose = () => {
    setBtnSideNav(false);
    sound.slide.pause();
    sound.slide.currentTime = 0;
    sound.slide.play();
  };

  const handleChange = async (access) => {
    const accessType = { 1: "Player", 2: "Staker", 3: "Organizador" };
    setUserLocalData({ access: access });
    // const fetchUser = await api.get(`users/${user._id}`);
    // setUser(fetchUser.data);
    setAlert(true);
    setAlertMsg(`Entrando como perfil de ${accessType[access]}`);
    setSeverity("success");
  };

  const userAccess = { 1: "PLAYER", 2: "STAKER", 3: "ORGANIZADOR" };

  return (
    <div>
      {/* <React.Fragment key={"left"}> */}
      <Drawer
        anchor={"left"}
        open={btnSideNav}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            // position: "relative",
          },
        }}
      >
        <div className={`container-side-navbar ${userLocalData ? userAccess[userLocalData.access] : userAccess[1]}`}>
          {user && (
            <>
              <nav className="navBar__side">
                <div className="infoUser__nav">
                  <h2 style={{ fontSize: "1.8rem" }}>
                    {text[0]}{" "}
                    <span style={{ fontSize: "1.6rem" }}>
                      <Pop cutValue={15}>{user.nickname}</Pop>
                    </span>
                  </h2>
                  <div className="btnsUser__Nav">
                    <Link to={"/"} className="myDataBtn__sideNav" onClick={handleClose}>
                      {text[1]}
                    </Link>
                    |
                    <button
                      className="exitBtn_sideNav"
                      onClick={() => {
                        remove();
                        setTimeout(() => {
                          document.location.reload(true);
                        }, 300);
                      }}
                    >
                      {text[2]}
                    </button>
                  </div>
                </div>
                <div className="wallet__nav">
                  <div className="wallet__nav-center">
                    <i class="fas fa-coins fa-lg"></i>
                    <div className="infoWallet">
                      <span className="wallet__nav-saldo">{text[3]}</span>
                      {status === "connected" ? (
                        // carteira ? (
                        <Link to="/wallet" className="wallet__nav-coins">
                          {/* {carteira} EQ9 */}
                          {eq9} EQ9
                          {/* {user.wallet.value} EQ9 */}
                        </Link>
                      ) : // ) : null
                      null}
                    </div>
                  </div>
                </div>
              </nav>
              {/* <div className="notifications__sideNav">
                <div className="msgBlock">
                  <div
                    style={{
                      height: "300px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div ref={chatscrollref} style={{ height: "150px", overflowY: "auto", overflowX: "none", padding: "10px" }}>
                      {messages && messages ? (
                        user.message.map((item) => {
                          return (
                            <p>
                              <Link to={item.path}>
                                <span>{item.origin}</span> <span>{item.msg}</span>
                              </Link>
                            </p>
                          );
                        })
                      ) : (
                        <p>SISTEMA: AINDA NÃO POSSUI MENSAGEM REGISTRADA.</p>
                      )}
                    </div>
                    <div
                      style={{
                        boxShadow: "0 0 20px 0px rgba(0,0,0,4)",
                        background: "linear-gradient(rgba(0,0,0,0.6),transparent)",
                        width: "100%",
                        height: "150px",
                        position: "absolute",
                        zIndex: 4000,
                        top: 0,
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                </div>
              </div> */}
              {user ? (
                <div className="container-buttons-sideNav">
                  <div className="buttons-box">
                    <Link
                      to={
                        userLocalData
                          ? userLocalData.access === 1
                            ? !mobile
                              ? "/player"
                              : "/mobile/player"
                            : userLocalData.access === 2
                            ? "/staker"
                            : userLocalData.access === 3
                            ? !mobile
                              ? "/organizer"
                              : "/mobile/evo"
                            : "/"
                          : "/"
                      }
                      onClick={handleClose}
                    >
                      <BtnSide>
                        {userLocalData
                          ? userLocalData.access === 1 || userLocalData.access === 2
                            ? user.nickname
                            : userLocalData.access === 3 && evo?.name
                          : null}
                      </BtnSide>
                    </Link>
                  </div>
                  {userLocalData ? (
                    userLocalData.access === 1 ? (
                      <>
                        <Link to="/tournaments" onClick={handleClose}>
                          <BtnSide>{text[5]}</BtnSide>
                        </Link>
                        {/* <Link to="/meusstakers" onClick={handleClose}>
                          <BtnSide>{text[6]}</BtnSide>
                        </Link> */}
                      </>
                    ) : null
                  ) : null}
                  {/* <Link to="/ranking" onClick={handleClose}>
                    <BtnSide>{text[7]}</BtnSide>
                  </Link> */}
                  {/* <Link to="/halloffame" onClick={handleClose}>
                    <BtnSide>{text[8]}</BtnSide>
                  </Link> */}
                  {/* <Link to="/store" onClick={handleClose}>
                    <BtnSide>{text[9]}</BtnSide>
                  </Link> */}
                  {/* <Link to="/onDemand" onClick={handleClose}>
                    <BtnSide>On Demand</BtnSide>
                  </Link> */}
                  <Link to="/wallet" onClick={handleClose}>
                    <BtnSide>{lang === "pt" ? "CARTEIRA" : "WALLET"}</BtnSide>
                  </Link>
                  {userLocalData ? (
                    userLocalData.access === 3 ? (
                      <div className="buttons-box">
                        <Link
                          to="/organizer"
                          // className="button-sideNav"
                          onClick={() => {
                            setAddTournament(true);
                            handleClose();
                          }}
                        >
                          <BtnSide>{text[10]}</BtnSide>
                        </Link>
                      </div>
                    ) : null
                  ) : null}
                </div>
              ) : null}
              <div className="graphic__sideNav">{/* <GraphicPlayer /> */}</div>

              <div className="container-button-profiles">
                {accessBtn ? (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      alignItems: "flex-start",
                      width: "98%",
                      height: "180px",
                      paddingTop: "20px",
                      bottom: "12%",
                      justifyContent: "space-evenly",
                      overflow: "hidden",
                    }}
                  >
                    <Link
                      className="translate-y"
                      to={!mobile ? "/player" : "/mobile/player"}
                      style={{ width: "30%" }}
                      onClick={() => {
                        screenPass.currentTime = 0;
                        handleChange(1);
                        setAccessBtn(false);
                        handleClose();
                        screenPass.play();
                        continuousSound.pause();
                        continuousSound.currentTime = 0;
                      }}
                      onMouseOver={() => setAccessBtn(true)}
                    >
                      <div className="w-button" style={{ width: "100%", background: "transparent" }}>
                        <div
                          className="w-button-icon-player"
                          style={{
                            width: "80px",
                            height: "80px",
                            fontSize: "1rem",
                          }}
                        />
                        <p style={{ fontSize: "1rem", lineHeight: "1rem" }}>{textAccess.button[0]}</p>
                      </div>
                    </Link>
                    <Link
                      className="translate-y"
                      to="/staker"
                      style={{ width: "30%" }}
                      onClick={() => {
                        screenPass.currentTime = 0;
                        handleChange(2);
                        setAccessBtn(false);
                        handleClose();
                        screenPass.play();
                        continuousSound.pause();
                        continuousSound.currentTime = 0;
                      }}
                      onMouseOver={() => setAccessBtn(true)}
                    >
                      <div className="w-button delay" style={{ width: "100%", background: "transparent" }}>
                        <div
                          className="w-button-icon-staker"
                          style={{
                            width: "80px",
                            height: "80px",
                            fontSize: "1rem",
                          }}
                        />
                        <p style={{ fontSize: "1rem", lineHeight: "1rem" }}>{textAccess.button[1]}</p>
                      </div>
                    </Link>
                    {evo && (
                      <Link
                        className="translate-y"
                        to={!mobile ? "/organizer" : "/mobile/evo"}
                        style={{ width: "30%" }}
                        onClick={() => {
                          screenPass.currentTime = 0;
                          handleChange(3);
                          setAccessBtn(false);
                          handleClose();
                          screenPass.play();
                          continuousSound.pause();
                          continuousSound.currentTime = 0;
                        }}
                        onMouseOver={() => setAccessBtn(true)}
                      >
                        <div className="w-button button-evo" style={{ width: "100%", background: "transparent" }}>
                          <div
                            className="w-button-icon-evo"
                            style={{
                              width: "80px",
                              height: "80px",
                              fontSize: "1rem",
                            }}
                          />
                          <p style={{ fontSize: ".8rem", lineHeight: "1rem" }}>{textAccess.button[2]}</p>
                        </div>
                      </Link>
                    )}
                  </div>
                ) : null}
                {/* <Link className="button-profiles-sideNav" to="/" onClick={handleClose}> */}
                <div
                  className="button-profiles-sideNav"
                  onMouseEnter={() => {
                    setAccessBtn(true);
                    continuousSound.currentTime = 0;
                    continuousSound.play();
                  }}
                  onMouseOut={() => {
                    continuousSound.currentTime = 0;
                    continuousSound.pause();
                  }}
                >
                  <div className="button-profiles-sideNav-content">
                    <div className="button-profiles-sideNav-content-img">
                      {userLocalData ? (
                        userLocalData.access === 1 ? (
                          <div className="playerIconSideBar" />
                        ) : userLocalData.access === 2 ? (
                          <div className="stakerIconSideBar" />
                        ) : userLocalData.access === 3 ? (
                          <div className="evoIconSideBar" />
                        ) : (
                          <div className="playerIconSideBar" />
                        )
                      ) : null}
                    </div>
                    <div className="button-profiles-sideNav-content-txt">
                      <p>{text[11]}</p>
                      <h4>{userLocalData ? userAccess[userLocalData.access] : userAccess[1]}</h4>
                    </div>
                  </div>
                  <p>{text[12]}</p>
                </div>
                {/* </Link> */}
              </div>
            </>
          )}
        </div>
      </Drawer>

      {/* </React.Fragment> */}
    </div>
  );
}
