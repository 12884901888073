import { useState, useCallback, useRef, useEffect } from "react";
import { useStore } from "../../context/storeContext";
import { useLocalDB } from "../../context/LocalDB";
import { useDropzone } from "react-dropzone";

import language from "../../assets/language/text";
import images from "../../assets/img";
import Resizer from "../imgResizer";
import api from "../../services/api";
import AvatarEditor from "react-avatar-editor";
import { calculateAge } from "../../utils/calculateAge";
import Pop from "../PopOver";

const initialState = {
  name: "",
  img: "",
  nickname: "",
  birthDate: "",
  img: "",
};

function Profile() {
  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState(initialState);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [ImgPreview, setImgPreview] = useState();
  const text = language[lang].profile;
  const { user } = useStore();
  const [data, setData] = useState();
  const twitchInput = useRef();
  const youtubeInput = useRef();
  const instagramInput = useRef();
  const [editorImage, setEditorImage] = useState();
  const [scaledImage, setScaledImage] = useState();
  // const imgConfirmedPreview = useRef();

  async function fetchUser() {
    const getUser = await api.get(`users/${user._id}`).catch(console.log);
    if (getUser) {
      setData(getUser.data);
    }
  }

  useEffect(() => {
    setData(user);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => {
        setAlert(true);
        setAlertMsg(text[0]);
        setSeverity("error");
      };
      reader.onerror = () => {
        setAlert(true);
        setAlertMsg(text[1]);
        setSeverity("error");
      };
      reader.onload = async () => {
        // const binaryStr = reader.result;
        const resize = await Resizer(file);
        setPreviewOpen(true);
        setImgPreview(resize);
        setScaledImage(resize);
        if (!edit) {
          setEdit(true);
        }
      };
      reader.readAsDataURL(file);
    });
    // eslint-disable-next-line
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  function handleInputEditChange(event) {
    const { value, name } = event.target;
    if (name === "nickname") {
      if (value.length < 10) {
        setEditValue({
          ...editValue,
          [name]: value,
        });
      }
    } else {
      setEditValue({
        ...editValue,
        [name]: value,
      });
    }
  }

  const imgConfirmedPreview = (e) => {
    setEditorImage(e);
  };

  useEffect(() => {
    if (editorImage) {
      // const canvas = editorImage.getImage();
      const canvasScaled = editorImage.getImageScaledToCanvas();
    }
  }, [editorImage]);

  async function handleEdit() {
    if (edit === true) {
      // setEditValue({
      //   name: data.name,
      //   img: data.img,
      //   nickname: data.nickname,
      //   birthDate: data.birthDate,
      //   img: imgConfirmedPreview.current ? imgConfirmedPreview.current.a : user.img,
      // });

      setEdit(false);
      let ValuesToUpload = {};
      Object.keys(editValue)
        .filter((item) => {
          return editValue[item];
        })
        .map((item) => {
          return (ValuesToUpload = {
            ...ValuesToUpload,
            [item]: editValue[item],
          });
        });
      const getSocial = {
        ...ValuesToUpload,
        img: scaledImage,
        socialMedia: {
          twitch: twitchInput.current?.value ? (twitchInput.current?.value.includes("twitch.tv") ? twitchInput.current?.value : "twitch.tv") : "twitch.tv",
          youtube: youtubeInput.current?.value
            ? youtubeInput.current?.value.includes("youtube.com")
              ? youtubeInput.current?.value
              : "youtube.com"
            : "youtube.com",
          instagram: instagramInput.current?.value
            ? instagramInput.current?.value.includes("instagram.com")
              ? instagramInput.current?.value
              : "instagram.com"
            : "instagram.com",
        },
      };
      if (Object.keys(getSocial).length > 0) {
        const putUser = await api.patch(`users`, getSocial).catch(() => {
          setAlert(true);
          setAlertMsg("Erro ao atualizar os dados, aguarde instantes e tente novamente");
          setSeverity("error");
        });
        if (putUser) {
          setAlert(true);
          setAlertMsg("Atualizado");
          setSeverity("success");
          fetchUser();
        }
      }
    } else {
      setEdit(true);
      setEditValue({
        name: data.name,
        nickname: data.nickname,
        img: data.img,
        birthDate: data.birthDate,
      });
    }
    setImgPreview();
  }

  async function handleAcceptInvitation(item, index) {
    const getEvo = await api.get(`users/${item.id}`);
    const dataEvo = getEvo.data;
    const evoPlayers = [
      ...dataEvo.evo.players,
      {
        _id: data._id,
        nickname: data.nickname,
        img: data.img,
        name: data.name,
      },
    ];
    //
    const spliceSolic = [...data.associateSolicitation];
    spliceSolic.splice(index, 1);
    fetchUser();
    const putPlayer = await api
      .patch(`users`, {
        user: { associated: item, associateSolicitation: spliceSolic },
      })
      .catch(console.log);
    async function SliceEvoArray(index) {
      const getEvoSolic = getEvo.data.evo.invites;
      getEvoSolic.splice(index, 1);
      // ANCHOR
      const putEvo = await api
        .patch(`users`, {
          user: {
            evo: {
              ...getEvo.data.evo,
              players: evoPlayers,
              invites: getEvoSolic,
            },
          },
        })
        .catch(console.log);
      return putEvo;
    }
    getEvo.data.evo.invites.filter((item, index) => {
      if (item === data._id) return SliceEvoArray(index);
    });
    setAlert(true);
    setAlertMsg("SUCCESSFULLY ASSOCIATED");
    setSeverity("success");
    return putPlayer;
  }
  if (!data) return null;
  return (
    <div className="c-profile">
      <button onClick={() => handleEdit()}>
        {!edit ? (lang === "pt" ? "EDITAR" : "EDIT") : lang === "pt" ? "SALVAR" : "SAVE"}
        <i class="fas fa-feather fa-2x" />
      </button>
      <div className="c-profile-avatar">
        {edit ? (
          !ImgPreview ? (
            <div {...getRootProps()} className="c-profile-avatar-dropzone">
              <input {...getInputProps()} />
              <i class="fas fa-camera"></i>
            </div>
          ) : (
            <AvatarEditor
              image={ImgPreview}
              ref={imgConfirmedPreview}
              className="c-profile-avatar-dropzone"
              style={{
                overflow: "hidden",
                width: "14vw",
                height: "14vw",
                padding: ".8rem",
              }}
              onImageChange={(e) => {
                setScaledImage(editorImage.getImageScaledToCanvas().toDataURL("image/jpeg"));
              }}
              border={10}
              borderRadius={200}
              color={[150, 150, 150, 0.6]}
              scale={1.2}
              rotate={0}
            />
          )
        ) : data.img ? (
          <>
            <div className="c-profile-avatar-dropzone">
              <i class="fas fa-camera" />
            </div>
            <div className="c-profile-avatar-photo" onDoubleClick={handleEdit}>
              <img src={data.img ? data.img : null} alt="Profile Photo" />
            </div>
          </>
        ) : !ImgPreview ? (
          <div {...getRootProps()} className="c-profile-avatar-dropzone">
            <input {...getInputProps()} />
            <i class="fas fa-camera"></i>
          </div>
        ) : (
          <div className="c-profile-avatar-photo" onDoubleClick={handleEdit}>
            <img src={images.unknown} alt="Profile Photo" />
          </div>
        )}
        <img src={images.bordaAvatar} alt="avatar border" />
        {/* <div
          className="c-profile-avatar-associate"
          onClick={() => {
            setAssociateSolicitation(!associateSolicitation);
          }}
        >
          {data.associateSolicitation && data.associateSolicitation.length > 0 ? (
            <div className="associate-tolltip">
              {data.associateSolicitation.length}
              <div
                className="associate-tolltip-container"
                style={{
                  display: associateSolicitation ? "flex" : "none",
                }}
              >
                <table>
                  <tbody>
                    {data.associateSolicitation.map((item, index) => {
                      return (
                        <tr onClick={() => handleAcceptInvitation(item, index)}>
                          <td>
                            <img src={item.img} alt="evo photo" />
                          </td>
                          <td>{item.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
          {data.associated ? (
            <>
              <div className="c-profile-avatar-associate-img">
                <img src={data.associated.img} alt="photo" />
              </div>
              <div>
                <p>{text[5]}</p>
                <h1> {data.associated.name}</h1>
              </div>
            </>
          ) : (
            <>
              <div className="c-profile-avatar-associate-img">
                <img src={LogoEsport} alt="photo" />
              </div>
              <div>
                <p>clique aqui para</p>
                <h1>ASSOCIAR TIME</h1>
              </div>
            </>
          )}
        </div> */}
      </div>
      <div
        className="c-profile-infos"
        onKeyDown={(e) => {
          if (edit && e?.code === "Enter") {
            handleEdit();
          }
        }}
      >
        <p>
          {edit ? (
            <>
              <input
                style={{ marginRight: ".6rem" }}
                type="text"
                name="name"
                placeholder={text[4]}
                autoComplete="off"
                defaultValue={user?.name}
                value={editValue.name}
                onChange={handleInputEditChange}
                className="default-input"
              />
              <input type="date" name="birthDate" value={editValue.age} onChange={handleInputEditChange} className="default-input" />
            </>
          ) : data.name ? (
            <>
              <Pop cutValue={10}>{data.name}</Pop>
              {` (${calculateAge(data.birthDate)})`}
            </>
          ) : (
            "Nome (idade)"
          )}
        </p>
        {!edit ? (
          <h1 className="gradient-txt" name="nickname">
            <Pop cutValue={12}>{data.nickname}</Pop>
          </h1>
        ) : (
          <input
            className="default-input"
            style={{ height: "40px", width: "100%" }}
            name="nickname"
            value={editValue.nickname}
            maxLength={10}
            onChange={handleInputEditChange}
          />
        )}
        <div className="c-profile-infos-results" style={{ height: "10px", paddingTop: "5px" }}>
          {data.score && !edit ? (
            <>
              W <b>{data.score.w ? data.score.w : 0}</b> | L <b>{data.score.l ? data.score.l : 0}</b> | D <b>{data.score.d ? data.score.d : 0}</b>
            </>
          ) : null}
        </div>
        <div
          className="c-profile-infos-social"
          style={
            edit
              ? {
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "-2.9rem",
                }
              : { display: "flex" }
          }
        >
          {edit === true ? (
            <input
              className="default-input"
              style={{ height: "30px", width: "100%" }}
              defaultValue={data.socialMedia?.twitch ? data.socialMedia.twitch : "twitch.tv/"}
              ref={twitchInput}
            />
          ) : (
            <a href={data.socialMedia?.twitch ? "https://" + data.socialMedia.twitch : "https://www.twitch.tv/"} rel="noreferrer" target="_blank">
              <i class="fab fa-twitch"></i>
            </a>
          )}
          {edit === true ? (
            <input
              className="default-input"
              style={{ height: "30px", width: "100%" }}
              defaultValue={data.socialMedia?.youtube ? data.socialMedia.youtube : "youtube.com/"}
              ref={youtubeInput}
            />
          ) : (
            <a href={data.socialMedia?.youtube ? "https://" + data.socialMedia.youtube : "https://www.youtube.com/"} rel="noreferrer" target="_blank">
              <i class="fab fa-youtube"></i>
            </a>
          )}
          {edit === true ? (
            <input
              className="default-input"
              style={{ height: "30px", width: "100%" }}
              defaultValue={data.socialMedia?.instagram ? data.socialMedia.instagram : "instagram.com/"}
              ref={instagramInput}
            />
          ) : (
            <a href={data.socialMedia?.instagram ? "https://" + data.socialMedia.instagram : "https://www.instagram.com/"} rel="noreferrer" target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
          )}
          {/* {!edit && (
            <a href="*" rel="noreferrer" target="_blank" style={{ marginLeft: "2.5vw" }}>
              <i class="fas fa-share-alt"></i>
            </a>
          )} */}
        </div>
      </div>
      {edit && <profileUpdate edit={edit} handleClose={handleEdit} />}
      {/* {previewOpen && <Preview open={previewOpen} setOpen={setPreviewOpen} imagePreview={ImgPreview} />} */}
    </div>
  );
}

export default Profile;
