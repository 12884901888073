import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import language from "../../assets/language/text";
import deckAddOptions from "../deck/options/deckAddOptions";
import TournamentJSON from "../../contracts/Tournament.json";
import api from "../../services/api";
import eq9Config from "../../services/eq9Config";
import NumberToWei from "../../utils/toWei";
import Pop from "../PopOver";
const tronweb = window.tronWeb;

const AddTournament = ({ fetchEvents, open, setOpen, evento }) => {
  const { user, evo } = useStore();
  const rewardItem = useRef(null);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const [rewardType, setRewardType] = useState("EQ9");
  const [loading, setLoading] = useState(false);
  const [fixedRounds, setFixedRounds] = useState(false);
  const [rulesSelected, setRulesSelected] = useState([]);
  const text = language[lang].addTournament;
  const checkDeck = useRef(null);
  const format = useRef(null);
  const gameType = useRef(null);
  const startDateRef = useRef(null);
  const { abi, bytecode } = TournamentJSON;
  const contractEQ9Hash = eq9Config.trc20ContractAddress;
  const rankingSelected = useRef(null);
  const [rankings, setRankings] = useState([]);
  const [ranking, setRanking] = useState(false);

  const swissPlayerLimit = [
    { min: 2, max: 2 },
    { min: 3, max: 4 },
    { min: 5, max: 7 },
    { min: 8, max: 16 },
    { min: 17, max: 32 },
    { min: 33, max: 64 },
    { min: 65, max: 128 },
    { min: 129, max: 212 },
    { min: 213, max: 384 },
    { min: 385, max: 672 },
    { min: 673, max: 1248 },
    { min: 1249, max: 2272 },
    { min: 2273, max: "inf" },
  ];

  useEffect(() => {
    fetchRankings();
  }, []);

  const initialValuesTournament = {
    name: "",
    startDate: "",
    img: evo ? evo.img && evo.img : null,
    rules: rulesSelected ? rulesSelected : "",
    mail: evo ? evo.emails[0] : "contato@equals9.com",
    game: {
      format: "MODERN",
      typeOf: "ARENA",
      name: "Magic",
    },
    description: "",
    subscription: "",
    hour: "",
    reward: {
      typeOf: "EQ9",
      // amount: 0,
    },
    tourneyConfig: {
      format: "swiss",
      playoffs: null,
      cutLimit: 8,
      maxRounds: null,
      bestOf: 3,
      winValue: 3,
      drawValue: 1,
      gameType: "magic",
      lossValue: 0,
    },
  };
  const [tournament, setTournament] = useState(initialValuesTournament);

  const contractOptions = {
    feeLimit: 1000000000,
    callValue: 0,
    userFeePercentage: 10,
    originEnergyLimit: 10,
    abi: abi,
    bytecode: bytecode,
    parameters: [contractEQ9Hash, tournament.subscription + "000000000000000000"],
    name: `Tournament@${tournament.name.replaceAll(" ", "_")}`,
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  async function handleAddTournament() {
    const getNewDate = new Date(`${startDateRef.current?.value} ${tournament.hour}`);
    setLoading(true);
    const tournamentUp = {
      ...tournament,
      startDate: getNewDate.toISOString(),
      rules: rulesSelected,
      ranking: ranking ? rankingSelected.current?.value : null,
      game: {
        ...tournament.game,
        format: format.current.value,
        typeOf: gameType.current.value === "MAGIC ONLINE" ? "MOL" : gameType.current.value,
        needsDeck: checkDeck.current.checked,
      },
      subscription: NumberToWei(tournament.subscription),
      reward: {
        ...tournament.reward,
      },
    };
    const postTournament = await api.post("tournaments", tournamentUp).catch((error) => {
      if (error.response.status === 405)
        return setAlert(true), setAlertMsg(`405: "ERRO AO CRIAR O TORNEIO, ENTRE EM CONTATO COM O SUPPORTE."`), setSeverity("error");
      setAlert(true);
      setAlertMsg(error.response.data.message[0]);
      setSeverity("error");
      setLoading(false);
    });
    if (postTournament) {
      setAlert(true);
      setAlertMsg(text[0]);
      setSeverity("success");
      fetchEvents();
      handleClose();
      setLoading(false);
    }
  }

  const handleChangeTournament = (event) => {
    const { value, name } = event.target;
    setTournament({
      ...tournament,
      [name]: value.toUpperCase(),
    });
  };

  const handleChangeDescription = (event) => {
    const { value, name } = event.target;
    setTournament({
      ...tournament,
      [name]: value,
    });
  };

  const fetchRankings = async () => {
    const getRankings = await api.get(`rankings`, { params: { evoId: evo._id, page: 0, size: 30 } }).catch(console.log);
    if (getRankings) {
      setRankings(getRankings.data);
    }
  };

  return (
    <Dialog
      open={open}
      className="modal-add-tournament"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          background: "transparent",
          boxShadow: "none",
        },
      }}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
        <section style={{ height: "100%", width: "60%" }}>
          <h1>create event</h1>
          <div className="modal-add-tournament-form">
            <div className="default-input-label">
              <input
                required
                type="text"
                id="input-name-event"
                placeholder={lang === "pt" ? "DIGITE AQUI O NOME DO SEU TORNEIO" : "TYPE HERE THE TOURNAMENT NAME"}
                autoComplete="off"
                value={tournament.name}
                name="name"
                onChange={handleChangeTournament}
                className="default-input-label-content inputTornament"
              />
              <label className="input-label" for="input-name-event">
                {text[2]}
              </label>
              <span className="input-label-line" for="input-name-event" style={{ width: "55%" }} />
            </div>
            <div className="modal-add-tournament-form-date">
              <div className="default-input-label width-2">
                <input
                  required
                  id="input-date-event"
                  type="date"
                  name="startDate"
                  ref={startDateRef}
                  max="1979-12-31"
                  className="default-input-label-content inputTornament"
                />
                <label className="input-label" for="input-date-event">
                  {text[3]}
                </label>
                <span className="input-label-line" for="input-date-event" style={{ width: "75%" }} />
              </div>
              <div className="default-input-label width-2">
                <input
                  required
                  id="input-hour-event"
                  type="time"
                  value={tournament.hour}
                  name="hour"
                  onChange={handleChangeTournament}
                  className="default-input-label-content inputTornament"
                />
                <label className="input-label" for="input-hour-event">
                  {text[8]}
                </label>
                <span className="input-label-line" for="input-hour-event" style={{ width: "82%" }} />
              </div>
            </div>
            <section style={{ display: "flex", height: "65px", alignItems: "center", justifyContent: "space-between" }}>
              <div className="default-input-label" style={{ width: "20%", padding: "10px", marginRight: "10px", marginTop: "10px" }}>
                <input
                  type="checkbox"
                  className="default-input-label-content inputTornament"
                  style={{ width: "22px", height: "22px", borderRadius: "15px", padding: "10px", marginTop: "7px" }}
                  checked={fixedRounds}
                  onChange={() => setFixedRounds(!fixedRounds)}
                />
                <label className="input-label" for="input-numberOfRounds-event">
                  {lang === "pt" ? "Rodadas Fixas?" : "Fixed Rounds?"}
                </label>
              </div>
              {fixedRounds && (
                <div className="default-input-label" style={{ width: "65%", display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <input
                      required
                      id="input-numberOfRounds-event"
                      type="number"
                      style={{ textAlign: "center" }}
                      value={tournament.tourneyConfig.maxRounds}
                      name="hour"
                      onChange={(e) => {
                        setTournament({ ...tournament, tourneyConfig: { ...tournament.tourneyConfig, maxRounds: e.target.value } });
                      }}
                      className="default-input-label-content inputTornament"
                    />
                    <label className="input-label" for="input-numberOfRounds-event">
                      {lang === "pt" ? "Rodadas" : "Rounds"}
                    </label>
                    <span className="input-label-line" for="input-numberOfRounds-event" style={{ width: "80%" }} />
                  </div>
                  <div style={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "-10px" }}>
                    <p style={{ fontFamily: "gilroy-bold", textAlign: "center", color: "var(--pink)" }}>{lang === "pt" ? "Jogadores" : "Players"}</p>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                      <p style={{ color: "var(--pink)", minWidth: "50%" }}>Min</p>{" "}
                      <p style={{ color: "var(--pink)", minWidth: "50%" }}>
                        {tournament.tourneyConfig.maxRounds
                          ? swissPlayerLimit[
                              tournament.tourneyConfig.maxRounds < swissPlayerLimit.length
                                ? tournament.tourneyConfig.maxRounds - 1
                                : swissPlayerLimit.length - 1
                            ]?.min
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </section>
            <select className="default-input inputTornament" ref={gameType}>
              {deckAddOptions.gameType.map((item) => {
                if (item === "MOL") {
                  return (
                    <option style={{ background: "black" }} key={item}>
                      MAGIC ONLINE
                    </option>
                  );
                } else {
                  return (
                    <option style={{ background: "black" }} key={item}>
                      {item}
                    </option>
                  );
                }
              })}
            </select>
            <select className="default-input inputTornament" defaultValue="MODERN" ref={format}>
              {Object.keys(deckAddOptions.format).map((item) => {
                return (
                  <option style={{ background: "black" }} key={item}>
                    {deckAddOptions.format[item].name}
                  </option>
                );
              })}
            </select>
            <div className="checkbox-div">
              <input id="check-deck" type="checkbox" className="checkbox-div-input" ref={checkDeck} />
              <label for="check-deck" className="checkbox-div-label">
                <i class="fas fa-check"></i>
              </label>
              <label for="check-deck" className="checkbox-div-text">
                {lang === "pt" ? "Lista de Deck" : "Deck List Required"}
              </label>
            </div>

            {/* <span className="form-hr" /> */}
            {/* <h1>PLAYOFFS</h1> */}
            {/* <div className='seletor'>
          <p>sim</p>
          <input type='CHECKBOX' placeholder='SIM' className='checkboxEvent' />
          <p>não</p>
          <input type='CHECKBOX' placeholder='NÃO' className='checkboxEvent' />
        </div> */}
            {/* <Select
          value='GAME'
          placeholder='GAME'
          labelId='demo-customized-select-label'
          id='demo-customized-select'
          style={{ background: "gray" }}>
          <MenuItem value='GAME' style={{ background: "gray" }}>
            <em>GAME</em>
          </MenuItem>
          <MenuItem value='MAGIC' style={{ background: "gray" }}>
            MAGIC THE GATHERING
          </MenuItem>
          <MenuItem value='LOL' style={{ background: "gray" }}>
            LEAGUE OF LEGENDS
          </MenuItem>
          <MenuItem value='DOTA2' style={{ background: "gray" }}>
            DOTA2
          </MenuItem>
        </Select> */}
            {/* <Select
          value='GAME'
          placeholder='GAME'
          labelId='demo-customized-select-label'
          id='demo-customized-select'
          style={{ background: "gray" }}>
          <MenuItem value='GAME' style={{ background: "gray" }}>
            <em>ESTILO</em>
          </MenuItem>
          <MenuItem value='MAGIC' style={{ background: "gray" }}>
            MTG ARENA
          </MenuItem>
          <MenuItem value='LOL' style={{ background: "gray" }}>
            TABLETOP
          </MenuItem>
        </Select> */}
            {/* <Select
          value='GAME'
          placeholder='GAME'
          labelId='demo-customized-select-label'
          id='demo-customized-select'
          style={{ background: "gray" }}>
          <MenuItem value='GAME' style={{ background: "gray" }}>
            <em>MODO</em>
          </MenuItem>
          <MenuItem value='MAGIC' style={{ background: "gray" }}>
            STANDARD
          </MenuItem>
          <MenuItem value='LOL' style={{ background: "gray" }}>
            MODERN
          </MenuItem>
        </Select> */}
            {/* <select
          className="default-input"
          onChange={(e) => {
            setRewardType(e.target.value);
          }}
          style={{ margin: "1rem 0" }}
        >
          <option value="EQ9">EQ9</option>
          <option value="PRESENCIAL">PRESENCIAL</option>
        </select> */}
            {rewardType === "EQ9" ? null : (
              // <div className="default-input-label" style={{ marginTop: "1rem" }}>
              //   <input
              //     required
              //     id="input-reward-event"
              //     type="number"
              //     placeholder="Type here..."
              //     name="reward"
              //     ref={RewardAmount}
              //     className="default-input-label-content inputTornament"
              //     // onChange={handleChangeTournament}
              //   />
              //   <label className="input-label" for="input-reward-event">
              //     {text[7]}
              //   </label>
              //   <span
              //     className="input-label-line"
              //     for="input-reward-event"
              //     style={{ width: "11.2rem" }}
              //   />
              // </div>

              <div style={{ width: "100%", padding: "5px" }} className="default-input-label">
                <input
                  required
                  type="text"
                  id="input-name-event"
                  // placeholder={lang === "pt" ? "DIGITE O OBJETO DE RECOMPENSA AQUI" : "TYPE THE TOURNAMENT NAME"}
                  autoComplete="off"
                  ref={rewardItem}
                  className="default-input-label-content inputTornament"
                />
                <label className="input-label" for="input-name-event">
                  OBJETO DE RECOMPENSA
                </label>
                <span className="input-label-line" for="input-name-event" style={{ width: "55%" }} />
              </div>
            )}
            <div className="default-input-label">
              <input
                required
                id="input-sub-event"
                type="number"
                placeholder={lang === "pt" ? "VALOR EM EQ9" : "EQ9 VALUE"}
                value={tournament.subscription}
                name="subscription"
                onChange={handleChangeTournament}
                className="default-input-label-content inputTornament"
              />
              <label className="input-label" for="input-sub-event">
                {text[6]}
              </label>
              <span className="input-label-line" for="input-sub-event" style={{ width: "60%" }} />
            </div>

            <div className="default-input-label">
              <textarea
                // col="30"
                // row="5"
                required
                id="input-desc-event"
                type="text"
                placeholder={lang === "pt" ? "DIGITE A DESCRIÇÃO DO SEU EVENTO AQUI" : "TYPE HERE THE TOURNAMENT DESCRIPTION"}
                value={tournament.description}
                name="description"
                onChange={handleChangeDescription}
                className="default-input-label-content inputTornament"
                style={{ height: "9rem", wordWrap: "break-word" }}
              />
              <label className="input-label" for="input-desc-event">
                {text[5]}
              </label>
              <span className="input-label-line" for="input-desc-event" style={{ width: "45%" }} />
            </div>
            <button
              onClick={() => {
                !loading && handleAddTournament();
              }}
              style={{
                fontSize: loading && "1rem",
                cursor: loading ? "not-allowed" : "pointer",
              }}
              className={"default-button creat-event__button"}
            >
              {!loading ? "Criar" : "Criando, aguarde a criação ser concluída."}
            </button>
          </div>
        </section>
        <section style={{ height: "100%", minHeight: "69vh", width: "40%" }}>
          <div className="default-input inputTornament" style={{ height: "250px", border: "2px solid rgb(0,190,210)" }}>
            <h3 style={{ color: "rgb(170,30,160)" }}>{lang === "pt" ? "REGRAS" : "RULES"}</h3>
            {evo?.rules?.map((item) => {
              return (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "left", height: "40px" }} key={item}>
                  <input
                    type="checkbox"
                    style={{ margin: "5px" }}
                    onClick={(e) => {
                      if (e.target.checked) {
                        return setRulesSelected((e) => [...e, item]);
                      } else {
                        let ruleIndice;
                        rulesSelected.filter((rule, index) => {
                          if (rule === item) {
                            ruleIndice = index;
                          }
                        });
                        let ruleSplice = rulesSelected;
                        ruleSplice.splice(ruleIndice, 1);
                        return setRulesSelected(ruleSplice);
                      }
                    }}
                  />
                  <div style={{ background: "black", fontSize: "0.8em" }}>
                    <Pop cutValue={65}>{item}</Pop>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: "10px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h3 style={{ color: "rgb(170,30,160)" }}>{lang === "pt" ? "RANKINGS" : "RANKINGS"}</h3>
              <input
                type="checkbox"
                style={{ width: "30px", height: "30px", margin: "10px" }}
                onChange={() => {
                  setRanking(!ranking);
                }}
              />
            </div>
            {ranking && (
              <select className="default-input inputTornament" ref={rankingSelected}>
                {rankings.map((item) => {
                  return (
                    <option value={item._id} style={{ background: "black" }} key={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
        </section>
      </div>
    </Dialog>
  );
};

export default AddTournament;
