const token = window.localStorage.getItem("token");
const TokenConfirm = token ? window.localStorage.getItem("token").split('"')[1] : null;

export default {
  auth: {
    token: TokenConfirm
  },
  transports: ["websocket"],
  timeout: 20000,
  reconnection: false,
  reconnectionAttempts: 3,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
};
