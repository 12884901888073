import BtnContinuo from "../../../components/buttons/BtnContinuo";

const confirmButton = (
  results,
  thirdRound,
  matchData,
  player2Bye,
  winner,
  confirm,
  text,
  socket,
  checkIn,
  confirmed,
  id,
  visualResults,
  setAlert,
  setAlertMsg,
  setSeverity,
  lang
) => {
  const handleConfirmResult = () => {
    if (!checkIn.player1 || !checkIn.player2) {
      return (
        setAlert(true),
        setAlertMsg(lang == "pt" ? "Os dois precisam fazer o check-in para submeter os resultados." : "Both players need to check-in to submit the results"),
        setSeverity("error")
      );
    }
    // const getConfirmed = yourSeat === 1 ? { playerOne: matchData?.playerOne.check } : { playerTwo: matchData?.playerTwo.check };
    // setConfirmed({ ...confirmed, ...getConfirmed });
    // setConfirm(!confirm);
    if (confirmed.playerOne === true && confirmed.playerTwo === true) {
      return setAlert(true), setAlertMsg(lang == "pt" ? "Os resultados já foram submetidos." : "Results have already been sent"), setSeverity("error");
    }
    socket.emit("setMatchData", {
      tournamentId: id,
      check: !confirm,
      results: {
        playerOneWins: visualResults.player1,
        playerTwoWins: visualResults.player2,
        draws: 0,
      },
      checkIn: true,
      rounds: results,
    });
  };

  if ((results && !thirdRound && results.r1 > 0 && results.r2 > 0) || (thirdRound && results.r3 > 0)) {
    if (!matchData?.playerOne.check || !matchData?.playerTwo.check) {
      if (!player2Bye && !winner) {
        return (
          <BtnContinuo
            classe="animate__animated animate__backInDown button-round-confirm"
            style={{
              background: confirm ? "#ff28f1" : "transparent",
            }}
            handleConfirm={handleConfirmResult}
          >
            {confirm ? text[1] : text[0]}
          </BtnContinuo>
        );
      } else {
        return (
          <p
            style={{
              fontSize: "clamp(1.8rem, 1.6vw, 2.4rem)",
              color: "var(--lightblue)",
              textAlign: "center",
            }}
          >
            CONFIRMED
          </p>
        );
      }
    }
  }
};

export default confirmButton;
