import { useState, useRef, useEffect } from "react";
import images from "../../assets/img";
import { useHistory } from "react-router-dom";
import { useLocalDB } from "../../context/LocalDB";
import { setTimeout } from "timers";
import api from "../../services/api";
import { useStore } from "../../context/storeContext";
import Pop from "../../components/PopOver";
import { Link } from "@material-ui/core";

function PlayerCard({
  id,
  data,
  children,
  deck,
  setPreview,
  seat,
  side,
  confirm,
  winner,
  tournament,
  connected,
  bye,
  player2,
  player2Bye,
  you,
  handleCheckIn,
  checkIn,
  roundData,
  player2Active,
}) {
  const [previewDeck, setPreviewDeck] = useState(false);
  const [imgPreview, setImgPreview] = useState();
  const [btnDrop, setBtnDrop] = useState(false);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const deckCardsBtn = useRef(null);
  const history = useHistory();
  const yourPlayer = seat && seat === 1 ? "player1" : "player2";
  const getMatchID = seat === 1 ? roundData?.playerOne?.arenaAlias : roundData?.playerTwo?.arenaAlias;
  const checkInButton = useRef(null);
  const { user } = useStore();

  const handleDeckPreview = () => {
    setPreviewDeck(!previewDeck);
  };

  useEffect(() => {
    if (setPreview) {
      setPreview(imgPreview);
    }
  }, [imgPreview]);
  function handleManatype(mana, index) {
    if (mana === "W") return <img className="mana" src={images.whiteMana} alt="imgMana" key={index} />;
    if (mana === "U") return <img className="mana" src={images.blueMana} alt="imgMana" key={index} />;
    if (mana === "B") return <img className="mana" src={images.blackMana} alt="imgMana" key={index} />;
    if (mana === "G") return <img className="mana" src={images.greenMana} alt="imgMana" key={index} />;
    if (mana === "R") return <img className="mana" src={images.redMana} alt="imgMana" key={index} />;
    else
      return (
        <div className="wasteMana" key={index}>
          {mana}
        </div>
      );
  }
  const handleDrop = async () => {
    const dropConfitm = tournament.players.filter((item) => item.player === user._id && item.drop);
    if (dropConfitm.length === 0) {
      const confirm = await window.confirm(lang == "pt" ? "Você sairá deste torneio, deseja confirmar?" : "You will be out of this tournament, are u shure?");
      if (confirm) {
        const getDrop = await api.delete(`tourneys/remove-self/${id}`).catch(console.log);
        if (getDrop) {
          setAlert(true);
          setAlertMsg("YOU HAVE LEFT THE TOURNAMENT");
          setSeverity("success");
          setBtnDrop(false);
          setTimeout(() => {
            history.push("/");
          }, 1000);
        }
      }
    } else {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "Você já foi removido desse torneio." : "You have already dropped from this tournament.");
      setSeverity("error");
    }
  };

  const ConfirmCheckIn = () => {
    if (you && !checkIn && player2 && !confirm && !winner) {
      return (
        <button
          className="button-effect-round"
          style={{
            position: "absolute",
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            fontFamily: "gilroy-bold",
            fontSize: ".9rem",
            top: "-50%",
            left: "40%",
            zIndex: "9999",
          }}
          ref={checkInButton}
          onClick={() => handleCheckIn(yourPlayer, true)}
        >
          Check-in
        </button>
      );
    }
  };

  if (bye) {
    return (
      <div
        className="room-card-player"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            fontFamily: "gilroy-bold",
            color: "var(--pink)",
            fontSize: "2.5rem",
          }}
        >
          BYE
        </div>
      </div>
    );
  }

  return (
    <>
      {data ? (
        <div className="room-card-player">
          {ConfirmCheckIn()}
          <div className="front">
            <div className="backCardIMG" />
            <div className="details-avatar-front" style={{ left: side === 1 ? "-4rem" : "13rem" }}>
              {data.img && data.img && <img src={data.img} alt="profileImg" />}
              {data ? (
                side === 1 ? (
                  <div
                    style={{
                      background: "green",
                      width: "1.2rem",
                      aspectRatio: "1",
                      borderRadius: "50%",
                      position: "absolute",
                      bottom: "0",
                      right: "1.2rem",
                      border: "2px #eee solid",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      background: connected ? "green" : "red",
                      width: "1.2rem",
                      aspectRatio: "1",
                      borderRadius: "50%",
                      position: "absolute",
                      bottom: "0",
                      left: "1.2rem",
                      border: "2px #eee solid",
                    }}
                  />
                )
              ) : null}
              {checkIn && (
                <div
                  className="details-avatar-front-readyBox"
                  style={{
                    right: side === 1 ? "-65%" : "100%",
                  }}
                >
                  <p>{lang === "pt" ? "Pronto" : "Ready"}</p>
                </div>
              )}
              {!you ? (
                connected ? (
                  <div
                    className="details-avatar-front-status"
                    style={{
                      color: "var(--lightgreen)",
                    }}
                  >
                    IN ROOM
                  </div>
                ) : (
                  <div
                    className="details-avatar-front-status"
                    style={{
                      color: "var(--pink)",
                    }}
                  >
                    OFFLINE
                  </div>
                )
              ) : null}
            </div>
            <div
              className="social-iconsCard"
              style={{
                transform: side === 2 ? "scaleX(-1)" : null,
                left: side === 1 ? "-20%" : "100%",
              }}
            >
              <a href="http://facebook.com.br/" rel="noreferrer" target="_blank" style={{ transform: side === 2 ? "scaleX(-1)" : null }}>
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="http://twitch.tv/" rel="noreferrer" target="_blank" style={{ transform: side === 2 ? "scaleX(-1)" : null }}>
                <i className="fab fa-twitch"></i>
              </a>
              <a href="http://youtube.com.br/" rel="noreferrer" target="_blank" style={{ transform: side === 2 ? "scaleX(-1)" : null }}>
                <i className="fab fa-youtube"></i>
              </a>
              <a href="http://instagram.com.br/" rel="noreferrer" target="_blank" style={{ transform: side === 2 ? "scaleX(-1)" : null }}>
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            {confirm && (
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  background: "var(--lightblue)",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1rem",
                  borderRadius: "50%",
                  position: "absolute",
                  right: side === 1 ? `2%` : "60%",
                  bottom: 0,
                }}
              >
                CONFIRMED
              </div>
            )}
            <div className="backContent">
              <h1
                style={{
                  textAlign: side === 1 ? "start" : "end",
                  marginLeft: side === 2 && "-25px",
                  fontSize: "1rem",
                  // background: "black",
                }}
              >
                <Pop
                  cutValue={8}
                  sx={{ textAlign: side === 1 ? "start" : "end", marginLeft: side === 2 && "-20px", fontSize: "1rem" }}
                  classe="gradientNickBack"
                >
                  {data.nickname.toUpperCase()}
                </Pop>
              </h1>
              {deck && (
                <>
                  <p>{deck && deck.deckName}</p>
                  {/* <div className="deck-orb" style={{ width: "100px" }} ref={deckCardsBtn} onClick={() => handleDeckPreview()}> */}
                  <Link
                    href={`${process.env.REACT_APP_ENDPOINT}deckView/${deck._id}`}
                    target="blank"
                    className="deck-orb"
                    style={{ width: "100px" }}
                    ref={deckCardsBtn}
                    // onClick={() => handleDeckPreview()}
                  >
                    <span
                      className="deck-orb-bg"
                      style={{
                        marginTop: "-23%",
                        background: deck && `var(--${deck.deckType})`,
                      }}
                    />
                    <span
                      style={{
                        zIndex: 2000,
                        fontSize: "1.2rem",
                        // marginTop: "18px",
                        fontFamily: "gilroy-bold",
                        textAlign: "center",
                        // width: "100px",
                      }}
                    >
                      Deck
                    </span>
                  </Link>
                </>
              )}
              {/* <p className="textScore">{`${data.score.w}/${data.score.l}`}</p> */}
            </div>
          </div>
          <div className="back">
            <div className="details">
              <div className="children">{children}</div>
              <div className="cardLogo" />
              {data.img && data.img && <img src={data.img} alt="profileImg" />}
              {/* <img src={Logo} alt='logo' /> */}
            </div>
          </div>
          {winner ? (
            winner === seat ? (
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "-40%",
                  fontSize: "3rem",
                  color: "var(--lightgreen)",
                  transform: "translateX(-50%)",
                }}
              >
                WON
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "-40%",
                  fontSize: "3rem",
                  color: "var(--pink)",
                  transform: "translateX(-50%)",
                }}
              >
                LOST
              </div>
            )
          ) : player2Bye ? (
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "-40%",
                fontSize: "3rem",
                color: "var(--lightgreen)",
                transform: "translateX(-50%)",
              }}
            >
              WON
            </div>
          ) : null}
          {side === 1 && (
            <>
              <button
                style={{
                  position: "absolute",
                  width: "60px",
                  aspectRatio: "1",
                  bottom: "0",
                  right: "-30%",
                  borderRadius: "50%",
                  fontSize: ".8rem",
                }}
                className="button-round"
                onClick={() => {
                  setBtnDrop(!btnDrop);
                }}
              >
                DROP
              </button>
              {btnDrop && (
                <div
                  style={{
                    position: "absolute",
                    width: "180px",
                    height: "40px",
                    right: "-52%",
                    bottom: "25%",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    transform: "scale(.8)",
                  }}
                >
                  <button
                    className="default-button"
                    style={{
                      width: "80px",
                      height: "30px",
                      background: "#ff28f1",
                      fontSize: ".8rem",
                    }}
                    onClick={() => {
                      setBtnDrop(!btnDrop);
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    className="default-button"
                    style={{
                      width: "80px",
                      height: "30px",
                      background: "#ff28f1",
                      fontSize: ".8rem",
                    }}
                    onClick={handleDrop}
                  >
                    CONFIRM
                  </button>
                </div>
              )}
            </>
          )}
          {tournament?.game.typeOf === "ARENA" && (
            <div
              style={{
                bottom: "-5rem",
                left: "50%",
                transform: "translateX(-50%)",
                position: "absolute",
                fontFamily: "gilroy-bold",
                color: "var(--pink)",
                cursor: "pointer",
              }}
              onClick={(e) => {
                navigator.clipboard.writeText(getMatchID);
                setAlert(true);
                setAlertMsg("Copiado para a área de transferênacia!");
                setSeverity("success");
              }}
            >
              {getMatchID}
              <i style={{ color: "var(--pink)" }} className="fas fa-share-alt"></i>
            </div>
          )}
          {side === 2 && !player2Active && !player2Bye ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                background: "var(--pink)",
                width: "100%",
                textAlign: "center",
                fontSize: "2rem",
                fontFamily: "gilroy-bold",
              }}
            >
              {lang === "pt" ? "JOGADOR SAIU DO TORNEIO" : "PLAYER LEFT THE TOURNAMENT"}
            </div>
          ) : null}
        </div>
      ) : (
        <div
          className="room-card-player"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontFamily: "gilroy-bold",
              color: "var(--pink)",
              fontSize: "1.5rem",
            }}
          >
            {lang == "pt" ? "AGUARDANDO OPONENTE..." : "WAITING OPPONENT..."}
          </div>
        </div>
      )}

      {previewDeck && (
        <div
          style={{
            position: "absolute",
            width: "400px",
            height: "560px",
            background: "rgba(0,0,0,0.5)",
            zIndex: 3000,
            backdropFilter: "blur(20px)",
            borderRadius: "15px",
          }}
        >
          <i style={{ position: "absolute", right: "10px", top: "10px" }} className="far fa-times-circle fa-2x" onClick={handleDeckPreview} />
          <div style={{ height: "550px", overflow: "auto" }}>
            <table style={{ margin: "20px" }}>
              <label>DECK</label>
              {deck.deckCards
                ? deck.deckCards.map((item, index) => {
                    const manacost =
                      item.card.manaCost &&
                      item.card.manaCost.split("").filter((char) => {
                        if (char !== "{" && char !== "}") return true;
                      });
                    return (
                      <tr key={index} onMouseOver={() => setImgPreview(item.card.imageUrl)} onMouseOut={() => setImgPreview()}>
                        <td>{item.count}</td>
                        <td>
                          <Pop cutValue={15}>{item.card.name}</Pop>
                        </td>
                        <td
                          style={{
                            display: "flex",
                            pointerEvents: "none",
                            textAlign: "right",
                          }}
                        >
                          {manacost &&
                            manacost.map((item, index) => {
                              return handleManatype(item, index);
                            })}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </table>
            <table style={{ margin: "20px" }}>
              <label>SideBoard</label>
              {deck.sideBoard?.map((item, index) => {
                const manacost =
                  item.card.manaCost &&
                  item.card.manaCost.split("").filter((char) => {
                    if (char !== "{" && char !== "}") return true;
                  });
                return (
                  <tr key={index} onMouseOver={() => setImgPreview(item.card.imageUrl)} onMouseOut={() => setImgPreview()}>
                    <td>{item.count}</td>
                    <td>
                      <Pop cutValue={15}>{item.card.name}</Pop>
                    </td>
                    <td
                      style={{
                        display: "flex",
                        pointerEvents: "none",
                        textAlign: "right",
                      }}
                    >
                      {manacost &&
                        manacost.map((item, index) => {
                          return handleManatype(item, index);
                        })}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default PlayerCard;
