import React, { useState, useEffect } from "react";
// import Tilt from "react-vanilla-tilt";
import { useLocalDB } from "../../context/LocalDB";

const options = {
  reverse: false, // reverse the tilt direction
  max: 35, // max tilt rotation (degrees)
  perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1, // 2 = 200%, 1.5 = 150%, etc..
  speed: 300, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  glare: true, // if it should have a "glare" effect
  // "max-glare":       1,      // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
  // "glare-prerender": false   // false = VanillaTilt creates the glare elements for you, otherwise
  //                            // you need to add .js-tilt-glare>.js-tilt-glare-inner by yourself
};

function Card({ data }) {
  const [qnt, setQnt] = useState(1);
  const { cartItems, setCartItems } = useLocalDB();

  const handleQntSub = () => {
    if (qnt > 0) setQnt(qnt - 1);
  };
  const handleQntAdd = () => {
    if (qnt < data.qnt || qnt < 10) setQnt(qnt + 1);
  };

  const handleCartAdd = () => {
    const dataItems = {
      name: data.name,
      qnt: qnt,
      img: data.img,
      value: data.value * qnt,
    };
    setCartItems([...cartItems, dataItems]);
  };

  return (
    <section className="store-card">
      <img src={data.img} alt="Product" />
      <div className="store-card__description">
        <h2>{data.name}</h2>
        <p>
          Valor:{" "}
          {parseInt(data.value * qnt).toLocaleString({
            style: "currency",
            currency: "USD",
          })}{" "}
          EQ9
        </p>
        <div className="store-card__description-footer">
          {/* <p>{data.description}</p> */}
          <div className="store-card__description-footer__items">
            <button value="-" onClick={handleQntSub}>
              -
            </button>
            <h3>{qnt}</h3>
            <button value="+" onClick={handleQntAdd}>
              +
            </button>
          </div>
        </div>
        <a onClick={handleCartAdd}>Buy</a>
      </div>
    </section>
  );
}

export default Card;
