import React, { useEffect, useState } from "react";
import Profile from "../../components/profile/Profile";
import StakerProfile from "../../components/profile/StakerProfile";
import { useStore } from "../../context/storeContext";
import Table from "../../components/table/table2";
import PizzaGraphic from "../../components/graphic/PizzaGraphic";
import { Link } from "react-router-dom";
import TableData from "../../data/tabledataStaker.json";
import LoadingPage from "../../components/pageloading/pageloading";
import { motion } from "framer-motion";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
import language from "../../assets/language/text";
import { useWallet } from "../../context/walletContext";
import { CircularProgress } from "@material-ui/core";
import ParticlesPage from "../../components/Particles";
import ModalList from "./modalList";
import RankingProfile from "../../components/table/rankingProfile";
import ClaimReward from "./claimReward";
import NumberFromWei from "../../utils/fromWei";

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};

const pageTransition = { type: "tween", easy: "circInOut", duration: 0.4 };

function Staker() {
  const { user } = useStore();
  const { stakersTable, setStakersTable, lang, setUserLocalData } = useLocalDB();
  const { eq9, getBalanceOf, getRewards } = useWallet();
  const [rewards, setRewards] = useState();
  const [stakerId, setStakerId] = useState();
  const [stakedPlayers, setStakedPlayers] = useState([]);
  const text = language[lang].staker_page;
  const [playersModal, setPlayersModal] = useState(false);
  const [pageSize, setPageSize] = useState(1000);
  const [totalAmount, setTotalAmount] = useState();
  const [claimOpen, setClaimOpen] = useState(false);
  const [searchAllUsers, setSearchAllUsers] = useState([]);
  const [searchedStakedPlayers, setSearchedStakedPlayers] = useState([]);

  useEffect(() => {
    getBalanceOf();
    if (!stakerId) {
      getStakerId();
    }
    if (!rewards) {
      getRewardsData();
    }
  }, []);

  const getRewardsData = async () => {
    const rewardsGet = await getRewards(user.wallets.default.address);
    setRewards(NumberFromWei(rewardsGet));
  };

  const getStakerId = async () => {
    const getStaker = await api.get(`users/staker/${user._id}`).catch(console.log);
    if (getStaker) {
      setStakerId(getStaker.data);
    }
  };

  const handleAttStakers = async () => {
    // const getStakers = await api.post("/stakes/staker/summarize").catch(console.log);
    // console.log(getStakers);
  };

  const fetchAllUser = async (name, page, size, add, setLoading) => {
    const getUsers = await api.get(`users`, { params: { searchParam: name ? name : "", page: page ? page : 0, size: size ? size : 20 } }).catch(console.log);
    setSearchAllUsers(add ? [...searchAllUsers, ...getUsers.data] : getUsers.data);
    setLoading && setLoading(false);
  };

  useEffect(() => {
    setUserLocalData({ access: 2 });
    if (!stakersTable) {
      async function StakersTable() {
        const getUser = await api.get("users");
        return setStakersTable(getUser.data);
      }
      StakersTable();
    }
  }, []);

  const coldata = [
    { col: text.tableData[0], id: "name", wd: "30%" },
    { col: text.tableData[1], id: "nickname", wd: "30%" },
    { col: text.tableData[2], id: "staked", wd: "15%" },
    { col: text.tableData[3], id: "rewarded", wd: "15%" },
    { col: text.tableData[4], id: "stake", wd: "5%" },
    { col: text.tableData[5], id: "unstake", wd: "5%" },
  ];

  const handleModalOpen = () => {
    setPlayersModal(true);
  };
  const handleClose = () => {
    setPlayersModal(false);
  };

  useEffect(() => {
    if (stakerId) {
      getStakedPlayers();
    }
  }, [stakerId]);

  const getStakedPlayers = async () => {
    const getPlayers = await api.get(`/stakes/from/${stakerId?._id}`, { params: { searchParam: "", page: 0, size: pageSize } }).catch(console.log);
    if (getPlayers) {
      setStakedPlayers(getPlayers.data);
      const totalAmount = getPlayers.data.reduce((acc, item) => {
        return +acc + +NumberFromWei(item.amount);
      }, 0);
      setTotalAmount(totalAmount);
    }
  };

  const handleSearchStakePlayers = (value) => {
    if (value.length > 0) {
      const filterPlayers = stakedPlayers.filter(
        (item) => item.user.nickname?.toLowerCase().includes(value?.toLowerCase()) || item.user.name?.toLowerCase().includes(value?.toLowerCase())
      );
      setSearchedStakedPlayers(filterPlayers);
    } else {
      setSearchedStakedPlayers([]);
    }
  };

  const handleClaimClose = () => {
    setClaimOpen(false);
  };

  if (!stakersTable) return <LoadingPage size={100} />;

  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="page-staker">
      {/* <ParticlesPage /> */}
      <span className="backgroundStaker" />
      <div className="page-staker-top">
        <div className="page-staker-top-left">
          <StakerProfile rewards={rewards} ClaimReward={setClaimOpen} />
        </div>
        <div className="page-staker-top-right">
          <div className="page-staker-top-right-staked">
            <h2>{text.topModalRight[1]}</h2>
            <span>
              {totalAmount ? totalAmount : 0}
              <i class="fas fa-lock"></i>
            </span>
          </div>
          <div className="page-staker-top-right-stake">
            <h2>{lang === "pt" ? "APOIE AQUI!" : "STAKE NOW!"}</h2>
            <i style={{ color: "var(--pink)" }} className="fas fa-arrow-right fa-2x" />
            <button className="button-stake" onClick={handleModalOpen}>
              {eq9 ? eq9 : 0}
              <div className="button-stake-img" />
            </button>
          </div>
        </div>
      </div>
      <div className="page-staker-bottom">
        <Table
          col={coldata}
          data={searchedStakedPlayers.length > 0 ? searchedStakedPlayers : stakedPlayers}
          getStakedPlayers={getStakedPlayers}
          handleAttStakers={handleAttStakers}
          fetchAllUser={fetchAllUser}
          searchStakePlayers={handleSearchStakePlayers}
        />
      </div>
      <ModalList open={playersModal} setOpen={handleClose} data={searchAllUsers} text={text} fetchAllUser={fetchAllUser} getStakedPlayers={getStakedPlayers} />
      <ClaimReward open={claimOpen} handleClose={handleClaimClose} claimValue={rewards} getRewardsData={getRewardsData} />
    </motion.div>
  );
}

export default Staker;
