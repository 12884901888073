import useSWR from "swr";
import api from "../services/api";

export function useFetch(url) {
  const { data, error, isValidating, mutate } = useSWR(url, async (url) => {
    const getEndpoint = url.split("/")[2];
    if (getEndpoint !== "undefined") {
      const response = await api.get(url).catch();
      return response.data;
    }
  });
  return { data, error, isValidating, mutate };
}
