import { AppBar, Tabs, Tab, Typography, Box, Dialog, FormControl, CircularProgress } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "./Event.css";
import { motion } from "framer-motion";
import LoadingPage from "../../components/pageloading/pageloading";
import api from "../../services/api";
import PropTypes from "prop-types";
import images from "../../assets/img";
import { useStore } from "../../context/storeContext";
import { useLocalDB } from "../../context/LocalDB";
import language from "../../assets/language/text";
import sound from "../../assets/sounds";
import Connect from "../wallet/connect";
import Calc from "./Calc";
import Pageloading from "../../components/pageloading/pageloading";
import { useWallet } from "../../context/walletContext";
import RightPageStandings from "./rightPage";
import { useSocket } from "../../context/webSocket";
import NumberFromWei from "../../utils/fromWei";
import BtnContinuo from "../../components/buttons/BtnContinuo";
import InputTextBox from "../deckAdd/inputTextBox";
import options from "../../components/deck/options/deckAddOptions";
import Suggestion from "../../components/Suggestion";
import { GetDate } from "../../utils/tourneyDate";
import { Link } from "react-router-dom";
import { confirmLegality } from "../../utils/confirmLegality";

const moneySound = sound.coin1;
const click = sound.btn;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};
const pageTransition = { type: "tween", easy: "anticipate", duration: 0.3 };

function Evento() {
  let { id } = useParams();
  const [value, setValue] = useState(0);
  const [sub, setSub] = useState(false);
  const [subModal, setSubModal] = useState(false);
  const [deckConfirm, setDeckConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAlert, setAlertMsg, setSeverity, lang, carteira, tournamentStartNotifications } = useLocalDB();
  const { user, setUser } = useStore();
  const [data, setData] = useState();
  const [tronWebConfirm, setTronWebConfirm] = useState(false);
  const { installed, eq9, getBalanceOf, account } = useWallet();
  const [rewardAmount, setRewardAmount] = useState(0);
  const [playerWinners, setPlayerWinners] = useState([]);
  const [stakerAmount, setStakerAmount] = useState([]);
  const [stakersTimeAmout, setStakersTimeAmout] = useState([]);
  const [players, setPlayers] = useState();
  const [tourneyData, setTourneyData] = useState();
  const { socket } = useSocket();
  const [importOpen, setImportOpen] = useState(false);

  const text = language[lang].events_page;
  const deckSelected = useRef();
  const aliasValue = useRef();

  const fetchTournament = async () => {
    const getTournament = await api.get(`tournaments/${id}`).catch(console.log);
    if (getTournament) {
      setData(getTournament.data);
    }
  };

  const getPlayerInviteConfirm = () => {
    data.players.filter((item) => {
      if (item.player === user._id) {
        return setSub(true);
      }
    });
  };

  useEffect(() => {
    if (data) {
      getPlayerInviteConfirm();
      if (data.status === "STARTED") {
        socket.off("tourneyData").on("tourneyData", (tourney) => {
          setTourneyData(tourney);
        });
        socket.off("exception").on("exception", (e) => console.log(e));
        socket.emit("tourneyData", { tournamentId: id });
      }
    }
  }, [data]);

  const fetchUser = async () => {
    const getUser = await api.get(`users/${user._id}`).catch(console.log);
    return setUser(getUser.data);
  };

  useEffect(() => {
    if (data?.reward?.amount) {
      Calc(NumberFromWei(data.reward.amount.toString()), 4, setPlayerWinners, setStakerAmount, setStakersTimeAmout, setRewardAmount);
    }
  }, [data]);

  const getUserDeckConfirm = async () => {
    if (data.game.needsDeck) {
      const getUser = await api.get(`users/${user._id}`).catch(console.log);
      if (getUser.data.game) {
        const getdeckdata = getUser.data.game.magic.deck.filter((item) => {
          if (item.format == data.game.format) {
            return true;
          }
        });
        if (getdeckdata.length === 0) {
          setAlert(true);
          setAlertMsg(`${text[0]} ${data.game.format} ${text[1]}. `);
          setSeverity("error");
          setDeckConfirm(false);
        } else {
          setDeckConfirm(true);
        }
      } else {
        setAlert(true);
        setAlertMsg(text[2]);
        setSeverity("error");
      }
    } else {
      setDeckConfirm(true);
    }
  };

  const fetchPlayers = async () => {
    if (id) {
      const getPlayers = await api.get(`tournaments/all-players/${id}`).catch(console.log);
      if (getPlayers) {
        setPlayers(getPlayers.data);
      }
    }
  };

  useEffect(() => {
    if (id) {
      if (!user) {
        fetchUser();
      }
      fetchPlayers();
      if (!data) {
        fetchTournament();
      }
    }
  }, [id]);

  useEffect(() => {
    if (tournamentStartNotifications.length > 0) {
      fetchTournament();
      fetchPlayers();
    }
  }, [tournamentStartNotifications]);

  const Players = () => {
    const col = [
      { col: "nickname", wd: "50%" },
      { col: "deck", wd: "30%" },
      { col: "pts", wd: "20%" },
    ];

    if (!data) return <Pageloading />;
    return (
      <div>
        <table style={{ width: "100%" }}>
          <thead style={{ background: "rgba(0,0,0,0.5)", textAlign: "center" }}>
            <th style={{}}>PLAYER</th>
            <th>NICKNAME</th>
            <th>Pts</th>
          </thead>
          <tbody>
            {players &&
              players.map((item) => {
                const { player } = item;
                return (
                  <tr key={player.nickname} style={{ minHeight: "70px" }}>
                    <td>
                      {player.img ? (
                        <img style={{ width: "70px", height: "70px", borderRadius: "50%" }} src={player.img} alt="imgSrc" />
                      ) : (
                        <div style={{ width: "70px", height: "70px", borderRadius: "50%", background: "grey" }} />
                      )}
                    </td>
                    <td>{player.nickname}</td>
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  const getTextRules = (text) => {
    let textRules = "";
    text.map((item) => {
      if (item && item !== undefined) textRules = `${textRules}\n${item}`;
    });
    return textRules;
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const getDeckAliasConfirm = (alias) => {
      if (alias) {
        const getAlias = alias;
        const confirmHash = getAlias.includes("#");
        const hashSplit = confirmHash && getAlias.split("#");
        const getNumbers = confirmHash && +hashSplit[1];
        if (confirmHash) {
          if (hashSplit[1].length === 5 && typeof getNumbers === "number") {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    };
    // const getDeckFullLegality = async () => {
    //   const getDeckConfirm = user.game.magic.deck.filter((item) => item.deckName === deckSelected.current.value);
    //   if (getDeckConfirm.length > 0) {
    //     const getDeck = await api.get(`decks/${getDeckConfirm[0]._id}`);
    //     // console.log(confirmLegality(getDeck.data.deckCards, data.game.format));
    //   }
    // };
    // if (data.game.needsDeck) {
    //   const DeckConfirm = getDeckFullLegality();
    //   if (!DeckConfirm)
    //     return (
    //       setAlert(true),
    //       setAlertMsg(
    //         lang === "pt"
    //           ? "O DECK SELECIONADO CONTÉM ALGUMA CARTA INVÁLIDA OU BANIDA PARA ESSE FORMATO"
    //           : "SELECTED DECK CONTAINS ONE OR MORE INVALID OR BANISHED CARDS"
    //       ),
    //       setSeverity("error")
    //     );
    // }
    const confirmAlias = aliasValue.current ? getDeckAliasConfirm(aliasValue.current.value) : false;
    if (data.game.typeOf === "ARENA" && !confirmAlias) {
      return setAlert(true), setAlertMsg(lang === "pt" ? "PRECISA DE UM NICKNAME VÁLIDO DO ARENA" : "NEED TO BE A VALID ARENA NICKNAME"), setSeverity("error");
    }
    if (+data.subscription <= +user.wallets.default.balance) {
      setLoading(true);
      const notificationMsg = {
        origin: "INSCRIÇÃO",
        msg: `${-NumberFromWei(data.subscription.toString())}EQ9, ${text[3]} ${data.name}`,
        path: `/event/${id}`,
      };
      const getDeck = deckSelected.current?.value
        ? user.game.magic.deck.filter((item) => {
            if (item.deckName === deckSelected.current.value) return true;
          })
        : null;
      const addUser = await api
        .post(`/tournaments/join/${data._id}`, {
          deckId: getDeck ? getDeck[0]._id : null,
          alias: aliasValue.current ? aliasValue.current.value : null,
        })
        .catch(() => {
          setAlert(true);
          setAlertMsg(text[5]);
          setSeverity("error");
          setLoading(false);
        });
      if (addUser) {
        // const getUserNotificated = await api
        //   .patch(`users`, {
        //     notifications: user.notifications ? [...user.notifications, notificationMsg] : [notificationMsg],
        //   })
        //   .catch(console.log);
        // setUser(getUserNotificated.data);
        fetchUser();
        getPlayerInviteConfirm();
        setLoading(false);
        setAlert(true);
        getBalanceOf();
        setAlertMsg(`${text[4]} ${data.name} .`);
        setSubModal(false);
        setSeverity("success");
        fetchPlayers();
        fetchTournament();
        sound.coin1.play();
      }
    } else {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "Saldo insuficiente" : "Not enough credits");
      setSeverity("error");
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDropSelf = async () => {
    if (!loading) {
      setLoading(true);
      const getPlayerDrop = await api.delete(`tournaments/drop-self/${id}`).catch(() => {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "NÃO FOI POSSÍVEL SAIR DO TORNEIO NESSE MOMENTO" : "WAS NOT POSSIBLE REMOVE PLAYER FROM TOURNAMENT");
        setSeverity("error");
      });
      if (getPlayerDrop) {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "VOCÊ SE REMOVEU COM SUCESSO" : "YOU WAS REMOVED FROM TOURNAMENT");
        setSeverity("success");
        // const notificationMsg = {
        //   origin: "INSCRIÇÃO",
        //   msg: `${-NumberFromWei(data.subscription.toString())}EQ9, ${text[3]} ${data.name}`,
        //   path: `/event/${id}`,
        // };
        // const getUserNotificated = await api
        //   .patch(`users`, {
        //     notifications: user.notifications ? [...user.notifications, notificationMsg] : [notificationMsg],
        //   })
        //   .catch(console.log);
        getBalanceOf();
        setLoading(false);
        fetchPlayers();
        fetchTournament();
        getPlayerInviteConfirm();
        setSub(false);
      }
    }
  };

  if (!data) return <LoadingPage />;

  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="EventPage">
      <SubscribeEvent />
      <div className="Event_nameNavBar" style={{ top: "2.1rem", left: "12rem" }}>
        {data.name}
      </div>
      <section item className="Event_Left">
        <div container className="leftBar">
          <div className="EventProfile">
            <div className="logoEvent">
              <div className="event_img" style={{ overflow: "hidden" }}>
                {data.img ? <img src={data.img} alt="logoEvento" /> : <img src={images.hiveLogo} alt="logoEvento" />}
              </div>
              <div className="event_infoSco">
                <div className="gradientName">{data.organizer?.name}</div>
                <div className="event_results">
                  P <b>{data.players.length}</b> T <b>{data.organizer?.tournamentsLength}</b>
                </div>
              </div>
            </div>
            <div className="event_description" style={{ height: "100%" }}>
              <textarea
                readOnly
                disabled
                style={{
                  width: "100%",
                  height: "90%",
                  background: "black",
                  maxHeight: "37vh",
                  background: "transparent",
                  resize: "none",
                  fontFamily: "gilroy-thin",
                  fontSize: ".85em",
                  border: "none",
                }}
              >
                {data.description.trim()}
              </textarea>
            </div>
            <div className="DateandBtn">
              <div>
                <div className="event_Date">{GetDate(data.startDate, lang)}</div>
                <div className="event_Hours">{data.hour}h</div>
              </div>
              {sub === true ? (
                <>
                  {data.status === "WAITING" && (
                    <button className="default-input" style={{ width: "150px", cursor: "pointer" }} onClick={handleDropSelf}>
                      {!loading ? lang === "pt" ? "SAIR" : "LEAVE" : <CircularProgress />}
                    </button>
                  )}
                  {data.status !== "STARTED" ? (
                    <button className="event_subBtn_disable" disabled>
                      {text[14]}
                    </button>
                  ) : (
                    <Link
                      className="event_subBtn"
                      style={{
                        // height: "80px",
                        minWidth: "300px",
                        fontSize: lang == "pt" ? "1.4rem" : "1.6rem",
                        inlineSize: "150px",
                        overflowWrap: "break-word",
                        letterSpacing: "2px",
                        textAlign: "center",
                        fontFamily: "gilroy-bold",
                        padding: "9px",
                      }}
                      to={`/room/${id}`}
                    >
                      {lang == "pt" ? "PAINEL DO JOGADOR" : "PLAYER PANEL"}
                    </Link>
                  )}
                </>
              ) : (
                <div>
                  {data.status !== "STARTED" && (
                    <>
                      <button
                        className="event_subBtn"
                        onClick={() => {
                          setSubModal(true);
                          getUserDeckConfirm();
                        }}
                      >
                        {text[15]}{" "}
                        <span>
                          <b>{NumberFromWei(data.subscription)} EQ9</b>
                        </span>
                      </button>

                      <span style={{ padding: "1rem" }}>
                        <Suggestion
                          value={
                            lang === "pt"
                              ? "Você poderá se inscrever no torneio em até 5 minutos antes do torneio iniciar."
                              : "You can join the tournament until 5 minutes before start time"
                          }
                        />
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="menuEvent">
            <AppBar
              position="static"
              style={{
                background: "transparent",
                width: "100%",
                alignItems: "space-between",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                style={{
                  background: "transparent",
                  width: "100%",
                  alignItems: "space-between",
                  boxShadow: "none",
                }}
              >
                {/* <Tab label="Agenda" {...a11yProps(1)} /> */}
                <Tab
                  label={text[30]}
                  {...a11yProps(1)}
                  onClick={() => {
                    click.currentTime = 0;
                    click.play();
                  }}
                />
                <Tab
                  label={text[31]}
                  {...a11yProps(0)}
                  onClick={() => {
                    click.currentTime = 0;
                    click.play();
                  }}
                />
                <Tab
                  label={text[32]}
                  {...a11yProps(2)}
                  onClick={() => {
                    click.currentTime = 0;
                    click.play();
                  }}
                />
                <Tab
                  label={text[33]}
                  {...a11yProps(3)}
                  onClick={() => {
                    click.currentTime = 0;
                    click.play();
                  }}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              {/* {text[16]} */}
              {data.reward.typeOf === "EQ9" ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", height: "100%" }}>
                  <h1 style={{ color: "var(--pink)" }}>
                    {text[34]} <span style={{ color: "var(--yellow)" }}>{rewardAmount.toString()} EQ9 </span>
                  </h1>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <td></td>
                        <td>{text[24]}</td>
                        <td>{text[25]}</td>
                        {/* <td>{text[25]}</td> */}
                      </tr>
                      <tr>
                        <td></td>
                        <td>{text[26]}</td>
                        <td>{lang === "pt" ? "RECOMPENSA" : "REWARD"}</td>
                        {/* <td>{text[28]}</td> */}
                      </tr>
                    </thead>
                    <tbody>
                      {playerWinners.map((item, index) => {
                        return (
                          <tr key={user._id + index}>
                            <td>
                              <span>{index + 1}</span> {text[29]}:
                            </td>
                            <td>
                              <span style={{ color: "var(--yellow)" }}>{playerWinners[index]}</span> EQ9
                            </td>
                            <td>
                              <span style={{ color: "var(--yellow)" }}>{stakerAmount[index]}</span> EQ9
                            </td>
                            {/* <td>
                              <span style={{ color: "var(--yellow)" }}>{stakersTimeAmout[index]}</span> EQ9
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div>
                <textarea
                  readOnly
                  disabled
                  style={{ width: "100%", height: "37vh", background: "transparent", resize: "none", fontSize: "1em", border: "none" }}
                >
                  {getTextRules(data.rules)}
                </textarea>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Players id={data._id} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              {data.mail &&
                data.mail.map((item) => {
                  return <div key={item}>{item}</div>;
                })}
            </TabPanel>
          </div>
        </div>
      </section>
      <section className="Event_Right">
        {data.status !== "STARTED" ? (
          <div
            style={{
              position: "absolute",
              display: "flex",
              width: "50%",
              height: "90%",
              zIndex: 1000,
              alignItems: "center",
              justifyContent: "center",
              backdropFilter: "blur(15px)",
              background: "rgba(0,0,0,0.3)",
              color: "var(--pink)",
              fontFamily: "gilroy-bold",
              fontSize: "1.5vw",
              padding: "1rem",
            }}
          >
            {text[22]}
          </div>
        ) : (
          <RightPageStandings id={id} tournament={tourneyData} />
        )}
      </section>
      {subModal && (
        <SubscribeEvent
          subModal={subModal}
          setSubModal={setSubModal}
          user={user}
          deckSelected={deckSelected}
          aliasValue={aliasValue}
          text={text}
          eq9={eq9}
          deckConfirm={deckConfirm}
          handleSubscribe={handleSubscribe}
          fetchUser={fetchUser}
          data={data}
          loading={loading}
          lang={lang}
          importOpen={importOpen}
          setImportOpen={setImportOpen}
          getUserDeckConfirm={getUserDeckConfirm}
          fetchTournament={fetchTournament}
        />
      )}
      <Connect open={tronWebConfirm} setOpen={setTronWebConfirm} />
    </motion.div>
  );
}

const SubscribeEvent = ({
  subModal,
  setSubModal,
  user,
  aliasValue,
  text,
  eq9,
  deckConfirm,
  handleSubscribe,
  getUserDeckConfirm,
  fetchUser,
  deckSelected,
  setDeckSelected,
  data,
  loading,
  lang,
  importOpen,
  setImportOpen,
  fetchTournament,
}) => {
  const handleClose = (e) => {
    if (!loading) {
      setSubModal(false);
    }
  };

  if (!subModal) return null;
  return (
    <Dialog
      open={subModal}
      className="AddTornamentModal"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ background: "rgba(0,0,0,0.85)" }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <FormControl>
        <div className="formSub_container">
          <div className="formSub_content">
            <h1>{text[6]}</h1>
            <p className="formSub__descSub">{text[7]}</p>
            {data.game.needsDeck && (
              <>
                <div className="formSub_top">Deck</div>
                <select className="default-input deckSelect" placeholder={lang === "pt" ? "SELECIONE SEU DECK" : "SELECT YOUR DECK"} ref={deckSelected}>
                  {user.game ? (
                    user.game.magic.deck &&
                    user.game.magic.deck.map((item) => {
                      if (item.format === data.game.format) {
                        return (
                          <option
                            selected
                            name={item._id}
                            onSelect={() => {
                              setDeckSelected(item._id);
                            }}
                          >
                            {item.deckName}
                          </option>
                        );
                      }
                    })
                  ) : (
                    <option>Create a deck before sign on tournament</option>
                  )}
                </select>
              </>
            )}
            {data.game.typeOf !== "TABLETOP" && (
              <input
                className="default-input"
                placeholder={lang === "pt" ? "Adicione aqui seu Nick no Arena" : "ADD HERE YOUR ARENA NICKNAME"}
                defaultValue={user.game?.magic?.arenaAlias ? user.game.magic.arenaAlias : null}
                ref={aliasValue}
              />
            )}
            <div className="formSub_bottom">
              <div className="formSub_bottom-sale">
                <p>{text[9]}</p>
                <p>{eq9} EQ9</p>
              </div>
              <div className="formSub_bottom-price">
                <p>{text[10]}</p>
                <p>{NumberFromWei(data.subscription)} EQ9</p>
              </div>
              <div className="formSub_infos">
                <p>{text[11]}</p>
                {/* <p>{user.wallet.value - data.subscription} EQ9</p> */}
                <p>{eq9 - NumberFromWei(data.subscription)}</p>
              </div>
            </div>
          </div>
          <div className="formSub_btns">
            <button className="event_cancel_btn" onClick={handleClose}>
              {text[12]}
            </button>
            {deckConfirm ? (
              <button
                onClick={(e) => {
                  if (!loading) {
                    handleSubscribe(e);
                    fetchUser();
                  }
                }}
                style={{ cursor: loading ? "not-allowed" : "pointer" }}
                className="event_confirm_btn"
              >
                {!loading ? text[13] : "Loading..."}
              </button>
            ) : (
              <div className="event_confirm_btn-disabled">{text[13]}</div>
            )}
          </div>
        </div>
      </FormControl>
      {data.game.needsDeck && (
        <BtnContinuo
          style={{
            width: "70px",
            height: "40px",
            fontSize: "1rem",
            // borderRadius: "50%",
            // marginTop: "30px",
            position: "absolute",
            top: "14%",
            right: "2%",
            fontSize: ".7rem",
            whiteSpace: "pre-wrap",
            textAlign: "center",
          }}
          classe="button-deck-add"
          onClick={() => {
            setImportOpen(true);
            // ImportDeck();
          }}
          // onMouseOver={() => setBtnPreview(1)}
          // onMouseOut={() => setBtnPreview()}
        >
          Import Deck
        </BtnContinuo>
      )}
      {importOpen ? (
        <div
          style={{
            position: "absolute",
            maxWidth: "80vw",
            // minWidth: "700px",
            height: "65vh",
            overflow: "auto",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            background: "rgba(0,0,0,0.4)",
            backdropFilter: "blur(5px)",
          }}
        >
          <InputTextBox
            open={importOpen}
            setOpen={setImportOpen}
            fetchUser={fetchUser}
            getUserDeckConfirm={getUserDeckConfirm}
            fetchTournament={fetchTournament}
            searchFormat={options.format[data?.game?.format]?.format}
          />
        </div>
      ) : null}
    </Dialog>
  );
};

export default Evento;
