import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStore } from "../../context/storeContext";
import * as mtg from "mtgsdk";
import images from "../../assets/img";
import { useLocalDB } from "../../context/LocalDB";
import { useParams, useHistory } from "react-router-dom";
import DeckOptions from "../../components/deck/options/deckAddOptions";
import api from "../../services/api";
import Pageloading from "../../components/pageloading/pageloading";
import sound from "../../assets/sounds/";
import language from "../../assets/language/text";
import CarouselCards from "../../components/slider/CarouselCards";
import deckTypes from "../../components/deck/options/deckTypes";
import { setTimeout } from "timers";
import BtnContinuo from "../../components/buttons/BtnContinuo";
import AddDeck from "../../components/cardInport";
import InputTextBox from "./inputTextBox";
import { Dialog } from "@material-ui/core";
import beyondLimit from "../../components/deck/options/beyondLimit";
import banlist from "../../components/deck/options/banlist";
import * as Scry from "scryfall-sdk";

const options = {
  reverse: false,
  max: 55,
  perspective: 1000,
  scale: 1,
  speed: 300,
  transition: true,
  axis: null,
  reset: true,
  easing: "cubic-bezier(.03,.98,.52,.99)",
  glare: false,
};

const format = DeckOptions.format;

function Index() {
  const { user, setUser } = useStore();
  const [searchBar, setSearchBar] = useState();
  const [searchedName, setSearchedName] = useState([]);
  const selectBar = useRef(null);
  const [deckName, setDeckName] = useState();
  const [cards, setCards] = useState([]);
  const [deck, setDeck] = useState([]);
  const [side, setSide] = useState([]);
  const [editedDeck, setEditedDeck] = useState([]);
  const [editedSide, setEditedSide] = useState([]);
  const [primaryDeckOptions, setPrimaryDeckOptions] = useState(format.MODERN);
  const [totalCards, setTotalCards] = useState(0);
  const [totalSideCards, setTotalSideCards] = useState(0);
  const [tourneyOptions, setTourneyOptions] = useState(format.MODERN);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const [banCard, setBanCard] = useState([]);
  const [imgPreview, setImgPreview] = useState();
  const [loading, setLoading] = useState(true);
  const [deckType, setDeckType] = useState([]);
  const [importOpen, setImportOpen] = useState(false);
  const [timer, setTimer] = useState();
  const text = language[lang].addDeck;
  const { id } = useParams();
  const history = useHistory();

  // useEffect(() => {
  //   if (searchBar?.length === 0) {
  //     setSearchedName([]);
  //   }
  // }, [searchBar]);

  // useEffect(() => {
  //   setTimeout(async () => {
  //     if (searchBar?.length > 0) {
  //       const GetCards = await Scry.Cards.autoCompleteName(searchBar);
  //       setSearchedName(GetCards);
  //     }
  //     setTimer();
  //   }, timer);
  // }, [timer]);

  const fetchUser = async () => {
    const getUser = await api.get(`users/${user._id}`).catch(console.log);
    if (getUser) {
      return setUser(getUser.data);
    }
  };

  useEffect(() => {
    const countSideBoardSize = side.reduce((a, b) => {
      if (b.count) return +a + +b.count;
    }, 0);
    setTotalSideCards(countSideBoardSize);
  }, [side]);

  useEffect(() => {
    const getColors = deck.reduce((a, b) => {
      if (b.card && b.card.colors) return a + b.card.colors;
    }, []);
    if (getColors) {
      const black = getColors.includes("Black");
      const white = getColors.includes("White");
      const blue = getColors.includes("Blue");
      const green = getColors.includes("Green");
      const red = getColors.includes("Red");
      const colors = { b: black, w: white, u: blue, g: green, r: red };
      const deckTypeReturn = Object.keys(colors).filter((item) => {
        if (colors[item] === true) return item;
      });
      const containsOnly = (ar1, ar2) => {
        return ar2.every((item) => ar1.includes(item));
      };
      const decktypefilter = Object.keys(deckTypes).filter((item) => {
        if (containsOnly(deckTypeReturn, deckTypes[item])) return true;
      });
      if (decktypefilter[decktypefilter.length - 2] !== undefined) {
        return setDeckType(decktypefilter[decktypefilter.length - 2]);
      } else {
        return setDeckType("colorless");
      }
    }
  }, [deck]);

  const getFirstCards = async () => {
    // const getFirstCards = await Scry.Cards.byName("black lotus");
    // if (getFirstCards) {
    //   setCards((cards) => {
    //     if (cards !== null) return [{ count: 1, card: getFirstCards !== 0 ? getFirstCards : null }];
    //   });
    // }
    // getFirstCards.map(async (item, index) => {
    //   if (index < 5) {
    //     if (getCards?.legalities[tourneyOptions.format.toLowerCase()]) {
    //       setCards((cards) => {
    //         if (cards !== null) return [...cards, { count: 1, card: getCards !== 0 ? getCards : null }];
    //       });
    //     }
    //   }
    // });
    return await mtg.card.where({ supertypes: "legendary", pageSize: 6, contains: "imageUrl" }).map((card) => {
      if (card) {
        setCards((cards) => {
          if (cards !== null) return [...cards, { count: 1, card: card !== 0 ? card : null }];
        });
      }
    });
  };

  useEffect(() => {
    getFirstCards();
  }, []);

  useEffect(() => {
    if (id) {
      async function getCards() {
        const getDeck = await api.get(`decks/${id}`).catch(console.log);
        if (deck) {
          const deck = getDeck.data;
          setPrimaryDeckOptions(format[deck.format]);
          setTourneyOptions(format[deck.format]);
          setDeck(deck.deckCards);
          setSide(deck.sideBoard);
          setDeckName(deck.deckName);
          setLoading(false);
          const totalCards = deck.deckCards.reduce((a, b) => {
            return +a + +b.count;
          }, 0);
          const countSideBoardSize = deck.sideBoard.reduce((a, b) => {
            return +a + +b.count;
          }, 0);
          setTotalSideCards(countSideBoardSize);
          setTotalCards(totalCards);
        }
      }
      return getCards();
    }
    setLoading(false);
  }, [id]);

  const confirmSearch = (e) => {
    e.preventDefault();
    handleSearchCard();
  };

  async function handleSearchCard(item) {
    setCards([]);
    let searchFilter = {};
    const alternativeFormats = ["Historic"];
    const objectRequestWithLegality = {
      gameFormat: tourneyOptions.format === "Alchemy" ? "Standard" : tourneyOptions.format,
      legality: "Legal",
    };
    const confirmFormat = alternativeFormats.includes(tourneyOptions.format) ? null : objectRequestWithLegality;
    // let manaCost = manacost.current.value;
    // let manaType = manatype.current.value;
    // let cardType = cardtype.current.value;
    // let cardRarity = cardrarity.current.value;
    const searchOptions = {
      name: item ? item : searchBar,
      pageSize: 20,
      // colors: manaType,
      // gameFormat: tourneyOptions.name,
      // types: cardType,
      // rarity: cardRarity,
      // cmc: manaCost,
      // contains: "imageUrl",
      ...objectRequestWithLegality,
    };
    const getCallbackNames = (res) => {
      searchFilter = { ...searchFilter, [res]: searchOptions[res] };
    };
    Object.keys(searchOptions).filter((item) => {
      if (searchOptions[item] !== "all" && searchOptions[item] !== undefined) {
        getCallbackNames(item);
      }
    });
    const card = await mtg.card.where(searchFilter);
    // const card = await Scry.Cards.byName(searchOptions.name);
    if (card) {
      card.map((item) => {
        setCards((cards) => {
          if (cards !== null) return [...cards, { count: 1, card: item !== 0 ? item : null }];
        });
      });
    }
    if (card.length === 0) {
      setAlert(true);
      setAlertMsg(lang === "en" ? "No card related with this research" : "Nenhuma carta encontrada nessa pesquisa");
      setSeverity("error");
    }
  }

  useEffect(() => {
    if (deck) {
      const totalCards = deck.reduce((a, b) => {
        return +a + +b.count;
      }, 0);
      setTotalCards(totalCards);
    }
  }, [deck]);

  const checkBanishment = (cardName, format) => {
    if (format && cardName && banlist) {
      if (banlist[format]?.banlist?.includes(cardName) || banlist[format]?.suspenso?.includes(cardName)) return true;
      else return false;
    }
  };

  const confirmLegality = (itemMap, setItem, formato) => {
    if (itemMap !== undefined && formato) {
      setItem([]);
      const deckCard = async (card) => {
        setItem((cards) => [...cards, card]);
      };
      const banListAdd = (card) => {
        setBanCard((cards) => [...cards, card]);
      };
      const getMapped = itemMap.map(async (item) => {
        const alternativeFormats = ["Historic"];
        const objectRequestWithLegality = {
          gameFormat: formato.format === "Alchemy" ? "Standard" : formato.format,
          legality: "Legal",
          page: 1,
          pageSize: 1,
        };
        const confirmFormat = alternativeFormats.includes(tourneyOptions.format) ? null : objectRequestWithLegality;
        const cardsplit = item.name.split("/", [1]);
        if (item.name.includes("///")) {
          const getCard = await mtg.card
            .where({
              name: cardsplit[0].trim(),
              contains: "imageUrl",
              ...confirmFormat,
            })
            .catch(console.log);
          if (getCard.length > 0) {
            const getBanishmant = checkBanishment(item.name, tourneyOptions.format);
            if (getBanishmant) return banListAdd(item.name);
            return deckCard({
              count: item.count,
              card: getCard[0],
              name: item.name,
            });
          } else {
            return banListAdd(cardsplit[0].trim());
          }
        } else {
          const getCard = await mtg.card
            .where({
              name: item.name.trim(),
              ...confirmFormat,
              contains: "imageUrl",
            })
            .catch(console.log);
          if (getCard.length > 0) {
            const getBanishmant = checkBanishment(item.name, tourneyOptions.format);
            if (getBanishmant) return banListAdd(item.name);
            return deckCard({
              count: item.count,
              card: getCard[0],
              name: item.name.trim(),
            });
          } else {
            const getBanishmant = checkBanishment(item.name, tourneyOptions.format);
            if (getBanishmant) return banListAdd(item.name);
            return banListAdd(item.name);
          }
        }
      });
      return getMapped;
    }
  };

  function handleSelectFormat(e) {
    setTourneyOptions(format[e.target.value]);
    confirmLegality(deck, setEditedDeck, format[e.target.value]);
    confirmLegality(side, setEditedSide, format[e.target.value]);
  }

  function handleManatype(mana, index) {
    if (mana === "W") return <img className="mana" src={images.whiteMana} alt="imgMana" key={index} />;
    if (mana === "U") return <img className="mana" src={images.blueMana} alt="imgMana" key={index} />;
    if (mana === "B") return <img className="mana" src={images.blackMana} alt="imgMana" key={index} />;
    if (mana === "G") return <img className="mana" src={images.greenMana} alt="imgMana" key={index} />;
    if (mana === "R") return <img className="mana" src={images.redMana} alt="imgMana" key={index} />;
    else
      return (
        <div className="wasteMana" key={index}>
          {mana}
        </div>
      );
  }

  // function handleMouseSound() {
  //   const cardPass = new Audio(sound.cardPass);
  //   cardPass.play();
  // }

  async function handleAddDeck() {
    const deckAdd = {
      deckName: deckName,
      deckCards: deck,
      sideBoard: side,
      deckType: deckType,
      format: tourneyOptions.name,
    };
    if (deckName !== undefined) {
      if (id) {
        const putDeck = await api.patch(`decks/${id}`, deckAdd).catch(console.log);
        if (putDeck) {
          setAlert(true);
          setAlertMsg(text[2]);
          setSeverity("success");
        } else {
          setAlert(true);
          setAlertMsg(text[3]);
          setSeverity("error");
        }
      } else {
        const putDeck = await api.post(`decks`, deckAdd).catch(console.log);
        if (putDeck) {
          setAlert(true);
          setAlertMsg(text[2]);
          setSeverity("success");
          fetchUser();
          setTimeout(() => {
            history.push("/");
          }, 800);
        } else {
          setAlert(true);
          setAlertMsg(text[3]);
          setSeverity("error");
        }
      }
    } else {
      setAlert(true);
      setAlertMsg(text[3]);
      setSeverity("error");
    }
  }

  function handleAddCard(e) {
    const alternativeFormats = ["Historic"];
    let card = { ...e, name: e.card.name };
    const alreadycheck = deck.filter((item) => {
      if (card.card.name !== "Plains" && e.card.name !== "Mountain" && card.card.name !== "Forest" && card.card.name !== "Swamp") {
        if (item.card.name === card.card.name) {
          return true;
        }
      }
    });
    const confirmUnic =
      alreadycheck.length > 0 && alreadycheck.length < 4
        ? alreadycheck.reduce((a, b) => {
            return +a + +b.count;
          }, 0)
        : null;
    const formatos = card.card.legalities.filter((item) => {
      if (!alternativeFormats.includes(tourneyOptions.format)) {
        const getTourneyOptions = tourneyOptions.format === "Alchemy" ? "standard" : tourneyOptions.name.toLowerCase();
        if (item.format.toLowerCase() === getTourneyOptions) return true;
      }
    });
    // const formatos = card.card.legalities[tourneyOptions.format.toLowerCase()];
    const getLimitBeyond = beyondLimit.includes(e.card.name);
    if (!card.card.supertypes?.includes("Basic") && !card.card.types?.includes("Land") && !getLimitBeyond) {
      if (confirmUnic < 4) {
        if (formatos.length > 0 || alternativeFormats.includes(tourneyOptions.format)) {
          if (totalCards < tourneyOptions.maxCount) {
            const deckClone = [...deck];
            let indexOf = null;
            deckClone.filter((item, index) => {
              if (item.card.id === card.card.id) {
                indexOf = index;
                return true;
              }
            });
            if (indexOf != null && deckClone[indexOf].count < tourneyOptions.unicMax) {
              deckClone[indexOf].count = deckClone[indexOf].count + 1;
              setDeck(deckClone);
            } else if (indexOf === null) {
              setDeck((cards) => {
                if (cards !== null) return [...cards, card];
              });
            }
          } else {
            handleAddSideCard(card);
          }
        } else {
          setAlert(true);
          setAlertMsg(text[4]);
          setSeverity("error");
        }
      }
    } else if (card.card.supertypes?.includes("Basic") && card.card.types?.includes("Land")) {
      if (totalCards < tourneyOptions.maxCount) {
        const deckClone = [...deck];
        let indexOf = null;
        deckClone.filter((item, index) => {
          if (item.card.id === card.card.id) {
            indexOf = index;
            return true;
          }
        });
        if (indexOf != null) {
          deckClone[indexOf].count = deckClone[indexOf].count + 1;
          setDeck(deckClone);
        } else if (indexOf === null) {
          setDeck((cards) => {
            if (cards !== null) return [...cards, card];
          });
        }
      } else {
        handleAddSideCard(card);
      }
    } else if (getLimitBeyond) {
      if (totalCards < tourneyOptions.maxCount) {
        const deckClone = [...deck];
        let indexOf = null;
        deckClone.filter((item, index) => {
          if (item.card.id === card.card.id) {
            indexOf = index;
            return true;
          }
        });
        if (indexOf != null) {
          deckClone[indexOf].count = deckClone[indexOf].count + 1;
          setDeck(deckClone);
        } else if (indexOf === null) {
          setDeck((cards) => {
            if (cards !== null) return [...cards, card];
          });
        }
      } else {
        handleAddSideCard(card);
      }
    }
  }

  function handleAddSideCard(e) {
    let sideCount = side.reduce((a, b) => {
      return +a + +b.count;
    }, 0);
    setTotalSideCards(sideCount);
    const formatos = e.card.legalities.filter((item) => {
      if (item.format.toLowerCase() === tourneyOptions.name.toLowerCase()) return true;
    });
    if (formatos.length === 1) {
      if (totalSideCards < 15) {
        const sideClone = [...side];
        let indexOfSideCard = null;
        sideClone.filter((item, index) => {
          if (item.card.id === e.card.id) {
            indexOfSideCard = index;
            return true;
          }
        });
        if (indexOfSideCard != null && sideClone[indexOfSideCard].count < tourneyOptions.unicMax) {
          sideClone[indexOfSideCard].count = sideClone[indexOfSideCard].count + 1;
          setSide(sideClone);
        } else if (indexOfSideCard === null) {
          setSide((cards) => {
            if (cards !== null) return [...cards, e];
          });
        }
      }
    }
  }

  function handleDeleteCard(card) {
    let deckClone = [...deck];
    setImgPreview();
    const cardIndex = deckClone.indexOf(card);
    if (deckClone[cardIndex].count > 1) {
      deckClone[cardIndex].count = deckClone[cardIndex].count - 1;
      setDeck(deckClone);
    } else {
      deckClone.splice(cardIndex, 1);
      setDeck(deckClone);
    }
  }

  function handleDeleteSideCard(card) {
    setImgPreview();
    let sideClone = [...side];
    const cardIndex = sideClone.indexOf(card);
    if (sideClone[cardIndex].count > 1) {
      sideClone[cardIndex].count = sideClone[cardIndex].count - 1;
      setSide(sideClone);
    } else {
      sideClone.splice(cardIndex, 1);
      setSide(sideClone);
    }
  }

  const AddCard = () => {
    return (
      <div
        style={{
          position: "absolute",
          width: "45vw",
          height: "65vh",
          overflow: "auto",
          left: "39%",
          top: "26%",
          background: "rgba(0,0,0,0.4)",
          backdropFilter: "blur(15px)",
          display: importOpen ? "block" : "none",
        }}
      >
        {importOpen && <AddDeck setOpen={setImportOpen} />}
      </div>
    );
  };

  if (loading) return <Pageloading />;

  return (
    <div className="page-addDeck">
      <div className="page-addDeck-slider_container">
        <div className="page-addDeck-slider_container-search-container" style={{ zIndex: 190 }}>
          <form onSubmit={confirmSearch}>
            <div style={{ position: "relative" }}>
              <div className="default-input-i search-div">
                <input
                  placeholder={text[5]}
                  type="text"
                  value={searchBar}
                  onChange={(e) => {
                    // setTimer(500);
                    setSearchBar(e.target.value);
                  }}
                />
                <i className="fas fa-search" onClick={confirmSearch} />
              </div>
              <div
                style={{
                  position: "absolute",
                  maxHeight: "200px",
                  overflow: "auto",
                  background: "black",
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                {searchedName.length > 0 && (
                  <table>
                    {searchedName.map((item) => {
                      return (
                        <tr key={item}>
                          <td
                            onClick={() => {
                              setSearchBar(item);
                              setTimer();
                              setSearchedName([]);
                              handleSearchCard(item);
                            }}
                          >
                            {item}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                )}
              </div>
            </div>
            <select ref={selectBar} className="default-input" onChange={handleSelectFormat} select={tourneyOptions.name} name="tourneyFormat">
              {Object.keys(DeckOptions.format).map((item, index) => {
                const format = DeckOptions.format[item];
                if (DeckOptions.format[item].name === tourneyOptions.name) {
                  return (
                    <option key={index} selected value={format.name}>
                      {format.name}
                    </option>
                  );
                }
                return (
                  <option key={index} value={format.name}>
                    {format.name}
                  </option>
                );
              })}
            </select>
          </form>
          <BtnContinuo
            onClick={() => {
              setImportOpen(true);
              // ImportDeck();
            }}
          >
            import
          </BtnContinuo>
        </div>
        <div className="page-addDeck-slider_container-swiper" style={{ zIndex: 100 }}>
          {cards.length > 0 && <CarouselCards data={cards} addCard={handleAddCard} format={tourneyOptions} />}
        </div>
      </div>
      <div className="page-addDeck-deck_list">
        <div className="page-addDeck-deck_list-deck-div">
          <div className="deck-component">
            <div className="deck-component-title">
              <h1>Deck</h1>
              <p>Total {totalCards}</p>
            </div>
            <div className="deck-component-content">
              <div className="deck-component-content-table">
                <table>
                  {deck &&
                    deck.map((item, index) => {
                      if (item.card) {
                        const manacost =
                          item.card.manaCost &&
                          item.card.manaCost.split("").filter((char) => {
                            if (char !== "{" && char !== "}") return true;
                          });
                        return (
                          <tr
                            key={index}
                            onClick={() => handleDeleteCard(item)}
                            onMouseOver={() => setImgPreview(item.card.imageUrl)}
                            onMouseLeave={() => setImgPreview()}
                          >
                            <td>{item.count}</td>
                            <td>{item.card.name}</td>
                            <td>
                              {manacost &&
                                manacost.map((item, index) => {
                                  return handleManatype(item, index);
                                })}
                            </td>
                          </tr>
                        );
                      }
                    })}
                </table>
              </div>
            </div>
          </div>
          <div className="sideBoard-component">
            <div className="sideBoard-component-title">
              <h1>SideBoard</h1>
              <p>Total {totalSideCards}</p>
            </div>
            <div className="sideBoard-component-content">
              <div className="sideBoard-component-content-table" onDrop={handleAddSideCard}>
                <table>
                  {side &&
                    side.map((item, index) => {
                      const manacost =
                        item.card.manaCost &&
                        item.card.manaCost.split("").filter((char) => {
                          if (char !== "{" && char !== "}") return true;
                        });
                      return (
                        <tr
                          key={index}
                          onClick={() => handleDeleteSideCard(item)}
                          onMouseOver={() => setImgPreview(item.card.imageUrl)}
                          onMouseLeave={() => setImgPreview()}
                        >
                          <td>{item.count}</td>
                          <td>{item.card.name}</td>
                          <td>
                            {manacost &&
                              manacost.map((item, index) => {
                                return handleManatype(item, index);
                              })}
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="page-addDeck-deck_list-name-deck">
          <input type="text" className="default-input" value={deckName} onChange={(e) => setDeckName(e.target.value)} placeholder={text[6]} />

          <button
            className="default-button"
            onMouseOver={() => {
              // sound.btn.play();
            }}
            onClick={handleAddDeck}
            // onDoubleClick={handleAddSideCard}
          >
            {text[8]}
          </button>
        </div>
      </div>
      {importOpen ? (
        <div
          style={{
            position: "absolute",
            maxWidth: "80vw",
            // minWidth: "700px",
            height: "65vh",
            // aspectRatio:"3/4",
            overflow: "auto",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            background: "rgba(0,0,0,0.4)",
            backdropFilter: "blur(5px)",
          }}
        >
          <InputTextBox open={importOpen} setOpen={setImportOpen} fetchUser={fetchUser} searchFormat={tourneyOptions.name} />
        </div>
      ) : null}
      {imgPreview && <img className="deck-img-preview" src={imgPreview} alt="imgPreview" />}
      {banCard && (
        <BanList
          list={banCard}
          setBanCard={setBanCard}
          setDeck={setDeck}
          setSide={setSide}
          setEditedDeck={setEditedDeck}
          setEditedSide={setEditedSide}
          editedSide={editedSide}
          editedDeck={editedDeck}
          setTourneyOptions={setTourneyOptions}
          primaryDeckOptions={primaryDeckOptions}
          setPrimaryDeckOptions={setPrimaryDeckOptions}
          tourneyOptions={tourneyOptions}
        />
      )}
    </div>
  );
}

const BanList = ({
  list,
  setBanCard,
  setDeck,
  setSide,
  setEditedDeck,
  setEditedSide,
  editedSide,
  editedDeck,
  setTourneyOptions,
  primaryDeckOptions,
  setPrimaryDeckOptions,
  tourneyOptions,
}) => {
  const [open, setOpen] = useState(false);
  const { lang } = useLocalDB();
  useEffect(() => {
    if (list.length > 0) {
      setOpen(true);
    }
  }, [list]);
  return (
    <Dialog open={open} PaperProps={{ style: { background: "rgba(0,0,0,0.9)" } }}>
      <div style={{ padding: "1rem", borderRadius: "15px" }}>
        <h3>
          {lang === "pt"
            ? "Essas cartas são inválidas ou banidas para esse formato, deseja continuar?."
            : "These cards are invalid or banished for this format, you want to continue?"}
        </h3>
        <div style={{ maxHeight: "500px", overflow: "auto", padding: "1rem" }}>
          <table>
            {list?.map((item) => {
              return (
                <tr>
                  <td>{item}</td>
                </tr>
              );
            })}
          </table>
        </div>
        <div style={{ display: "flex" }}>
          <button
            className="default-input"
            style={{ width: "50%", padding: "10px" }}
            onClick={() => {
              setBanCard([]);
              setEditedDeck([]);
              setEditedSide([]);
              setTourneyOptions(primaryDeckOptions);
              setOpen(false);
            }}
          >
            {lang === "pt" ? "CANCELAR" : "CANCEL"}
          </button>
          <button
            className="default-input"
            style={{ width: "50%", padding: "10px" }}
            onClick={() => {
              setBanCard([]);
              setDeck(editedDeck);
              setSide(editedSide);
              setPrimaryDeckOptions(tourneyOptions);
              setEditedDeck([]);
              setEditedSide([]);
              setOpen(false);
            }}
          >
            {lang === "pt" ? "CONFIRMAR" : "CONFIRM"}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default Index;
