import { CircularProgress, Dialog } from "@material-ui/core";
import { useState } from "react";
import { useLocalDB } from "../../../context/LocalDB";
import { useWallet } from "../../../context/walletContext";
import NumberFromWei from "../../../utils/fromWei";
import toBigNumber from "../../../utils/toBigNumber";
import Btn from "../../buttons/Btn";

const UnstakeConfirm = ({
  setUnstakeConfirm,
  unstakeOnPlayer,
  player,
  text,
  coin2,
  unstake,
  fetchUser,
  unstakeconfirm,
  loading,
  setLoading,
  handleClose,
  getStakedPlayers,
}) => {
  const [value, setValue] = useState();
  const { stepApprove, getBalanceOf } = useWallet();
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();

  const handleUnstakeClose = () => {
    setUnstakeConfirm(false);
  };

  const handleUnstakeConfirm = async () => {
    if (value > 0) {
      // setLoading(true);
      const getUnstakePlayer = await unstakeOnPlayer(player?._id, toBigNumber(value)).catch((error) => {
        handleUnstakeClose();
        setLoading(false);
        handleClose && handleClose();
        setAlert(true);
        // setAlertMsg(error.response.data.message[lang]);
        setAlertMsg(lang === "pt" ? "O Stake tem que ter mais de 24hrs para fazer Unstake" : "Need to be ");
        setSeverity("error");
      });
      if (getUnstakePlayer) {
        setAlert(true);
        setAlertMsg(`${value} ${text[9]} ${player.nickname} ${text[6]}.`);
        setSeverity("success");
        handleClose && handleClose();
        setLoading(false);
        coin2.currentTime = 0;
        coin2.play();
        unstake.currentTime = 0;
        unstake.play();
        getStakedPlayers();
        handleUnstakeClose();
        getBalanceOf();
        // fetchUser();
      }
    }
  };

  return (
    <Dialog open={unstakeconfirm} onClose={() => !loading && handleUnstakeClose}>
      <div
        style={{
          width: "300px",
          zIndex: 2000,
          display: "flex",
          background: "black",
        }}
      >
        {!loading ? (
          <div className="stakes-modal" style={{ width: "300px", height: "300px" }}>
            <div className="stakes-modal-title">
              <span>{text[4]}: </span>
              <span>{player?.amount && NumberFromWei(player?.amount)} EQ9</span>
            </div>
            <input placeholder={`${player?.amount} EQ9`} type="number" value={value} onChange={(e) => setValue(e.target.value)} />
            <div className="stakes-modal-buttons" style={{ height: "30%", padding: "5px", marginTop: "3rem" }}>
              <Btn onClick={() => setUnstakeConfirm(false)}>{text[1]}</Btn>
              <Btn onClick={handleUnstakeConfirm}>{text[13]}</Btn>
            </div>
          </div>
        ) : (
          <div
            className="stakes-modal stake"
            style={{ width: "300px", height: "300px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
          >
            <p>{stepApprove[lang]}</p>
            <CircularProgress />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default UnstakeConfirm;
