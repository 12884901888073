function TimerCheckIn({ serverTimer, lang, tourneyData }) {
  if (!tourneyData?.extraTime) {
    const serverCheckInTimer = serverTimer?.minutes - 47 >= 0;
    if ((serverCheckInTimer && serverTimer?.seconds > 0) || serverCheckInTimer) {
      return (
        <section>
          <div style={{ position: "absolute", top: "2rem", left: "70%", transform: "translateX(-50%)", textAlign: "center" }}>
            <p style={{ color: "var(--pink)" }}>{lang === "pt" ? "TEMPO RESTANTE PARA CHECK-IN" : "TIME LEFT TO CHECK-IN"}</p>
            <h1
              style={{
                color: "var(--lightblue)",
                fontSize: "clamp(1.4rem, 1.8vw, 2.6rem)",
              }}
            >
              {" "}
              {serverTimer?.minutes - 47}:{serverTimer?.seconds < 10 ? `0${serverTimer?.seconds}` : serverTimer?.seconds}
            </h1>
          </div>
        </section>
      );
    } else return <></>;
  }
  return <></>;
}

export default TimerCheckIn;
