import { useEffect, useState } from "react";
import { useLocalDB } from "../../../context/LocalDB";
import { useSocket } from "../../../context/webSocket";
import TimerCheckIn from "./TimerCheckin";
import TimerRound from "./TimerRound";

function Timer({ id, checkIn, timeForCheckIn, timeForRound, tourneyData, extraTimer, canExtraTime, setCanExtraTime, extraTimeRunning }) {
  const [serverTimer, setServerTimer] = useState();
  const { lang } = useLocalDB();
  const { socket } = useSocket();

  useEffect(() => {
    socket.off(`timer/${id}`).on(`timer/${id}`, setServerTimer);
  }, []);

  return (
    <>
      <TimerRound
        id={id}
        timeForRound={timeForRound}
        tourneyData={tourneyData}
        extraTimer={extraTimer}
        serverTimer={serverTimer}
        lang={lang}
        canExtraTime={canExtraTime}
        setCanExtraTime={setCanExtraTime}
        extraTimeRunning={extraTimeRunning}
      />
      <TimerCheckIn id={id} checkIn={checkIn} timeForCheckIn={timeForCheckIn} serverTimer={serverTimer} lang={lang} tourneyData={tourneyData} />
    </>
  );
}

export default Timer;
