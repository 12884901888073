import { CircularProgress, Dialog } from "@material-ui/core";
import React, { useState } from "react";
import { useLocalDB } from "../../context/LocalDB";
import { useWallet } from "../../context/walletContext";

function ClaimReward({ open, handleClose, claimValue, getRewardsData }) {
  const { setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const [loading, setLoading] = useState(false);
  const { claimRewards } = useWallet();

  const handleClaim = async (value) => {
    setLoading(true);
    const getClaim = await claimRewards(value).catch(() => {
      setLoading(false);
      setAlert(true);
      setAlertMsg("Erro ao dar Claim, o unstake deve ter mais de 24hrs.");
      setSeverity("error");
      handleClose();
    });
    if (getClaim) {
      setLoading(false);
      setAlert(true);
      setAlertMsg("Claim realizado com sucesso!");
      setSeverity("success");
      handleClose();
      getRewardsData();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={!loading ? handleClose : null}
      PaperProps={{
        style: {
          background: "transparent",
          width: "300px",
          height: "150px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "15px",
        },
      }}
      BackdropProps={{ style: { background: "rgba(0,0,0,0.9)" } }}
    >
      <h3 style={{ padding: "1rem", color: "white" }}>
        Claim <span style={{ color: "yellow" }}>{claimValue}EQ9</span> ?
      </h3>
      <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
        <button className="default-input" style={{ height: "70px", padding: "5px" }} onClick={handleClose}>
          CANCEL
        </button>
        <button
          className="default-input"
          style={{ height: "70px", padding: "5px" }}
          onClick={() => {
            if (!loading) {
              handleClaim(claimValue);
            }
          }}
        >
          {!loading ? "CONFIRM" : <CircularProgress />}
        </button>
      </div>
    </Dialog>
  );
}

export default ClaimReward;
