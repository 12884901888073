import { useState } from "react";
import "./table.scss";
// import ProfilePicture from "../ProfileTablePicture";
import language from "../../assets/language/text";
import images from "../../assets/img/";
import twoDecimals from "../../utils/twoDecimals";
import { Link } from "@material-ui/core";
import Pop from "../../components/PopOver";
import RankingProfile from "../../components/table/rankingProfile";

function Index({ data }) {
  const [player, setPlayer] = useState();
  const [modalPlayer, setModalPlayer] = useState(false);

  const getStakedPlayers = async () => {};

  return (
    <div className="tableGrid">
      <table className="table">
        <table style={{ width: "100%" }}>
          <thead>
            <tr style={{ background: "rgba(0,0,0,0.4)", height: "10px" }}>
              <td style={{ textAlign: "center", width: "14.28%" }}>POSITION</td>
              <td style={{ textAlign: "center", width: "20vw" }}></td>
              <td style={{ textAlign: "center", width: "100%" }}>PLAYER</td>
              <td style={{ textAlign: "center", width: "100%" }}>NICKNAME</td>
              <td style={{ textAlign: "center", width: "14.28%" }}>POINTS</td>
              <td style={{ textAlign: "center", width: "14.28%" }}>OMW</td>
              <td style={{ textAlign: "center", width: "14.28%" }}>PGW</td>
              <td style={{ textAlign: "center", width: "14.28%" }}>OGW</td>
              <td style={{ textAlign: "center", width: "14.28%" }}>DECK</td>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => {
                return (
                  <tr
                    key={item.alias + index}
                    onClick={() => {
                      setPlayer(item);
                      setModalPlayer(true);
                    }}
                  >
                    <td style={{ textAlign: "center" }}>{index + 1} º</td>
                    <td style={{ textAlign: "center" }}>
                      {item.img ? (
                        <img
                          style={{ maxWidth: "50px", maxHeight: "50px", borderRadius: "50%", border: "3px solid white" }}
                          src={item.img}
                          alt="Player Photo"
                        />
                      ) : item.user.img ? (
                        <img
                          style={{ maxWidth: "50px", maxHeight: "50px", borderRadius: "50%", border: "3px solid white" }}
                          src={item.user.img}
                          alt="Player Photo"
                        />
                      ) : (
                        <img
                          style={{ maxWidth: "50px", maxHeight: "50px", borderRadius: "50%", border: "3px solid white" }}
                          src={images.unknown}
                          alt="player-img"
                        />
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Pop cutValue={12}>{item.name ? item.name : "-"}</Pop>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Pop cutValue={10}>{item.nickname}</Pop>
                    </td>
                    <td style={{ textAlign: "center", minWidth: "10rem" }}>{item.tiebreakers.matchPoints}</td>
                    <td style={{ textAlign: "center" }}>
                      <p>{twoDecimals(item.tiebreakers.omwp * 100) > 100 ? 100 : twoDecimals(item.tiebreakers.omwp * 100)}%</p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>{twoDecimals(item.tiebreakers.gwp * 100) > 100 ? 100 : twoDecimals(item.tiebreakers.gwp * 100)}% </p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>{twoDecimals(item.tiebreakers.ogwp * 100) > 100 ? 100 : twoDecimals(item.tiebreakers.ogwp * 100)}%</p>
                    </td>
                    <td style={{ zIndex: "3000" }}>
                      {item.etc?.deckId ? (
                        <Link href={`${process.env.REACT_APP_ENDPOINT}editDeck/${item.etc.deckId}`} target="blank">
                          Deck
                        </Link>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </table>
      <RankingProfile
        modalPlayerProfile={modalPlayer}
        setModalPlayerProfile={setModalPlayer}
        player={player}
        getStakedPlayers={getStakedPlayers}
        fromBadges={true}
      />
    </div>
  );
}

export default Index;
