export default [
  "Dragon's Approach",
  "Persistent Petitioners",
  "Rat Colony",
  "Relentless Rats",
  "Shadowborn Apostle",
  "Forest",
  "Mountain",
  "Island",
  "Swamp",
  "Snow-Covered Island",
  "Snow-Covered Forest",
  "Snow-Covered Plains",
  "Snow-Covered Mountain",
  "Snow-Covered Swamp",
];
