function TimerCheckIn({ id, player2Bye, checkIn, timeForCheckIn, serverTimer, lang }) {
  if (serverTimer && !player2Bye) {
    const serverCheckInTimer = serverTimer?.minutes - 47;
    if (serverCheckInTimer >= 0 || (serverTimer.seconds > 0 && serverCheckInTimer >= 0)) {
      return (
        <section>
          {!checkIn.player1 || !checkIn.player2 ? (
            <div style={{ position: "absolute", top: "7rem", left: "50%", transform: "translateX(-50%)", textAlign: "center" }}>
              <p style={{ color: "var(--pink)" }}>{lang === "pt" ? "TEMPO RESTANTE PARA CHECK-IN" : "TIME LEFT TO CHECK-IN"}</p>
              <h1
                style={{
                  color: "var(--lightblue)",
                  fontSize: "clamp(1.4rem, 1.8vw, 2.6rem)",
                }}
              >
                {" "}
                {serverTimer.minutes - 47}:{serverTimer.seconds < 10 ? `0${serverTimer.seconds}` : serverTimer.seconds}
              </h1>
            </div>
          ) : null}
        </section>
      );
    }
    return <></>;
  }
  return <></>;
}

export default TimerCheckIn;
