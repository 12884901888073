import React from "react";

const CutName = (txt, length) => {
  if (txt) {
    const maxLength = length ? length : 5;
    const txtLength = txt.length;
    if (txtLength > maxLength) {
      return txt.toUpperCase().substring(maxLength, 0) + "...";
    } else {
      return txt.toUpperCase();
    }
  }
};

export default CutName;
