import { CircularProgress, Dialog } from "@material-ui/core";
import React, { useState } from "react";
import { useLocalDB } from "../../../context/LocalDB";
import { useStore } from "../../../context/storeContext";
import { useWallet } from "../../../context/walletContext";
import toBigNumber from "../../../utils/toBigNumber";
import Btn from "../../buttons/Btn";

const StakeConfirm = ({
  setValueModal,
  loading,
  setLoading,
  player,
  text,
  unstake,
  valueModal,
  handleClose,
  stakeOnPlayer,
  coin2,
  getStakedPlayers,
  playerId,
}) => {
  const [transferValue, setTransferValue] = useState("");
  const { user } = useStore();
  const { stepApprove, account, getBalanceOf } = useWallet();
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();

  // const responseError = {
  //   pt: ["SUA CARTEIRA PRECISA SER A MESMA CADASTRADA NA SUA CONTA"],
  //   en: ["YOUR WALLET MUST BE THE SAME AS YOUR ACCOUNT DEFAULT WALLET"],
  // };

  const handleConfirmClose = () => {
    setValueModal(false);
  };

  const handleValueChange = (e) => {
    e.preventDefault();
    setTransferValue(e.target.value);
  };

  const StakeEQ9 = async () => {
    // if (account.toLowerCase() !== user.wallets.default.address.toLowerCase()) {
    //   return setAlert(true), setAlertMsg(responseError[lang][0]), setSeverity("error");
    // }
    if (transferValue > 0) {
      setLoading(true);
      const getStakeonPlayer = await stakeOnPlayer(playerId ? playerId : player?._id, toBigNumber(transferValue)).catch((error) => {
        handleConfirmClose();
        setLoading(false);
        handleClose && handleClose();
        setAlert(true);
        setAlertMsg(error.response.data.message);
        setSeverity("error");
      });
      if (getStakeonPlayer) {
        setAlert(true);
        setAlertMsg(`${text[8]} ${transferValue}EQ9 ${text[12]} ${player.user ? player.user.nickname : player.nickname} ${text[6]}!`);
        setSeverity("success");
        handleConfirmClose();
        handleClose && handleClose();
        getStakedPlayers();
        setLoading(false);
        getBalanceOf();
        coin2.currentTime = 0;
        coin2.play();
        unstake.currentTime = 0;
        unstake.play();
      }
    }
  };

  return (
    <Dialog open={valueModal} onClose={() => !loading && handleConfirmClose()}>
      <div
        style={{
          width: "300px",
          zIndex: 2000,
          display: "flex",
          background: "black",
        }}
      >
        {!loading ? (
          <div className="stakes-modal stake" style={{ width: "300px", height: "300px" }}>
            <h3>
              {text[0]} <br />
              {player ? (player.user ? player.user.nickname : player.nickname) : null}
            </h3>
            <input placeholder={text[3]} type="number" value={transferValue} onChange={handleValueChange} />
            <div className="stakes-modal-buttons" style={{ height: "30%", padding: "5px", marginTop: "3rem" }}>
              <Btn onClick={handleConfirmClose}>{text[1]}</Btn>
              <Btn onClick={StakeEQ9}>{text[2]}</Btn>
            </div>
          </div>
        ) : (
          <div
            className="stakes-modal stake"
            style={{ width: "300px", height: "300px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
          >
            <p>{stepApprove[lang]}</p>
            <CircularProgress />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default StakeConfirm;
