import React, { useEffect, useState } from "react";
import useStorage from "../../utils/useStorage";
import { useStore } from "../../context/storeContext";
import { useLocalDB } from "../../context/LocalDB";
import { useWallet } from "../../context/walletContext";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import language from "../../assets/language/text";
import sound from "../../assets/sounds";

function MenuApp() {
  const { lang } = useLocalDB();
  const history = useHistory();
  const location = useLocation();
  const text = language[lang].nav;
  const [connectWallet, setConnectWallet] = useState();
  // eslint-disable-next-line
  const { token, setToken, remove } = useStorage();
  const { user } = useStore();
  // eslint-disable-next-line
  const { loginNav, setLoginNav, setLeftNav, userLocalData, setBtnSideNav, carteira, setCarteira } = useLocalDB();
  const { eq9, connected } = useWallet();
  function WelcomePage(se, nao) {
    if (location.pathname === "/") return se;
    else return nao;
  }
  function RestrictPages(se, nao) {
    if (location.pathname === "/" || location.pathname.includes("/standingsRoom")) return se;
    else return nao;
  }

  return (
    <nav className="menu">
      <div className="menu-left">
        {/* {RestrictPages(
          null,
          <button
            className="rowback"
            onClick={() => {
              history.goBack();
              sound.btn.pause();
              sound.btn.currentTime = 0;
              sound.btn.play();
            }}
          >
            <i class="fas fa-arrow-left fa-2x"></i>
          </button>
        )} */}
        {WelcomePage(
          null,
          <button
            className="button-side-navbar"
            onClick={() => {
              setBtnSideNav(true);
              sound.slide.pause();
              sound.slide.currentTime = 0;
              sound.slide.play();
            }}
          >
            <i class="fas fa-bars fa-lg"></i>
          </button>
        )}
      </div>

      <div className="menu-right">
        {/* <Link
          style={{
            margin: "10px",
            padding: "0.8rem 1.5rem",
            border: "1px solid white",
          }}
          to="/cardinport"
        >
          addcards
        </Link> */}
        {/* <Link to="/winner">btns</Link> */}
        {user ? (
          user.wallet ? (
            <div className="navBar-wallet">
              <div className="navBar-wallet-infos">
                {eq9 ? (
                  <Link to="/wallet">
                    <i class="fas fa-coins"></i>
                    <div style={{ color: "#ff28f1", fontStyle: "italic" }}>{text[1]}</div>
                    <div className="navBar-wallet-infos-coins">{Math.round(eq9 / 100000000).toFixed(1)} EQ9</div>
                  </Link>
                ) : (
                  <Link to="/wallet">
                    <button
                      onClick={() => {
                        // setConnectWallet(true);
                        sound.btn.pause();
                        sound.btn.currentTime = 0;
                        sound.btn.play();
                      }}
                      className="navBar-wallet-infos-coins"
                      style={{
                        color: "var(--pink)",
                        background: "none",
                        border: "none",
                        fontStyle: "italic",
                        marginRight: "1rem",
                      }}
                    >
                      {text[0]}
                    </button>
                  </Link>
                )}
              </div>
            </div>
          ) : null
        ) : (
          <Link to="/login">
            <button
              className="btnEnterNavbar"
              onClick={() => {
                setLoginNav(true);
                sound.btn.pause();
                sound.btn.currentTime = 0;
                sound.btn.play();
              }}
            >
              {text[2]}
            </button>
          </Link>
        )}
        {WelcomePage(
          <ReactWhatsapp
            number="11-78945-6123"
            message="teste"
            className="button-wpp"
            name="contant"
            onClick={() => {
              sound.btn.pause();
              sound.btn.currentTime = 0;
              sound.btn.play();
            }}
          >
            <i class="fab fa-whatsapp fa-lg"></i>
          </ReactWhatsapp>,
          null
        )}
      </div>
      {/* <Link
        to="/teste"
        style={{
          width: "300px",
          heiht: "300px",
          background: "rgba(0,0,0,0.5)",
          position: "absolute",
        }}
      >
        teste
      </Link> */}
    </nav>
  );
}

export default MenuApp;
