import React, { useState, useEffect } from "react";
import { useLocalDB } from "../../context/LocalDB";
import language from "../../assets/language/text";
import api from "../../services/api";

const List = ({ data, fetchAllTransactions }) => {
  const [dataFetch, setDataFetch] = useState([]);
  const { lang, setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const text = language[lang].wallet_page.list;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const { getBalanceOf } = useLocalDB();
  useEffect(() => setDataFetch(data), [data]);

  const calculateAge = (date) => {
    const data = new Date(date);
    const completeDate = new Date(date).toJSON().slice(0, 10).replace("/-/g", "/");
    return `${completeDate} | ${data.getHours()}:${data.getMinutes()}`;
  };

  const confirmPayment = (txId) => {
    setTimeout(async () => {
      const getTransactionConfirm = await api.get(`payments/transaction/${txId}`).catch(console.log);
      if (getTransactionConfirm.data.status === "CONCLUIDA") {
        setAlert(true);
        setAlertMsg("Transação concluída com sucesso!");
        setSeverity("success");
        getBalanceOf();
        api.put(`payments/virtual/${txId}`);
      } else {
        confirmPayment(txId);
      }
    }, 10000);
  };

  const handleConfirm = async (txId) => {
    const getAttTransaction = await api.put(`payments/virtual/${txId}`).catch(() => {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "Transação não encontrada" : "Transaction not found");
      setSeverity("error");
    });
    if (getAttTransaction) {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "Transação concluída com sucesso!" : "Transaction successfully completed");
      setSeverity("success");
      getBalanceOf();
    }
  };

  const handleInfiniteScroll = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight + 1 >= e.target.scrollHeight) {
      if (!loading) {
        fetchAllTransactions(page + 1, size, setLoading);
        setLoading(true);
        e.target.scrollTop = e.target.scrollTop - 10;
        setTimeout(() => {
          setPage(page + 1);
        }, 400);
      }
    }
  };

  const updateList = () => {
    if (!loading) {
      fetchAllTransactions(page, size, setLoading);
    }
  };

  function ListEvent({ item }) {
    return (
      <div className="list-item" style={{ width: "95%" }} key={item.txId}>
        <div className="list-item__link">
          <div
            style={{
              position: "absolute",
              top: 0,
              right: "30%",
              display: "flex",
              alignItems: "center",
              fontSize: ".9rem",
              color: item.eq9WasSent ? "var(--lightgreen)" : "var(--pink)",
            }}
          >
            <div
              style={{
                background: item.eq9WasSent ? "var(--lightgreen)" : "var(--pink)",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
              }}
            />
            {item.eq9WasSent ? " CONFIRMED" : " UNCONFIRMED"}
          </div>
          {!item.eq9WasSent && (
            <button
              className="default-input"
              style={{
                position: "absolute",
                top: "40px",
                right: "30%",
                width: "120px",
                height: "40px",
                textAlign: "center",
                fontSize: "1em",
                cursor: "pointer",
                margin: "10px",
              }}
              onClick={() => handleConfirm(item.txId)}
            >
              {lang === "pt" ? "VERIFICAR" : "CHECK"}
            </button>
          )}
          <div className="list-item__link__header">
            <span>{calculateAge(item.createdAt)}</span>
          </div>
          <div className="list-item__link__title">
            <div className="dot" />
            <span>{"Purchased " + item.eq9Amount + " EQ9"}</span>
          </div>
          <div className="list-item__link__footer">
            <span style={{ color: "yellow" }}>{item.value} R$</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="listGrid">
      <div className="listDiv">
        <div className="listUL" style={{ flexDirection: "column" }} onScroll={handleInfiniteScroll}>
          <div className="linhaList" style={{ flexDirection: "column" }} />
          {dataFetch ? (
            dataFetch.map((item, index) => {
              return <ListEvent key={"dataFech" + item._id + index} item={item} />;
            })
          ) : (
            <p>{text[0]}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default List;
