import { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Check } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { Button, Dialog, Typography } from "@material-ui/core";
import { harmonyConfig } from "../../../context/config/harmonyConfig";

import eq9Config from "../../../services/eq9Config";
import { useWallet } from "../../../context/walletContext";
import MetamaskIcon from "../../../assets/img/metamask.png";
import HarmonyIcon from "../../../assets/img/harmony.png";
import { useStore } from "../../../context/storeContext";
import api from "../../../services/api";
import { useLocalDB } from "../../../context/LocalDB";

const steps = ["INSTALL METAMAST WALLET", "CHANGE TO HARMONY NETWORK", "ADD EQ9 TOKEN TO ASSETS"];

const tokenAddress = eq9Config.trc20ContractAddress;
const tokenSymbol = "EQ9";
const tokenDecimals = 18;
const tokenImage = "https://equalsimages.s3.amazonaws.com/eq9.png";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 136deg, var(--pink) 0%, var(--pink) 50%, var(--purple) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 136deg, var(--pink) 0%, var(--pink) 50%, var(--purple) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient( 136deg, var(--pink) 0%, var(--pink) 50%, var(--purple) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient( 136deg, var(--pink) 0%, var(--pink) 50%, var(--purple) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <img src={MetamaskIcon} style={{ width: "30px", height: "30px" }} alt="metamask" />,
    2: <img src={HarmonyIcon} alt="harmony" style={{ width: "33px", height: "33px" }} />,
    3: <img src={"https://equalsimages.s3.amazonaws.com/eq9.png"} style={{ width: "33px", height: "33px" }} alt="eq9" />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function HorizontalLinearStepper({ open, setOpen, text, canClose, setLoading, loading }) {
  const { user, setUser } = useStore();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { status, connect, account } = useWallet();
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();

  const handleReset = () => {
    setActiveStep(0);
  };

  const addHarmonyNetwork = async () => {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...harmonyConfig,
        },
      ],
    });
  };

  const addEQ9TOKEN = async () => {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: tokenImage,
        },
      },
    });
  };

  const firstStep = () => {
    if (status === "unavailable") {
      return (
        <div style={{ width: "100%", height: "100%", marginTop: "3rem", textAlign: "center" }}>
          <div>{text[27]}</div>
          <a href="https://metamask.io/" target="_blank" className="default-button" color="primary">
            <Typography>{text[27]}</Typography>
          </a>
        </div>
      );
    }
    if (status === "notConnected") {
      return (
        <div style={{ width: "100%", height: "100%", marginTop: "3rem", textAlign: "center" }}>
          <button
            className="default-button"
            color="primary"
            onClick={() => {
              connect();
            }}
          >
            <Typography style={{ fontSize: ".8rem" }}>{text[29]}</Typography>
          </button>
        </div>
      );
    }
    if (status == "connected") setActiveStep(1);
  };

  const secondStep = () => {
    if (status === "connected") {
      return (
        <div style={{ width: "100%", height: "100%", marginTop: "3rem", textAlign: "center" }}>
          {/* <div>{text[31]}</div> */}
          <button className="default-button" color="primary" onClick={() => addHarmonyNetwork()}>
            <Typography style={{ fontSize: ".8rem" }}>{text[32]}</Typography>
          </button>
          <button
            onClick={() => {
              setActiveStep(2);
            }}
            style={{ position: "absolute", height: "30px", bottom: "8rem", right: "1rem" }}
            className="default-button"
          >
            {lang == "pt" ? "PRÓXIMO " : "NEXT STEP "}
            <span>
              <i className="fas fa-arrow-right" style={{ color: "var(--pink)" }}></i>
            </span>
          </button>
        </div>
      );
    }
  };
  const thirdStep = () => {
    if (status === "connected") {
      return (
        <div style={{ width: "100%", height: "100%", marginTop: "3rem", textAlign: "center", overflow: "hidden" }}>
          {/* <div>{text[31]}</div> */}
          <button className="default-button" color="primary" onClick={() => addEQ9TOKEN()}>
            <Typography style={{ fontSize: ".8rem" }}>{text[33]}</Typography>
          </button>
          <button
            onClick={() => {
              setActiveStep(1);
            }}
            style={{ position: "absolute", height: "30px", bottom: "8rem", left: "1rem" }}
            className="default-button"
          >
            <span>
              <i className="fas fa-arrow-left" style={{ color: "var(--pink)" }}></i>
            </span>
            {lang == "pt" ? "VOLTAR" : "BACK"}
          </button>
          <button
            onClick={async () => {
              if (!user.wallets?.default?.address) {
                const associar = await api
                  .patch(`/users/wallet`, {
                    wallet: account,
                  })
                  .catch((error) => {
                    setAlert(true);
                    setAlertMsg(error.response.data.message);
                    setSeverity("error");
                  });
                if (associar) {
                  setAlert(true);
                  setAlertMsg(lang == "pt" ? "Carteira associada com sucesso!" : "Successfully wallet associated.");
                  setSeverity("success");
                  setUser(associar.data);
                  setLoading(false);
                  setOpen();
                }
              } else {
                setLoading(false);
                setOpen();
              }
            }}
            className="default-button"
            style={{ position: "absolute", height: "30px", bottom: "8rem", right: "1rem" }}
          >
            {lang == "pt" ? "FINALIZAR" : "FINISH"}{" "}
            <span>
              <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      );
    }
  };

  return (
    <Dialog
      open={open}
      // onClose={!canClose ? activeStep === steps.length && setOpen : setOpen}
      PaperProps={{ style: { background: "rgba(0,0,0,0.7)" } }}
      BackdropProps={{ style: { background: "rgba(0,0,0,0.8)" } }}
    >
      <Box sx={{ width: "100%" }}>
        <h2 style={{ textAlign: "center", padding: "1rem" }}>{lang == "pt" ? "PASSOS NECESSÁRIOS PARA CONTINUAR" : "STEPS NEEDED TO CONTINUE"}</h2>
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <p style={{ fontSize: ".9rem", marginTop: "-1rem" }}>{label}</p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <section style={{ width: "100%", height: "150px", display: "flex", alignItems: "top", justifyContent: "center" }}>{firstStep()}</section>
          )}
          {activeStep === 1 && (
            <section style={{ width: "100%", height: "150px", display: "flex", alignItems: "top", justifyContent: "center" }}>{secondStep()}</section>
          )}
          {activeStep === 2 && (
            <section style={{ width: "100%", height: "150px", display: "flex", alignItems: "top", justifyContent: "center" }}>{thirdStep()}</section>
          )}
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Box>
    </Dialog>
  );
}
