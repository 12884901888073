import { useState, useEffect } from "react";
import Carousel from "../../components/slider/carousel";
import { useFetch } from "../../hooks/useFetch";
import LoadingPage from "../../components/pageloading/pageloading";
import { motion } from "framer-motion";
import images from "../../assets/img";
import pais from "../../assets/img/paises/Brazil.png";
import pais2 from "../../assets/img/paises/South Korea.png";
import pais3 from "../../assets/img/paises/United States of America(USA).png";
import language from "../../assets/language/text";
import { useLocalDB } from "../../context/LocalDB";
import sound from "../../assets/sounds/index";

const ambientSound = sound.HallOfFame;

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};
const pageTransition = { type: "tween", easy: "circInOut", duration: 0.4 };

function Index() {
  const [search, setSearch] = useState();
  const [tournaments, setTournaments] = useState([]);
  const [timer, setTimer] = useState();
  const { lang } = useLocalDB();
  const { data, error, mutate } = useFetch("tournaments");
  const text = language[lang].hof_page;
  useEffect(
    () =>
      setTimeout(() => {
        SearchTournament();
        setTimer();
      }, timer),
    [timer]
  );
  useEffect(() => {
    ambientSound.currentTime = 1;
    ambientSound.play();
  }, []);

  function SearchTournament() {
    if (data) {
      const torneios = data.filter((item) => {
        const lowercase = item.name.toLowerCase();
        if (lowercase.includes(search)) {
          return true;
        }
      });
      return setTournaments(torneios);
    }
  }
  const HandleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    setTimer(300);
  };

  if (error) return <div>failed to load</div>;
  if (!data) return <LoadingPage />;
  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="hof-page">
      <svg className="svg-mask">
        <clipPath id="svgShield1" clipPathUnits="objectBoundingBox">
          <path
            d="M0,0.143 C0.286,0.141,0.41,0.07,0.5,0 C0.588,0.07,0.709,0.141,1,0.143 C0.991,0.566,0.972,0.605,0.908,0.711 C0.819,0.849,0.609,0.956,0.5,1 C0.378,0.944,0.181,0.849,0.09,0.704 C0.057,0.646,0.012,0.559,0,0.143"
            fill="#fff"
          />
        </clipPath>
      </svg>
      <div className="hof-page_left">
        <div className="hof-page_left-card reverse">
          <div className="hof-page_left-card_avatar-container">
            <div className="photo-one">
              <img src={images.avatar1} alt="1# avatar" />
            </div>
          </div>
          <div className="hof-page_left-card_infos">
            <div className="hof-page_left-card_infos_nick">
              <h1>NOAH</h1>
              <img src={pais} alt="flag-country" />
            </div>
            <span>
              W <b>14</b> | L <b>2</b> | D <b>0</b>
            </span>
          </div>
        </div>
        <div className="hof-page_left-card">
          <div className="hof-page_left-card_avatar-container">
            <div className="photo-two">
              <img src={images.avatar2} alt="2# avatar" />
            </div>
          </div>
          <div className="hof-page_left-card_infos">
            <div className="hof-page_left-card_infos_nick">
              <h1>ILLAOI</h1>
              <img src={pais2} alt="flag-country" />
            </div>
            <span>
              W <b>10</b> | L <b>4</b> | D <b>1</b>
            </span>
          </div>
        </div>
        <div className="hof-page_left-card reverse">
          <div className="hof-page_left-card_avatar-container">
            <div className="photo-three">
              <img src={images.avatar3} alt="3# avatar" />
            </div>
          </div>
          <div className="hof-page_left-card_infos">
            <div className="hof-page_left-card_infos_nick">
              <h1>DERICK</h1>
              <img src={pais3} alt="flag-country" />
            </div>
            <span>
              W <b>9</b> | L <b>6</b> | D <b>3</b>
            </span>
          </div>
        </div>
      </div>
      <div className="hof-page_right">
        <div className="hof-page_right-top">
          <div className="default-input-i hof-input-box">
            <input type="text" value={search} onChange={HandleSearch} placeholder={text[0]} autoComplete="off" />
            <i className="fas fa-search fa-xs"></i>
          </div>
        </div>
        <Carousel data={tournaments.length > 0 ? tournaments : data} loop={false} />
      </div>
    </motion.div>
  );
}

export default Index;
