import { useState } from "react";
import { Button, Dialog } from "@material-ui/core";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";

function AddWallet({ Address, setDefault }) {
  const [open, setOpen] = useState(true);
  const { setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const { setUser } = useStore();
  const { lang } = useLocalDB();
  const language = {
    pt: [
      "DESEJA ADICIONAR COMO SUA CARTEIRA PRINCIPAL?",
      "CANCELAR",
      "CONFIRM",
      "VOCÊ PRECISA DE UMA CARTEIRA PRINCIPAL PARA RECEBER AS RECOMPENSAS",
      "CARTEIRA ADICIONADA COM SUCESSO!",
      "ERRO AO ADICIONAR A CARTEIRA",
    ],
    en: [
      "DO YOU WANNA ADD THIS WALLET AS DEFAULT?",
      "CANCEL",
      "CONFIRMAR",
      "YOU NEED A DEFAULT WALLET TO CLAIM YOUR REWARDS",
      "WALLET SUCCESSFULLY ADDED!",
      "ERROR ADDING WALLET",
    ],
  };
  const text = language[lang];

  const handleAddWallet = async () => {
    const defaultWallet = await setDefault(Address).catch((error) => {
      setAlert(true);
      setAlertMsg(text[5]);
      setSeverity("error");
    });
    if (defaultWallet) {
      setAlert(true);
      setAlertMsg(text[4]);
      setSeverity("success");
      setUser(defaultWallet.data);
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ style: { background: "transparent" } }}>
      <div style={{ width: "400px", height: "170px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
        <h4 style={{ textAlign: "center", fontFamily: "gilroy-bold" }}>{text[3]}</h4>
        <p style={{ textAlign: "center" }}>{text[0]}</p>
        <p style={{ color: "var(--pink)" }}>{Address}</p>
        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
          <Button onClick={handleClose}>{text[1]}</Button>
          <Button onClick={handleAddWallet}>{text[2]}</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddWallet;
