import React, { useEffect, useState } from "react";
import Profile from "../../components/profile/Profile";
import DisplayBadges from "../../components/badges/displayBadge";
import BadgesSlider from "../../components/badges/badgesSlider";
import GraphicPlayer from "../../components/graphic/Graphic";
import { Link } from "react-router-dom";
import { useStore } from "../../context/storeContext";
import { motion } from "framer-motion";
import Loading from "../../components/pageloading/pageloading";
import language from "../../assets/language/text";
import { useLocalDB } from "../../context/LocalDB";
import BtnContinuo from "../../components/buttons/BtnContinuo";
import api from "../../services/api";
import HallOfFameList from "../../components/list/hallOfFameList";
import Pop from "../../components/PopOver";

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};
const pageTransition = { type: "tween", easy: "anticipate", duration: 0.3 };

function Index() {
  const { user } = useStore();
  const { lang, setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const [stakersOnMe, setStakersOnMe] = useState([]);
  const text = language[lang].player_page;

  useEffect(() => {
    getPlayerStakers();
  }, []);

  const getPlayerStakers = async () => {
    const getPlayers = await api.get(`/stakes/to/${user._id}`).catch(console.log);
    if (getPlayers) {
      setStakersOnMe(getPlayers.data);
    }
  };

  // if (!data) return <Loading />;
  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="half-page-player">
      <div className="half-page-player-profile">
        <Profile />
      </div>
      <div className="half-page-player-badges">
        <p>{text[3]}</p>
        <BadgesSlider data={user.badges} />

        <BtnContinuo
          component={Link}
          to="/player"
          onClick={() => {
            setAlert(true);
            setAlertMsg("Coming Soon!");
            setSeverity("error");
          }}
          className={"default-button hof-button"}
        >
          Hall of fame
        </BtnContinuo>
      </div>
      <div className="half-page-player-graphic">
        <Link
          style={{ textDecoration: "none" }}
          to="/player"
          onClick={() => {
            setAlert(true);
            setAlertMsg("Coming Soon!");
            setSeverity("error");
          }}
        >
          {/* <GraphicPlayer stakers={stakersOnMe} stakersOnMe={stakersOnMe} wallet={user.wallet} /> */}
          <HallOfFameList />
        </Link>
      </div>
    </motion.div>
  );
}

export default Index;
