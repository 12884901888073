import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingPage from "../../components/pageloading/pageloading";
import { useFetch } from "../../hooks/useFetch";
import api from "../../services/api";
import PlayerCard from "./playerCardStandings";
import language from "../../assets/language/text";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import firebaseApi from "../../services/firebaseApi";
import sound from "../../assets/sounds/index";
import { Link } from "react-router-dom";

const newMessage = sound.NewMessage;

const StandingsRoom = () => {
  const { id } = useParams();
  const [players, setPlayers] = useState([]);
  const [listMsg, setListMsg] = useState([]);
  const { lang } = useLocalDB();
  const chatscrollref = useRef(null);
  const { user, setUser } = useStore();
  const [standings, setStandings] = useState([]);
  const [nextRoundReady, setNextRoundReady] = useState(false);
  const [msgInputValue, setMsgInputValue] = useState();
  const [tourney, setTourney] = useState();
  const { data, error } = useFetch(`tournament/${id}`);
  const text = language[lang].standingsRoom;

  const newPairing = sound.newPairing2;

  const addPlayers = useCallback((item) => {
    if (players.length === 0) {
      setPlayers((players) => [...players, item]);
    }
  }, []);

  useEffect(() => {
    if (chatscrollref.current !== null) {
      if (chatscrollref.current.scrollHeight) {
        if (chatscrollref.current.scrollHeight > chatscrollref.current.offsetHeight) {
          chatscrollref.current.scrollTop = chatscrollref.current.scrollHeight - chatscrollref.current.offsetHeight;
        }
      }
    }
  }, [listMsg]);
  useEffect(() => {
    newPairing.play();
  }, [nextRoundReady]);

  useEffect(() => {
    if (listMsg && listMsg.length > 0) {
      if (chatscrollref.current !== null) {
        newMessage.currentTime = 0;
        newMessage.volume = 0.05;
        newMessage.play();
      }
    }
  }, [listMsg]);

  useEffect(() => {
    if (tourney && data) {
      const win = tourney[`round${tourney.currentRound}`].winner.filter((item) => {
        if (item === user._id) return true;
      });
      const loss = tourney[`round${tourney.currentRound}`].looser.filter((item) => {
        if (item === user._id) return true;
      });
      const confirm = () => {
        if (win.length > 0 || loss.length > 0) {
          return setNextRoundReady(false);
        } else {
          return setNextRoundReady(true);
        }
      };
      return confirm();
    }
  }, [tourney]);

  useEffect(() => {
    if (id) {
      // firebaseApi.getTourneyOnSnapshot(id, setTourney, setStandings, setListMsg);
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (data.players) {
        let getPlayer = data.players.map(async (item) => {
          let getPlayers = await api.get(`users/${item._id}`);
          return addPlayers(getPlayers.data);
        });
        return getPlayer;
      }
    }
  }, [data]);

  useEffect(() => {
    if (tourney) {
      const confirm = tourney[`round${data.tourney.currentRound}`];
      return confirm;
    }
  }, [tourney]);

  function WLConfimr(player) {
    if (tourney) {
      const tourneyRound = tourney[`round${data.tourney.currentRound}`];
      const winConfirm = tourneyRound.winner.filter((item) => {
        if (item === player._id) return true;
      });
      const looseConfirm = tourneyRound.looser.filter((item) => {
        if (item === player._id) return true;
      });
      if (winConfirm.length > 0) return "win";
      if (looseConfirm.length > 0) return "loose";
      return null;
    }
  }
  async function handleUpdateUser() {
    const userGet = api.get(`users/${user._id}`);
    setUser(userGet.data);
  }

  const handleSubmitMsg = (e) => {
    e.preventDefault();
    if (msgInputValue !== "") {
      // firebaseApi.sendWaitRoomMessage(id, user.nickname, msgInputValue);
    }
    setMsgInputValue("");
  };

  if (error) return <div>Error loading data...</div>;
  if (!data) return <LoadingPage />;
  if (!tourney) return <LoadingPage />;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {nextRoundReady && tourney.currentRound < 3 ? (
        <Link
          to={`/room/${id}`}
          className="button-deck-add"
          style={{
            position: "absolute",
            width: "230px",
            height: "230px",
            fontSize: "1.7rem",
            zIndex: "2000",
            bottom: "10vh",
            left: "37vw",
          }}
        >
          Next Round
        </Link>
      ) : !tourney.finished ? (
        <div
          style={{
            position: "absolute",
            width: "190px",
            height: "190px",
            fontSize: "1.7rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            background: "gray",
            zIndex: "2000",
            bottom: "10vh",
            left: "38.5vw",
            cursor: "not-allowed",
          }}
        >
          Next Round
        </div>
      ) : (
        <Link
          to={`/player`}
          className="button-deck-add"
          onClick={handleUpdateUser}
          style={{
            position: "absolute",
            width: "230px",
            height: "230px",
            fontSize: "1.7rem",
            zIndex: "2000",
            bottom: "10vh",
            left: "37vw",
          }}
        >
          Finish
        </Link>
      )}
      <div style={{ textAlign: "center" }}>
        <h1>Players</h1>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "52vw",
            height: "28vw",
            overflowY: "auto",
            OverflowX: "hidden",
          }}
        >
          {players &&
            players.map((item) => {
              return (
                <div
                  key={item._id}
                  style={{
                    transform: "scale(.5)",
                    width: "200px",
                    height: "160px",
                    position: "relative",
                    marginTop: "-40px",
                    padding: "2rem",
                  }}
                >
                  <PlayerCard data={item} />
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 2000,
                      width: "150px",
                      height: "150px",
                      fontSize: "1.7rem",
                      top: "15rem",
                      left: "10rem",
                      borderRadius: "50%",
                      background:
                        (WLConfimr(item) === "win" && "var(--lightblue)") ||
                        (WLConfimr(item) === "loose" && "var(--pink2)") ||
                        (WLConfimr(item) === null && "var(--blue)"),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {(WLConfimr(item) === "win" && "WON") || (WLConfimr(item) === "loose" && "LOST") || (WLConfimr(item) === null && "PLAYING...")}
                  </div>
                </div>
              );
            })}
        </div>
        <div
          style={{
            width: "40vw",
            height: "40vh",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <div className="room-page_chat-container">
            <div className="room-page_chat-container-messages" ref={chatscrollref}>
              {listMsg.map((item, key) => {
                return (
                  <div key={key}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        paddingRight: "5px",
                        flexDirection: "row-reverse",
                        justifyContent: item.author === user.nickname ? "flex-start" : "flex-end",
                      }}
                    >
                      {item.author !== user.nickname ? (
                        <div
                          className="room-page_chat-container-messages-chat-box"
                          style={{
                            background: "rgba(200,0,150,0.1)",
                            borderRadius: "8px",
                            maxWidth: "78%",
                            padding: "10px",
                            margin: "5px",
                          }}
                        >
                          <div style={{ color: "rgb(200,0,200)" }}>{item.author}</div>
                          <div
                            style={{
                              whiteSpace: "wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {item.body}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            background: "rgba(0,200,150,0.1)",
                            borderRadius: "8px",
                            maxWidth: "78%",
                            textAlign: "right",
                            padding: "10px",
                            margin: "5px",
                          }}
                        >
                          {/* <div
                        style={{
                          color: "rgb(200,0,200)",
                          fontWeight: "bold",
                        }}
                      >
                        {item.author}
                      </div> */}
                          <div
                            style={{
                              whiteSpace: "wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {item.body}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <form onSubmit={handleSubmitMsg}>
              <input
                type="text"
                placeholder="Digite aqui..."
                className="room-page_chat-container-input"
                value={msgInputValue}
                onChange={(e) => setMsgInputValue(e.target.value)}
              />
            </form>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "48vw",
          margin: "2vw",
        }}
      >
        <h1>Standings</h1>
        <table style={{ width: "100%", height: "75vh" }}>
          <thead>
            <tr style={{ background: "rgba(0,0,0,0.4)", height: "30px" }}>
              <td>POSITION</td>
              <td>PLAYER</td>
              <td>GAME POINTS</td>
              <td>MATCH POINTS</td>
              <td>MATCHES</td>
            </tr>
          </thead>
          <tbody>
            {standings.map((item, index) => {
              return (
                <tr
                  style={{
                    background: index % 2 === 1 ? "rgba(0,0,0,0.1)" : "rgba(0,0,0,0.2)",
                  }}
                  key={index}
                >
                  <td>{index + 1}#</td>
                  <td>{item.alias}</td>
                  <td>{item.gamePoints}</td>
                  <td>{item.matchPoints}</td>
                  <td>{item.matches}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Link to={`/winner/${id}`} style={{ position: "absolute" }}>
        winnerPage
      </Link>
    </div>
  );
};

export default StandingsRoom;
