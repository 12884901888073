import React from "react";
import Pageloading from "../pageloading/pageloading";

const EvoList = ({ data }) => {
  if (!data) return <Pageloading />;
  return (
    <div className="item_evoList">
      <div className="item_evoList-img">{data.img && <img src={data.img ? data.img : null} alt="Logo" />}</div>
      <div className="item_evoList-infos">
        <div className="item_evoList-infos-name gradient-txt">{data.name ? data.name : null}</div>
        <div className="item_evoList-infos-points">
          <div className="item_evoList-infos-points-item" style={{ marginRight: "1.8rem" }}>
            P <b>{data.players && data.players.length}</b>
          </div>
          <div className="item_evoList-infos-points-item">
            T <b>{data.tournaments && data.evo.tournaments.length}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvoList;
