import { useEffect, useState } from "react";
import { useLocalDB } from "../../../context/LocalDB";
import { useSocket } from "../../../context/webSocket";
import TimerCheckIn from "./TimerCheckin";
import TimerRound from "./TimerRound";

function Timer({ id, player2Bye, checkIn, timeForCheckIn, timeForRound, tourneyData, extraTimer }) {
  const [serverTimer, setServerTimer] = useState();
  const { lang } = useLocalDB();
  const { socket } = useSocket();

  useEffect(() => {
    socket.off(`timer/${id}`).on(`timer/${id}`, setServerTimer);
    socket.off(`extraTimer/${id}`).on(`extraTimer/${id}`, setServerTimer);
  }, []);

  return (
    <>
      <TimerRound id={id} timeForRound={timeForRound} tourneyData={tourneyData} extraTimer={extraTimer} serverTimer={serverTimer} lang={lang} />
      <TimerCheckIn id={id} player2Bye={player2Bye} checkIn={checkIn} timeForCheckIn={timeForCheckIn} serverTimer={serverTimer} lang={lang} />
    </>
  );
}

export default Timer;
