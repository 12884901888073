import api from "./api";

export default {
  stakeOnPlayer: async (playerId, Value) => {
    return await api.post(`stakes/increase`, { playerId: playerId, amount: Value });
  },
  unstakeOnPlayer: async (playerId, Value) => {
    return await api.post(`stakes/decrease`, { playerId: playerId, amount: Value });
  },
};
