import { useEffect, useState, useRef } from "react";
import { useStore } from "../../context/storeContext";
import { useLocalDB } from "../../context/LocalDB";
import { motion } from "framer-motion";
// import eq9Config from "../../services/eq9Config";
import language from "../../assets/language/text";
import sound from "../../assets/sounds";
import { Dialog } from "@material-ui/core";
import ParticlesPage from "../../components/Particles";

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};
const pageTransition = { type: "tween", easy: "circInOut", duration: 0.4 };

function Welcome() {
  const { lang, setLogin } = useLocalDB();
  const text = language[lang].w_page;
  const audio = new Audio();

  useEffect(() => {
    audio.play();
    audio.currentTime = 0.7;
    audio.loop = true;
  }, []);

  return (
    <div
      style={{ width: "100vw", height: "100vh" }}
      onClick={() => {
        setLogin(true);
      }}
    >
      <ParticlesPage />
      <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="welcome">
        <div className="w-left">
          <div className="w-title">
            <p>{text.title[0]}</p>
            <br />
            <h1>{text.title[1]}</h1>
          </div>
          <div className="w-logo">
            <div className="w-logo-img" />
          </div>
        </div>
        <div className="w-right">
          <div className="w-right-link">
            <div className="w-right-link-line" />
            <div className="w-right-link-txt" to="/ranking/61044b407c963538d01b5fd8">
              <p>{text.middle_text[0]}</p>
              <h3>{text.middle_text[1]}</h3>
            </div>
          </div>

          <div className="w-right-container-buttons">
            <div className="translate-y">
              <div className="w-button" style={{ pointerEvents: "none" }}>
                <div className="w-button-icon-player" />
                {text.button[0]}
              </div>
            </div>
            <div className="translate-y">
              <div className="w-button delay" style={{ pointerEvents: "none" }}>
                <div className="w-button-icon-staker" />
                {text.button[1]}
              </div>
            </div>
            <div className="translate-y">
              <div className="w-button button-evo">
                <div className="w-button-icon-evo" />
                {text.button[2]}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Welcome;
