import React from "react";
import "./table.scss";
import ProfilePicture from "../ProfileTablePicture";
import { useLocalDB } from "../../context/LocalDB";
import language from "../../assets/language/text";

function Index({ col, data }) {
  const { setModalPlayerProfile, setPlayer, lang } = useLocalDB();
  const text = language[lang].table;
  function alternateStyle(props) {
    if (props % 2) return { backgroundColor: "rgba(0,0,0,0.2)" };
    else return { backgroundColor: "transparent" };
  }
  return (
    <div className="tableGrid">
      <table className="table">
        {col ? (
          col.map((item) => {
            return (
              <th
                style={{
                  width: item.wd,
                  backgroundColor: "rgba(0,0,0,0.5)",
                }}
              >
                {item.col}
              </th>
            );
          })
        ) : (
          <th>{text[0]}</th>
        )}
        {data ? (
          data.map((item) => {
            return (
              <tr
                style={alternateStyle(data.indexOf(item))}
                onClick={() => {
                  setPlayer(item);
                  setModalPlayerProfile(true);
                }}
              >
                <td>{`${data.indexOf(item) + 1}#`}</td>
                <td className="nameImgTable">
                  <ProfilePicture />
                  {item.name}
                </td>
                <td className="degradeName">{item.nickname}</td>
                <td>{item.tag}</td>
                <td>{item.stakers}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <th>{text[0]}</th>
          </tr>
        )}
      </table>
    </div>
  );
}

export default Index;
