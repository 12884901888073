import { useEffect, useRef, useState } from "react";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import { useSocket } from "../../context/webSocket";
// import useStorage from "../../utils/useStorage";
import "./onDemand.scss";

function OnDemand() {
  const { lang, setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const { user } = useStore();
  const [passVisible, setPassVisible] = useState(false);
  const eventName = useRef();
  const eventPassword = useRef();
  const eventValue = useRef();
  // const [event, setEvent] = useStorage("events");
  const [event, setEvent] = useState([]);
  const [activeSearch, setActiveSearch] = useState(false);
  const [page, setPage] = useState(0);
  const { socket } = useSocket();
  const searchInput = useRef(null);

  useEffect(() => {
    socket.off("findAllChallenges").on("findAllChallenges", (e) => {
      setEvent(e);
    });
    socket.off("exception").on("exception", (e) => {
      console.log(e);
    });
    socket.emit("findAllChallenges", { active: activeSearch, page: page, size: 20 });
  }, []);

  const handleCreateChallenge = (e) => {
    e.preventDefault();
    const confirmValues = eventName.current?.value && eventValue.current?.value;
    // const getEvents = event ? [...event] : [];
    if (confirmValues) {
      socket.emit("createChallenge", { name: eventName.current?.value, password: eventPassword.current?.value, amount: eventValue.current?.value });
      eventName.current.value = "";
      eventPassword.current.value = "";
      eventValue.current.value = "";
      setAlert(true);
      setAlertMsg(lang === "pt" ? "EVENTO CRIADO COM SUCESSO!" : "EVENT CREATED SUCCESSFULLY!");
      setSeverity("success");
    } else {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "ERRO AO CRIAR EVENTO!" : "ERROR ON CREATE EVENT!");
      setSeverity("error");
    }
  };

  const handleDeleteChallenge = (item, index) => {
    let getEventSlice = event;
    getEventSlice.splice(index, 1);
    setEvent(getEventSlice);
    setAlert(true);
    setAlertMsg(lang === "pt" ? "ERRO AO CRIAR EVENTO!" : "ERROR ON CREATE EVENT!");
    setSeverity("error");
  };

  const handleEmitActive = (bool) => {
    setActiveSearch(bool);
    console.log("emitindo", "findAllChallenges :", { active: bool, page: page, size: 20 });
    socket.emit("findAllChallenges", { active: bool, page: page, size: 20 });
  };

  const handleSearchOneChallenge = (value, e) => {
    e.preventDefault();
    if (value) {
      console.log("searching:", "findAllChallenges", { searchParam: value, active: activeSearch, page: page, size: 20 });
      socket.emit("findAllChallenges", { searchParam: value, active: activeSearch, page: page, size: 20 });
    } else {
      console.log("searching:", "findAllChallenges", { active: activeSearch, page: page, size: 20 });
      socket.emit("findAllChallenges", { active: activeSearch, page: page, size: 20 });
    }
  };

  return (
    <div className="onDemand-Page">
      <h1 className="-Tittle">{lang === "pt" ? "CRIAR DESAFIO ON DEMAND" : "CREATE ON DEMAND CHALLENGE"}</h1>
      <form className="-TopBar" onSubmit={handleCreateChallenge}>
        <div className="-Inputs">
          <h2>{lang === "pt" ? "Nome da sala:" : "Room name:"}</h2>
          <input required ref={eventName} className="default-input onDemandInput-Name" />
        </div>
        <div className="-Inputs">
          <h2>{lang === "pt" ? "Senha:" : "Password:"}</h2>
          <div className="-PassCheck">
            <input ref={eventPassword} type={passVisible ? "text" : "password"} className="default-input onDemandInput-Password" />
            {passVisible ? (
              <i className="fas fa-eye" onClick={() => setPassVisible(!passVisible)} />
            ) : (
              <i className="fas fa-eye-slash" onClick={() => setPassVisible(!passVisible)} />
            )}
          </div>
        </div>
        <div className="-Inputs">
          <h2>{lang === "pt" ? "Valor:" : "Value:"}</h2>
          <input ref={eventValue} className="default-input onDemandInput-Value" />
        </div>
        <button type="submit" className="default-input buttonOnDemand">
          {lang === "pt" ? "CRIAR" : "CREATE"}
        </button>
      </form>
      <form
        onSubmit={(e) => handleSearchOneChallenge(searchInput.current?.value, e)}
        style={{ position: "relative", display: "flex", justifyContent: "flex-start" }}
      >
        <input className="default-input inputSearch" ref={searchInput} placeholder={lang === "pt" ? "Procure um desafio" : "Search for a challenge"} />
        <i className="fas fa-search" style={{ position: "absolute", top: "50%", right: "3rem", color: "var(--pink)", transform: "translateY(-50%)" }} />
      </form>
      <div className="-btnActive">
        <button
          className="default-input"
          style={{ background: !activeSearch ? "black" : "rgba(0,0,0,0.2)", borderTopRightRadius: 0 }}
          onClick={() => handleEmitActive(false)}
        >
          {lang === "" ? "Abertas" : "Open"}
        </button>
        <button
          className="default-input"
          style={{ background: !activeSearch ? "rgba(0,0,0,0.2)" : "black", borderTopLeftRadius: 0 }}
          onClick={() => handleEmitActive(true)}
        >
          {lang === "" ? "Ativas" : "Active"}
        </button>
      </div>
      <section className="-BottomBar">
        <div className="findGameTable">
          <table>
            <thead>
              <tr style={{ height: "30px", background: "rgba(0,0,0,0.8)" }}>
                <th className="t-name">{lang === "pt" ? "Nome" : "Name"}</th>
                <th className="t-owner">{lang === "pt" ? "Criador" : "Owner"}</th>
                <th className="t-value">{lang === "pt" ? "Value" : "Value"}</th>
                <th className="t-lock">{true ? <i className="fas fa-lock lockEvent" /> : <i className="fas fa-unlock lockEvent" />}</th>
                <th className="t-BTN"></th>
              </tr>
            </thead>
            <tbody>
              {event &&
                event.map((item, index) => {
                  return (
                    <tr key={item.name + item.user} style={{ background: index % 2 === 0 ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0.2)" }}>
                      <td>{item.name}</td>
                      <td>{item.owner?.name}</td>
                      <td>{item.amount} EQ9</td>
                      <td style={{ textAlign: "center" }}>
                        {item.hasPassword ? (
                          <i className="fas fa-lock" style={{ color: "var(--pink)" }} />
                        ) : (
                          <i style={{ color: "var(--lightgreen)" }} className="fas fa-unlock" />
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.owner?._id === user._id ? (
                          <i
                            className="fas fa-trash fa-2x"
                            style={{ padding: "1rem", color: "var(--pink)" }}
                            onClick={() => handleDeleteChallenge(item, index)}
                          />
                        ) : (
                          <button className="default-input" style={{ width: "7rem", height: "3rem" }}>
                            {lang === "pt" ? "ENTRAR" : "ENTER"}
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default OnDemand;
