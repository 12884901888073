import { useState, useCallback, useRef, useEffect } from "react";
import images from "../../assets/img";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";

function ProfilePlayer({ playerId }) {
  const { lang } = useLocalDB();
  const [data, setData] = useState();

  async function fetchUser(id) {
    const getUser = await api.get(`users/${id}`).catch(console.log);
    if (getUser) {
      setData(getUser.data);
    }
  }

  useEffect(() => {
    fetchUser(playerId);
  }, []);

  if (!data)
    return (
      <div
        style={{
          width: "50vw",
          height: "25vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ color: "var(--pink)", fontFamily: "gilroy-bold" }}>{lang === "pt" ? "CARREGANDO DADOS DO USUÁRIO..." : "LOADING USER DATA..."}</p>
      </div>
    );

  return (
    <div className="c-profile" style={{ width: "50vw", height: "25vh" }}>
      <div className="c-profile-avatar">
        {data.img ? (
          <>
            <div className="c-profile-avatar-dropzone">
              <i class="fas fa-camera" />
            </div>
            <div className="c-profile-avatar-photo">
              <img src={data.img ? data.img : null} alt="Profile Photo" />
            </div>
          </>
        ) : (
          <div className="c-profile-avatar-photo">
            <img src={images.unknown} alt="Profile Photo" />
          </div>
        )}
        <img src={images.bordaAvatar} alt="avatar border" />
      </div>
      <div className="c-profile-infos">
        <p></p>
        <h1 className="gradient-txt" name="nickname">
          {data.nickname}
        </h1>
        <div className="c-profile-infos-results" style={{ height: "10px", paddingTop: "5px" }}>
          {data.score ? (
            <>
              W <b>{data.score.w ? data.score.w : 0}</b> | L <b>{data.score.l ? data.score.l : 0}</b> | D <b>{data.score.d ? data.score.d : 0}</b>
            </>
          ) : null}
        </div>
        <div className="c-profile-infos-social" style={{ display: "flex" }}>
          <a href={data.socialMedia?.twitch ? "https://" + data.socialMedia.twitch : "https://www.twitch.tv/"} rel="noreferrer" target="_blank">
            <i class="fab fa-twitch"></i>
          </a>
          <a href={data.socialMedia?.youtube ? "https://" + data.socialMedia.youtube : "https://www.youtube.com/"} rel="noreferrer" target="_blank">
            <i class="fab fa-youtube"></i>
          </a>
          <a href={data.socialMedia?.instagram ? "https://" + data.socialMedia.instagram : "https://www.instagram.com/"} rel="noreferrer" target="_blank">
            <i class="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProfilePlayer;
