import React, { useEffect, useState } from "react";

import { Route, Redirect, useLocation, Switch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
// import SlideRoutes from "react-slide-routes";
import WelcomeNoLogin from "../../pages/welcomeNoLogin";
import Alert from "../../components/alert/alert";
import DialogBox from "../dialog/AddPlayer";
import RotasTemporarias from "./RotasTemporarias";
import MobileRoutes from "./MobileRoutes";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import decode from "jwt-decode";
import api from "../../services/api";
import Login from "../../pages/login/index";
import PrivatePolicy from "../../pages/privatePolicy/loginPolicy";
import AdamLogin from "../../pages/login/LoginAdam";
import { useSocket } from "../../context/webSocket";
import Pageloading from "../pageloading/pageloading";
import { useWallet } from "../../context/walletContext";
import ResetPasswordPage from "../../pages/resetPassword";
import Organizer from "../../pages/EVO";

const { innerWidth } = window;

function Routes() {
  const { mobile, setMobile, login, setLogin } = useLocalDB();
  const { user, setUser, token, setEvo, setToken } = useStore();
  const { getBalanceOf } = useWallet();
  const { getSocket, socket } = useSocket();
  const [userId, setUserId] = useState();
  const [timeout, isTimeout] = useState(false);
  const [isEvo, setIsEvo] = useState(false);
  const location = useLocation();

  const fetchUserByToken = async () => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    const decoded = decode(token);
    const expiration = decoded.exp;
    const hoje = new Date();
    if (!decoded.sub) return setToken();
    if (hoje <= expiration) return setToken();
    const getUser = await api.get(`users/${decoded.sub}`).catch(console.log);
    if (getUser) {
      setUser(getUser.data);
      setUserId(decoded.sub);
      if (decoded.evo) {
        setIsEvo(true);
        fetchEvo(decoded.evo._id);
      }
      isTimeout(false);
    } else {
      setToken();
    }
  };

  const fetchEvo = async (evo) => {
    if (evo) {
      const getEvoData = await api.get(`evos/${evo}`).catch(console.log);
      if (getEvoData) {
        setEvo(getEvoData.data);
      }
    }
  };

  useEffect(() => {
    if (token) {
      fetchUserByToken();
      setLogin(true);
      getSocket();
      getBalanceOf();
    }
  }, [token]);

  useEffect(() => {
    if (timeout && token) {
      fetchUserByToken();
    } else {
    }
  }, [timeout]);

  if (location.pathname.includes("/resetPassword")) return <ResetPasswordPage />;

  if (location.pathname === "/login/adam") return <AdamLogin />;

  if (location.pathname === "/PrivateProvicy") return <PrivatePolicy />;

  if (!token && !login) return <WelcomeNoLogin />;

  if (login && !token) return <Login />;

  if (!socket) return <Pageloading />;

  return (
    <AnimatePresence style={{ zIndex: 1 }}>
      <DialogBox />
      <Alert />
      <RotasTemporarias isEvo={isEvo} />
    </AnimatePresence>
  );
}

export default Routes;
