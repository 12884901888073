import { useRef, useState } from "react";
import { setTimeout } from "timers";
import AlertMsg from "../../components/alert/alert";
import Controller from "../../components/Controller";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
import { Redirect } from "react-router-dom";
import { Dialog } from "@material-ui/core";

export default function ResetSend({ open, handleClose }) {
  const emailConfirm = useRef();
  const [status, setStatus] = useState(0);
  const { lang, setAlert, setAlertMsg, setSeverity } = useLocalDB();

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (status === 0) {
      setStatus(1);
      const usernameValue = emailConfirm.current?.value;
      if (usernameValue.length > 10) {
        const getPasswordUpdated = await api.post(`emails/recovery`, { username: usernameValue }).catch((error) => {
          const errorResponse = error.response;
          setAlert(true);
          setAlertMsg(lang === "pt" ? "Erro ao solicitar atualização da senha" : "Error trying to request update for your password");
          setSeverity("error");
          setStatus(0);
        });
        if (getPasswordUpdated) {
          setAlert(true);
          setAlertMsg(
            lang === "pt"
              ? "TROCA DE SENHA SOLICITADA COM SUCESSO! CONFIRA SEU EMAIL PARA CONTINUAR"
              : "PASSWORD CHANGE SUCCESSFULLY REQUESTED! GO TO YOUR E-MAIL TO CONTINUE"
          );
          setSeverity("success");
          setStatus(0);
          setTimeout(() => {
            handleClose();
          }, 1000);
        }
      } else {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "Insira um e-mail valido." : "Type a valid e-mail");
        setSeverity("error");
        setStatus(0);
      }
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (status === 0) {
      handleClose();
    }
  };

  return (
    <Dialog open={open} PaperProps={{ style: { background: "rgba(0,0,0,0.7)", borderRadius: "15px" } }}>
      <div className="sing-up" style={{ width: "400px", padding: "2rem" }}>
        <h2 style={{ marginTop: "-4rem", fontSize: "2em" }}>{lang === "pt" ? "Resetar Senha" : "Reset Password"}</h2>
        <form className="formSingup" onSubmit={handleSignUp} noValidate style={{ width: "350px" }}>
          <div className="form-div">
            <input type="email" id="input-singUp-username" pattern=".+" minLength={6} ref={emailConfirm} required className="form-div-input" />
            <label className="input-label" for="input-singUp-username" style={{ fontSize: "1em", marginTop: "5px" }}>
              {lang === "pt" ? "ENDERÇO DE EMAIL" : "E-MAIL ADDRESS"}
            </label>
            <span className="input-label-line width-passC" for="input-singUp-password" style={{ width: lang === "en" ? "155px" : "175px" }}></span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
            <button className="button-login" style={{ margin: "5px" }} onClick={handleCancel}>
              {lang === "pt" ? "CANCELAR" : "CANCEL"}
            </button>
            <button className="button-login" style={{ margin: "5px" }} onClick={handleSignUp}>
              {status === 0 ? (lang === "pt" ? "CONFIRMAR" : "CONFIRM") : lang === "pt" ? "SOLICITANDO..." : "REQUESTING..."}
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}
