import { Dialog } from "@material-ui/core";
import { useEffect } from "react";
import { browserName, browserVersion } from "react-device-detect";
import "./TronLinkGuide.scss";

const getBrowser = () => {
  const chrome = "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en";
  const mozilla = "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/";
  const edge = "https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US";
};

const WEBSTORE_URL = "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en";

const openTronLink = () => {
  window.open(WEBSTORE_URL, "_blank");
};

const TronLinkGuide = ({ open, setOpen, installed }) => {
  useEffect(() => {
    getBrowser();
  }, []);

  if (!installed) {
    return (
      // <Dialog open={open} PaperProps={{ style: { background: "transparent" } }}>
      <Dialog open={open} onClose={() => (!installed ? setOpen(false) : null)} PaperProps={{ style: { background: "transparent" } }}>
        <div className="tronLink" onClick={openTronLink}>
          <div className="info">
            <h1>MetaMask Wallet needed to continue.</h1>
            <p>
              Install it now from{" "}
              <a href={WEBSTORE_URL} style={{ color: "black", fontFamily: "gilroy-bold" }} target="_blank" rel="noopener noreferrer">
                Chrome Store,{" "}
              </a>
              once installed, return and refresh the page.
            </p>
          </div>
        </div>
        {installed && (
          <h1 onClick={installed} style={{ textAlign: "center", color: "var(--blue)", backdropFilter: "blur(20px)", marginTop: "-1rem" }}>
            {" "}
            I Have an Account.
          </h1>
        )}
      </Dialog>
    );
  }

  return (
    <Dialog open={installed ? true : false} PaperProps={{ style: { background: "transparent" } }}>
      <div className="tronLink hover" onClick={openTronLink}>
        <div className="info">
          <h1>Login Required</h1>
          <p>MetaMask is installed but require to login. Open MetaMask Wallet on browser extensions and synchronize your wallet to continue.</p>
        </div>
      </div>
    </Dialog>
  );
};

export default TronLinkGuide;
