function TimerRound({ id, timeForRound, tourneyData, extraTimer, serverTimer, lang }) {
  if (serverTimer) {
    if (!tourneyData?.extraTime) {
      if (serverTimer?.minutes >= 0 || serverTimer?.seconds > 0) {
        return (
          <div style={{ position: "absolute", top: "1.8rem", left: "78%", transform: "translateX(-50%)", textAlign: "center" }}>
            <p style={{ color: "var(--pink)" }}>{lang === "pt" ? "TEMPO PARA FINALIZAR O ROUND" : "TIME LEFT TO END THE ROUND"}</p>
            <h1
              style={{
                color: "var(--lightblue)",
                fontSize: "clamp(1.4rem, 1.8vw, 2.6rem)",
              }}
            >
              {" "}
              {serverTimer.minutes}:{serverTimer.seconds < 10 ? `0${serverTimer.seconds}` : serverTimer.seconds}
            </h1>
          </div>
        );
      } else {
        return (
          <div style={{ position: "absolute", top: "2rem", left: "78%", transform: "translateX(-50%)", textAlign: "center" }}>
            <p style={{ color: "var(--pink)" }}>{lang === "pt" ? "TEMPO DO ROUND FINALIZADO" : "THE ROUND TIME HAS ENDED"}</p>
          </div>
        );
      }
    } else if (tourneyData?.extraTime) {
      if (serverTimer?.minutes >= 0 || serverTimer?.seconds > 0) {
        return (
          <section>
            <div
              style={{
                position: "absolute",
                top: "2rem",
                left: "78%",
                transform: "translateX(-50%)",
                textAlign: "center",
              }}
            >
              <p style={{ color: "var(--pink)" }}>{lang === "pt" ? "TEMPO EXTRA" : "EXTRA TIME"}</p>
              <h1
                style={{
                  color: "var(--lightblue)",
                  fontSize: "clamp(1.4rem, 1.8vw, 2.6rem)",
                }}
              >
                {serverTimer?.minutes === extraTimer ? serverTimer?.minutes - 1 : serverTimer?.minutes}:
                {serverTimer.seconds < 10 ? `0${serverTimer.seconds}` : serverTimer.seconds}
              </h1>
            </div>
          </section>
        );
      } else {
        return (
          <div style={{ position: "absolute", top: "3rem", left: "70%", transform: "translateX(-50%)", textAlign: "center" }}>
            <p style={{ color: "var(--pink)" }}>{lang === "pt" ? "TEMPO EXTRA DO ROUND FINALIZADO" : "THE ROUND EXTRA TIME HAS ENDED"}</p>
          </div>
        );
      }
    }
  } else {
    return <></>;
  }
}

export default TimerRound;
