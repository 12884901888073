import React from "react";

function Pageloading() {
  return (
    <div className="loading-page">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default Pageloading;
