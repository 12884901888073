import { useEffect, useState } from "react";
import { useLocalDB } from "../../context/LocalDB";
import image from "../../assets/img";
import api from "../../services/api";
import sounds from "../../assets/sounds";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, EffectCoverflow, Pagination, Navigation } from "swiper";
import Pop from "../PopOver";

SwiperCore.use([Keyboard, EffectCoverflow, Pagination, Navigation]);
const slideCard = sounds.slideCard;

function HallOfFameList() {
  const { lang } = useLocalDB();
  const [players, setPlayers] = useState([]);
  const playersIds = [
    "61ddab3812191b51d7f0c2d6", //1
    "62153a43d6a030d29e3fb079", //2
    "6202cd3236d3a5455627fffb", //3
    "6216ac21aa63a0008a26021c", //4
    "61d4bcfbafa0fe0795965a51", //5
    "61d454d9afa0fe079595dc16", //6
    "620bfdd7e0043975c2d7b555", //7
    "61ddf4f412191b51d7f13daf", //8
  ];
  const playersPoints = [
    9, //1
    6, //2
    6, //3
    6, //4
    3, //5
    3, //6
    3, //7
    0, //8
  ];

  const fetchPlayers = async () => {
    setPlayers([]);
    const getPromisses = playersIds.map((item) => {
      return api.get(`users/${item}`);
    });

    const ranking = await api.get(`rankings/playersRank`, { params: {
      rankingId: "621796a18f811e1ee29a1493",
      page: 0,
      size: 8
    }});
    setPlayers(ranking.data);
  };
  useEffect(() => {
    fetchPlayers();
  }, []);

  return (
    <div style={{ width: "37vw", minWidth: "400px", padding: "1rem" }}>
      <Swiper
        style={{
          "--swiper-navigation-color": "#2ac1d8",
          "--swiper-pagination-color": "#2ac1d8",
        }}
        keyboard={{ enabled: true }}
        effect={"coverflow"}
        centeredSlides={true}
        slidesPerView={"auto"}
        // loop={true}
        loopFillGroupWithBlank={true}
        coverflowEffect={{
          rotate: 0,
          depth: 30,
          modifier: 1,
          slideShadows: true,
        }}
        // pagination={{ clickable: true }}
        navigation={true}
        id="main"
        className="main-swiper"
      >
        {players.map((item, index) => {
          return (
            <SwiperSlide key={`swiper-person-${item.user._id}`} style={{ width: "30%", display: "flex", alignItems: "center", justifyContent: "center", height: "30vh" }}>
              <section style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "26vh" }}>
                <div style={{ fontSize: "2em", color: "var(--pink)" }}>
                  <span style={{ fontFamily: "gilroy-bold", color: "var(--pink)" }}>{index + 1}</span>#
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", height: "70%" }}>
                  <img
                    style={{ width: "120px", height: "120px", borderRadius: "50%", border: "3px solid var(--lightgreen)" }}
                    src={item.user.img ? item.user.img : image.avatar1}
                    alt="playerimg"
                  />
                </div>
                <Pop cutValue={20} sx={{ fontSize: "1.4em", padding: "10px", textAlign: "center" }}>
                  {item.user.name ? item.user.name : item?.user?.nickname}
                </Pop>
                <footer className="carousel-card-content">
                  <div>Pts {item.matchPoints}</div>
                </footer>
              </section>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <table style={{ width: "100%", borderRadius: "15px" }}>
        <thead
          style={{
            display: "table",
            width: "100%",
            padding: "7px",
            background: "black",
            borderTopRightRadius: "15px",
            borderTopLeftRadius: "15px",
          }}
        >
          <tr style={{ color: "black" }}>
            <th style={{ width: "5%" }}>{lang === "pt" ? "Pos" : "Pos"}</th>
            <th style={{ width: "10%" }}>{lang === "pt" ? "" : ""}</th>
            <th style={{ width: "40%" }}>{lang === "pt" ? "Nome" : "Name"}</th>
            <th style={{ width: "30%" }}>{lang === "pt" ? "Nickname" : "Nickname"}</th>
            <th style={{ width: "15%" }}>{lang === "pt" ? "Pontos" : "Points"}</th>
          </tr>
        </thead>
        <tbody style={{ display: "block", maxHeight: "29vh", overflow: "auto" }}>
          {players?.map((item, index) => {
            return (
              <tr style={{ width: "100%", display: "table", background: index % 2 === 0 ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0.1)" }}>
                <td style={{ width: "5%", textAlign: "center" }}>{index + 1}</td>
                <td style={{ width: "10px" }}>
                  <img style={{ width: "40px", height: "40px", borderRadius: "50%" }} src={item.img ? item.img : image.avatar1} alt="playerimg" />
                </td>
                <td style={{ width: "40%" }}>{item.name}</td>
                <td style={{ width: "30%" }}>{item.nickname}</td>
                <td style={{ width: "15%", textAlign: "center" }}>0</td>
              </tr>
            );
          })}
        </tbody>
      </table> */}
    </div>
  );
}

export default HallOfFameList;
