import { style } from "@mui/system";
import img from "../../assets/img";
import sounds from "../../assets/sounds";
import Pop from "../PopOver";

function Card({ item, onClick, sx, position, color }) {
  const proxy = false;
  const { name, manaCost, type, text, toughness, power } = item.card;
  const manacost =
    manaCost &&
    manaCost.split("").filter((char) => {
      if (char !== "{" && char !== "}") return true;
    });
  function handleManatype(mana, index) {
    if (mana === "W") return <img style={{ maxWidth: "16px", maxHeight: "16px", borderRadius: "50%" }} src={img.whiteMana} alt="imgMana" key={mana + index} />;
    if (mana === "U") return <img style={{ maxWidth: "16px", maxHeight: "16px", borderRadius: "50%" }} src={img.blueMana} alt="imgMana" key={mana + index} />;
    if (mana === "B") return <img style={{ maxWidth: "16px", maxHeight: "16px", borderRadius: "50%" }} src={img.blackMana} alt="imgMana" key={mana + index} />;
    if (mana === "G") return <img style={{ maxWidth: "16px", maxHeight: "16px", borderRadius: "50%" }} src={img.greenMana} alt="imgMana" key={mana + index} />;
    if (mana === "R") return <img style={{ maxWidth: "16px", maxHeight: "16px", borderRadius: "50%" }} src={img.redMana} alt="imgMana" key={mana + index} />;
    else
      return (
        <div style={{ minWidth: "16px", minHeight: "16px", background: "gray", borderRadius: "50%", textAlign: "center" }} key={index}>
          {mana}
        </div>
      );
  }
  const styleCard = {
    card: { position: "absolute", color: "rgb(10,30,180)" },
    name: {
      top: proxy ? "7%" : "4%",
      left: proxy ? "16%" : "10%",
      fontFamily: proxy ? "gilroy-medium" : "gilroy-medium",
      fontSize: "0.9vw",
    },
    manaCost: {
      top: proxy ? "12.6%" : "4%",
      left: proxy && "16%",
      right: !proxy && "10%",
      display: "flex",
      height: "16px",
      width: "130px",
      justifyContent: "flex-end",
      fontFamily: proxy ? "gilroy-medium" : "gilroy-medium",
      fontSize: "0.9vw",
    },
    tipo: {
      top: proxy ? "24.5%" : "55%",
      left: proxy ? "17%" : "10%",
      fontFamily: proxy ? "gilroy-medium" : "gilroy-medium",
      fontSize: "0.6vw",
    },
    poder: {
      bottom: proxy ? "9%" : "5%",
      right: proxy ? "17%" : "10%",
      fontFamily: proxy ? "gilroy-medium" : "gilroy-medium",
      fontSize: "0.9vw",
    },
    descricao: {
      top: proxy ? "32%" : "62%",
      left: proxy ? "17%" : "10%",
      maxWidth: "10.5vw",
      fontFamily: proxy ? "gilroy-medium" : "gilroy-medium",
      fontSize: !proxy ? "0.45vw" : "0.6vw",
    },
  };
  const slideCard = sounds.slideCard;
  return (
    <section>
      <div style={{ position: "relative" }}>
        <img
          src={proxy ? img.folhaPapel : img.createCard}
          style={{ marginTop: "-8px", aspectRatio: "3/4", ...sx }}
          onClick={onClick}
          onMouseEnter={() => {
            slideCard.currentTime = 0.04;
            slideCard.play();
          }}
          alt="card-deck"
        />
        <p style={{ ...styleCard.card, ...styleCard.name }}>
          {manacost ? (
            <Pop cutValue={16} sx={{ fontFamily: "gilroy", fontSize: ".8em", color: color }}>
              {name}
            </Pop>
          ) : (
            <p style={{ color: color }}>{name}</p>
          )}
        </p>
        <div style={{ ...styleCard.card, ...styleCard.manaCost }}>{manacost && manacost.map((item, index) => handleManatype(item, index))}</div>
        <p style={{ ...styleCard.card, ...styleCard.tipo }}>{type}</p>
        <p style={{ ...styleCard.card, ...styleCard.descricao }}>{text}</p>
        <p style={{ ...styleCard.card, ...styleCard.poder }}>
          {power && power}/{toughness && toughness}
        </p>
      </div>
    </section>
  );
}

export default Card;
