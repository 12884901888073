import { Pie } from "react-chartjs-2";

function RadarComponent({ name, dataProp }) {
  const labels = dataProp && Object.keys(dataProp);
  const getDataValues = labels.map((item) => {
    return dataProp[item];
  });
  const data = {
    labels: labels,
    datasets: [
      {
        data: getDataValues,
        borderColor: "rgb(122, 137, 219)",
        fill: true,
        backgroundColor: "rgba(122, 137, 219, 0.2)",
        pointBackgroundColor: "rgb(122, 137, 219)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(0, 0, 0, 0.2)",
          "rgba(50, 200, 50, 0.2)",
          "rgba(255, 255, 255, 0.2)",
          "rgba(140, 140, 140, 0.2)",
          "rgba(140, 240, 140, 0.2)",
        ],
        borderColor: [
          "rgb(54, 162, 235)",
          "rgb(255, 99, 132)",
          "rgb(80, 80, 80)",
          "rgb(50, 200, 50)",
          "rgb(255, 255, 255)",
          "rgb(140, 140, 140)",
          "rgb(140, 240, 140)",
        ],
      },
    ],
  };

  const config = {
    type: "Colors",
    data: data,
    layout: { autoPadding: true },
    plugins: {
      title: {
        align: "center",
        display: true,
        text: name,
        fullSize: true,
        font: { weight: "bold" },
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        display: true,
        align: "left",
        position: "right",
        labels: {
          boxWidth: 7,
          boxHeight: 7,
        },
      },
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
  return (
    <Pie
      style={{ maxHeight: "250px", maxWidth: "530px" }}
      height="300px"
      // width={width}
      data={data}
      options={config}
    />
  );
}

export default RadarComponent;
