import { Route, Redirect, useLocation, Switch } from "react-router-dom";
import LoadingPage from "../pageloading/pageloading";
import RotasTemporarias from "./RotasTemporarias";
import TourneyAdmin from "../../pages/EVO/tourneyAdmin";

// MOBILE IMPORTS
import MobileMenuApp from "../../mobile/navbar/MenuApp";
import MobileSideMenu from "../../mobile/navbar/SideNav";
import MobilePlayer from "../../mobile/player";
// import MobileStaker from "../../mobile/staker";
import Staker from "../../pages/staker";
import MobileRoom from "../../mobile/room";
import MobileDeck from "../../mobile/deck";
import MobileEvo from "../../mobile/evo";
import Welcome from "../../pages/welcome";
import SideMenu from "../navbar/SideNav";
import { useStore } from "../../context/storeContext";
import { useLocalDB } from "../../context/LocalDB";

function MobileRoutes() {
  const { user, evo } = useStore();
  const { mobile } = useLocalDB();

  if (!user) return <LoadingPage />;
  if (!mobile) return <RotasTemporarias />;

  if (evo) {
    return (
      <>
        {/* <Redirect from="*" to="/mobile/evo" /> */}
        <SideMenu />
        <MobileMenuApp />
        {/* <MobileSideMenu /> */}
        <div className="App">
          <Switch>
            <Route path="/mobile/evo" component={MobileEvo} />
            <Route path="/mobile/player" exact={true} component={MobilePlayer} />
            {/* <Route path="/mobile/staker" exact={true} component={MobileStaker} /> */}
            <Route path="/staker" component={Staker} />
            <Route path="/" exact={true} component={Welcome} />
            <Route path="/mobile/room/:id" exact={true} component={MobileRoom} />
            <Route path="/tourneyAdm/:id" component={TourneyAdmin} />
          </Switch>
        </div>
      </>
    );
  } else {
    return (
      <>
        {/* <Redirect from="*" to="/mobile/player" /> */}
        <MobileMenuApp />
        <SideMenu />
        {/* <MobileSideMenu /> */}
        <div className="App">
          <Switch>
            <Route path="/mobile/player" component={MobilePlayer} />
            <Route path="/" exact={true} component={Welcome} />
            <Route path="/staker" component={Staker} />
            <Route path="/mobile/room/:id" component={MobileRoom} />
            {/* <Route path="/mobile/deck" component={MobileDeck} /> */}
          </Switch>
        </div>
      </>
    );
  }
}

export default MobileRoutes;
