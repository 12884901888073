import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "../../context/storeContext";
import Doughnut from "../../components/deck/charts/Doughnut";
import BarChart from "../../components/deck/charts/BarChart";
import api from "../../services/api";
import "./EditDeck.scss";
import LoadingPage from "../../components/pageloading/pageloading";
import BtnContinuo from "../../components/buttons/BtnContinuo";
import RadarChart from "../../components/deck/charts/RadarChart";
import { Link } from "react-router-dom";
import { useLocalDB } from "../../context/LocalDB";
import images from "../../assets/img";
import Card from "../../components/slider/Card";

function DeckView() {
  const { id } = useParams();
  const { user } = useStore();
  const [deck, setDeck] = useState([]);
  const [sideBoard, setSideBoard] = useState([]);
  const [deckSize, setDeckSize] = useState({ deck: 0, sideBoard: 0 });
  const [preview, setPreview] = useState();
  const [previewPosition, setPreviewPosition] = useState({ x: 0, y: 0 });
  const [deckTypes, setDeckTypes] = useState([]);
  const [deckInfo, setDeckInfo] = useState();
  const cardRef = useRef();
  const [card, setCard] = useState();
  const { lang, setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const [deckOptions, setDeckOptions] = useState({
    cardsType: {
      Creature: 0,
      Instant: 0,
      Sorcery: 0,
      Artifact: 0,
      Enchantment: 0,
      Land: 0,
      Planeswalker: 0,
    },
    deckColors: {
      Blue: 0,
      Red: 0,
      Black: 0,
      Green: 0,
      White: 0,
      Colorless: 0,
    },
    manaCurve: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      "6+": 0,
    },
  });

  useEffect(() => {
    if (id) {
      const fechDeck = async () => {
        const getDeck = await api.get(`decks/${id}`).catch(console.log);
        if (getDeck) {
          const sortDeck = getDeck.data.deckCards.sort((a, b) => {
            if (a.card.cmc > b.card.cmc) return 1;
            if (a.card.cmc < b.card.cmc) return -1;
            return 0;
          });
          setDeck(sortDeck);
          const sortSide = getDeck.data.sideBoard.sort((a, b) => {
            if (a.card.cmc > b.card.cmc) return 1;
            if (a.card.cmc < b.card.cmc) return -1;
            return 0;
          });
          setSideBoard(sortSide);
          setDeckInfo(getDeck.data);
          const deckTypesData = () => {
            let Creature = [];
            let Instant = [];
            let Sorcery = [];
            let Artifact = [];
            let Enchantment = [];
            let Land = [];
            let Planeswalker = [];
            const deck = getDeck.data.deckCards;
            deck.map((item) => {
              if (item.card) {
                item.card.types.includes("Creature") && Creature.push(item);
                item.card.types.includes("Instant") && Instant.push(item);
                item.card.types.includes("Sorcery") && Sorcery.push(item);
                item.card.types.includes("Artifact") && Artifact.push(item);
                item.card.types.includes("Enchantment") && Enchantment.push(item);
                item.card.types.includes("Land") && Land.push(item);
                item.card.types.includes("Planeswalker") && Planeswalker.push(item);
              }
            });
            setDeckTypes({
              Creature,
              Instant,
              Sorcery,
              Artifact,
              Enchantment,
              Land,
              Planeswalker,
            });
          };
          deckTypesData();
        }
      };
      fechDeck();
    }
  }, [id]);

  const handleCardPreview = (item, e) => {
    setPreview(item === "card" ? "card" : item.card.imageUrl);
    const position = { x: e.target.x, y: e.target.y };
    setPreviewPosition(position);
  };

  const handleExportArena = async () => {
    const cutString = (arr, type) => {
      let txt = `${type}`;
      for (let i = 0; i < arr.length; i++) {
        txt += "\n" + `${arr[i].count} ${arr[i].name}`;
      }
      return txt;
    };
    const deckReturn = cutString(deck, "Deck");
    const sideReturn = cutString(sideBoard, "Sideboard");
    const companion = deckInfo.companion[0] ? cutString(deckInfo.companion, "Companion") : null;
    const commander = deckInfo.commander[0] ? cutString(deckInfo.commander, "Commander") : null;
    const getExportText = `${companion !== null ? companion + "\n\n" : ""}${commander ? commander + "\n\n" : ""}${deckReturn}\n\n${sideReturn}`;
    navigator.clipboard.writeText(getExportText);
    setAlert(true);
    setAlertMsg(lang === "pt" ? "Deck copiado para clipboard" : "Deck copied to clipboard");
    setSeverity("success");
  };

  useEffect(() => {
    if (deck) {
      const countDeckSize = deck.reduce((a, b) => {
        return +a + +b.count;
      }, 0);
      const countSideBoardSize = sideBoard.reduce((a, b) => {
        return +a + +b.count;
      }, 0);
      setDeckSize({ deck: countDeckSize, sideBoard: countSideBoardSize });
      let cmcTest = deckOptions.manaCurve;
      let deckColors = deckOptions.deckColors;
      let cardsType = deckOptions.cardsType;
      Object.keys(deckOptions.manaCurve).map((item) => {
        deck.filter((itemDeck) => {
          if (+itemDeck?.card?.cmc === +item && item != "6+") {
            cmcTest[item] = cmcTest[item] + 1 * itemDeck.count;
          }
          if (+itemDeck?.card?.cmc >= 6) {
            cmcTest["6+"] = cmcTest["6+"] + 1 * itemDeck.count;
          }
        });
      }, []);
      Object.keys(deckOptions.deckColors).map((item, index) => {
        deck.filter((itemDeck) => {
          if (itemDeck?.card?.colors != undefined) {
            if (itemDeck?.card?.colors?.includes(item)) {
              deckColors[item] = deckColors[item] + 1 * itemDeck.count;
            }
          } else if (index === 0) {
            if (itemDeck?.card?.types.includes("Artifact")) {
              deckColors["Colorless"] = deckColors["Colorless"] + 1 * itemDeck.count;
            }
          }
        });
      }, []);
      Object.keys(deckOptions.cardsType).map((item) => {
        deck.filter((itemDeck) => {
          if (itemDeck?.card?.types.includes(item)) {
            cardsType[item] = cardsType[item] + 1 * itemDeck.count;
          }
        });
      }, []);
      setDeckOptions({
        ...deckOptions,
        manaCurve: cmcTest,
        deckColors: deckColors,
        cardsType: cardsType,
      });
    }
  }, [deckInfo]);

  if (!deckInfo || !deck) return <LoadingPage />;
  return (
    <div className="editDeckDeckPage">
      <div className="LeftSideDeckPage">
        <div className="listDeckPage">
          <div className="listDeck-Deck">
            <h3
              style={{
                textAlign: "center",
                background: "black",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                width: "250px",
                marginBottom: "-10px",
                marginTop: "10px",
              }}
            >
              Deck
            </h3>
            {deck &&
              deck.map((item, index) => {
                return (
                  <div className="listDeckPage-item">
                    {item.card.imageUrl ? (
                      <div style={{ position: "relative" }}>
                        <img
                          className="imageDeckPage"
                          style={
                            index === 0
                              ? {
                                  borderTopLeftRadius: "0px",
                                  borderTopRightRadius: "0px",
                                  background: "black",
                                  marginBottom: deck.length < 10 ? "-260px" : deck.length < 20 ? "-275px" : "-315px",
                                }
                              : {
                                  marginBottom: deck.length < 10 ? "-260px" : deck.length < 20 ? "-275px" : "-315px",
                                }
                          }
                          src={item.card.imageUrl}
                          onMouseEnter={(e) => {
                            handleCardPreview(item, e);
                          }}
                          onMouseLeave={() => {
                            setPreview();
                          }}
                          key={`cardID/${item.card.id}`}
                          alt="cardImage"
                          ref={cardRef}
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            fontFamily: "gilroy-bold",
                            background: "var(--purple)",
                            padding: "2px",
                            borderRadius: "50%",
                          }}
                        >
                          x{item.count}
                        </span>
                      </div>
                    ) : (
                      <div
                        className="imageDeckPage"
                        style={{ position: "relative" }}
                        onMouseEnter={(e) => {
                          handleCardPreview("card", e);
                          setCard(item);
                        }}
                        onMouseLeave={() => {
                          setPreview();
                          setCard();
                        }}
                      >
                        <Card item={item} sx={{ width: "250px", height: "350px", borderRadius: "15px" }} color="black" />
                        <span
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            fontFamily: "gilroy-bold",
                            background: "var(--purple)",
                            padding: "2px",
                            borderRadius: "50%",
                          }}
                        >
                          x{item.count}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="listDeckPage-Side">
            {sideBoard.length > 0 && (
              <div>
                <h3
                  style={{
                    textAlign: "center",
                    background: "black",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                >
                  SideBoard
                </h3>
                {sideBoard.map((item, index) => {
                  return (
                    <div style={{ position: "relative" }}>
                      {item.card.imageUrl ? (
                        <img
                          className="imageDeckPage"
                          src={item.card.imageUrl}
                          style={{
                            marginBottom: "-315px",
                            width: "250px",
                            borderRadius: "15px",
                            borderTopLeftRadius: index === 0 ? "0" : "15px",
                            borderTopRightRadius: index === 0 ? "0" : "15px",
                            background: index === 0 ? "black" : "transparent",
                          }}
                          onMouseEnter={(e) => {
                            handleCardPreview(item, e);
                          }}
                          onMouseLeave={() => {
                            setPreview();
                          }}
                          key={`cardSideBoard/${item.card.id}`}
                          alt="cardImage"
                          ref={cardRef}
                        />
                      ) : (
                        <div
                          className="imageDeckPage"
                          style={{
                            position: "relative",
                            marginBottom: "-315px",
                            width: "250px",
                            borderRadius: "15px",
                            borderTopLeftRadius: index === 0 ? "0" : "15px",
                            borderTopRightRadius: index === 0 ? "0" : "15px",
                            background: index === 0 ? "black" : "transparent",
                          }}
                        >
                          {/* <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            textAlign: "center",
                            zIndex: 2,
                            top: "15px",
                            fontSize: "1.2rem",
                            fontFamily: "gilroy-bold",
                          }}
                        >
                          {item.card.name}
                        </div> */}
                          <Card item={item} sx={{ width: "250px", height: "350px", borderRadius: "15px" }} color="black" />
                          {/* <img
                          style={{
                            background: "black",
                            height: "350px",
                            borderRadius: "15px",
                          }}
                          src="https://i.redd.it/qnnotlcehu731.jpg"
                          key={`cardID/${item.card.id}`}
                          alt="cardImage"
                          ref={cardRef}
                        /> */}
                          <span
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              fontFamily: "gilroy-bold",
                              background: "var(--purple)",
                              padding: "2px",
                              borderRadius: "50%",
                            }}
                          >
                            x{item.count}
                          </span>
                        </div>
                      )}
                      <span
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          fontFamily: "gilroy-bold",
                          background: "var(--purple)",
                          padding: "2px",
                          borderRadius: "50%",
                        }}
                      >
                        x{item.count}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <img
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "280px",
            opacity: 0.4,
          }}
          src={images.branca}
          alt="equalsLogo"
        />
      </div>

      <div className="RightSideDeckPage">
        {deckInfo.commander.length > 0 && deckInfo.commander[0] ? (
          <h3
            style={{
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translateX(-50%)",
              top: "2rem",
              fontFamily: "gilroy-bold",
              color: "var(--pink)",
            }}
          >
            Commander: {deckInfo.commander[0].card.name}
          </h3>
        ) : null}
        {deckInfo.companion.length > 0 && deckInfo.companion[0] ? (
          <h3
            style={{
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translateX(-50%)",
              top: "2rem",
              fontFamily: "gilroy-bold",
              color: "var(--pink)",
            }}
          >
            Companion: {deckInfo.companion[0].card.name}
          </h3>
        ) : null}
        <div className="rightDeckCardInfo" style={{ position: "relative" }}>
          <h3>Deck Name: {deckInfo.deckName}</h3>
          <h3>Archetype: {deckInfo.deckType.toUpperCase()}</h3>
          <h3>Deck Size: {deckSize.deck}</h3>
          <h3>SideBoard Size: {deckSize.sideBoard}</h3>
          <h3>Format: {deckInfo.format}</h3>
          <BtnContinuo
            style={{
              position: "absolute",
              top: "50%",
              right: "1rem",
              transform: "translateY(-50%)",
            }}
            onClick={handleExportArena}
          >
            {lang === "pt" ? "Exportar" : "Export"}
          </BtnContinuo>
        </div>

        <section className="DisplayCards">
          <div className="rightDeckCardPage">{id && <Doughnut dataProp={deckOptions.deckColors} />}</div>
          <div className="rightDeckCardPage">{id && <BarChart name="Mana Curve" dataProp={deckOptions.manaCurve} />}</div>
          <div className="rightDeckCardPage">{id && <RadarChart name="Types" dataProp={deckOptions.cardsType} />}</div>
        </section>
      </div>
      {preview ? (
        preview === "card" ? (
          <div style={{ pointerEvents: "none", position: "absolute", top: previewPosition.y, left: previewPosition.x, width: "250px", height: "350px" }}>
            <Card item={card} sx={{ width: "250px", height: "350px", borderRadius: "15px" }} color="black" />
          </div>
        ) : (
          <img style={{ zIndex: 1 }} className="cardPreview" style={{ top: previewPosition.y, left: previewPosition.x }} src={preview} alt="previewImage" />
        )
      ) : null}
    </div>
  );
}

export default DeckView;
