import { Doughnut } from "react-chartjs-2";

function DoughnutComponent({ dataProp }) {
  const labels = dataProp && Object.keys(dataProp);
  const getDataValues = labels.map((item) => {
    return dataProp[item];
  });
  const data = {
    labels: labels,

    datasets: [
      {
        label: "CardsTypes",
        data: getDataValues,
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(0, 0, 0, 0.2)",
          "rgba(50, 200, 50, 0.2)",
          "rgba(255, 255, 255, 0.2)",
          "rgba(140, 140, 140, 0.2)",
        ],
        borderColor: [
          "rgb(54, 162, 235)",
          "rgb(255, 99, 132)",
          "rgb(80, 80, 80)",
          "rgb(50, 200, 50)",
          "rgb(255, 255, 255)",
          "rgb(140, 140, 140)",
        ],
        borderWidth: 1,
        hoverOffset: 6,
      },
    ],
  };

  const config = {
    type: "Colors",
    data: data,
    layout: { autoPadding: true, padding: 3 },
    plugins: {
      title: {
        align: "center",
        display: true,
        text: "Color",
        fullSize: true,
        font: { weight: "bold" },
      },
      legend: {
        display: true,
        align: "left",
        position: "right",
        labels: {
          boxWidth: 7,
          boxHeight: 7,
        },
      },
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
  return (
    <Doughnut
      style={{ maxHeight: "250px", maxWidth: "250px" }}
      height="300px"
      width="300px"
      data={data}
      options={config}
    />
  );
}

export default DoughnutComponent;
