import { useState, useEffect } from "react";
import { useWallet } from "../context/walletContext";
import testeValues from "./testeValues";

const Teste = () => {
  const { setPlayersDistrib, setStakersDistrib } = useWallet();
  const [corte, setCorte] = useState(4);
  const [valor, setValor] = useState(0);
  const [allStakers, setAllStakers] = useState([]);
  const [dataCalc, setDataCalc] = useState({
    tax: { evo: 0, equals9: 0 },
    distribution: 0,
    distWinners: 0,
    distStaker: 0,
  });
  const [winnersResult, setWinnerResult] = useState([]);

  useEffect(() => {
    setWinnerResult(testeValues[`corte${corte}`].jogadores);
    const winResultMAP = winnersResult.map((item) => {
      return setAllStakers((stakers) => [...stakers, ...item.stakers]);
    }, []);
    return winResultMAP;
  }, [corte]);

  const calculoTeste = (valor) => {
    let winners = winnersResult;
    let stakers = allStakers;
    const Tax = valor * 0.03;
    const Dist = valor - Tax;
    const jogadoresValue = parseInt(Dist * 0.7);
    const ApoiadoresValue = parseInt(Dist - jogadoresValue);

    const getPeso = (total, final) => Array.from({ length: total + 1 - final }, (_, i) => total - i * 1);

    const peso = getPeso(corte, 1);
    const getTotalValue = peso.reduce((a, b) => {
      return a + b;
    }, 0);
    const pesoMin = 100 / getTotalValue;
    const divisao = peso.map((item) => {
      return (jogadoresValue * (item * pesoMin)) / 100;
    });

    // ANCHOR WINNERS

    const percentualCorte = divisao;
    const vencedoresResult = winners.map((item, index) => {
      return {
        ...item,
        reward: parseInt(percentualCorte[index]),
      };
    });

    const quantidade = ApoiadoresValue * 0.6;
    const tempo = ApoiadoresValue * 0.4;

    // ANCHOR STAKERS

    const totalValueRes = stakers.reduce((a, b) => {
      return a + b.value;
    }, 0);

    const totalDays = stakers.reduce((a, b) => {
      return a + b.days;
    }, 0);
    const MediaQnt = quantidade / totalValueRes;

    const totalMediaDays = tempo / totalDays;

    const StakersDistribution = stakers.map((staker) => {
      return {
        ...staker,
        reward: {
          ...staker.reward,
          qnt: parseInt(staker.value * MediaQnt),
          time: parseInt(staker.days * totalMediaDays),
        },
      };
    });
    setAllStakers(StakersDistribution);
    setDataCalc({
      tax: { evo: Tax / 2, equals9: Tax / 2 },
      distribution: Dist,
      distWinners: jogadoresValue,
      distStaker: ApoiadoresValue,
    });
    setWinnerResult(vencedoresResult);
  };
  useEffect(() => {
    const getAllStakersSum = allStakers.reduce((acc, item) => {
      return acc + item.reward.qnt;
    }, 0);
    const getAllStakersTime = allStakers.reduce((acc, item) => {
      return acc + item.reward.time;
    }, 0);
    const getAllWinnersResults = winnersResult.reduce((acc, item) => {
      return acc + item.reward;
    }, 0);
    const getTaxSum = dataCalc.tax.evo + dataCalc.tax.equals9;
    const sum = getAllStakersSum + getAllStakersTime + getAllWinnersResults + getTaxSum;
  }, [winnersResult]);

  return (
    <div style={{ margin: "20px" }}>
      {Object.keys(dataCalc).map((item) => {
        if (item !== "tax") {
          return (
            <h1>
              {item}: {dataCalc[item].toString()}
            </h1>
          );
        } else {
          return (
            <h1>
              {item}: evo: {dataCalc[item].evo.toString()}, equals9: {dataCalc[item].equals9.toString()}
            </h1>
          );
        }
      })}

      <select className="default-input" defaultValue={corte} onChange={(e) => setCorte(+e.target.value)}>
        <option value={4}>4</option>
        <option value={8}>8</option>
      </select>
      <input className="default-input" value={valor} onChange={(e) => setValor(e.target.value)} />
      <button className="default-button" onClick={() => calculoTeste(valor)}>
        Calcular
      </button>
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "50%" }}>
          <table>
            <thead>
              <tr>
                <td>Nome</td>
                <td>Reward</td>
              </tr>
            </thead>
            {winnersResult.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.reward}</td>
                </tr>
              );
            })}
          </table>
        </div>
        <div style={{ width: "50%" }}>
          {allStakers && (
            <table>
              <thead>
                <tr>
                  <td>Nome</td>
                  <td>Reward Quantidade</td>
                  <td>Reward Tempo</td>
                </tr>
              </thead>
              {allStakers.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.reward.qnt}</td>
                    <td>{item.reward.time}</td>
                  </tr>
                );
              })}
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Teste;
