import api from "./api";

const rotas = {
  user: "users/",
  auth: "users/login",
  pass: "change-password/",
  tournament: "tournaments/",
};
const Service = {
  // ANCHOR Players ============================================== //
  GetAllPlayer: async () => {
    let GetAll = await api.get(rotas.user);
    return GetAll.data;
  },
  GetPlayer: async (id) => {
    let getByID = await api.get(`users/${id}`);
    return getByID.data;
  },

  // ========================================================= //

  // ANCHOR Tournament ============================================== //

  GetTournamentById: async (id) => {
    let getOneTornament = await api.get(`${rotas.tournament}${id}`);
    return getOneTornament.data;
  },

  GetAllTournaments: async () => {
    let getAll = await api.get(`${rotas.tournament}`);
    return getAll.data;
  },

  AddTournament: async (data) => {
    let addTornament = await api.post(`${rotas.tournament}`, data);
    return addTornament;
  },

  DeleteTournament: async (id) => {
    let delTornament = await api.delete(`${rotas.tournament}${id}`);
    return delTornament;
  },

  // ========================================================= //

  // ANCHOR Login =================================================== //

  AddUser: async (props) => {
    let adduser = await api.post(`${rotas.user}`, props);
    return adduser;
  },

  ChangePassword: async (props) => {
    let ChangePass = await api.post(`${rotas.pass}`, props);
    return ChangePass;
  },

  AuthUser: async (props) => {
    let Auth = await api.post(`${rotas.auth}`, props);
    return Auth;
  },

  PutUser: async (id, data) => {
    let PutUser = await api.put(`${rotas.user}${id}`, data);
    return PutUser;
  },

  PatchUser: async (id, data) => {
    let PatchUser = await api.patch(`${rotas.user}${id}`, data);
    return PatchUser;
  },

  // TODO ADICIONAR RECUPERAÇÃO DE SENHA

  DeleteUser: async (id) => {
    let deleteUser = await api.delete(`${rotas.user}${id}`);
    return deleteUser;
  },

  // ========================================================= //

  // ANCHOR Wallet ================================================== //

  AddWallet: async () => {},

  // ========================================================= //
};

export default Service;
