import { useEffect, useRef, useState } from "react";
import { useSocket } from "../../context/webSocket";

function Chat({ id, messageTo, chatList, evoChatList, chatInputRef, handleSubmitMsg, user, player2, player2Bye, tournament, notifications }) {
  const { socket } = useSocket();
  const chatscrollref = useRef(null);
  let chatReturnList = messageTo === "ALL" ? chatList : messageTo === "EVO" ? evoChatList : null;
  let chatListData = chatReturnList ? chatReturnList : [];

  useEffect(() => {
    if (chatListData) {
      if (chatscrollref.current.scrollHeight > chatscrollref.current.offsetHeight) {
        chatscrollref.current.scrollTop = chatscrollref.current.scrollHeight - chatscrollref.current.offsetHeight;
      }
    }
  }, [chatListData]);

  useEffect(() => {
    if (player2) {
      if (messageTo === "ALL") {
        socket.emit(`joinChatRoom`, { userId: player2?._id });
      }
    }
    if (tournament) {
      if (messageTo === "EVO") {
        socket.emit(`joinChatRoom`, { userId: tournament?.organizer?.owner });
      }
    }
  }, [messageTo, player2, tournament]);

  useEffect(() => {
    if (messageTo) {
      messageTo === "ALL" && socket.emit("wipe", { userId: player2?._id });
      messageTo === "EVO" && socket.emit("wipe", { userId: tournament?.organizer?.owner });
    }
  }, [messageTo]);

  if (!chatListData) return null;

  return (
    <>
      <div className="room-page-container3-chat-messages" ref={chatscrollref}>
        {chatListData?.map((item) => {
          return (
            <div key={item.body}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  paddingRight: "5px",
                  flexDirection: "row",
                  justifyContent: item.author === user._id ? "flex-start" : "flex-end",
                }}
              >
                {item.author !== user._id ? (
                  <div className="chat-box-vs animate__animated animate__fadeInRight animate__faster">
                    <div style={{ color: "rgb(200,0,200)" }}>
                      {messageTo === "ALL" ? player2?.nickname : messageTo === "EVO" ? tournament?.organizer?.name : null}
                    </div>
                    <div style={{ whiteSpace: "wrap", wordWrap: "break-word", userSelect: "text" }}>{item.body}</div>
                  </div>
                ) : (
                  <div className="chat-box-author animate__animated animate__fadeInLeft animate__faster">
                    <div
                      style={{
                        whiteSpace: "wrap",
                        wordWrap: "break-word",
                        userSelect: "text",
                      }}
                    >
                      {item.body}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <form
        onSubmit={(e) => {
          handleSubmitMsg(e);
          if (messageTo === "ALL") {
            socket.emit(`wipe`, { userId: player2?._id });
          }
          if (messageTo === "EVO") {
            socket.emit(`wipe`, { userId: tournament?.organizer?.owner });
          }
        }}
      >
        <input type="text" placeholder="Digite aqui..." className="room-page-container3-chat-input" ref={chatInputRef} />
      </form>
    </>
  );
}

export default Chat;
