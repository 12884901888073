import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import images from "../../assets/img";
import { Link } from "react-router-dom";
import { useStore } from "../../context/storeContext";
import SwiperCore, { Keyboard, EffectCoverflow, Pagination, Navigation } from "swiper";
import language from "../../assets/language/text";
import { useLocalDB } from "../../context/LocalDB";
import sound from "../../assets/sounds/index";
import LoadingPage from "../pageloading/pageloading";
import NumberFromWei from "../../utils/fromWei";

SwiperCore.use([Keyboard, EffectCoverflow, Pagination, Navigation]);
const click = sound.btn;

function Carousel({ data, loop }) {
  const { user } = useStore();
  const { lang } = useLocalDB();
  // const [rewardAmount, setRewardAmount] = useState();

  const text = language[lang].carousel;

  const GetDate = (date) => {
    if (date) {
      const getDateString = date.split("T", 1);
      const getRealDateSplited = getDateString[0].split("-");
      const getDateServer = new Date(getRealDateSplited[1] + "/" + getRealDateSplited[2] + "/" + getRealDateSplited[0]);
      // const getDateLocal = new Date(getRealDateSplited[2] + "/" + getRealDateSplited[1] + "/" + getRealDateSplited[0]);
      const getServer = process.env.REACT_APP_APP_ENV;
      return lang === "pt"
        ? getServer === "mainet"
          ? getDateServer.toLocaleDateString("pt-br")
          : getDateServer.toLocaleDateString("pt-br")
        : getServer === "mainet"
        ? getDateServer.toLocaleDateString("en-us")
        : getDateServer.toLocaleDateString("en-us");
    }
  };

  function confirmIncr(tourney) {
    if (tourney) {
      const confirm = tourney.players.filter((item) => {
        if (item.player === user._id) {
          return true;
        }
      });
      if (confirm.length > 0) {
        return (
          <div className="slider_sub">
            <i className="fas fa-clipboard-check"></i>
            {text[2]}
          </div>
        );
      } else {
        return (
          <div className="slider_sub">
            <i className="fas fa-clipboard-check"></i>
            {NumberFromWei(tourney.subscription)} EQ9
          </div>
        );
      }
    }
  }

  if (!data.length > 0) return <div>{lang === "pt" ? "NENHUM TORNEIO ENCONTRADO..." : "NO TOURNAMENT FOUND..."}</div>;

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#2ac1d8",
          "--swiper-pagination-color": "#2ac1d8",
        }}
        keyboard={{ enabled: true }}
        effect={"coverflow"}
        centeredSlides={true}
        slidesPerView={"auto"}
        // loop={true}
        loopFillGroupWithBlank={true}
        coverflowEffect={{
          rotate: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{ clickable: true }}
        navigation={true}
        id="main"
        className="main-swiper"
      >
        {data.map((item, key) => {
          return (
            <SwiperSlide className="carousel-card" key={`swiper-${key}`}>
              <Link
                to={item.status !== "ENDED" ? `event/${item._id}` : `badges/${item._id}`}
                onClick={() => {
                  click.currentTime = 0;
                  click.play();
                }}
              >
                {/* <div className="carousel-card"> */}
                <div className="carousel-card-title">
                  <span style={{ fontFamily: "gilroy-bold", color: "var(--pink)" }}>
                    {item.game.typeOf === "MOL" ? "MAGIC ONLINE" : item.game.typeOf} - {item.game.format}
                  </span>
                  <h2>{item.name}</h2>
                </div>
                <footer className="carousel-card-content">
                  <div className="carousel-card-content-data">
                    <div className="carousel-card-content-data-box">
                      <b>{GetDate(item.startDate)}</b>
                      <b>{item.hour}</b>
                    </div>
                    <span>{confirmIncr(item)}</span>
                  </div>
                  <div className="carousel-card-content-line" />
                  <div className="carousel-card-content-rewards">
                    <span>
                      <i class="fas fa-users"></i> {item.playerCount}
                    </span>
                    <span>
                      <i class="fas fa-trophy"></i>
                      {item.reward.typeOf === "EQ9" ? NumberFromWei(item.reward.amount.toString()) + "EQ9" : null}
                    </span>
                  </div>
                </footer>
                <div className="carousel-card-logo" style={{ overflow: "hidden" }}>
                  {item.img ? <img src={item.img} alt="logo loja" /> : <img src={images.hiveLogo} alt="logo loja" />}
                </div>
                {/* </div> */}
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default Carousel;
