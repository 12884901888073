import unstake from "../sounds/ogg/stake2.ogg";
import btnContinuous from "../sounds/ogg/continuousBtn4.ogg";
import BtnEventCreate1 from "../sounds/ogg/BtnEventCreate1.ogg";
import BtnEventCreate2 from "../sounds/ogg/BtnEventCreate2.ogg";
import BtnEventCreate3 from "../sounds/ogg/BtnEventCreate3.ogg";
import BtnEventCreate4 from "../sounds/ogg/BtnEventCreate4.ogg";
import EventCreate from "../sounds/ogg/eventCreate1.ogg";
import msg from "../sounds/ogg/msg3.ogg";
import coin1 from "../sounds/ogg/coin1.ogg";
import coin2 from "../sounds/ogg/coin2.ogg";
import screenPass from "../sounds/ogg/ScreenPass4.ogg";
import slideCard from "../sounds/ogg/SlideCard1.ogg";
import StadiumSound from "../sounds/ogg/StadiumSound2.ogg";
import newPairing from "../sounds/ogg/newPairing.ogg";
import newStaking from "../sounds/ogg/voz2.ogg";
import HallOfFame from "../sounds/ogg/stadium3Fadeout.ogg";
import NewMessage from "../sounds/ogg/newMessage.ogg";

const sounds = {
  btn: new Audio(slideCard),
  unstake: new Audio(unstake),
  slide: new Audio(BtnEventCreate4),
  slideCard: new Audio(slideCard),
  screenPass: new Audio(screenPass),
  coin1: new Audio(coin1),
  coin2: new Audio(coin2),
  msg: new Audio(msg),
  msg2: new Audio(BtnEventCreate3),
  btnContinuous: new Audio(btnContinuous),
  BtnEventCreate1: new Audio(BtnEventCreate1),
  BtnEventCreate2: new Audio(BtnEventCreate2),
  BtnEventCreate3: new Audio(BtnEventCreate3),
  BtnEventCreate4: new Audio(BtnEventCreate4),
  EventCreate: new Audio(EventCreate),
  StadiumSound: new Audio(StadiumSound),
  newPairing: new Audio(newPairing),
  newStaking: new Audio(newStaking),
  HallOfFame: new Audio(HallOfFame),
  NewMessage: new Audio(NewMessage),
};
export default sounds;
