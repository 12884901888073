import { Deck } from "@material-ui/icons";

const text = {
  en: {
    coming: ["COMING SOON!"],
    nav: ["Associate your wallet by clicking here", "BALANCE", "ENTER"],
    w_page: {
      title: ["WELCOME TO", "STADIUM"],
      button: ["PLAYER", "STAKER", "EVENT ORGANIZER"],
      middle_text: ["YOU CAN BE THE NEXT CHAMPION", "EQUALSSPORT ARENA/2022"],
    },
    wallet_page: {
      balance: ["BALANCE"],
      history: ["PURCHASE TRANSACTIONS"],
      buttons: ["BUY EQ9", "TRANSFER EQ9", "PRODUCTS", "EXIT WALLET", "WAITING WALLET CONFIRM...", "CONNECT YOUR WALLET"],
      wallet_transfer: ["WALLET ADDRESS TO TRANSFER", "VALUE", "CONFIRM TRANSFER", "TRANSFER EQ9", "CLICK HERE TO GET PLAYER WALLET"],
      tronlink: ["CONNECT TRONLINK", "TRONLINK IS INSTALLED, BUT REQUIRE LOGIN TO CONTINUE. BEFORE IT, REFRESH THE PAGE."],
      list: ["PURCHASE TRANSACTIONS"],
    },
    counter: ["TEMPO RESTANTE", "DIAS", "HORAS", "MINUTOS"],
    torn_page: ["ROUND DESCRIPTION", "REGISTERED"],
    store_page: {},
    staker_page: {
      topModalRight: ["MY REWARDS", "EQ9 STAKED", "STAKE NOW!"],
      tableData: ["MY CHAMPIONS", "NICKNAME", "EQ9 STAKED", "REWARDED", "STAKE", "UNSTAKE"],
    },
    filter_input: {},
    room_page: ["CONFIRM", "CONFIRMED", "ROUND", "FINISHED ROUND, WAITING FOR THE NEXT OPPONENT...", "RESULT SUCCESSFULY SUBMITED!", "LEAVE"],
    standingsRoom: ["PLAYERS", "NEXT ROUND", "STANDINGS"],
    ranking_page: {},
    p_half_page: {},
    player_page: ["FIND NEW TOURNAMENTS", "BUILD", "ENTER", "YOUR ACHIEVEMENTS", "YOU DON'T HAVE ANY ACHIEVEMENTS YET", "NOT ENGAGED IN ANY TOURNAMENTS YET"],
    myStaker_page: {},
    login_page: [
      "SIGN UP",
      "SIGN IN",
      "E-mail",
      "Password",
      "Password Confirm",
      "Nickname",
      "I DECLARE THAT I AGREE WITH THE",
      "PRIVATE POLICY.",
      "REMEMBER ME",
      "FORGOT YOUR PASSWORD?",
      "LOGIN WITH GOOGLE",
      "LOGIN",
      "CREATE ACCOUNT",
      "INVALID PASSWORD OR E-MAIL!",
      "HELLO",
      "WELCOME TO EQUALS SPORTS!",
      "LOADING...",
      "EXISTING E-MAIL OR NICKNAME!",
      "SIGN IN TO CONTINUE",
      "SUCCESS",
      "ACCEPT TERMS TO CONTINUE",
      "ACCEPT TERMS AND CONDITIONS TO CONTINUE",
      "PASSWORDS DOESN'T MATCH",
      "ENTER PASSWORD AGAIN TO CONTINUE.",
      "YOUR ACCOUNT WAS SUCCESSFULLY CREATED! REDIRECTING...",
      "I HAVE AN ACCOUNT",
      "SYNCHRONIZATION WITH METAMASK ONGOING...",
      "METAMASK WALLET REQUIRED",
      "CLICK HERE TO INSTALL",
      "CONNECT YOUR WALLET",
      "CONNECTING...",
      "CONNECTED ON METAMASK, CLICK BELLOW TO:",
      "SWITCH TO HARMONY NETWORK",
      "ADD EQ9 TOKEN ADDRESS TO YOUR WALLET",
    ],
    hof_page: ["RANKING SEARCH"],
    evo_solic_page: {},
    evo_page: ["ANY TOURNAMENTS CREATED YET", "RULES", "CONTACT", "RANKINGS", "PRODUCTS"],
    events_page: [
      "YOU NEED TO BUILD A DECK ON",
      "FORMAT TO CONTINUE",
      "ANY DECK REGISTERED",
      "REGISTER ON",
      "SUCCESSFULY REGISTERED ON",
      "INSUFICIENT EQ9 TO CONTINUE",
      "EVENT REGISTRATION",
      "YOU ARE ABOUT TO REGISTER FOR THE EVENT, CONFIRM THE INFORMATION TO CONTINUE",
      "WALLET",
      "WALLET BALANCE",
      "REGISTER VALUE",
      "BALANCE AFTER REGISTER",
      "CANCEL",
      "CONFIRM",
      "REGISTERED",
      "REGISTER",
      "REWARD",
      "CALENDAR",
      "RULES",
      "E-MAIL",
      "STANDINGS",
      "ROUNDS",
      "THIS AREA WILL BE OPEN WHEN THE TOURNAMENT STARTS",
      "REWARD",
      "PLAYERS",
      "STAKERS",
      "WINNERS",
      "FOR AMOUNT",
      "FOR TIME",
      "PLACE",
      "REWARD",
      "RULES",
      "PARTICIPANTS",
      "CONTACT",
      "TOTAL REWARDS",
    ],
    deckadd_page: {},
    badges_page: ["HALL OF FAME", "JOIN A TOURNAMENT TO CONQUER YOUR REWARD"],
    addDeck: [
      "CONFIRM",
      "SIDEBOARD",
      "DECK SUCCESSFULY ADDED!",
      "DECK NAME REQUIRED TO CONTINUE",
      "INVALID FORMAT",
      "SEARCH CARD",
      "DECK NAME",
      "CONFIRM",
      "CONFIRM",
    ],
    addEvo: ["CANCEL", "CONFIRM"],
    addPlayer: ["CANCEL", "CONFIRM"],
    addTournament: [
      "Successfuly created!",
      "Tournament name already exists",
      "TOURNAMENT NAME",
      "INITIAL DATE",
      "START TIME",
      "DESCRIPTION",
      "SUBSCRIPTION VALUE",
      "STARTER REWARD",
      "START TIME",
    ],
    sideNav: [
      "HELLO",
      "WELCOME",
      "LOGOUT",
      "BALANCE",
      "HOME",
      "TOURNAMENTS",
      "MY STAKERS",
      "SEASON ONE",
      "HALL OF FAME",
      "STORE",
      "CREATE EVENT",
      "CONNECTED AS",
      "SWITCH",
    ],
    config: ["VOLUME", "LANGUAGE"],
    rankingProfile: [
      "VALUE TO BE STAKED ON",
      "CANCEL",
      "STAKE",
      "ADD A VALUE",
      "TOTAL STAKED VALUE",
      "UNSTAKED",
      "SUCCESSFULLY",
      "ADD A VALID VALUE",
      "STAKED",
      "UNSTAKED ON",
      "ON YOU",
      "STAKED ON",
      "ON",
      "UNSTAKE",
    ],
    startEvent: ["START EVENT"],
    graphic: ["STAKERS AMOUNT"],
    profile: [
      "IMPORT PROCESS CANCELLED",
      "IMPOSSIBLE TO READ THE FILE",
      "SUCCESSFULLY MODIFIED PROFILE IMAGE!",
      "LARGE IMAGE SIZE, TRY A SMALLEST SIZE ",
      "NAME",
      "ASSOCIATED TO:",
    ],
    table: ["NO CHAMPIONS YET", "SEARCH CHAMPION"],
    messages: ["(CLICK HERE)", "SYSTEM: ANY REGISTERED MESSAGES YET."],
    friendMsg: ["FRIEND SOLICITATION", "SEARCH FOR NICKNAME", "ONLINE", "OFFLINE", "AWAY"],
    carousel: ["ROUND DESCRIPTION", "ROUND", "REGISTERED"],
  },
  pt: {
    coming: ["EM BREVE!"],
    nav: ["Associe sua carteira clicando aqui.", "SALDO", "ENTRAR"],
    w_page: {
      title: ["BEM VINDO AO", "STADIUM"],
      button: ["PLAYER", "STAKER", "EVENT ORGANIZER"],
      middle_text: ["VOCÊ PODE SER O PRÓXIMO CAMPEÃO", "EQUALSSPORT ARENA/2022"],
    },
    wallet_page: {
      balance: ["SALDO"],
      history: ["HISTÓRICO DE COMPRA"],
      buttons: ["COMPRAR EQ9", "TRANSFERIR EQ9", "PRODUTOS", "SAIR DA CARTEIRA", "AGUARDANDO CONFIRMAÇÃO...", "CONECTAR CARTEIRA"],
      wallet_transfer: ["ENDEREÇO DA CARTEIRA PARA TRANSFERIR", "VALOR", "CONFIRMAR TRANSFERÊNCIA", "TRANSFERIR EQ9", "CLIQUE AQUI PARA PROCURAR PELO PLAYER"],
      tronlink: ["CONECTAR TRONLINK", "TRONLINK ESTÁ INSTALADA PORÉM PRECISA LOGAR PARA CONTINUAR, ATUALIZE A PÁGINA APÓS LOGAR."],
      list: ["HISTÓRICO DE COMPRA"],
    },
    counter: ["TIME LEFT", "DAYS", "HOURS", "MINUTES"],
    torn_page: ["DESCRIÇÃO DA ETAPA", "INSCRITO"],
    store_page: {},
    staker_page: {
      topModalRight: ["MINHAS RECOMPENSAS", "STAKED", "APOIE AGORA!"],
      tableData: ["MEUS CAMPEÕES", "NICKNAME", "EQ9 STAKED", "RECOMPENSAS", "STAKE", "UNSTAKE"],
    },
    filter_input: {},
    room_page: ["CONFIRMAR", "CONFIRMADO", "ROUND", "ROUND FINALIZADO, AGUARDANDO PRÓXIMO OPONENTE...", "RESULTADO SUBMETIDO COM SUCESSO!", "SAIR"],
    standingsRoom: ["JOGADORES", "PROXIMA RODADA", "STANDINGS"],
    ranking_page: {},
    p_half_page: {},
    player_page: [
      "ENCONTRE NOVOS TORNEIOS",
      "CRIAR",
      "ENTRAR",
      "SUAS RECOMPENSAS",
      "VOCê NÃO POSSUI NENHUMA RECOMPENSA",
      "AINDA NÃO POSSUI INSCRIÇÃO EM TORNEIOS",
    ],
    myStaker_page: {},
    login_page: [
      "REGISTRAR",
      "ENTRAR",
      "Usuário",
      "Senha",
      "Confirmar Senha",
      "Nickname",
      "EU DECLARO QUE CONCORDO COM OS ",
      "TERMOS E CONDIÇÕES.",
      "LEMBRAR DE MIM",
      "ESQUECEU A SENHA?",
      "ENTRAR COM O GOOGLE",
      "ENTRAR",
      "CRIAR CONTA",
      "E-MAIL OU SENHA INVÁLIDOS!",
      "OLÁ",
      "BEM-VINDO AO EQUALSSPORT!",
      "CARREGANDO...",
      "E-MAIL OU APELIDO JÁ EXISTENTES!",
      "LOGUE PARA CONTINUAR",
      "SUCESSO",
      "ACEITE OS TERMOS PARA CONTINUAR",
      "ACEITE OS TERMOS E CONDIÇÕES PARA CONTINUAR",
      "SENHAS NÃO CONFEREM",
      "INSIRA A SENHA NOVAMENTE PARA CONTINUAR.",
      "SUA CONTA FOI CRIADA COM SUCESSO! ENTRANDO...",
      "EU TENHO UMA CONTA",
      "SINCRONIZANDO COM MATAMASK",
      "CARTEIRA METAMASK REQUERIDA",
      "CLIQUE AQUI PARA INSTALAR",
      "CONECTE SUA CARTEIRA",
      "CONECTANDO...",
      "CONECTADO NA METAMASK, CLIQUE ABAIXO PARA:",
      "TROCAR PARA A REDE DA HARMONY",
      "ADICIONAR O ENDEREÇO DA TOKEN EQ9 NA SUA CARTEIRA",
    ],
    hof_page: ["PESQUISAR RANKING"],
    evo_solic_page: {},
    evo_page: ["NENHUM TORNEIO CRIADO", "REGRAS", "CONTATOS", "PLAYERS", "PRODUTOS"],
    events_page: [
      "VOCÊ PRECISA CADASTRAR UM DECK FORMATO",
      "PARA CONTINUAR",
      "VOCÊ NÃO POSSUI DECK CADASTRADO",
      "INSCRITO NO",
      "INSCRITO COM SUCESSO NO",
      "VOCÊ NÃO POSSUI EQ0 SUFICIENTES PARA CONTINUAR",
      "INSCRIÇÃO NO EVENTO",
      "VOCÊ ESTÁ PRESTES A SE INSCREVER NO EVENTO, CONFIRME AS INFORMAÇÕES PARA CONTINUAR",
      "CARTEIRA",
      "SALDO NA CARTEIRA",
      "VALOR DA INSCRIÇÃO",
      "SALDO APÓS INSCRIÇÃO",
      "CANCELAR",
      "CONFIRMAR",
      "INSCRITO",
      "INSCREVER",
      "PREMIAÇÃO",
      "AGENDA",
      "REGRAS",
      "E-MAIL",
      "STANDINGS",
      "ROUNDS",
      "ESSA ÁREA ESTARÁ DISPONÍVEL QUANDO O EVENTO COMEÇAR",
      "PREMIAÇÃO",
      "JOGADORES",
      "STAKERS",
      "VENCEDORES",
      "POR QUANTIDADE",
      "POR TEMPO",
      "LUGAR",
      "PREMIAÇÃO",
      "REGRAS",
      "PARTICIPANTES",
      "CONTATO",
      "PREMIAÇÃO TOTAL",
    ],
    deckadd_page: {},
    badges_page: ["HALL OF FAME", "PARTICIPE DE UM TORNEIO PARA CONQUISTAR"],
    addDeck: [
      "CONFIRMAR",
      "SIDEBOARD",
      "DEDCK ADICIONADO COM SUCESSO!",
      "É PRECISO ADICIONAR UM NOME PARA CONTINUAR",
      "FORMATO INVALIDO",
      "PROCURAR CARTA",
      "NOME DO DECK",
      "CONFIRMAR",
      "CONFIRMAR",
    ],
    addEvo: ["CANCELAR", "CONFIRMAR"],
    addPlayer: ["CANCELAR", "CONFIRMAR"],
    addTournament: [
      "TORNEIO CRIADO COM SUCESSO!",
      "NOME DE TORNEIO JÁ CADASTRADO",
      "NOME DO TORNEIO",
      "DATA INICIAL",
      "HORA INICIAL",
      "DESCRIÇÃO",
      "VALOR DE INSCRIÇÃO",
      "RECOMPENSA INICIAL",
      "HORA INICIAL",
    ],
    sideNav: [
      "OLÁ",
      "WELCOME",
      "SAIR",
      "SALDO",
      "INÍCIO",
      "TORNEIOS",
      "MEUS STAKERS",
      "SEASON ONE",
      "HALL DA FAMA",
      "LOJAS",
      "CRIAR TORNEIO",
      "NAVEGANDO COMO",
      "TROCAR",
    ],
    config: ["VOLUME", "LINGUAGEM"],
    rankingProfile: [
      "VALOR A SER CONGELADO EM",
      "CANCELAR",
      "STAKE",
      "INSIRA UM VALOR.",
      "VALOR TOTAL CONGELADO",
      "DESCONGELADO",
      "REALIZADO COM SUCESSO",
      "INSIRA UM VALOR VÁLIDO",
      "CONGELOU",
      "DESCONGELADO EM",
      "EM VOCÊ",
      "CONGELADO EM",
      "EM",
      "UNSTAKE",
    ],
    startEvent: ["INICIAR EVENTO"],
    graphic: ["QUANTIDADE DE STAKERS"],
    profile: [
      "IMPORTAÇÃO CANCELADA",
      "NÃO FOI POSSÍVEL LER O ARQUIVO",
      "IMAGEM DE PERFIL MODIFICADA COM SUCESSO!",
      "IMAGEM MUITO GRANDE, TENTE UMA IMAGEM MENOR",
      "NOME",
      "ASSOCIADO A:",
    ],
    table: ["VOCÊ AINDA NÃO POSSUI CAMPEÕES", "PROCURAR CAMPEÃO"],
    messages: ["(CLIQUE AQUI)", "SISTEMA: NENHUM REGISTRO DE MENSAGENS."],
    friendMsg: ["PEDIDO DE AMIZADE", "PESQUISE POR NICKNAME", "ONLINE", "OFFLINE", "AWAY"],
    carousel: ["DESCRIÇÃO DA ETAPA", "PARTIDA", "INSCRITO"],
  },
};
export default text;
