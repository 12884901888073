import { useEffect, useRef, useState } from "react";
import { setTimeout } from "timers";
import AlertMsg from "../../components/alert/alert";
import Controller from "../../components/Controller";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
import decode from "jwt-decode";
export default function ResetPasswordPage() {
  const passwordConfirmRegister = useRef();
  const passwordRegister = useRef();
  const [status, setStatus] = useState(0);
  const { lang, setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const path = window.location.pathname;
  const getParam = path?.split("/")[2];
  const decodeParam = getParam.length > 10 ? decode(getParam) : null;

  const handleSignUp = async (e) => {
    e.preventDefault();
    setStatus(1);
    const passwordValue = passwordRegister.current?.value;
    const confirmPasswordValue = passwordConfirmRegister.current?.value;
    if (passwordValue === confirmPasswordValue) {
      const getPasswordUpdated = await api.patch(`emails/recovery-confirm`, { newPassword: passwordValue, token: getParam }).catch(() => {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "Erro ao atualizar a senha, tente novamente mais tarde" : "Error trying to update your password, try again later");
        setSeverity("error");
        setStatus(0);
      });
      if (getPasswordUpdated) {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "SENHA ATUALIZADA COM SUCESSO! REDIRECIONANDO PARA LOGIN...." : "PASSWORD SUCCESSFULLY UPDATED! REDIRECTING TO LOGIN...");
        setSeverity("success");
        setStatus(0);
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_ENDPOINT;
        }, 2000);
      }
    } else {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "AS SENHAS NÃO CONFEREM." : "PASSWORDS DO NOT MATCH");
      setSeverity("error");
      setStatus(0);
    }
  };

  return (
    <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div className="sing-up" style={{ width: "400px" }}>
        <h2>{lang === "pt" ? "Resetar Senha" : "Reset Password"}</h2>
        <form className="formSingup" noValidate style={{ width: "350px" }}>
          <p style={{ fontSize: "1em", color: "var(--pink)", padding: "1rem" }}>{decodeParam ? decodeParam.username : null}</p>
          <div className="form-div">
            <input
              type="password"
              id="input-singUp-password"
              pattern=".+"
              minLength={6}
              name="password"
              ref={passwordRegister}
              autocomplete="off"
              required
              className="form-div-input"
            />
            <label className="input-label" for="input-singUp-password" style={{ fontSize: "1em", marginTop: "5px" }}>
              {lang === "pt" ? "NOVA SENHA" : "NEW PASSWORD"}
            </label>
            <span className="input-label-line width-passC" for="input-singUp-password" style={{ width: "155px" }}></span>
          </div>
          <div className="form-div">
            <input
              type="password"
              id="input-singUp-password-confirm"
              pattern=".+"
              minLength={6}
              name="passwordConfirm"
              ref={passwordConfirmRegister}
              autocomplete="off"
              required
              className="form-div-input"
            />
            <label className="input-label" for="input-singUp-password-confirm" style={{ fontSize: "1em", marginTop: "5px" }}>
              {lang === "pt" ? "CONFIRMAR NOVA SENHA" : "CONFIRM NEW PASSWORD"}
            </label>
            <span className="input-label-line width-passC" for="input-singUp-password-confirm" style={{ width: "235px" }}></span>
          </div>
          <button className="button-login" onClick={handleSignUp}>
            {lang === "pt" ? "CONFIRMAR" : "CONFIRM"}
          </button>
        </form>
      </div>
      <Controller />
      <AlertMsg />
    </div>
  );
}
