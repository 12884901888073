import { createContext, useContext } from "react";
import api from "../services/api";
import { useStore } from "./storeContext";

const FetchDataContext = createContext();

export default function FetchProvider({ children }) {
  const { user, setUser } = useStore();

  const UpdateUser = async () => {
    const getUpdate = await api.get(`users/${user._id}`).catch(console.log);
    if (getUpdate) {
      setUser(getUpdate.data);
    }
  };

  return (
    <FetchDataContext.Provider
      value={{
        UpdateUser,
      }}
    >
      {children}
    </FetchDataContext.Provider>
  );
}

export function useFetchData() {
  const context = useContext(FetchDataContext);
  if (!context) throw new Error("useImport precisa de um Arquivo importado");
  const { UpdateUser } = context;
  return { UpdateUser };
}
