import React, { useEffect, Redirect } from "react";
import { Route } from "react-router-dom";
import Login from "./Login";
import LoginAdam from "./LoginAdam";
import PrivatePolicy from "../privatePolicy/loginPolicy";
import Controller from "../../components/Controller";
import ResetPasswordPage from "../resetPassword";
import AlertMsg from "../../components/alert/alert";

function Index() {
  return (
    <>
      <Route path="/" component={Login} />
      <Route path="/login/adam" component={LoginAdam} />
      <Route path="/PrivateProvicy" component={PrivatePolicy} />
      <Route path="/resetPassword/:id" component={ResetPasswordPage} />
      <Controller />
      <AlertMsg />
    </>
  );
}

export default Index;
