import React, { useState } from "react";
import "./playerProfile.scss";
import { useStore } from "../../context/storeContext";
import language from "../../assets/language/text";
import { useLocalDB } from "../../context/LocalDB";
import images from "../../assets/img";
import { calculateAge } from "../../utils/calculateAge";
import Pop from "../PopOver";

const initialState = () => {
  return {
    name: "",
    nickname: "",
    img: "",
  };
};

function Profile(props) {
  const [edit, setEdit] = useState(false);
  const { lang } = useLocalDB();
  const text = language[lang].profile;

  const { img, associated, name, nickname, score, birthDate } = props;

  return (
    <div
      style={{
        width: "600px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "240px", height: "240px" }}>
        <div className="c-profile-avatar">
          <div className="c-profile-avatar-photo" style={{ width: "150px", height: "150px", marginLeft: "-5rem" }}>
            {img ? <img src={img} alt="Profile Photo" /> : <img src={images.unknown} alt="Profile Photo" />}
          </div>
          <img src={images.bordaAvatar} alt="avatar border" style={{ width: "220px", height: "220px", marginLeft: "-5rem" }} />
        </div>
      </div>
      {associated ? (
        <h1
          style={{
            position: "absolute",
            top: "13rem",
            left: "8rem",
            background: "var(--pink)",
            display: "flex",
            padding: "5px",
            borderRadius: "10px",
            fontSize: "1.5rem",
          }}
        >
          {associated}
        </h1>
      ) : (
        <h1
          style={{
            position: "absolute",
            top: "12rem",
            left: "8.5rem",
            background: "var(--pink)",
            display: "flex",
            padding: "5px",
            borderRadius: "10px",
            fontSize: "1.7rem",
          }}
        >
          NOT ASSOCIATED
        </h1>
      )}
      {/* <span className="tagOrganizer">HIVE JOGOS</span> */}
      <div className="infosProfilePlayer2" style={{ width: "32%" }}>
        <span className="playerNamePlayer">{name ? `${name} ${birthDate ? `( ${calculateAge(birthDate)} )` : ""} ` : "Nome (idade)"}</span>
        <div className="NickBLockPlayer">
          <h1 contentEditable={edit} className="gradientNickPlayer">
            <Pop cutValue={10}>{nickname}</Pop>
          </h1>
          {/* <img className="paisIMG" src={pais} alt="pais" /> */}
        </div>
        <div className="playerResultPlayer" style={{ fontSize: "1rem" }}>
          {score ? (
            <>
              W <b>{score.w ? score.w : 0}</b> | L <b>{score.l ? score.l : 0}</b> | D <b>{score.d ? score.d : 0}</b>
            </>
          ) : null}
        </div>
        {/* <div className="social-icons">
          <a href="http://twitch.tv/" rel="noreferrer" target="_blank">
            <i class="fab fa-twitch fa-lg"></i>
          </a>
          <a href="http://youtube.com/" rel="noreferrer" target="_blank">
            <i className="fab fa-youtube fa-lg"></i>
          </a>
          <a href="http://intagram.com/" rel="noreferrer" target="_blank">
            <i className="fab fa-instagram fa-lg"></i>
          </a>
          <Link to={"*"}>
            <i className="fas fa-share-square fa-lg"></i>
          </Link>
        </div> */}
      </div>
    </div>
  );
}

export default Profile;
