import Room from "./room";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import WinnerPage from "./winnerPage";
import api from "../../services/api";

function Index() {
  const { id } = useParams();
  const [finished, setFinished] = useState(false);
  const [tournament, setTournament] = useState();

  useEffect(() => {
    if (id) fetchTournament();
  }, [id]);

  const fetchTournament = async () => {
    const getTournament = await api.get(`tournaments/${id}`).catch(console.log);
    if (getTournament) {
      setTournament(getTournament.data);
      const status = getTournament.data.status;
      if (status === "ENDED") {
        setFinished(true);
      } else {
        setFinished(false);
      }
    }
  };

  // return <>{!finished ?
  return <Room tournament={tournament} />;
  // : <WinnerPage tournament={tournament} winner={tournament.tourney.standings[0]} />}</>;
}

export default Index;
