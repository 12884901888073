import { createContext, useContext, useState, useEffect } from "react";
import options from "../services/apiWebsocket";
import io from "socket.io-client";

const SocketIoContext = createContext();

export default function SocketProvider({ children }) {
  const [socket, setSocket] = useState();
  const [socketUpdate, setSocketUpdate] = useState(0);

  const getSocket = () => {
    setSocket(io(process.env.REACT_APP_API_HOST, options));
  };

  useEffect(() => {
    if (socket) {
      socket.off("connect").on("connect", (e) => {
        setSocketUpdate(socketUpdate + 1);
      });
      socket.off("disconnect").on("disconnect", (e) => {
        const data = new Date();
        socket.connect();
        console.log(`${data.getTime()} - desconectado "disconnect", ${e}`);
      });
      socket.off("connect_error").on("connect_error", (e) => {
        const data = new Date();
        socket.connect();
        console.log(`${data.getTime()} - desconectado "connect_error", ${e}`);
      });
      socket.off("reconnect_attempt").on("reconnect_attempt", (e) => {
        const data = new Date();
        console.log(`${data.getTime()} - desconectado "reconnect_attempt", ${e}`);
        socket.io.opts.query.x++;
      });
    }
  }, [socket]);

  return <SocketIoContext.Provider value={{ socket, getSocket, socketUpdate, setSocketUpdate }}>{children}</SocketIoContext.Provider>;
}

export function useSocket() {
  const context = useContext(SocketIoContext);
  if (!context) throw new Error("Socket cannot connect to the endPoint.");
  const { socket, getSocket, socketUpdate, setSocketUpdate } = context;
  return { socket, getSocket, socketUpdate, setSocketUpdate };
}
