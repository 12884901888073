import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/App";
import { MetaMaskProvider } from "metamask-react";

ReactDOM.render(
  <MetaMaskProvider>
    <App />
  </MetaMaskProvider>,
  document.getElementById("root")
);
