import React, { createContext, useContext, useState } from "react";
import useStorage from "../utils/useStorage";

const StoreContext = createContext({
  token: null,
  setToken: () => {},
});

export default function StoreProvider({ children }) {
  const [token, setToken] = useStorage("token");
  const [user, setUser] = useState();
  const [evo, setEvo] = useState();
  const [tournament, setTournament] = useStorage("tournament");

  return (
    <StoreContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        tournament,
        setTournament,
        evo,
        setEvo,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}

export function useStore() {
  const context = useContext(StoreContext);
  if (!context) throw new Error("useToken precisa de um token!");
  const { token, setToken, user, setUser, tournament, setTournament, evo, setEvo } = context;
  return {
    token,
    setToken,
    user,
    setUser,
    tournament,
    setTournament,
    evo,
    setEvo,
  };
}
