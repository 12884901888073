import React from "react";
import sound from "../../assets/sounds";

const BtnContinuo = ({ classe, children, handleConfirm, onMouseOut, onMouseOver, onClick, style, component: Component, ...restProps }) => {
  return (
    <Component
      className={`default-button ${classe}`}
      style={{ ...style, overflow: "hidden" }}
      onClick={() => {
        sound.btn.play();
        sound.btnContinuous.pause();
        sound.btnContinuous.loop = false;
        sound.btnContinuous.currentTime = 0;
        if (handleConfirm) return handleConfirm();
        if (onClick) return onClick();
      }}
      onMouseOver={() => {
        sound.btnContinuous.play();
        sound.btnContinuous.loop = true;
        if (onMouseOver) {
          onMouseOver();
        }
      }}
      onMouseOut={() => {
        sound.btnContinuous.pause();
        sound.btnContinuous.loop = false;
        sound.btnContinuous.currentTime = 0;
        if (onMouseOut) {
          onMouseOut();
        }
      }}
      {...restProps}
    >
      {children}
    </Component>
  );
};

BtnContinuo.defaultProps = {
  component: "button",
  classe: "",
};

export default BtnContinuo;
