import { useState, useEffect } from "react";
import Carousel from "../../components/slider/carousel";
import EvoList from "../../components/list/EvoList";
import { motion } from "framer-motion";
import { useFetch } from "../../hooks/useFetch";
import Pageloading from "../../components/pageloading/pageloading";
import api from "../../services/api";
import { useLocalDB } from "../../context/LocalDB";
import { CircularProgress } from "@material-ui/core";

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};

const pageTransition = { type: "tween", easy: "circInOut", duration: 0.4 };

function Index() {
  const [search, setSearch] = useState();
  const [tournaments, setTournaments] = useState([]);
  const [tournamentsData, setTournamentsData] = useState([]);
  const [timer, setTimer] = useState();
  const [evos, setEvos] = useState([]);
  const [evoList, setEvoList] = useState([]);
  const [findByEvo, setFindByEvo] = useState(false);
  const { lang } = useLocalDB();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("WAITING");

  const fetchTournaments = async (param) => {
    setLoading(true);
    const getTournament = await api.get(`tournaments`, { params: { status: param } }).catch(() => {
      setError(true);
      setLoading(false);
    });
    if (getTournament.data.length > 0) {
      setLoading(false);
      setTournamentsData(getTournament.data);
    }
    if (getTournament.data.length === 0) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTournaments(status);
  }, []);

  useEffect(
    () =>
      setTimeout(() => {
        GetAllData();
      }, timer),
    // eslint-disable-next-line
    [timer]
  );

  const GetAllData = () => {
    SearchTournament();
    setTimer();
    searchEvo();
  };

  useEffect(() => {
    async function getEvos() {
      const getEvo = await api.get(`evos`).catch(console.log);
      if (getEvo) {
        const filteredEvos = getEvo.data.filter((item) => {
          if (item.name !== "Teste" && item.name !== "Evo emotions" && item.name !== "HomeGaming") return true;
        });
        setEvos(filteredEvos);
      }
    }
    getEvos();
  }, []);

  const handleEvoCLick = async (id) => {
    if (!findByEvo) return GetAllData();
    const getTournamentsByEvo = await api.get(`tournaments/find-by-evo/${id}`).catch(console.log);
    if (getTournamentsByEvo?.data) {
      setTournaments(getTournamentsByEvo?.data);
    }
  };

  const searchEvo = async () => {
    if (evos) {
      const EvosFilter = evos.filter((item) => {
        const lowercase = item.name.toLowerCase();
        if (lowercase?.includes(search)) {
          return true;
        }
      });
      if (!search) return setEvoList([]);
      else return setEvoList(EvosFilter);
    }
  };

  const SearchTournament = () => {
    if (tournamentsData.length > 0) {
      const torneios = tournamentsData.filter((item) => {
        const namelowercase = item.name?.toLowerCase();
        const lowerCaseTypeOf = item.game?.typeOf?.toLowerCase();
        const lowerCaseFormat = item.game?.format?.toLowerCase();
        const MagicOnlineFormat = "magic online";
        if (
          namelowercase?.includes(search) ||
          lowerCaseTypeOf?.includes(search) ||
          lowerCaseFormat?.includes(search) ||
          (lowerCaseTypeOf?.includes("mol") && search?.includes(MagicOnlineFormat))
        ) {
          return true;
        }
      });
      return setTournaments(torneios);
    }
  };

  function HandleSearch(e) {
    e.preventDefault();
    setSearch(e.target.value);
    setTimer(300);
  }

  if (error) return <div>failed to load</div>;
  if (!tournaments) return <Pageloading />;

  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="page-tournaments">
      <div className="page-tournaments-left">
        <div className="page-tournaments-left-list">
          {evoList.length == 0
            ? evos.map((item) => {
                return (
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      width: "100%",
                    }}
                    key={item._id}
                    onClick={() => {
                      handleEvoCLick(item._id);
                      setFindByEvo(!findByEvo);
                    }}
                  >
                    <EvoList data={item} />
                  </button>
                );
              })
            : evoList.map((item) => {
                return (
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      width: "100%",
                    }}
                    key={item._id}
                    onClick={() => {
                      handleEvoCLick(item._id);
                      setFindByEvo(!findByEvo);
                    }}
                  >
                    <EvoList data={item} />
                  </button>
                );
              })}
        </div>
      </div>
      <div className="page-tournaments-right">
        <div className="page-tournaments-right-top" style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
          <div className="default-input-i" style={{ width: "60%" }}>
            <input type="text" value={search} onChange={HandleSearch} placeholder="FILTRAR TORNEIOS/ORGANIZADORES" />
            <i className="fas fa-search" />
          </div>
          <div>
            <select
              className="default-input"
              style={{ maxWidth: "160px" }}
              onChange={(e) => {
                setStatus(e.target.value);
                fetchTournaments(e.target.value);
              }}
            >
              <option name={lang === "pt" ? "DISPONÍVEIS" : "OPEN"} value="WAITING">
                {lang === "pt" ? "DISPONÍVEIS" : "OPEN"}
              </option>
              <option name={lang === "pt" ? "INICIADOS" : "RUNNING"} value="STARTED">
                {lang === "pt" ? "INICIADOS" : "RUNNING"}
              </option>
              <option name={lang === "pt" ? "FINALIZADOS" : "FINISHED"} value="ENDED">
                {lang === "pt" ? "FINALIZADOS" : "FINISHED"}
              </option>
            </select>
          </div>
        </div>
        <div className="page-tournaments-right-carousel">
          {loading ? (
            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <Carousel data={tournaments.length > 0 ? tournaments : tournamentsData} />
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default Index;
