import { Dialog } from "@material-ui/core";
import { useEffect, useState } from "react";
import api from "../../services/api";

function PlayerModal({ open, setOpen, setPlayerWallet }) {
  const [players, setPlayers] = useState([]);
  const [timer, setTimer] = useState();
  const [playersTable, setPlayersTable] = useState();
  const [searchValue, setSearchValue] = useState();
  const [searchAllUsers, setSearchAllUsers] = useState([]);

  const fetchAllUser = async (name, page, size, add, setLoading) => {
    const getUsers = await api.get(`users`, { params: { searchParam: name ? name : "", page: page ? page : 0, size: size ? size : 20 } }).catch(console.log);
    setPlayersTable(add ? [...searchAllUsers, ...getUsers.data] : getUsers.data);
    setLoading && setLoading(false);
  };

  useEffect(
    () =>
      setTimeout(() => {
        // filterPlayers();
        fetchAllUser(searchValue, 0, 20);
        setTimer();
      }, timer),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timer]
  );

  useEffect(() => {
    fetchPlayers();
  }, []);

  const fetchPlayers = async () => {
    const getPlayers = await api.get(`users`).catch(console.log);
    if (getPlayers) setPlayers(getPlayers.data);
  };

  const filterPlayers = () => {
    if (players) {
      const filter = players.filter((item) => {
        if (item.nickname.toLowerCase().includes(searchValue) || (item.name && item.name.toLowerCase().includes(searchValue))) return true;
      });
      if (searchValue !== "") return setPlayersTable(filter);
      else return setPlayersTable();
    }
  };

  const handlePlayerSearch = (e) => {
    setSearchValue(e.target.value.toLowerCase());
    setTimer(700);
  };

  return (
    <Dialog open={open} onClose={setOpen} PaperProps={{ style: { background: "black", width: "31vw" } }}>
      <div style={{ width: "30vw", height: "60vh", padding: "1rem" }}>
        <input className="default-input" value={searchValue} placeholder="TYPE HERE TO SEARCH THE PLAYER" onChange={handlePlayerSearch} />
        <table style={{ width: "100%", padding: "5px" }}>
          <tbody>
            {playersTable &&
              playersTable.map((item, index) => {
                return (
                  <tr
                    key={`playersTable${index}`}
                    onClick={() => {
                      setPlayerWallet(item.wallets.default.address);
                      setOpen();
                    }}
                    style={{ margin: "5px" }}
                  >
                    <td>
                      {item.img ? (
                        <img
                          src={item.img}
                          alt="playerIMG"
                          style={{ width: "50px", height: "50px", borderRadius: "50%", border: "3px solid var(--lightblue)" }}
                        />
                      ) : (
                        <div style={{ width: "50px", height: "50px", borderRadius: "50%", border: "3px solid var(--lightblue)", background: "grey" }} />
                      )}
                    </td>
                    <td>{item.nickname}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Dialog>
  );
}

export default PlayerModal;
