import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useLocalDB } from "../../context/LocalDB";
import sounds from "../../assets/sounds";
import PropTypes from "prop-types";
import api from "../../services/api";
import { useStore } from "../../context/storeContext";
import language from "../../assets/language/text";
import decode from "jwt-decode";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const click = sounds.btn;

function AbasEvo() {
  const [open, setOpen] = useState(false);
  const [playerSearch, setPlayerSearch] = useState();
  const [playersTable, setPlayersTable] = useState([]);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const [value, setValue] = useState(0);
  const { evo, setEvo, token } = useStore();
  const InvitePlayerInput = useRef(null);
  const RulesInput = useRef(null);
  const MailInput = useRef(null);
  const rankingName = useRef(null);
  const startDate = useRef(null);
  const endDate = useRef(null);
  const rankingDescription = useRef(null);
  const [rankings, setRankings] = useState([]);

  const text = language[lang].evo_page;

  useEffect(() => {
    fetchRankings();
  }, []);

  const fetchEvo = async () => {
    const userTokenDecoded = decode(token);
    if (userTokenDecoded.evo) {
      const getEvoData = await api.get(`evos/${userTokenDecoded.evo._id}`).catch(console.log);
      setEvo(getEvoData.data);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmitNewRanking = async (e) => {
    e.preventDefault();
    const rankingObject = {
      name: rankingName.current?.value,
      description: rankingDescription.current?.value,
      startDate: startDate.current?.value,
      endDate: endDate.current?.value,
    };
    if (rankingObject.name || rankingObject.description || rankingObject.startDate || rankingObject.endDate) {
      const addRanking = await api.post(`rankings`, rankingObject).catch(() => {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "ERRO AO ADICIONAR O RANKING" : "ERROR ON TRYING TO ADD RANKING");
        setSeverity("error");
      });
      if (addRanking) {
        // fetchEvo();
        fetchRankings();
        setAlert(true);
        setAlertMsg(lang === "pt" ? "RANKING CRIADO COM SUCESSO" : "RANKING SUCCESSFULLY ADDED");
        setSeverity("success");
      }
    } else {
      setAlert(true);
      setAlertMsg(lang === "pt" ? "PREENCHA TODOS OS ITENS PARA ADICIONAR UM NOVO RANKING" : "COMPLETE THE FORM TO SUBMIT NEW RANKING");
      setSeverity("error");
    }
  };

  async function handleDell(e, item, index) {
    e.preventDefault();
    const rulesReturn = [...evo[item]];
    rulesReturn.splice(index, 1);
    const getDell = await api
      .patch(`evos`, {
        [item]: rulesReturn,
      })
      .catch(() => {
        setAlert(true);
        setAlertMsg("Erro ao atualizar as regras");
        setSeverity("error");
      });
    if (getDell) {
      setAlert(true);
      setAlertMsg("Regras Atualizadas");
      setSeverity("success");
      fetchEvo();
    }
  }

  const handleSubmitValue = async (e, item) => {
    e.preventDefault();
    let itemSubmit = item === "rules" ? RulesInput : MailInput;
    let textValue = item === "rules" ? itemSubmit.current.value.toUpperCase() : itemSubmit.current.value.toUpperCase();
    if (textValue.length !== 0) {
      const putData = await api.patch(`evos`, { [item]: [...evo[item], textValue] }).catch(console.log);
      if (putData) {
        fetchEvo();
        itemSubmit.current.value = "";
        setAlert(true);
        setAlertMsg("Regra Atualizada");
        setSeverity("success");
      } else {
        setAlert(true);
        setAlertMsg("Erro ao atualizar a regra");
        setSeverity("error");
      }
    }
  };

  const fetchRankings = async () => {
    const getRankings = await api.get(`rankings`, { params: { evoId: evo._id, page: 0, size: 30 } }).catch(console.log);
    if (getRankings) {
      setRankings(getRankings.data);
    }
  };

  const handleRankingDelete = async (rankingId) => {
    const confirm = await window.confirm(lang == "pt" ? `Você deseja remover o ranking?` : `Are you shure to remove the ranking?`);
    if (confirm) {
      const disableRanking = await api.delete(`rankings/${rankingId}`).catch(console.log);
      if (disableRanking) {
        fetchRankings();
        setAlert(true);
        setAlertMsg(disableRanking.data.message[lang]);
        setSeverity("success");
      }
    }
  };
  const handleActiveRanking = async (rankingId) => {
    const confirm = await window.confirm(lang == "pt" ? `Você deseja reativar o ranking?` : `Are you shure to reactivate the ranking?`);
    if (confirm) {
      const reactivateRanking = await api.post(`rankings/reactivate/${rankingId}`).catch(console.log);
      if (reactivateRanking) {
        fetchRankings();
        setAlert(true);
        setAlertMsg(`Ranking reativado com sucesso!`);
        setSeverity("success");
      }
    }
  };

  return (
    <>
      <AppBar
        position="static"
        className="menuOrganizer"
        style={{
          background: "transparent",
          width: "100%",
          alignItems: "space-between",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          style={{
            background: "transparent",
            alignItems: "space-between",
          }}
        >
          <Tab
            label={text[1]}
            {...a11yProps(0)}
            onClick={() => {
              click.currentTime = 0;
              click.play();
            }}
          />
          <Tab
            label={text[2]}
            {...a11yProps(1)}
            onClick={() => {
              click.currentTime = 0;
              click.play();
            }}
          />
          <Tab
            label={"Associated"}
            {...a11yProps(2)}
            onClick={() => {
              click.currentTime = 0;
              click.play();
            }}
          />
          <Tab
            label={text[3]}
            {...a11yProps(3)}
            onClick={() => {
              click.currentTime = 0;
              click.play();
            }}
          />
          {/* <Tab
            label={text[4]}
            {...a11yProps(3)}
            style={{ width: "25%" }}
            onClick={() => {
              click.currentTime = 0;
              click.play();
            }}
          /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} style={{ maxHeight: "100%", overflow: "auto" }}>
        <form className="default-input-i" onSubmit={(e) => handleSubmitValue(e, "rules")}>
          {/* <input type="text" placeholder="ADD RULES" ref={RulesInput} /> */}
          <textarea
            col="30"
            row="5"
            required
            id="input-desc-event"
            type="text"
            placeholder="Digite as regras aqui..."
            name="description"
            ref={RulesInput}
            className="default-input-label-content inputTornament"
            style={{ height: "7rem", wordWrap: "break-word" }}
          />
          <i className="fas fa-plus fa-lg" onClick={(e) => handleSubmitValue(e, "rules")} />
        </form>
        <div
          style={{
            height: "26vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {evo.rules &&
            evo.rules.map((item, index) => {
              return (
                <div key={"rulesItens" + index} style={{ display: "flex", alignItems: "center", height: "80px" }}>
                  <span style={{ padding: "10px", fontFamily: "gilroy-bold" }}>{index + 1}º</span>
                  <textarea
                    readOnly
                    disabled
                    style={{
                      width: "100%",
                      maxHeight: "10vh",
                      background: "transparent",
                      resize: "none",
                      fontFamily: "gilroy-thin",
                      fontSize: ".85em",
                      border: "none",
                    }}
                  >
                    {item}
                  </textarea>
                  <button style={{ background: "none", border: "none", padding: "1rem" }} onClick={(e) => handleDell(e, "rules", index)}>
                    <i
                      className="far fa-trash-alt fa-lg"
                      style={{
                        color: "var(--pink)",
                        cursor: "pointer",
                      }}
                    />
                  </button>
                </div>
              );
            })}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <form className="default-input-i" onClick={(e) => handleSubmitValue(e, "emails")}>
          <input type="email" placeholder="ADD E-MAIL ADDRESS" ref={MailInput} />
          <i className="fas fa-plus fa-lg" onClick={(e) => handleSubmitValue(e, "emails")} />
        </form>
        <ul
          style={{
            height: "30vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {evo.emails &&
            evo.emails.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1rem",
                  }}
                >
                  {item}
                  <button style={{ background: "none", border: "none" }} onClick={(e) => handleDell(e, "emails", index)}>
                    <i className="far fa-trash-alt fa-lg" style={{ color: "var(--pink)", cursor: "pointer" }}></i>
                  </button>
                </li>
              );
            })}
        </ul>
      </TabPanel>
      <TabPanel value={value} index={2}>
        {playerSearch ? (
          <div
            style={{
              position: "absolute",
              width: "30%",
              maxHeight: "26%",
              left: "10%",
              margin: "58px 0",
              padding: "0 10px",
              background: "rgba(0,0,0,0.3)",
              backdropFilter: "blur(15px)",
              borderRadius: "10px",
              zIndex: 3000,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <table style={{ width: "100%", margin: "4px" }}>
              <tbody></tbody>
            </table>
          </div>
        ) : null}
        <form
          className="default-input-i"
          onSubmit={(e) => e.preventDefault()}
          // onSubmit={handleInvitePlayer}
        >
          <input placeholder="INVITE PLAYER" ref={InvitePlayerInput} />
          <i className="fas fa-search fa-lg" />
        </form>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <form className="default-input-i" style={{ display: "flex", height: "50px" }} onSubmit={handleSubmitNewRanking}>
          <div style={{ width: "30%", height: "50px", margin: "5px" }}>
            <label>{lang ? "Nome do Ranking" : "Ranking Name"}</label>
            <input style={{ fontSize: ".9em" }} type="text" placeholder="RANKING NAME" ref={rankingName} />
          </div>
          <div style={{ width: "20%", height: "50px", margin: "5px" }}>
            <label>{lang === "pt" ? "Data Inicial" : "Start Date"}</label>
            <input style={{ fontSize: ".9em" }} type="date" placeholder="START DATE" ref={startDate} />
          </div>

          <div style={{ width: "20%", height: "50px", margin: "5px" }}>
            <label>{lang === "pt" ? "Data Final" : "End Date"}</label>
            <input style={{ fontSize: ".9em" }} type="date" placeholder="END DATE" ref={endDate} />
          </div>
          <div style={{ width: "30%", height: "50px", margin: "5px" }}>
            <label>{lang === "pt" ? "Descrição" : "Description"}</label>
            <textarea
              style={{
                width: "100%",
                height: "35px",
                background: "transparent",
                resize: "none",
                fontFamily: "gilroy-thin",
                fontSize: ".85em",
                border: "none",
                margin: "5px",
              }}
              className="default-input"
              placeholder="DESCRIPTION"
              ref={rankingDescription}
            ></textarea>
          </div>
          <i className="fas fa-plus fa-2x" style={{ position: "absolute", top: "1rem", right: "-2rem", cursor: "pointer" }} onClick={handleSubmitNewRanking} />
        </form>
        <ul
          style={{
            height: "30vh",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "10px",
          }}
        >
          {rankings &&
            rankings.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "2rem",
                    color: !item.active && "grey",
                    textDecoration: !item.active && "line-through",
                  }}
                >
                  {item.name}
                  <button
                    style={{ background: "none", border: "none" }}
                    onClick={() => (item.active ? handleRankingDelete(item._id) : handleActiveRanking(item._id))}
                  >
                    {item.active ? (
                      <i className="fas fa-trash-alt fa-lg" style={{ color: "var(--pink)", cursor: "pointer" }} />
                    ) : (
                      <i className="fa fa-calendar-plus fa-lg" style={{ color: "var(--pink)", cursor: "pointer" }} />
                    )}
                  </button>
                </li>
              );
            })}
        </ul>
      </TabPanel>
    </>
  );
}

export default AbasEvo;
