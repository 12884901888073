export const harmonyConfig = {
  chainName: "Harmony Mainnet",
  rpcUrls: ["https://api.harmony.one/"],
  nativeCurrency: {
    name: "ONE",
    symbol: "ONE",
    decimals: 18,
  },
  chainId: `0x${Number(1666600000).toString(16)}`,
  blockExplorerUrls: ["https://explorer.harmony.one"],
};
