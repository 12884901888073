import React from "react";
import Pop from "../PopOver";

function VisualSuggestion({ value, children }) {
  return (
    <Pop infoValue={value} sx={{ width: "20px", height: "20px", margin: "5px" }}>
      {children}
    </Pop>
  );
}

export default VisualSuggestion;
