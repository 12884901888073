import UIButton from "../../components/UI/Button/Button";
import SingUp from "../../components/forms/singUp";
import { Link } from "react-router-dom";
import { Dialog } from "@material-ui/core";

function CreateAccount({ open, setOpen, user, setInputs }) {
  return (
    <Dialog open={open} onClose={setOpen} className="page-create-acc" PaperProps={{ style: { background: "transparent" } }}>
      <div className="page-create-acc-right">
        <h2 style={{ textAlign: "center" }}>Complete agora abaixo para iniciar a compra</h2>
        <p style={{ textAlign: "center" }}>Esse cadastro ocorrerá somente uma vez.</p>

        <div className="page-create-acc-right-form">
          <SingUp user={user} setClose={setOpen} setInputsValue={setInputs} />
        </div>
      </div>
      <UIButton component={Link} to="/" className="ui-button--colorful-green exit-button">
        <p>x</p>
      </UIButton>
    </Dialog>
  );
}

export default CreateAccount;
