import bg1 from "./bg1.webp";
import bg2 from "./bg2.webp";
import bg3 from "./bg3.webp";
import bg4 from "./bg4.webp";
import bg5 from "./bg5.webp";
import bg6 from "./bg6.webp";
import bg7 from "./bg7.webp";
import bg8 from "./bg8.webp";
import bg9 from "./bg9.webp";
import bg10 from "./bg10.webp";
import bg11 from "./bg11.webp";
import bg12 from "./bg12.webp";
import bg13 from "./bg13.webp";
import bg14 from "./bg14.webp";
import bg15 from "./bg15.webp";
import bg16 from "./bg16.webp";
import bordaAvatar from "./borda_avatar.webp";
import bordaAvatar2 from "./borda_avatar2.webp";
import bordaAvatar3 from "./borda_avatar3.webp";
import branca from "./branca.webp";
import branca2 from "./branca2.webp";
import branca3 from "./branca3.webp";
import Share from "./compartilhar-azul.webp";
import evoIcon from "./evoIcon.webp";
import firstPlace from "./firstPlace.webp";
import googleIcon from "./googleIcon.webp";
import hiveLogo from "./hiveLogo.webp";
import instagram from "./instagram-azul.webp";
import playerIcon from "./playerIcon.webp";
import secondPlace from "./secondPlace.webp";
import stakerIcon from "./stakerIcon.webp";
import thirdPlace from "./thirdPlace.webp";
import trophyIcon from "./trophyIcon.webp";
import twitch from "./twitch-azul.webp";
import youtube from "./Youtube-Azul.webp";
import blackMana from "./manas/black_mana.webp";
import blueMana from "./manas/blue_mana.webp";
import greenMana from "./manas/green_mana.webp";
import redMana from "./manas/red_mana.webp";
import whiteMana from "./manas/white_mana.webp";
import unknown from "./avatar/unknown.webp";
import avatar1 from "./avatar/profile1.webp";
import avatar2 from "./avatar/profile2.webp";
import avatar3 from "./avatar/profile3.webp";
import avatar4 from "./avatar/profile4.webp";
import avatar5 from "./avatar/profile5.webp";
import magicBackCard from "./magicBackCard.webp";
import createCard from "./createcard.jpg";
import folhaPapel from "./folhaPapel.png";

export default {
  bg1,
  bg2,
  bg3,
  bg4,
  bg5,
  bg6,
  bg7,
  bg8,
  bg9,
  bg10,
  bg11,
  bg12,
  bg13,
  bg14,
  bg15,
  bg16,
  bordaAvatar,
  bordaAvatar2,
  bordaAvatar3,
  branca,
  branca2,
  branca3,
  Share,
  evoIcon,
  firstPlace,
  googleIcon,
  hiveLogo,
  instagram,
  playerIcon,
  secondPlace,
  stakerIcon,
  thirdPlace,
  trophyIcon,
  twitch,
  youtube,
  blackMana,
  blueMana,
  greenMana,
  redMana,
  whiteMana,
  unknown,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  magicBackCard,
  createCard,
  folhaPapel,
};
