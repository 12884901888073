const options = {
  format: {
    STANDARD: {
      name: "STANDARD",
      unicMax: 4,
      minCount: 60,
      maxCount: 60,
      format: "Standard",
    },
    MODERN: {
      name: "MODERN",
      unicMax: 4,
      minCount: 60,
      maxCount: 60,
      format: "Modern",
    },
    LEGACY: {
      name: "LEGACY",
      unicMax: 4,
      minCount: 60,
      maxCount: 60,
      format: "Legacy",
    },
    PAUPER: {
      name: "PAUPER",
      unicMax: 4,
      minCount: 60,
      maxCount: 60,
      format: "Pauper",
    },
    HISTORIC: {
      name: "HISTORIC",
      unicMax: 4,
      minCount: 60,
      maxCount: 60,
      format: "Historic",
    },
    ALCHEMY: {
      name: "ALCHEMY",
      unicMax: 4,
      minCount: 60,
      maxCount: 60,
      format: "Alchemy",
    },
    PIONEER: {
      name: "PIONEER",
      unicMax: 4,
      minCount: 60,
      maxCount: 60,
      format: "Pioneer",
    },
    COMMANDER: {
      name: "COMMANDER",
      unicMax: 1,
      minCount: 100,
      maxCount: 100,
      format: "Commander",
    },
    BRAWL: {
      name: "BRAWL",
      unicMax: 1,
      minCount: 60,
      maxCount: 60,
      format: "Brawl",
    },
  },
  gameType: ["ARENA", "TABLETOP", "MOL"],
  manaCost: ["all", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  manaType: ["all", "White", "Blue", "Black", "Green", "Colorless"],
  types: ["all", "Instant", "Sorcery", "Artifact", "Creature", "Enchantment", "Land", "Planeswalker"],
  rarity: ["all", "Common", "Uncommon", "Rare", "Mythic Rare"],
  companions: [
    "Lurrus of the Dream-Den",
    "Yorion, Sky Nomad",
    "Zirda, the Dawnwaker",
    "Kaheera, the Orphanguard",
    "Gyruda, Doom of Depths",
    "Keruga, the Macrosage",
    "Jegantha, the Wellspring",
    "Obosh, the Preypiercer",
    "Umori, the Collector",
    "Lutri, the Spellchaser",
  ],
};
export default options;
