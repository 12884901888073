import React from "react";
import Pop from "../PopOver";

function Suggestion({ value }) {
  return (
    <Pop infoValue={value} sx={{ width: "20px", height: "20px", padding: "3px 7px", borderRadius: "50%", background: "var(--purple)", margin: "5px" }}>
      <i className="fas fa-question fa-sm" />
    </Pop>
  );
}

export default Suggestion;
