import Room from "./room";

function index() {
  return (
    <>
      <Room />
    </>
  );
}

export default index;
