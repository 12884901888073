import { Popover, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CutName from "../../utils/cutName";

function Pop({ children, cutValue, infoValue, classe, sx, color }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "mouse-over-popover" : undefined;

  const setElementOver = (e) => {
    if (cutValue) {
      if (children?.length > cutValue) {
        setAnchorEl(e.currentTarget);
      }
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span aria-owns={id} aria-haspopup="true" className={classe} style={sx} onMouseOver={setElementOver} onMouseOut={handlePopoverClose}>
        {cutValue ? CutName(children, cutValue) : children}
      </span>
      <Popover
        id={id}
        style={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        PaperProps={{ style: { background: "var(--purple)" } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography style={{ fontSize: "1rem", padding: "10px" }}>{infoValue ? infoValue : children}</Typography>
      </Popover>
    </>
  );
}

export default Pop;
