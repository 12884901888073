import { useState, useEffect, useRef } from "react";
import { Typography, Button } from "@material-ui/core";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import services from "../../services/services";
import DoneIcon from "@material-ui/icons/Done";
import { useStore } from "../../context/storeContext";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
// import firebaseApi from "../../services/firebaseApi";
import language from "../../assets/language/text";
import decode from "jwt-decode";
import { useWallet } from "../../context/walletContext";
import ParticlesPage from "../../components/Particles";
// import { harmonyConfig } from "../../config/harmonyConfig";

const { innerWidth } = window;

const initialState = { username: "", password: "" };
const initialSignUpState = {
  username: "",
  password: "",
  passwordConfirm: "",
  nickname: "",
  wallets: {
    default: "",
  },
};

const LoginAdam = () => {
  const { token, setToken, setUser } = useStore();
  const [loginChecked, setLoginChecked] = useState(false);
  const { setLoginNav, setAlert, setAlertMsg, setAccess, setSeverity, lang, mobile, setMobile } = useLocalDB();
  const [valuesLogin, setValuesLogin] = useState(initialState);
  const [signUp, setSignUp] = useState(false);
  const text = language[lang].login_page;
  const usernameLogin = useRef(null);
  const passwordLogin = useRef(null);
  const submitLogin = useRef(null);
  const [openWalletGuide, setOpenWalletGuide] = useState(false);
  const { status, setInstalled, account, setAddress } = useWallet();

  const history = useHistory();

  useEffect(() => {
    if (status === "unavailable") {
      setOpenWalletGuide(true);
      setInstalled(false);
    } else if (status === "connected") {
      setAddress(account);
      setInstalled(true);
    }
  }, [status]);

  useEffect(() => {
    if (!token) setLoginNav(true);
    else if (token) setLoginNav(false);
  }, []);

  const [process, setProcess] = useState({
    icon: "",
    label: text[11],
    color: "primary",
  });

  async function Log_in(values) {
    const auth = await api.post(`login/adams`, values).catch(() => {
      setProcess({
        label: text[13],
        color: "secondary",
      });
      setAlertMsg(text[13]);
      setSeverity("error");
      setAlert(true);
      setTimeout(() => {
        setValuesLogin(initialState);
        setProcess({ label: text[11] });
      }, 1000);
    });
    if (auth) {
      api.defaults.headers.Authorization = `Bearer ${auth.data.access_token}`;
      const decoded = decode(auth.data.access_token);
      const getUser = await api.get(`users/${decoded.sub}`);
      setAlertMsg(`${text[14]} ${getUser.data.nickname}, ${text[15]}`);
      setProcess({ icon: <DoneIcon />, label: "SUCESSO!", color: "primary" });
      setSeverity("success");
      setAlert(true);
      setAccess(1);
      const token = auth.data.access_token;
      const user = getUser.data;
      setUser(user);
      setToken(token);
      return loginChecked === true
        ? setTimeout(() => {
            setToken(token);
            setLoginNav(false);
            // history.push("/");
          }, 1000)
        : setTimeout(() => {
            // history.push("/");
            setLoginNav(false);
          }, 1000);
    }
  }

  async function handleLogin(e) {
    e.preventDefault();
    setProcess({ label: text[16], color: "default" });
    Log_in({
      username: usernameLogin.current.value,
      password: passwordLogin.current.value,
    });
  }

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh" }} className="modal">
      <ParticlesPage />
      <>
        <div className="FormGrid">
          <div className="sing-in">
            <h2>ADMIN {text[1]} </h2>
            <form className="formSingin" noValidate>
              <div className="form-div">
                <input
                  type="text"
                  id="input-singIn-user"
                  pattern=".+"
                  name="username"
                  ref={usernameLogin}
                  autocomplete="off"
                  required
                  className="form-div-input"
                />
                <label className="input-label" for="input-singIn-user">
                  {text[2]}
                </label>
                <span style={{ width: "23%" }} className="input-label-line" for="input-singIn-user"></span>
              </div>
              <div className="form-div">
                <input
                  type="password"
                  id="input-singIn-password"
                  pattern=".+"
                  name="password"
                  ref={passwordLogin}
                  autocomplete="off"
                  required
                  className="form-div-input"
                />
                <label className="input-label" for="input-singIn-password">
                  {text[3]}
                </label>
                <span className="input-label-line width-pass" for="input-singIn-password"></span>
              </div>
              <button className="button-login" ref={submitLogin} onClick={handleLogin}>
                {process.label}
              </button>
            </form>
          </div>
        </div>
      </>
    </div>
  );
};

export default LoginAdam;
