export default {
  STANDARD: {
    suspenso: [],
    banlist: ["Omnath, Locus of Creation", "Alrund's Epiphany", "Divide by Zero", "Faceless Haven"],
  },
  MODERN: {
    suspenso: [],
    banlist: [
      "Ancient Den",
      "Arcum's Astrolabe",
      "Birthing Pod",
      "Blazing Shoal",
      "Bridge From Below",
      "Chrome Mox",
      "Cloudpost",
      "Dark Depths",
      "Deathrite Shaman",
      "Dig Through Time",
      "Dread Return",
      "Eye of Ugin",
      "Faithless Looting",
      "Field of the Dead",
      "Gitaxian Probe",
      "Glimpse of Nature",
      "Golgari Grave-Troll",
      "Great Furnace",
      "Green Sun's Zenith",
      "Hogaak, Arisen Necropolis",
      "Hypergenesis",
      "Krark-Clan Ironworks",
      "Mental Misstep",
      "Mox Opal",
      "Mycosynth Lattice",
      "Mystic Sanctuary",
      "Oko, Thief of Crowns",
      "Once Upon a Time",
      "Ponder",
      "Preordain",
      "Punishing Fire",
      "Rite of Flame",
      "Seat of the Synod",
      "Second Sunrise",
      "Seething Song",
      "Sensei's Divining Top",
      "Simian Spirit Guide",
      "Skullclamp",
      "Splinter Twin",
      "Summer Bloom",
      "Tibalt's Trickery",
      "Treasure Cruise",
      "Tree of Tales",
      "Umezawa's Jitte",
      "Uro, Titan of Nature's Wrath",
      "Vault of Whispers",
    ],
  },
  VINTAGE: {
    suspenso: [],
    banlist: [
      "Ancestral Recall",
      "Balance",
      "Black Lotus",
      "Brainstorm",
      "Chalice of the Void",
      "Channel",
      "Demonic Consultation",
      "Demonic Tutor",
      "Dig Through Time",
      "Flash",
      "Gitaxian Probe",
      "Golgari Grave-Troll",
      "Gush",
      "Imperial Seal",
      "Karn, the Great Creator",
      "Library of Alexandria",
      "Lion's Eye Diamond",
      "Lodestone Golem",
      "Lotus Petal",
      "Mana Crypt",
      "Mana Vault",
      "Memory Jar",
      "Mental Misstep",
      "Merchant Scroll",
      "Mind's Desire",
      "Monastery Mentor",
      "Mox Emerald",
      "Mox Jet",
      "Mox Pearl",
      "Mox Ruby",
      "Mox Sapphire",
      "Mystic Forge",
      "Mystical Tutor",
      "Narset, Parter of Veils",
      "Necropotence",
      "Ponder",
      "Sol Ring",
      "Strip Mine",
      "Thorn of Amethyst",
      "Time Vault",
      "Time Walk",
      "Timetwister",
      "Tinker",
      "Tolarian Academy",
      "Treasure Cruise",
      "Trinisphere",
      "Vampiric Tutor",
      "Wheel of Fortune",
      "Windfall",
      "Yawgmoth's Will",
      "Adriana's Valor",
      "Advantageous Proclamation",
      "Assemble the Rank and Vile",
      "Backup Plan",
      "Brago's Favor",
      "Double Stroke",
      "Echoing Boon",
      "Emissary's Ploy",
      "Hired Heist",
      "Hold the Perimeter",
      "Hymn of the Wilds",
      "Immediate Action",
      "Incendiary Dissent",
      "Iterative Analysis",
      "Muzzio's Preparations",
      "Natural Unity",
      "Power Play",
      "Secrets of Paradise",
      "Secret Summoning",
      "Sentinel Dispatch",
      "Sovereign's Realm",
      "Summoner's Bond",
      "Unexpected Potential",
      "Weight Advantage",
      "Worldknit",
      "Amulet of Quoz",
      "Bronze Tablet",
      "Contract from Below",
      "Darkpact",
      "Demonic Attorney",
      "Jeweled Bird",
      "Rebirth",
      "Tempest Efreet",
      "Timmerian Fiends",
    ],
  },
  LEGACY: {
    suspenso: [],
    banlist: [
      "Ancestral Recall",
      "Arcum's Astrolabe",
      "Balance",
      "Bazaar of Baghdad",
      "Black Lotus",
      "Channel",
      "Chaos Orb",
      "Deathrite Shaman",
      "Demonic Consultation",
      "Demonic Tutor",
      "Dig Through Time",
      "Dreadhorde Arcanist",
      "Earthcraft",
      "Falling Star",
      "Fastbond",
      "Flash",
      "Frantic Search",
      "Gitaxian Probe",
      "Goblin Recruiter",
      "Gush",
      "Hermit Druid",
      "Imperial Seal",
      "Library of Alexandria",
      "Lurrus of the Dream-Den",
      "Mana Crypt",
      "Mana Drain",
      "Mana Vault",
      "Memory Jar",
      "Mental Misstep",
      "Mind Twist",
      "Mind’s Desire",
      "Mishra’s Workshop",
      "Mox Emerald",
      "Mox Jet",
      "Mox Pearl",
      "Mox Ruby",
      "Mox Sapphire",
      "Mystical Tutor",
      "Necropotence",
      "Oath of Druids",
      "Oko, Thief of Crowns",
      "Sensei's Divining Top",
      "Shahrazad",
      "Skullclamp",
      "Sol Ring",
      "Strip Mine",
      "Survival of the Fittest",
      "Time Vault",
      "Time Walk",
      "Timetwister",
      "Tinker",
      "Tolarian Academy",
      "Treasure Cruise",
      "Underworld Breach",
      "Vampiric Tutor",
      "Wheel of Fortune",
      "Windfall",
      "Wrenn and Six",
      "Yawgmoth's Bargain",
      "Yawgmoth's Will",
      "Zirda, the Dawnwaker",
      "Adriana's Valor",
      "Advantageous Proclamation",
      "Assemble the Rank and Vile",
      "Backup Plan",
      "Brago's Favor",
      "Double Stroke",
      "Echoing Boon",
      "Emissary's Ploy",
      "Hired Heist",
      "Hold the Perimeter",
      "Hymn of the Wilds",
      "Immediate Action",
      "Incendiary Dissent",
      "Iterative Analysis",
      "Muzzio's Preparations",
      "Natural Unity",
      "Power Play",
      "Secrets of Paradise",
      "Secret Summoning",
      "Sentinel Dispatch",
      "Sovereign's Realm",
      "Summoner's Bond",
      "Unexpected Potential",
      "Weight Advantage",
      "Worldknit",
      "Amulet of Quoz",
      "Bronze Tablet",
      "Contract from Below",
      "Darkpact",
      "Demonic Attorney",
      "Jeweled Bird",
      "Rebirth",
      "Tempest Efreet",
      "Timmerian Fiends",
      "Ragavan, Nimble Pilferer",
    ],
  },
  COMMANDER: {
    suspenso: [],
    banlist: [
      "Ancestral Recall",
      "Balance",
      "Biorhythm",
      "Black Lotus",
      "Braids, Cabal Minion",
      "Chaos Orb",
      "Coalition Victory",
      "Channel",
      "Emrakul, the Aeons Torn",
      "Erayo, Soratami Ascendant",
      "Falling Star",
      "Fastbond",
      "Flash",
      "Gifts Ungiven",
      "Golos, Tireless Pilgrim",
      "Griselbrand",
      "Hullbreacher",
      "Iona, Shield of Emeria",
      "Karakas",
      "Leovold, Emissary of Trest",
      "Library of Alexandria",
      "Limited Resources",
      "Lutri, the Spellchaser",
      "Mox Emerald",
      "Mox Jet",
      "Mox Pearl",
      "Mox Ruby",
      "Mox Sapphire",
      "Panoptic Mirror",
      "Paradox Engine",
      "Primeval Titan",
      "Prophet of Kruphix",
      "Recurring Nightmare",
      "Rofellos, Llanowar Emissary",
      "Shahrazad",
      "Sundering Titan",
      "Sway of the Stars",
      "Sylvan Primordial",
      "Time Vault",
      "Time Walk",
      "Tinker",
      "Tolarian Academy",
      "Trade Secrets",
      "Upheaval",
      "Yawgmoth's Bargain",
      "Adriana's Valor",
      "Advantageous Proclamation",
      "Assemble the Rank and Vile",
      "Backup Plan",
      "Brago's Favor",
      "Double Stroke",
      "Echoing Boon",
      "Emissary's Ploy",
      "Hired Heist",
      "Hold the Perimeter",
      "Hymn of the Wilds",
      "Immediate Action",
      "Incendiary Dissent",
      "Iterative Analysis",
      "Muzzio's Preparations",
      "Natural Unity",
      "Power Play",
      "Secrets of Paradise",
      "Secret Summoning",
      "Sentinel Dispatch",
      "Sovereign's Realm",
      "Summoner's Bond",
      "Unexpected Potential",
      "Weight Advantage",
      "Worldknit",
      "Amulet of Quoz",
      "Bronze Tablet",
      "Contract from Below",
      "Darkpact",
      "Demonic Attorney",
      "Jeweled Bird",
      "Rebirth",
      "Tempest Efreet",
      "Timmerian Fiends",
    ],
  },
  BRAWL: {
    suspenso: [],
    banlist: [
      "Drannith Magistrate",
      "Lutri, the Spellchaser",
      "Oko, Thief of Crowns",
      "Omnath, Locus of Creation",
      "Runed Halo",
      "Sorcerous Spyglass",
      "Winota, Joiner of Forces",
      "Pithing Needle",
    ],
  },
  Historyc_Brawl: {
    suspenso: [],
    banlist: ["Runed Halo", "Gideon's Intervention", "Meddling Mage", "Pithing Needle"],
  },
  PAUPER: {
    suspenso: [],
    banlist: [
      "Arcum's Astrolabe",
      "Atog",
      "Bonder's Ornament",
      "Chatterstorm",
      "Cloud of Faeries",
      "Cloudpost",
      "Cranial Plating",
      "Daze",
      "Empty the Warrens",
      "Expedition Map",
      "Fall from Favor",
      "Frantic Search",
      "Gitaxian Probe",
      "Grapeshot",
      "Gush",
      "Invigorate",
      "Mystic Sanctuary",
      "Peregrine Drake",
      "Prophetic Prism",
      "Sojourner's Companion",
      "Temporal Fissure",
      "Treasure Cruise",
      "High Tide",
      "Hymn to Tourach",
      "Sinkhole",
    ],
  },
  PIONEER: {
    suspenso: [],
    banlist: [
      "Balustrade Spy",
      "Bloodstained Mire",
      "Felidar Guardian",
      "Field of the Dead",
      "Flooded Strand",
      "Inverter of Truth",
      "Kethis, the Hidden Hand",
      "Leyline of Abundance",
      "Nexus of Fate",
      "Oko, Thief of Crowns",
      "Once Upon a Time",
      "Polluted Delta",
      "Smuggler’s Copter",
      "Undercity Informer",
      "Underworld Breach",
      "Uro, Titan of Nature's Wrath",
      "Teferi, Time Raveler",
      "Veil of Summer",
      "Walking Ballista",
      "Wilderness Reclamation",
      "Windswept Heath",
      "Wooded Foothills",
    ],
  },
  HISTORIC: {
    suspenso: [],
    banlist: [
      "Agent of Treachery",
      "Brainstorm",
      "Field of the Dead",
      "Fires of Invention",
      "Memory Lapse",
      "Nexus of Fate",
      "Oko, Thief of Crowns",
      "Omnath, Locus of Creation",
      "Once Upon a Time",
      "Tibalt's Trickery",
      "Thassa's Oracle",
      "Uro, Titan of Nature's Wrath",
      "Veil of Summer",
      "Wilderness Reclamation",
      "Winota, Joiner of Forces",
    ],
  },
};
