import React from "react";
import "./profilePicture.css";
import images from "../../assets/img";

function Index() {
  return (
    <span className="AvatarRadarMini">
      {/* <div className="AvatarIMG">A</div> */}
      <img src={images.avatar1} className="AvatarIMGMini" alt="iconPlayr" />
    </span>
  );
}

export default Index;
