import React from "react";
import { Dialog, Button } from "@material-ui/core";
import axios from "axios";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import service from "../../services/services";
import api from "../../services/api";
import "./Dialog.css";
import language from "../../assets/language/text";

const DialogBox = () => {
  const { btnDialog, setBtnDialog, dialogMsg, setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const { user, setUser } = useStore();
  const text = language[lang].addPlayer;

  const handleCreatePlayer = async (event) => {
    event.preventDefault();
    if (dialogMsg.type === "player") {
      await api
        .put(`users/${user._id}`, {
          user: { access: 1 },
        })
        .catch(console.log);
      setBtnDialog(false);
      setAlertMsg(`Player ${user.nickname} Criado`);
      setAlert(true);
      setSeverity("success");
      service.GetPlayerById(user.id);
    } else if (dialogMsg.type === "evo") {
      setBtnDialog(false);
      setAlert(true);
      setAlertMsg("Solicitação enviada!");
    }
  };

  const handleClose = (e) => {
    setBtnDialog(false);
  };

  return (
    <>
      <Dialog
        open={btnDialog}
        className="dialog"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <div className="dialog-box">
          <div className="form-dialog-title">{dialogMsg.title}</div>
          <div>
            <div> {dialogMsg.content} </div>
          </div>
          <div className="dialog-action">
            <Button onClick={handleClose}>{text[0]}</Button>
            <Button onClick={handleCreatePlayer}>{text[1]}</Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DialogBox;
