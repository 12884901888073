import { useEffect, useState } from "react";
import { CircularProgress, Dialog } from "@material-ui/core";
import { useWallet } from "../../context/walletContext";
import { useStore } from "../../context/storeContext";
import { setTimeout } from "timers";
import LoadingPage from "../../components/modais/loadingPage";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
import axios from "axios";
import CreateAccount from "./finalizationForm";
import twoDecimals from "../../utils/twoDecimals";

function BuyEq9({ open, setOpen }) {
  const { user } = useStore();
  const [pixLoading, setPixLoading] = useState(false);
  const [realValue, setRealValue] = useState("1");
  const [pix, setPix] = useState(false);
  const [pixImg, setPixImg] = useState();
  const [pixConfirm, setPixConfirm] = useState(false);
  const [info, setInfo] = useState();
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const { getBalanceOf } = useWallet();
  const [username, setName] = useState();
  const [eq9Price, setEq9Price] = useState();
  const [eq9Value, setEq9Value] = useState(eq9Price);
  const [openForm, setOpenForm] = useState(false);
  const [discount, setDiscount] = useState();
  const [eq9ServerValue, setEq9ServerValue] = useState(setEq9Price);
  const [coupons, setCoupons] = useState({});
  const cashback = false;
  const cashbackPromotion = {
    title: lang === "pt" ? "Semana do CashBack!" : "CashBack Week!",
    description: lang === "pt" ? "Compre 2 leve 3" : "Buy 2 take 3",
    valid: lang === "pt" ? "Válido até dia 27/02" : "Valid thru day 02/27",
  };
  const [inputs, setInputs] = useState({
    name: user.name ? user.name : "",
    cpf: user.cpf,
    birthDate: user.birthDate,
    username: user.username,
    address: user.address,
    addressNumber: user.addressNumber,
    complement: user.complement,
    zipCode: user.zipCode,
    district: user.district,
    city: user.city,
    state: user.state,
    country: user.country,
  });

  const language = {
    pt: [
      "COMPRAR EQ9",
      "COMPRAR",
      "GERANDO PIX...",
      "FINALIZAR",
      "Pagamento Concluído com Sucesso!",
      "CONFIRMANDO PAGAMENTO! POR FAVOR, NÃO SAIA DA TELA.",
      "Clique na imagem para copiar.",
    ],
    en: [
      "BUY EQ9",
      "BUY",
      "GENERATING PIX...",
      "FINISH",
      "Payment Successfully Completed!",
      "CONFIRMING PAYMENT! PLEASE DO NOT LEAVE THE SCREEN.",
      "Click on the image to copy.",
    ],
  };

  const text = language[lang];

  const getCoupons = async () => {
    const fetchCoupons = await api.get(`payments/coupons`).catch(console.log);
    if (fetchCoupons) {
      setCoupons(fetchCoupons.data);
    }
  };

  const getEq9Value = async () => {
    const getValue = await api.get("/payments/eq9/value").catch(console.log);
    if (getValue) {
      setEq9ServerValue(+getValue.data);
      setEq9Price(+getValue.data);
      setEq9Value(1);
      setRealValue(+getValue.data);
    }
  };

  useEffect(() => {
    if (open) {
      const validateInputs = Object.keys(inputs).filter((item) => {
        if (item != "complement" && !inputs[item]) return true;
      });

      if (validateInputs.length > 0) {
        setOpenForm(true);
      }
      getCoupons();
      getEq9Value();
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseForm = () => {
    setOpenForm(false);
    setOpen(false);
  };

  const getValue = (value) => {
    if (value) {
      const Value = value.toString();
      if (Value.includes(".")) {
        const valueSplited = Value.split(".");
        if (valueSplited[1].length > 2) return valueSplited[0] + "." + valueSplited[1].slice(0, 2);
        if (valueSplited[1].length === 1) return valueSplited[0] + "." + valueSplited[1] + "0";
      } else {
        return value + ".00";
      }
    }
  };

  const handleDiscountChange = (e) => {
    if (e) {
      const confirmDiscount = coupons[e];
      if (confirmDiscount !== undefined) {
        const getCouponValue = coupons[e];
        setEq9Price(coupons[e]);
        setEq9Value(getValue(realValue / getCouponValue));
      } else {
        setEq9Price(+eq9ServerValue);
        setEq9Value(getValue(+realValue / +eq9ServerValue));
      }
    }
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    if (name === "realValue" && (value !== 0 || value !== null)) {
      setEq9Value(value / eq9Price);
      setRealValue(value);
    } else if (name === "eq9Value" && (value !== 0 || value !== null)) {
      setEq9Value(value);
      setRealValue(value * eq9Price);
    }
    if (value == 0 || value < 0) {
      setEq9Value(0);
      setRealValue(0);
    }
  };

  const handleCopyQrValue = () => {
    navigator.clipboard.writeText(pix);
    setAlert(true);
    setAlertMsg("Successfuly Copied!");
    setSeverity("success");
  };

  // ====================== / LOOP DE CONFIRMAÇÃO / ======================== //

  const confirmPayment = (txId) => {
    setTimeout(async () => {
      const getTransactionConfirm = await api.get(`payments/transaction/${txId}`).catch(console.log);
      if (getTransactionConfirm.data.status === "CONCLUIDA") {
        setAlert(true);
        setAlertMsg("Transação concluída com sucesso!");
        setSeverity("success");
        setPixConfirm(true);
        getBalanceOf();
        api.put(`payments/virtual/${txId}`);
      } else {
        confirmPayment(txId);
      }
    }, 10000);
  };

  // ====================== // ======================== //

  const handlePixGenerate = async () => {
    if (!pixConfirm) {
      const PixValue = { name: user.name !== undefined ? user.name : username, cpf: user.cpf, value: getValue(realValue), coupon: discount };
      if (PixValue.name !== undefined && PixValue.cpf !== undefined && PixValue.value !== undefined) {
        setPixLoading(true);
        const getPixGen = await api.post("/payments", PixValue).catch(console.log);
        if (getPixGen) {
          setPix(getPixGen.data.qrcode.qrcode);
          setPixImg(getPixGen.data.qrcode.imagemQrcode);
          setInfo(getPixGen.data.pix.infoAdicionais[0]);
          setAlert(true);
          setAlertMsg("PIX SUCCESSFULY CREATED!");
          setSeverity("success");
          setTimeout(() => {
            confirmPayment(getPixGen.data.pix.txid);
          }, 10000);
        }
      } else {
        setAlert(true);
        setAlertMsg("NEED A VALID VALUE TO CONTINUE");
        setSeverity("error");
      }
    } else {
      setPixConfirm(true);
    }
  };

  const getDoubleLength = (value) => {
    const DoubleLenght = (value) => {
      if (value.length < 2) return `${value}0`;
      else return value;
    };
    if (value) {
      const stringValue = value.toString();
      const valueSplited = stringValue.split(".");
      const ResValue = valueSplited[0] + "," + DoubleLenght(valueSplited[1]);
      return ResValue;
    }
  };

  if (openForm) return <CreateAccount open={openForm} setOpen={handleCloseForm} user={user} setInputs={setInputs} />;

  return (
    <Dialog
      open={open}
      onClose={() => {
        !pixLoading && handleClose();
      }}
      PaperProps={{ style: { padding: "5px", maxWidth: "500px", borderRadius: "15px", background: "none" } }}
      BackdropProps={{ style: { backdropFilter: "blur(10px)" } }}
    >
      {pix ? (
        !pixConfirm ? (
          <section
            style={{
              width: "450px",
              height: "450px",
              borderRadius: "15px",
              background: "rgba(0,0,0,0.7)",
              display: open ? "block" : "none",
              zIndex: "300",
            }}
          >
            <div
              className="default-input"
              style={{
                height: "350px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              {info && (
                <p style={{ padding: "5px" }}>
                  {info.nome} {info.valor}
                </p>
              )}
              {pixImg ? (
                <img src={pixImg} style={{ width: "280px", height: "280px", color: "var(--pink)" }} alt="pixImg" onClick={handleCopyQrValue} />
              ) : (
                <img
                  src="https://www.kaspersky.com.br/content/pt-br/images/repository/isc/2020/9910/a-guide-to-qr-codes-and-how-to-scan-qr-codes-2.png"
                  style={{ width: "280px", height: "280px" }}
                  alt="pixImg"
                  onClick={handleCopyQrValue}
                />
              )}
              <div style={{ padding: "10px 10px 0px 10px", color: "var(--pink)" }} onClick={handleCopyQrValue}>
                {text[6]}
              </div>
            </div>
            <p className="default-input" style={{ fontSize: "0.7rem", textAlign: "center" }}>
              {text[5]}
            </p>
            {/* <button
              className="default-input"
              style={{ background: "transparent" }}
              onClick={() => {
                setPixLoading(false);
                setPix(false);
              }}
            >
              FINALIZAR
            </button> */}
          </section>
        ) : (
          <section
            style={{
              width: "450px",
              height: "450px",
              background: "rgba(0,0,0,0.7)",
              zIndex: "100",
              borderRadius: "10px",
              padding: "1rem",
              zIndex: "300",
            }}
          >
            <div
              className="default-input"
              style={{
                height: "350px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "1rem",
                fontSize: "1rem",
                fontFamily: "gilroy-bold",
              }}
            >
              <h1 style={{ fontFamily: "gilroy-bold", color: "var(--lightblue)", fontSize: "1rem" }}>{text[4]}</h1>
            </div>
            <button
              className="default-input"
              onClick={() => {
                setPixLoading(false);
                setPixConfirm(false);
                setPix(false);
                setPixImg();
                handleClose();
              }}
              style={{ background: "transparent", color: "var(--lightblue)", border: "2px var(--lightblue) solid" }}
            >
              {text[3]}
            </button>
          </section>
        )
      ) : pixLoading ? (
        <section
          style={{
            width: "450px",
            height: "450px",
            background: "rgba(0,0,0,0.7)",
            zIndex: "100",
            borderRadius: "10px",
            padding: "1rem",
            zIndex: "300",
          }}
        >
          <div
            className="default-input"
            style={{
              height: "350px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "1rem",
              fontSize: "1rem",
              fontFamily: "gilroy-bold",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
          <button className="default-input" style={{ background: "transparent" }}>
            {text[2]}
          </button>
        </section>
      ) : (
        <>
          <h1
            style={{
              textAlign: "center",
              color: "var(--pink)",
              position: "absolute",
              top: "2.6rem",
              left: "50%",
              transform: "translate(-50%,-50%)",
              zIndex: 1000,
            }}
          >
            {cashback ? (lang === "pt" ? "EQ9" : "BUY EQ9") : text[0]}
          </h1>
          <section
            style={{
              width: "450px",
              height: "450px",
              display: open ? "block" : "none",
              background: "rgba(0,0,0,0.7)",
              zIndex: "100",
              borderRadius: "10px",
              zIndex: "300",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "350px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem",
              }}
            >
              <span style={{ fontSize: "0.9rem", paddingTop: "10px" }}>
                1 EQ9 = <b style={{ color: "var(--lightgreen)" }}>R$ {getDoubleLength(eq9Price)}</b>
              </span>
              {/* {!user.name && (
                <input
                  className="default-input"
                  style={{
                    width: "380px",
                    height: "3rem",
                    margin: "1rem",
                    fontSize: "1.7rem",
                    letterSpacing: "5px",
                    textAlign: "center",
                    color: "var(--pink)",
                  }}
                  value={username}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="DIGITE SEU NOME AQUI"
                />
              )} */}
              {/* <CPFInput
                className="default-input"
                style={{
                  width: "380px",
                  height: "3rem",
                  fontSize: "1.7rem",
                  letterSpacing: "5px",
                  textAlign: "center",
                  color: "var(--pink)",
                }}
                value={cpfValue}
                onChange={(event, type) => {
                  setCpfValue(event.target.value);
                  setMask(type === "CPF");
                }}
                maxLength={14}
                placeholder="DIGITE AQUI O CPF"
              /> */}
              <p style={{ padding: "5px", color: "var(--lightblue)", marginTop: "1rem", marginBottom: "-10px", fontSize: "1rem", fontFamily: "gilroy" }}>
                {user?.wallets?.default?.address}
              </p>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <label style={{ padding: "2rem", fontSize: "2rem", fontFamily: "gilroy-bold", color: "var(--pink)", marginLeft: "-0.8rem" }}>R$</label>
                <input
                  type="number"
                  style={{
                    padding: "10px",
                    margin: "1rem",
                    fontSize: "1rem",
                    textAlign: "right",
                    width: "100%",
                    height: "3rem",
                    background: "none",
                    border: "none",
                    color: "var(--pink)",
                    outline: "none",
                  }}
                  name="realValue"
                  value={twoDecimals(realValue)}
                  min={0}
                  onChange={handleValueChange}
                  onClick={() => setRealValue()}
                  placeholder="VALOR"
                />
              </div>
              <div style={{ width: "100%", height: "1px", background: "var(--blue)", opacity: "0.4" }} />
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <label style={{ padding: "2rem", fontSize: "2rem", fontFamily: "gilroy-bold", color: "var(--pink)", marginLeft: "-0.8rem" }}>EQ9</label>
                <input
                  type="number"
                  style={{
                    padding: "10px",
                    margin: "1rem",
                    fontSize: "1rem",
                    textAlign: "right",
                    width: "100%",
                    height: "3rem",
                    background: "none",
                    border: "none",
                    color: "var(--pink)",
                    outline: "none",
                  }}
                  name="eq9Value"
                  value={twoDecimals(eq9Value)}
                  min={0}
                  onChange={handleValueChange}
                  placeholder="EQ9"
                />
                {cashback && <span style={{ color: "var(--lightgreen)" }}>+{twoDecimals(eq9Value / 2)}</span>}
              </div>
              <div style={{ width: "90%", height: "30px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <input
                  style={{
                    width: "93%",
                    height: "30px",
                    textAlign: "center",
                    border: "none",
                    borderBottom: `1px solid rgba(0,150,200,0.5)`,
                    color: coupons[discount] ? "var(--lightgreen)" : "black",
                    outline: "none",
                    fontFamily: "gilroy-bold",
                    background: "transparent",
                    color: "var(--pink)",
                  }}
                  value={discount}
                  placeholder="UTILIZE SEU CUPOM DE DESCONTO AQUI!"
                  onChange={(e) => {
                    handleDiscountChange(e.target.value.toUpperCase());
                    setDiscount(e.target.value.toUpperCase());
                  }}
                />
                {coupons[discount] && <i className="fas fa-check" style={{ color: "var(--lightgreen)", fontStyle: "uppercase" }} />}
              </div>
              {coupons[discount] && (
                <span style={{ color: "var(--lightgreen)", fontSize: "0.8em", position: "absolute", bottom: "5rem" }}>
                  {lang === "pt" ? "CUPOM APLICADO!" : "COUPON APPLIED!"}
                </span>
              )}
            </div>
            <button
              className="default-input"
              style={{ background: "transparent", width: "85%", cursor: "pointer" }}
              onClick={() => {
                handlePixGenerate();
              }}
            >
              {text[1]}
            </button>
            {cashback && (
              <div style={{ position: "absolute", top: "0", right: "0", fontSize: ".8em", textAlign: "center", padding: "3px" }}>
                <h1 style={{ color: "var(--lightgreen)" }}>{cashbackPromotion.title}</h1>
                <p style={{ fontSize: "1rem", color: "var(--pink)" }}>{cashbackPromotion.description}</p>
                <p style={{ fontFamily: "gilroy-thin", fontSize: "1.1em" }}>{cashbackPromotion.valid}</p>
              </div>
            )}
          </section>
        </>
      )}
    </Dialog>
  );
}

export default BuyEq9;
