import React, { useEffect, useState } from "react";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import language from "../../assets/language/text";

function Counter({ date, hour }) {
  const { lang } = useLocalDB();
  const [secondsRadius, setSecondsRadius] = useState();
  const text = language[lang].counter;
  let year = 2021;
  const getDateNow = new Date();

  const calculateTimeLeft = () => {
    const difference = +new Date(`${date.a}-${date.m}-${date.d} ${hour}`) - +getDateNow;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  function mapNumber(number, in_min, in_max, out_min, out_max) {
    return ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  }
  const SVGCircle = ({ radius }) => (
    <svg className="countdown-svg" style={{ position: "absolute", right: "-15rem", top: "-0.7rem" }}>
      <path fill="none" stroke="white" stroke-width="2" d={describeArc(25, 18, 17, 10, radius)} />
    </svg>
  );
  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }
  function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");

    return d;
  }

  useEffect(() => {
    setSecondsRadius(mapNumber(timeLeft.seconds, 60, 0, 0, 360));
  }, [timeLeft]);

  // const [year] = useState(new Date().getFullYear());

  setTimeout(() => {
    setTimeLeft(calculateTimeLeft());
  }, 1000);

  if (!date) return null;
  return (
    <div
      className="counterPage"
      style={{ width: "200px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}
    >
      <div>
        <h1
          style={{
            fontSize: "0.8rem",
            textAlign: "center",
            width: "250px",
            transform: "scale(0.85)",
          }}
        >
          <h3>{text[0]}</h3>
          {/* <p>{text[8]}</p> */}
          {timeLeft ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginLeft: "-1.5rem",
                marginTop: "1.5rem",
                transform: "scale(1.4)",
              }}
            >
              <div
                style={{
                  padding: "0 0.7rem",
                  fontFamily: "digital-counter",
                }}
              >
                <p
                  style={{
                    fontFamily: "digital-counter",
                    marginBottom: "5px",
                  }}
                >
                  {timeLeft.days}
                </p>
                <p style={{ fontSize: ".5em", fontFamily: "gilroy-bold" }}>{text[1]}</p>
              </div>

              <p>:</p>
              <div
                style={{
                  fontFamily: "digital-counter",
                  marginBottom: "5px",
                }}
              >
                <p
                  style={{
                    fontFamily: "digital-counter",
                    marginBottom: "5px",
                  }}
                >
                  {timeLeft.hours}
                </p>
                <p style={{ fontSize: ".5em", fontFamily: "gilroy-bold" }}>{text[2]}</p>
              </div>
              <p>:</p>

              <div
                style={{
                  padding: "0 0.7rem",
                  fontFamily: "digital-counter",
                }}
              >
                <p
                  style={{
                    fontFamily: "digital-counter",
                    marginBottom: "5px",
                  }}
                >
                  {timeLeft.minutes}
                </p>
                <p style={{ fontSize: ".5em", fontFamily: "gilroy-bold" }}>{text[3]}</p>
              </div>
              <div
                style={{
                  padding: "0 3rem",
                  fontFamily: "digital-counter",
                  width: "25px",
                  position: "absolute",
                  right: "1.0rem",
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontFamily: "digital-counter",
                    marginBottom: "5px",
                    minWidth: "25px",
                    textAlign: "center",
                  }}
                >
                  {timeLeft.seconds}
                </p>
                <div>
                  <SVGCircle radius={secondsRadius} />
                </div>
              </div>

              <div></div>
            </div>
          ) : (
            <span>Time's up!</span>
          )}
        </h1>
      </div>
    </div>
  );
}

export default Counter;
