import React from "react";

const UIInput = ({ children, className, type, id, typeIcon, icon, placeholder, wdt, theme, ref, ...restProps }) => {
  return (
    <div style={{ width: `${wdt}%` }} className={`input-box ${className}`}>
      <input type={type} id={`input-id-${id}`} ref={ref} autocomplete="off" required className={`ui-input ui-input--${theme} ${className}`} {...restProps} />
      <label for={`input-id-${id}`}>{placeholder}</label>
      <i className={`${typeIcon} ${icon}`}></i>
    </div>
  );
};

UIInput.defaultProps = {
  wdt: "100",
  theme: "default",
  placeholder: "input",
  className: "",
  type: "text",
  id: "0",
  typeIcon: "fas",
  icon: "fa-angle-right",
};

export default UIInput;
