import { useState, useRef } from "react";
import images from "../../assets/img";
import { useHistory } from "react-router-dom";
import { useLocalDB } from "../../context/LocalDB";
import Pop from "../../components/PopOver";

function WinnerCard({ data, side, style }) {
  return (
    <>
      {data ? (
        <div className="room-card-player" style={style}>
          <div className="front">
            <div className="backCardIMG" />
            <div className="details-avatar-front" style={{ left: side === 1 ? "-4rem" : "13rem" }}>
              {data.img ? (
                <img src={data.img && data.img} alt="Player Photo" />
              ) : data.user?.img ? (
                <img src={data.user.img} alt="Player Photo" />
              ) : (
                <img src={images.unknown} alt="player-img" />
              )}
            </div>
            <div
              className="social-iconsCard"
              style={{
                transform: side === 2 ? "scaleX(-1)" : null,
                left: side === 1 ? "-20%" : "100%",
              }}
            >
              <a href="http://facebook.com.br/" rel="noreferrer" target="_blank" style={{ transform: side === 2 ? "scaleX(-1)" : null }}>
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="http://twitch.tv/" rel="noreferrer" target="_blank" style={{ transform: side === 2 ? "scaleX(-1)" : null }}>
                <i className="fab fa-twitch"></i>
              </a>
              <a href="http://youtube.com.br/" rel="noreferrer" target="_blank" style={{ transform: side === 2 ? "scaleX(-1)" : null }}>
                <i className="fab fa-youtube"></i>
              </a>
              <a href="http://instagram.com.br/" rel="noreferrer" target="_blank" style={{ transform: side === 2 ? "scaleX(-1)" : null }}>
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <div className="backContent">
              <h1
                className="gradientNickBack"
                style={{
                  textAlign: side === 1 ? "start" : "end",
                  marginLeft: side === 2 && "-25px",
                }}
              >
                <Pop cutValue={6}>{data?.nickname ? data?.nickname : data.name}</Pop>
              </h1>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default WinnerCard;
