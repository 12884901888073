import { useState, useEffect, useRef } from "react";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import { useStore } from "../../context/storeContext";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
import language from "../../assets/language/text";
import decode from "jwt-decode";
import { useWallet } from "../../context/walletContext";
import ParticlesPage from "../../components/Particles";
import { harmonyConfig } from "../../context/config/harmonyConfig";
import eq9Config from "../../services/eq9Config";
import ResetSend from "../resetPassword/resetSend";
import AlertMsg from "../../components/alert/alert";
import { Link as Link2 } from "@material-ui/core";

const initialState = { username: "", password: "" };
const initialSignUpState = {
  username: "",
  password: "",
  passwordConfirm: "",
  nickname: "",
  // wallets: {
  //   default: "",
  // },
};

const tokenAddress = eq9Config.trc20ContractAddress;
const tokenSymbol = "EQ9";
const tokenDecimals = 18;
const tokenImage = "https://equalsimages.s3.amazonaws.com/eq9.png";

const Login = () => {
  const { token, setToken, setUser } = useStore();
  const [loginChecked, setLoginChecked] = useState(false);
  const [signUpChecked, setSignUpChecked] = useState(false);
  const { loginNav, setLoginNav, setAlert, setAlertMsg, setUserLocalData, setAccess, setSeverity, lang, mobile, setMobile } = useLocalDB();
  const [valuesSignUp, setValuesSignUp] = useState(initialSignUpState);
  const [valuesLogin, setValuesLogin] = useState(initialState);
  const [signUp, setSignUp] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const text = language[lang].login_page;
  const usernameLogin = useRef(null);
  const passwordLogin = useRef(null);
  const usernameRegister = useRef(null);
  const passwordConfirmRegister = useRef(null);
  const passwordRegister = useRef(null);
  const submitLogin = useRef(null);
  const submitSignUp = useRef(null);
  const [passwordResetModal, setPasswordResetModal] = useState(false);
  const [openGuide, setOpenGuide] = useState(false);
  const [openWalletGuide, setOpenWalletGuide] = useState(false);
  const { setInstalled, account, setAddress, status } = useWallet();

  const history = useHistory();

  useEffect(() => {
    if (!token) setLoginNav(true);
    else if (token) setLoginNav(false);
  }, []);

  const [process, setProcess] = useState({
    icon: "",
    label: text[11],
    color: "primary",
  });

  const [signUpProcess, setSignUpProcess] = useState({
    icon: "",
    label: text[12],
    color: "primary",
  });

  const handlePasswordReset = (e) => {
    e.preventDefault();
    setPasswordResetModal(true);
  };

  const handleSignUpCheck = () => {
    setSignUpChecked(!signUpChecked);
  };

  const handleLoginCheck = () => {
    setLoginChecked(!loginChecked);
  };

  async function Log_in(values) {
    const auth = await api.post(`login`, values).catch(() => {
      setProcess({
        label: text[13],
        color: "secondary",
      });
      setAlertMsg(text[13]);
      setSeverity("error");
      setAlert(true);
      setTimeout(() => {
        setValuesLogin(initialState);
        setProcess({ label: text[11] });
      }, 1000);
    });
    if (auth) {
      api.defaults.headers.Authorization = `Bearer ${auth.data.access_token}`;
      const decoded = decode(auth.data.access_token);
      const getUser = await api.get(`users/${decoded.sub}`);
      setAlertMsg(`${text[14]} ${getUser.data.nickname}, ${text[15]}`);
      setProcess({ icon: <DoneIcon />, label: "SUCESSO!", color: "primary" });
      setSeverity("success");
      setAlert(true);
      setAccess(1);
      const token = auth.data.access_token;
      const user = getUser.data;
      setUser(user);
      return setTimeout(() => {
        window.location.reload();
        setToken(token);
        setLoginNav(false);
      }, 1000);
      // return loginChecked === true
      //   ? setTimeout(() => {
      //       window.location.reload();
      //       setToken(token);
      //       setLoginNav(false);
      //     }, 1000)
      //   : setTimeout(() => {
      //       window.location.reload();
      //       setLoginNav(false);
      //       setToken(token);
      //     }, 1000);
    }
  }

  async function handleLogin(e) {
    e.preventDefault();
    setProcess({ label: text[16], color: "default" });
    Log_in({
      username: usernameLogin.current.value,
      password: passwordLogin.current.value,
    });
    // Log_in(valuesLogin);
  }

  async function handleSignUp(e) {
    e.preventDefault();
    const registerValues = {
      ...valuesSignUp,
      username: usernameRegister.current.value,
      password: passwordRegister.current.value,
      passwordConfirm: passwordConfirmRegister.current.value,
      // wallets: { default: tronWalletValue.current.value },
    };
    // if (tronWalletValue.current.value.length > 10) {
    if (registerValues.password === registerValues.passwordConfirm) {
      setSignUpProcess({ label: text[16], color: "default" });
      e.preventDefault();
      if (signUpChecked) {
        const register = await api
          .post(`users/no-wallet`, {
            username: registerValues.username,
            password: registerValues.password,
            nickname: registerValues.nickname,
            // wallets: registerValues.wallets,
          })
          .catch((error) => {
            setSignUpProcess({
              label: text[17],
              color: "secondary",
            });
            setAlertMsg(text[17]);
            setSeverity("error");
            setAlert(true);
            setTimeout(() => {
              setSignUpProcess({ label: text[12] });
              // setValuesSignUp(initialSignUpState);
            }, 1000);
          });
        if (register) {
          setAlertMsg(`${text[14]} ${register.data.nickname}, ${text[15]}, ${text[18]}`);
          setSignUpProcess({
            icon: <DoneIcon />,
            label: text[19],
            color: "primary",
          });
          setSeverity("success");
          setAlert(true);
          Log_in({
            username: registerValues.username,
            password: registerValues.password,
          });
          setTimeout(() => {
            setSignUpProcess({ label: text[12] });
            setValuesSignUp(initialSignUpState);
          }, 1500);
        }
      } else {
        setSignUpProcess({
          label: text[20],
          color: "secondary",
        });
        setAlertMsg(text[21]);
        setSeverity("error");
        setAlert(true);
        setTimeout(() => {
          setSignUpProcess({ label: text[12] });
        }, 1000);
      }
    } else {
      setSignUpProcess({
        label: text[22],
        color: "secondary",
      });
      setAlertMsg(text[23]);
      setSeverity("error");
      setAlert(true);
      setTimeout(() => {
        setSignUpProcess({ label: text[12] });
        setValuesSignUp({
          ...valuesSignUp,
          password: "",
          passwordConfirm: "",
        });
      }, 1000);
    }
    // } else {
    //   setSignUpProcess({ label: "Need to be a valid Wallet" });
    //   setAlert(true);
    //   setAlertMsg("Need to be a valid Metamask Wallet Hash");
    //   setSeverity("error");
    //   setTimeout(() => {
    //     setSignUpProcess({ label: text[12] });
    //   }, 1500);
    // }
  }

  function handleInputLoginChange(event) {
    const { value, name } = event.target;
    setValuesLogin({
      ...valuesLogin,
      [name]: value,
    });
  }
  function handleInputSignUpChange(event) {
    const { value, name } = event.target;
    setValuesSignUp({
      ...valuesSignUp,
      [name]: value,
    });
  }
  const handleClose = () => {
    setLoginNav(false);
  };

  const addHarmonyNetwork = async () => {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...harmonyConfig,
        },
      ],
    });
  };

  const addEQ9TOKEN = async () => {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: tokenImage,
        },
      },
    });
  };

  const handleCloseGuide = () => {
    setOpenGuide(false);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh" }} className="modal">
      <ParticlesPage />
      <>
        {/* <span className="backDrop" /> */}
        <div className="FormGrid">
          {signUp ? (
            <div className="sing-up">
              <h2>{text[0]}</h2>
              <form className="formSingup" noValidate>
                <div className="form-div">
                  <input
                    type="text"
                    id="input-singUp-user"
                    name="username"
                    ref={usernameRegister}
                    style={{ textTransform: "lowercase" }}
                    autocomplete="off"
                    required
                    className="form-div-input"
                  />
                  <label className="input-label" for="input-singUp-user">
                    {text[2]}
                  </label>
                  <span style={{ width: "24%" }} className="input-label-line" for="input-singUp-user"></span>
                </div>
                <div className="form-div">
                  <input
                    type="password"
                    id="input-singUp-password"
                    pattern=".+"
                    minLength={6}
                    name="password"
                    ref={passwordRegister}
                    autocomplete="off"
                    required
                    className="form-div-input"
                  />
                  <label className="input-label" for="input-singUp-password">
                    {text[3]}
                  </label>
                  <span className="input-label-line width-pass" for="input-singUp-password"></span>
                </div>
                <div className="form-div">
                  <input
                    type="password"
                    id="input-singUp-password-confirm"
                    pattern=".+"
                    minLength={6}
                    name="passwordConfirm"
                    ref={passwordConfirmRegister}
                    autocomplete="off"
                    required
                    className="form-div-input"
                  />
                  <label className="input-label" for="input-singUp-password-confirm">
                    {text[4]}
                  </label>
                  <span className="input-label-line width-passC" for="input-singUp-password-confirm"></span>
                </div>
                <div className="form-div">
                  <input
                    type="text"
                    id="input-singUp-nickname"
                    pattern=".+"
                    maxLength={10}
                    name="nickname"
                    onChange={handleInputSignUpChange}
                    value={valuesSignUp.nickname}
                    autocomplete="off"
                    required
                    className="form-div-input"
                  />
                  <label className="input-label" for="input-singUp-nickname">
                    {text[5]}
                  </label>
                  <span className="input-label-line width-pass" for="input-singUp-nickname"></span>
                </div>
                <div className="checkbox-div">
                  <input
                    id="check-label-privacy"
                    type="checkbox"
                    className="checkbox-div-input"
                    // checked={signUpChecked}
                    ref={submitSignUp}
                    onChange={handleSignUpCheck}
                    onClick={() => setSignUpChecked(!signUpChecked)}
                  />
                  <label for="check-label-privacy" className="checkbox-div-label">
                    <i class="fas fa-check fa-lg"></i>
                  </label>

                  <label className="checkbox-div_policy-box" for="check-label-privacy">
                    {text[6]}
                    <br />
                    <Link href="/PrivateProvicy" target="blank" className="link-policy">
                      {text[7]}
                    </Link>
                  </label>
                </div>
                <Link2 variant="body2" className="link-forgot-pass" onClick={() => setSignUp(false)}>
                  {text[25]}
                </Link2>
                <button className="button-login" onClick={handleSignUp}>
                  {signUpProcess.label}
                </button>
              </form>
              {/* {!installed ? <WalletGuide open={openWalletGuide} setOpen={setOpenWalletGuide} installed={installed} /> : null} */}
            </div>
          ) : (
            <div className="sing-in">
              <h2>{text[1]}</h2>

              <form className="formSingin" noValidate>
                <div className="form-div">
                  <input
                    type="text"
                    id="input-singIn-user"
                    pattern=".+"
                    name="username"
                    style={{ textTransform: "lowercase" }}
                    ref={usernameLogin}
                    autocomplete="off"
                    required
                    className="form-div-input"
                  />
                  <label className="input-label" for="input-singIn-user">
                    {text[2]}
                  </label>
                  <span style={{ width: "23%" }} className="input-label-line" for="input-singIn-user"></span>
                </div>
                <div className="form-div">
                  <input
                    type="password"
                    id="input-singIn-password"
                    pattern=".+"
                    name="password"
                    ref={passwordLogin}
                    autocomplete="off"
                    required
                    className="form-div-input"
                  />
                  <label className="input-label" for="input-singIn-password">
                    {text[3]}
                  </label>
                  <span className="input-label-line width-pass" for="input-singIn-password"></span>
                </div>
                {/* <div className="checkbox-div">
                  <input id="check-label-remember" type="checkbox" className="checkbox-div-input" checked={loginChecked} onChange={handleLoginCheck} />
                  <label for="check-label-remember" className="checkbox-div-label">
                    <i class="fas fa-check fa-lg"></i>
                  </label>
                  <label className="checkbox-div_remember" for="check-label-remember">
                    {text[8]}
                  </label>
                </div> */}
                <Link2 variant="body2" className="link-forgot-pass" style={{ cursor: "pointer" }} onClick={() => setSignUp(true)}>
                  {text[12]}
                </Link2>
                <button className="button-login" ref={submitLogin} onClick={handleLogin}>
                  {process.label}
                </button>
                <Link2 variant="body2" className="link-forgot-pass" onClick={handlePasswordReset} style={{ cursor: "pointer" }}>
                  {text[9]}
                </Link2>
              </form>
            </div>
          )}
        </div>
      </>
      <ResetSend open={passwordResetModal} handleClose={setPasswordResetModal} />
      <AlertMsg />
    </div>
  );
};

export default Login;
