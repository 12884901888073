import React, { useState, useEffect, useCallback } from "react";
import { Dialog } from "@material-ui/core";
import Profile from "../profile/playerProfile";
import DisplayBadges from "../badges/displayBadge";
import { motion } from "framer-motion";
import { useLocalDB } from "../../context/LocalDB";
import { useStore } from "../../context/storeContext";
import api from "../../services/api";
import { useFetch } from "../../hooks/useFetch";
import "./rankingProfile.scss";
import Pageloading from "../pageloading/pageloading";
import language from "../../assets/language/text";
import eq9Config from "../../services/eq9Config";
import BadgesSlider from "../badges/badgesSlider.js";
import sounds from "../../assets/sounds";
import Btn from "../buttons/Btn";
import BigNumber from "big-number";
import toBigNumber from "../../utils/toBigNumber";
import stakeService from "../../services/stakeService";
import ModalStake from "../table/modalStake/stake";
import ModalUnstake from "../table/modalStake/unstake";

const coin1 = sounds.coin1;
const coin2 = sounds.coin2;
const newStaking = sounds.newStaking;
const unstake = sounds.unstake;

const RankingProfile = () => {
  const { modalPlayerProfile, setModalPlayerProfile, player, setPlayer, setAlert, setAlertMsg, setSeverity, setStakersTable, lang } = useLocalDB();
  const [valueDialog, setValueDialog] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const { user, setUser } = useStore();
  const [btnUnstake, setBtnUnstake] = useState(true);
  const [unstakeconfirm, setUnstakeConfirm] = useState(false);
  const [staked, setStaked] = useState([]);
  const [valueModal, setValueModal] = useState(false);
  const text = language[lang].rankingProfile;
  const { stakeOnPlayer, unstakeOnPlayer } = stakeService;
  const [playerBadge, setPlayerBadge] = useState([]);
  const [loading, setLoading] = useState(false);
  // const initialBtnValue = text[]

  useEffect(() => {
    if (player) {
      isStaked();
      const getUserBadges = async () => {
        const getBadges = await api.get(`users/${player._id}`).catch(console.log);
        if (getBadges) {
          setPlayerBadge(getBadges.data.badges);
        }
      };
      getUserBadges();
    }
  }, [player]);

  useEffect(() => {
    fetchUsers();
    fetchUser();
  }, []);

  const fetchUsers = async () => {
    const getAllUser = await api.get("users").catch(console.log);
    if (getAllUser) {
      setStakersTable(getAllUser.data);
    }
  };
  const fetchUser = async () => {
    const getUser = await api.get(`users/${user._id}`).catch(console.log);
    setUser(getUser.data);
  };

  const pageVariants = {
    initial: { opacity: 0 },
    in: { opacity: 1 },
    out: { opacity: 0 },
  };
  const pageTransition = { type: "tween", easy: "anticipate", duration: 0.3 };

  const handleClose = (e) => {
    setModalPlayerProfile(false);
  };

  const UnstakeConfirm = () => {
    const [value, setValue] = useState();

    const handleUnstakeClose = () => {
      setUnstakeConfirm(false);
    };

    const total = staked.reduce((a, item) => {
      return a + +item.value;
    }, 0);

    const handleUnstakeConfirm = async () => {
      // if (value <= total || value <= 0) {
      const getUnstakePlayer = await unstakeOnPlayer(player.blockchainId, toBigNumber(value));
      if (getUnstakePlayer) {
        setAlert(true);
        setAlertMsg(`${value} ${text[9]} ${player.nickname} ${text[6]}.`);
        setSeverity("success");
        handleClose();
        setLoading(false);
        coin2.currentTime = 0;
        coin2.play();
        unstake.currentTime = 0;
        unstake.play();
        setUnstakeConfirm(false);
        fetchUser();
      } else {
        setAlert(true);
        setAlertMsg(text[7]);
        setSeverity("error");
      }
      // let tronweb = await window.tronWeb;
      // let contract = await tronweb.contract().at(eq9Config.trc20ContractAddress);
      // let contract2 = await tronweb.contract().at(eq9Config.investorContract);
      // await contract.approve(eq9Config.investorContract, value * 100000000).send();
      // await contract2.deStakeDec(value * 100000000, player.wallet.default).send();
      // // let stake = await contract.removeStake(value * 100000000).send();
      // if (contract2) {
      //   coin2.currentTime = 0;
      //   coin2.play();
      //   unstake.currentTime = 0;
      //   unstake.play();
      //   setAlert(true);
      //   setAlertMsg(`${value} ${text[9]} ${player.nickname} ${text[6]}.`);
      //   setSeverity("success");
      //   setUnstakeConfirm(false);
      //   fetchUsers();
      //   fetchUser();
      // }
      // }
      // return setTimeout(() => {
      //   setPlayer();
      //   // setValueDialog(false);
      //   setTransfer(false);
      //   handleClose();
      //   handleUnstakeClose();
      //   setValue();
      // }, 200);
    };

    return (
      <div
        style={{
          position: "absolute",
          width: "300px",
          zIndex: 2000,
          display: unstakeconfirm ? "flex" : "none",
          left: "30%",
          top: "30%",
        }}
      >
        <div className="stakes-modal">
          <div className="stakes-modal-title">
            <span>{text[4]}: </span>
            <span>{total} EQ9</span>
          </div>
          <input placeholder={`${total} EQ9`} type="number" value={value} onChange={(e) => setValue(e.target.value)} />
          <Btn onClick={() => setUnstakeConfirm(false)}>{text[1]}</Btn>
          <Btn onClick={handleUnstakeConfirm}>{text[13]}</Btn>
        </div>
      </div>
    );
  };

  const StakeConfirm = () => {
    const [transferValue, setTransferValue] = useState("");

    const handleConfirmClose = () => {
      setValueModal(false);
    };

    const handleValueChange = (e) => {
      e.preventDefault();
      setTransferValue(e.target.value);
    };

    const StakeEQ9 = async () => {
      if (transferValue > 0) {
        setLoading(true);
        const getStakeonPlayer = await stakeOnPlayer(player.blockchainId, toBigNumber(transferValue));
        if (getStakeonPlayer) {
          setAlert(true);
          setAlertMsg(`${text[8]} ${transferValue}EQ9 ${text[12]} ${player.nickname} ${text[6]}!`);
          setSeverity("success");
          handleConfirmClose();
          handleClose();
          setLoading(false);
          coin2.currentTime = 0;
          coin2.play();
          unstake.currentTime = 0;
          unstake.play();
        }
      }
      // ANCHOR Função de stake para quando o player tiver uma carteira de recebimento cadastrada
      // let contract = await tronweb.contract().at(eq9Config.trc20ContractAddress);
      // let contract2 = await tronweb.contract().at(eq9Config.investorContract);
      // let approve = await contract.approve(eq9Config.investorContract, transferValue + "00000000").send();
      // if (approve) {
      //   let stake = await contract2.invest(transferValue + "00000000", "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb").send();
      //   if (stake) {
      //     setAlert(true);
      //     setAlertMsg(`${text[8]} ${transferValue}EQ9 ${text[12]} ${player.nickname} ${text[6]}!`);
      //     setSeverity("success");
      //   }
      //   return setTimeout(() => {
      //     setPlayer();
      //     setValueDialog(false);
      //     setTransfer(false);
      //     setTransferValue();
      //     handleConfirmClose();
      //     handleClose();
      //   }, 300);
      // } else {
      //   setAlert(true);
      //   setAlertMsg("Confirmação necessária");
      //   setSeverity("error");loading
      // }
    };

    return (
      <div
        style={{
          position: "absolute",
          width: "300px",
          zIndex: 2000,
          display: valueModal ? "flex" : "none",
          left: "30%",
          top: "30%",
        }}
      >
        <div className="stakes-modal stake">
          <h3>
            {text[0]} <br />
            {player ? player.nickname : null}
          </h3>
          <input placeholder={text[3]} type="number" value={transferValue} onChange={handleValueChange} />
          <div className="stakes-modal-buttons">
            <Btn onClick={handleConfirmClose}>{text[1]}</Btn>
            <Btn onClick={StakeEQ9}>{text[2]}</Btn>
          </div>
        </div>
      </div>
    );
  };

  const handleUnstake = () => {
    setUnstakeConfirm(true);
  };

  const isStaked = () => {
    // const isStakedor = user.staked.regAt.filter((item) => {
    //   if (item._id === player._id) {
    //     return true;
    //   }
    // });
    // if (isStakedor.length > 0) {
    //   setBtnUnstake(true);
    //   setStaked(isStakedor);
    // } else {
    //   setBtnUnstake(false);
    //   setStaked([]);
    // }
  };

  // if (!data) return <Pageloading />;

  return (
    <Dialog
      open={modalPlayerProfile}
      className="AddTornamentModal"
      onClose={() => (!loading ? handleClose() : null)}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "rgba(0,0,0,0.8)",
          transition: "backdropFilter ease 1s",
          boxShadow: "none",
          overflow: "hidden",
          minWidth: "700px",
        },
      }}
      BackdropProps={{ style: { background: "rgba(0,0,0,0.8)" } }}
    >
      {player ? (
        <motion.div
          exit="out"
          animate="in"
          initial="initial"
          variants={pageVariants}
          transition={pageTransition}
          style={{
            margin: "10px",
            // width: "100%",
            // display: "flex",
            // flexDirection: "column",
            // alignItems: "center",
            // justifyContent: "center",
          }}
        >
          {/* <StakeConfirm />
          <UnstakeConfirm /> */}
          <div
            className="centerContentHALF"
            style={{ justifyContent: "center", alignItems: "center", width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div className="halfcontainerprofile">
              <Profile nickname={player.nickname} name={player.name} score={player.score} age={player.age} img={player.img} associated={player.associated} />
            </div>
            <div className="halfcontainerbadges" style={{ maxWidth: "400px" }}>
              <div className="achievTitle" style={{ textAlign: "center" }}>
                <p>Your Achievements</p>
              </div>
              <BadgesSlider data={playerBadge} />
            </div>
            {/* <div className='halfcontainergraphic'>
            <Link style={{ textDecoration: "none" }} to='/meusstakers'>
              <GraphicPlayer data={player} />
            </Link>
          </div> */}
            {/* <StakeConfirm /> */}
            <div className="footer-buttons" style={{ width: "100%" }}>
              <Btn
                onClick={() => {
                  // setAlert(true);
                  // setAlertMsg("Coming Soon!");
                  // setSeverity("error");
                  setValueModal(true);
                }}
              >
                {text[2]}
              </Btn>
              {btnUnstake ? <Btn onClick={handleUnstake}>{text[13]}</Btn> : null}
            </div>
          </div>
          <ModalStake
            setValueModal={setValueModal}
            setLoading={setLoading}
            player={player}
            text={text}
            unstake={unstake}
            valueModal={valueModal}
            stakeOnPlayer={stakeOnPlayer}
            coin2={coin2}
            loading={loading}
            // getStakedPlayers={getStakedPlayers}
          />
          <ModalUnstake
            setUnstakeConfirm={setUnstakeConfirm}
            unstakeOnPlayer={unstakeOnPlayer}
            player={player}
            text={text}
            coin2={coin2}
            unstake={unstake}
            fetchUser={fetchUser}
            unstakeconfirm={unstakeconfirm}
            loading={loading}
            setLoading={setLoading}
            // getStakedPlayers={getStakedPlayers}
          />
        </motion.div>
      ) : null}
    </Dialog>
  );
};

export default RankingProfile;
