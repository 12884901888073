import { useState, useRef, useEffect } from "react";
import images from "../../assets/img";
import { useHistory } from "react-router-dom";
import { useLocalDB } from "../../context/LocalDB";

function PlayerCard({ data, children, deck, setPreview, seat, side, confirm, winner, connected }) {
  const [previewDeck, setPreviewDeck] = useState(false);
  const [imgPreview, setImgPreview] = useState();
  const [btnDrop, setBtnDrop] = useState(false);
  const { setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const deckCardsBtn = useRef(null);
  const history = useHistory();

  const handleDeckPreview = () => {
    setPreviewDeck(!previewDeck);
  };

  useEffect(() => {
    if (setPreview) {
      setPreview(imgPreview);
    }
  }, [imgPreview]);
  function handleManatype(mana, index) {
    if (mana === "W") return <img className="mana" src={images.whiteMana} alt="imgMana" key={index} />;
    if (mana === "U") return <img className="mana" src={images.blueMana} alt="imgMana" key={index} />;
    if (mana === "B") return <img className="mana" src={images.blackMana} alt="imgMana" key={index} />;
    if (mana === "G") return <img className="mana" src={images.greenMana} alt="imgMana" key={index} />;
    if (mana === "R") return <img className="mana" src={images.redMana} alt="imgMana" key={index} />;
    else
      return (
        <div className="wasteMana" key={index}>
          {mana}
        </div>
      );
  }
  const handleDrop = () => {
    // api.delete(`tourneys/removePlayer/${id}`,{user._id})
    // setAlert(true);
    // setAlertMsg("NOT IMPLEMENTED YET");
    // setSeverity("success");
    // setBtnDrop(false);
    // setTimeout(() => {
    //   history.push("/");
    // }, 300);
  };

  return (
    <>
      {previewDeck && (
        <div
          style={{
            position: "absolute",
            width: "400px",
            height: "560px",
            background: "rgba(0,0,0,0.5)",
            zIndex: 3000,
            backdropFilter: "blur(20px)",
            borderRadius: "15px",
          }}
        >
          <i style={{ position: "absolute", right: "10px", top: "10px" }} className="far fa-times-circle fa-2x" onClick={handleDeckPreview} />
          <div style={{ height: "550px", overflow: "auto" }}>
            <table style={{ margin: "20px" }}>
              <label>DECK</label>
              {deck.deckCards?.map((item, index) => {
                const manacost =
                  item.card.manaCost &&
                  item.card.manaCost.split("").filter((char) => {
                    if (char !== "{" && char !== "}") return true;
                  });
                return (
                  <tr key={index} onMouseOver={() => setImgPreview(item.card.imageUrl)} onMouseOut={() => setImgPreview()}>
                    <td>{item.count}</td>
                    <td>{item.card.name}</td>
                    <td style={{ display: "flex", pointerEvents: "none" }}>
                      {manacost &&
                        manacost.map((item, index) => {
                          return handleManatype(item, index);
                        })}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      )}
      {data ? (
        <div className="card-player-mobile">
          <div className="card-player-mobile-avatar">
            {data.img && data.img && <img src={data.img} alt="avatar photo" />}
            <h2 className="gradient-txt">{data.nickname.toUpperCase()}</h2>
          </div>
          {confirm && (
            <div className="card-player-mobile-confirm-flag">
              <h3>P1</h3>
              <h3>confirm</h3>
            </div>
          )}
          {deck && (
            <>
              <div className="card-player-mobile-orb">
                <div className="deck-orb-mobile">
                  <span
                    style={{
                      background: deck && `var(--${deck.deckType})`,
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div className="card-player-mobile-social">
            <a href="http://facebook.com.br/" rel="noreferrer" target="_blank">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="http://twitch.tv/" rel="noreferrer" target="_blank">
              <i className="fab fa-twitch"></i>
            </a>
            <a href="http://youtube.com.br/" rel="noreferrer" target="_blank">
              <i className="fab fa-youtube"></i>
            </a>
            <a href="http://instagram.com.br/" rel="noreferrer" target="_blank">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      ) : (
        <div className="waiting-banner">
          <h3 className="gradient-txt">Aguardando Oponente...</h3>
        </div>
      )}
      {/* {winner ? (
        winner === `player${seat}` ? (
          <div
            style={{
              position: "absolute",
              left: "43%",
              top: "-9%",
              fontSize: "2rem",
            }}
          >
            WON
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              left: "43%",
              top: "-9%",
              fontSize: "2rem",
            }}
          >
            LOST
          </div>
        )
      ) : null} */}
      {/* {side === 1 && (
        <>
          <button
            style={{
              position: "absolute",
              width: "80px",
              height: "80px",
              bottom: "-80%",
              borderRadius: "50%",
              fontSize: "1.3rem",
            }}
            className="button-round"
            onClick={() => {
              setBtnDrop(!btnDrop);
            }}
          >
            DROP
          </button>
          {btnDrop && (
            <div
              style={{
                position: "absolute",
                width: "260px",
                height: "40px",
                bottom: "-50%",
                borderRadius: "50%",
                fontSize: "1.3rem",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <button
                className="default-button"
                style={{ width: "120px", height: "50px" }}
                onClick={() => {
                  setBtnDrop(!btnDrop);
                }}
              >
                CANCEL
              </button>
              <button
                className="default-button"
                style={{ width: "120px", height: "50px" }}
                onClick={handleDrop}
              >
                CONFIRM
              </button>
            </div>
          )}
          {data ? (
            side === 1 ? (
              <div
                style={{
                  background: "green",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "9rem",
                  left: "10rem",
                  border: "2px #eee solid",
                }}
              />
            ) : (
              <div
                style={{
                  background: "green",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "9rem",
                  right: "10.5rem",
                  border: "2px #eee solid",
                }}
              />
            )
          ) : null}
        </>
      )} */}
    </>
  );
}

export default PlayerCard;
