import NumberFromWei from "../../utils/fromWei";
import toBigNumber from "../../utils/toBigNumber";
import twoDecimals from "../../utils/twoDecimals";

function Calc(value, corte, setPlayerWinners, setStakerAmount, setStakerByTime, setRewardAmount) {
  const valueToBignumber = value;
  const Tax = valueToBignumber * 0.03;
  const Dist = valueToBignumber - Tax;
  const jogadoresValue = Dist * 0.7;
  const ApoiadoresValue = Dist - jogadoresValue;

  const getPeso = (total, final) => Array.from({ length: total + 1 - final }, (_, i) => total - i * 1);

  const peso = getPeso(corte, 1);

  const getTotalValue = peso.reduce((a, b) => {
    return a + b;
  }, 0);

  const pesoMin = 100 / getTotalValue;

  const divisaoWinners = peso.map((item) => {
    const resultWinners = (jogadoresValue * (item * pesoMin)) / 100;
    return resultWinners.toFixed(2);
  });

  // // ANCHOR WINNERS

  const divisaoStakers = peso.map((item) => {
    const resultStakers = (ApoiadoresValue * (item * pesoMin)) / 100;
    return resultStakers.toFixed(2);
  });

  // // ANCHOR STAKERS
  // console.log(divisaoStakers, divisaoWinners)
  return setPlayerWinners(divisaoWinners), setStakerAmount(divisaoStakers), setRewardAmount(value);
}

export default Calc;
