import React from "react";
import sound from "../../assets/sounds/";

const BtnSide = ({ children }) => {
  return (
    <button
      className="button-home"
      onClick={() => {
        sound.btn.play();
        sound.btnContinuous.pause();
        sound.btnContinuous.loop = false;
        sound.btnContinuous.currentTime = 0;
      }}
      onMouseOver={() => {
        sound.btnContinuous.play();
        sound.btnContinuous.loop = true;
      }}
      onMouseOut={() => {
        sound.btnContinuous.pause();
        sound.btnContinuous.loop = false;
        sound.btnContinuous.currentTime = 0;
      }}
    >
      {children}
    </button>
  );
};

export default BtnSide;
