import ParticlesPage from "../../components/Particles";
import { useStore } from "../../context/storeContext";
import { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocalDB } from "../../context/LocalDB";
import language from "../../assets/language/text";
import DoneIcon from "@material-ui/icons/Done";
import { Dialog } from "@material-ui/core";
import sound from "../../assets/sounds/";
import { motion } from "framer-motion";
import api from "../../services/api";
import decode from "jwt-decode";
import { setTimeout } from "timers";

const audio = sound.StadiumSound;

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};
const pageTransition = { type: "tween", easy: "circInOut", duration: 0.4 };

function Welcome() {
  const { setAlert, setAlertMsg, setSeverity, setUserLocalData, setEq9, lang, mobile, volume } = useLocalDB();
  const { user, setUser, setEvo, evo, token, setToken } = useStore();
  const [evoSolicitation, setEvoSolicitation] = useState(false);
  const text = language[lang].w_page;
  const textLogin = language[lang].login_page;
  const [process, setProcess] = useState({
    icon: "",
    label: textLogin[11],
  });
  const screenPass = sound.screenPass;
  const [loginEvo, setLoginEvo] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      if (!evo) {
        const getEvo = async () => {
          const userTokenDecoded = decode(token);
          if (userTokenDecoded.evo) {
            const getEvoData = await api.get(`evos/${userTokenDecoded.evo._id}`).catch(console.log);
            setEvo(getEvoData.data);
          }
        };
        return getEvo();
      }
    }
  }, []);

  const handleEvoLogin = () => {
    setLoginEvo(true);
  };

  const handleChange = async (access) => {
    const accessType = { 1: "Player", 2: "Staker", 3: "Organizador" };
    setUserLocalData({ access: access });
    // const fetchUser = await api.get(`users/${user._id}`);
    // setUser(fetchUser.data);
    setAlert(true);
    setAlertMsg(`Entrando como perfil de ${accessType[access]}`);
    setSeverity("success");
  };

  // useEffect(() => {
  //   audio.play();
  //   audio.loop = true;
  // }, []);

  async function Log_in(values) {
    const auth = await api.post(`login`, values).catch(() => {
      setProcess({
        label: text[13],
        color: "secondary",
      });
      setAlertMsg(textLogin[13]);
      setSeverity("error");
      setAlert(true);
      setTimeout(() => {
        setProcess({ label: textLogin[11] });
      }, 1000);
    });
    if (auth) {
      api.defaults.headers.Authorization = `Bearer ${auth.data.access_token}`;
      const decoded = decode(auth.data.access_token);
      if (decoded.evo) {
        const getUser = await api.get(`users/${decoded.sub}`);
        setAlertMsg(`${textLogin[14]} ${getUser.data.nickname}, ${textLogin[15]}, redirecionando...`);
        setProcess({ icon: <DoneIcon />, label: "SUCESSO!", color: "primary" });
        setSeverity("success");
        setAlert(true);
        const token = auth.data.access_token;
        const user = getUser.data;
        setTimeout(() => {
          history.push("/");
          setProcess({ label: textLogin[11] });
          setUser(user);
          setToken(token);
        }, 1000);
      } else {
        setAlertMsg(lang === "pt" ? "Usuário não é um EVO" : "User is not a EVO");
        setSeverity("error");
        setAlert(true);
        setTimeout(() => {
          setProcess({ label: textLogin[11] });
        }, 1000);
      }
    }
  }

  async function handleLogin(username, password, e) {
    e.preventDefault();
    setProcess({ label: text[16], color: "default" });
    Log_in({ username: username, password: password });
    // Log_in(valuesLogin);
  }

  if (user)
    return (
      <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="welcome">
        <div className="w-left">
          <div className="w-title">
            <p>{text.title[0]}</p>
            <br />
            <h1>{text.title[1]}</h1>
          </div>
          <div className="w-logo">
            {/* <img src={whitelogo} alt='Logo Equals Sports' /> */}
            <div className="w-logo-img" />
          </div>
        </div>
        <div className="w-right">
          <div className="w-right-link">
            <div className="w-right-link-line" />
            {/* <Link className="w-right-link-txt" to="/ranking/61044b407c963538d01b5fd8"> */}
            <Link
              className="w-right-link-txt"
              to="/"
              onClick={() => {
                setAlert(true);
                setAlertMsg("Coming soon!");
                setSeverity("error");
              }}
            >
              <p>{text.middle_text[0]}</p>
              <h3>{text.middle_text[1]}</h3>
            </Link>
          </div>

          <div className="w-right-container-buttons">
            <Link
              className="translate-y"
              to={!mobile ? "/player" : "/mobile/player"}
              // style={{ cursor: "not-allowed" }}
              onClick={() => {
                handleChange(1);
                screenPass.currentTime = 0;
                audio.currentTime = 0;
                screenPass.play();
                audio.pause();
                // sound.btn2();
              }}
            >
              <div
                className="w-button"
                // style={{ pointerEvents: "none", filter: "grayscale(100)" }}
              >
                <div className="w-button-icon-player" />
                {text.button[0]}
              </div>
            </Link>
            <Link
              className="translate-y"
              to={!mobile ? "/staker" : "/mobile/staker"}
              style={{ cursor: "not-allowed" }}
              // style={{ pointerEvents: "none", filter: "grayscale(100)" }}
              onClick={() => {
                handleChange(2);
                audio.currentTime = 0;
                audio.pause();
                screenPass.currentTime = 0;
                screenPass.play();
                // setAlert(true);
                // setAlertMsg("Coming soon!");
                // setSeverity("error");
              }}
            >
              <div className="w-button delay">
                <div className="w-button-icon-staker" />
                {text.button[1]}
              </div>
            </Link>
            {evo ? (
              <Link
                className="translate-y"
                to={!mobile ? "/organizer" : "/mobile/evo"}
                onClick={() => {
                  handleChange(3);
                  audio.currentTime = 0;
                  audio.pause();
                  screenPass.currentTime = 0;
                  screenPass.play();
                }}
              >
                <div className="w-button button-evo">
                  <div className="w-button-icon-evo" />
                  {text.button[2]}
                </div>
              </Link>
            ) : (
              <div className="translate-y" onClick={handleEvoLogin}>
                <div className="w-button button-evo">
                  <div className="w-button-icon-evo" />
                  {text.button[2]}
                </div>
              </div>
            )}
            {user.user === "equalsadmin" && (
              <Link to="/evosolicitation" onClick={() => console.log(user)}>
                Aceitar Evo
              </Link>
            )}
          </div>
        </div>
        {loginEvo && (
          <LoginEvo
            loginEvo
            setLoginEvo={setLoginEvo}
            handleLogin={handleLogin}
            process={process}
            evoSolicitation={evoSolicitation}
            setEvoSolicitation={setEvoSolicitation}
          />
        )}
        {evoSolicitation && <EvoSolicitation evoSolicitation={evoSolicitation} setEvoSolicitation={setEvoSolicitation} user={user} />}
      </motion.div>
    );
}
const LoginEvo = ({ loginEvo, setLoginEvo, handleLogin, process, evoSolicitation, setEvoSolicitation }) => {
  const userValue = useRef();
  const passwordValue = useRef();

  const handleClose = () => {
    setLoginEvo(false);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          background: "rgba(150,150,150,0.2)",
          borderRadius: "15px",
          padding: "2rem",
        },
      }}
      style={{
        backdropFilter: "blur(15px)",
      }}
      onClose={handleClose}
      open={loginEvo}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Event Organizer</h2>
        <form className="formSingin" noValidate>
          <div className="form-div">
            <input type="text" id="input-singIn-user" pattern=".+" name="username" ref={userValue} autocomplete="off" required className="form-div-input" />
            <label className="input-label" for="input-singIn-user">
              User
            </label>
            <span className="input-label-line" for="input-singIn-user"></span>
          </div>
          <div className="form-div">
            <input
              type="password"
              id="input-singIn-password"
              pattern=".+"
              name="password"
              ref={passwordValue}
              autocomplete="off"
              required
              className="form-div-input"
            />
            <label className="input-label" for="input-singIn-password">
              Password
            </label>
            <span className="input-label-line width-pass" for="input-singIn-password"></span>
          </div>
          <div variant="body2" className="link-forgot-pass">
            Forgot your password?
          </div>
          {/* <div className="checkbox-div">
            <input
              id="check-label-remember"
              type="checkbox"
              className="checkbox-div-input"
              // checked={loginChecked}
              // onChange={handleLoginCheck}
            />
            <label for="check-label-remember" className="checkbox-div-label">
              <i class="fas fa-check fa-lg"></i>
            </label>

            <label
              className="checkbox-div_remember"
              for="check-label-remember"
            ></label>
          </div> */}
          <div
            variant="body2"
            className="link-forgot-pass"
            style={{ fontFamily: "gilroy-bold", fontSize: "1.3rem", padding: "7px", color: "blue" }}
            onClick={() => {
              setEvoSolicitation(true);
            }}
          >
            Want to be a Event Organizer?
          </div>
          <button className="button-login" onClick={(e) => handleLogin(userValue.current.value, passwordValue.current.value, e)}>
            {process.label}
          </button>
        </form>
      </div>
    </Dialog>
  );
};
const EvoSolicitation = ({ evoSolicitation, setEvoSolicitation, user }) => {
  const evoNameValue = useRef();
  const tronWalletEvoValue = useRef();
  const evoEmailValue = useRef();
  const { setAlertMsg, setAlert, setSeverity } = useLocalDB();

  const [process, setProcess] = useState({
    label: "Solicitar",
  });
  // const userValue = useRef();
  // const passwordValue = useRef();

  const handleSubmitEvoSolicitation = async (evoName, evoMail, e) => {
    e.preventDefault();
    if (!user.wallets) return setAlert(true), setAlertMsg("Cadastre uma carteira para essa conta para continuar."), setSeverity("error");
    setProcess({ label: "Enviando..." });
    const evoSolic = { name: evoName, emails: [evoMail] };
    const postSolic = await api.post(`evos/solicitations`, evoSolic).catch(() => {
      setAlert(true);
      setAlertMsg("Erro ao solicitar, tente novamente mais tarde.");
      setSeverity("error");
      setProcess({
        label: "Erro ao Solicitar",
      });
      setTimeout(() => {
        setProcess({
          label: "Solicitar",
        });
      }, 700);
    });
    if (postSolic) {
      setAlert(true);
      setAlertMsg("Solicitação enviada com sucesso!");
      setSeverity("success");
      setProcess({
        label: "Solicitar",
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setEvoSolicitation(false);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          background: "rgba(150,150,150,0.2)",
          borderRadius: "15px",
          padding: "2rem",
        },
      }}
      style={{
        backdropFilter: "blur(15px)",
      }}
      onClose={handleClose}
      open={evoSolicitation}
    >
      <h2>Event Organizer Solicitation</h2>
      <form className="formSingin" noValidate>
        <div className="form-div">
          <input type="text" id="input-singIn-user" pattern=".+" name="EVONAME" ref={evoNameValue} autocomplete="off" required className="form-div-input" />
          <label className="input-label" for="input-singIn-user" style={{ pointerEvents: "none" }}>
            ORGANIZER NAME
          </label>
          <span className="input-label-line" for="input-singIn-user"></span>
        </div>

        <div className="form-div">
          <input type="text" id="input-singIn-user" pattern=".+" name="username" ref={evoEmailValue} autocomplete="off" required className="form-div-input" />
          <label className="input-label" for="input-singIn-user" style={{ pointerEvents: "none" }}>
            EMAIL
          </label>
          <span className="input-label-line" for="input-singIn-user"></span>
        </div>
        <button className="button-login" onClick={(e) => handleSubmitEvoSolicitation(evoNameValue.current?.value, evoEmailValue.current?.value, e)}>
          {process.label}
        </button>
      </form>
    </Dialog>
  );
};

export default Welcome;
