// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // CONTRATO SHASTA PEDRO
  trc20ContractAddress: process.env.REACT_APP_EQ9_TRC_20_ADDRESS,
  // CONTRATO EQ9 MAINNET
  stakerContract: process.env.REACT_APP_STAKER_CONTRACT_ADDRESS,
  // trc20ContractAddress: "TZ1KcoVfNbB2vHa57rqCoCNHEUp1fikHsH",
  // CONTRATO TESTE SHASTA PEDRO (STAKE, UNSTAKE)
  investorContract: "TSnycwkJEKuHFvJJU2CuwyHozwJ2ZonPEJ",
};
