import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "../../../context/storeContext";
import Doughnut from "../../../components/deck/charts/Doughnut";
import BarChart from "../../../components/deck/charts/BarChart";
import api from "../../../services/api";
import LoadingPage from "../../../components/pageloading/pageloading";
import BtnContinuo from "../../../components/buttons/BtnContinuo";
import RadarChart from "../../../components/deck/charts/RadarChart";
import { Link } from "react-router-dom";

function EditDeck() {
  const { id } = useParams();
  const { user } = useStore();
  const [deck, setDeck] = useState([]);
  const [sideBoard, setSideBoard] = useState([]);
  const [deckSize, setDeckSize] = useState({ deck: 0, sideBoard: 0 });
  const [preview, setPreview] = useState();
  const [previewPosition, setPreviewPosition] = useState({ x: 0, y: 0 });
  const [deckTypes, setDeckTypes] = useState([]);
  const [deckInfo, setDeckInfo] = useState();
  const cardRef = useRef();
  const [deckOptions, setDeckOptions] = useState({
    cardsType: {
      Creature: 0,
      Instant: 0,
      Sorcery: 0,
      Artifact: 0,
      Enchantment: 0,
      Land: 0,
      Planeswalker: 0,
    },
    deckColors: {
      Blue: 0,
      Red: 0,
      Black: 0,
      Green: 0,
      White: 0,
      Colorless: 0,
    },
    manaCurve: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      "6+": 0,
    },
  });

  useEffect(() => {
    if (id) {
      const fechDeck = async () => {
        const getDeck = await api.get(`decks/${id}`).catch(console.log);
        if (getDeck) {
          setDeck(getDeck.data.deckCards);
          setSideBoard(getDeck.data.sideBoard);
          setDeckInfo(getDeck.data);
          const deckTypesData = () => {
            let Creature = [];
            let Instant = [];
            let Sorcery = [];
            let Artifact = [];
            let Enchantment = [];
            let Land = [];
            let Planeswalker = [];
            const deck = getDeck.data.deckCards;
            deck.map((item) => {
              if (item.card) {
                item.card.types.includes("Creature") && Creature.push(item);
                item.card.types.includes("Instant") && Instant.push(item);
                item.card.types.includes("Sorcery") && Sorcery.push(item);
                item.card.types.includes("Artifact") && Artifact.push(item);
                item.card.types.includes("Enchantment") && Enchantment.push(item);
                item.card.types.includes("Land") && Land.push(item);
                item.card.types.includes("Planeswalker") && Planeswalker.push(item);
              }
            });
            setDeckTypes({ Creature, Instant, Sorcery, Artifact, Enchantment, Land, Planeswalker });
          };
          deckTypesData();
        }
      };
      fechDeck();
    }
  }, [id]);

  const handleCardPreview = (item, e) => {
    setPreview(item.card.imageUrl);
    const position = { x: e.target.x, y: e.target.y };
    setPreviewPosition(position);
  };

  useEffect(() => {
    if (deck) {
      const countDeckSize = deck.reduce((a, b) => {
        return +a + +b.count;
      }, 0);
      const countSideBoardSize = sideBoard.reduce((a, b) => {
        return +a + +b.count;
      }, 0);
      setDeckSize({ deck: countDeckSize, sideBoard: countSideBoardSize });

      let cmcTest = deckOptions.manaCurve;
      let deckColors = deckOptions.deckColors;
      let cardsType = deckOptions.cardsType;
      Object.keys(deckOptions.manaCurve).map((item) => {
        deck.filter((itemDeck) => {
          if (itemDeck.card) {
            if (+itemDeck.card.cmc === +item && item != "6+") {
              cmcTest[item] = cmcTest[item] + 1;
            }
            if (+itemDeck.card.cmc >= 6) {
              cmcTest["6+"] = cmcTest["6+"] + 1;
            }
          }
        });
      }, []);
      Object.keys(deckOptions.deckColors).map((item, index) => {
        deck.filter((itemDeck) => {
          if (itemDeck.card) {
            if (itemDeck.card.colors != undefined) {
              if (itemDeck.card.colors.includes(item)) {
                deckColors[item] = deckColors[item] + 1;
              }
            } else if (index === 0) {
              if (itemDeck.card.types.includes("Artifact")) {
                deckColors["Colorless"] = deckColors["Colorless"] + 1;
              }
            }
          }
        });
      }, []);
      Object.keys(deckOptions.cardsType).map((item) => {
        deck.filter((itemDeck) => {
          if (itemDeck.card) {
            if (itemDeck.card.types.includes(item)) {
              cardsType[item] = cardsType[item] + 1;
            }
          }
        });
      }, []);
      setDeckOptions({
        ...deckOptions,
        manaCurve: cmcTest,
        deckColors: deckColors,
        cardsType: cardsType,
      });
    }
  }, [deck, sideBoard]);

  if (!deckInfo || !deck) return <LoadingPage />;
  // if (!data) return <LoadingPage />;
  return (
    <div className="editDeckDeckPage">
      <div className="LeftSideDeckPage">
        {deckTypes && (
          <div className="listDeckPage">
            {Object.keys(deckTypes).map((type) => {
              if (deckTypes[type].length > 0)
                return (
                  <div className="listDeckPage-item" key={`cardID/${type}`}>
                    <h3 style={{ textAlign: "center" }}>{type}</h3>
                    {deckTypes[type].map((item, index) => {
                      return (
                        <>
                          {item.card.imageUrl ? (
                            <img
                              className="imageDeckPage"
                              src={item.card.imageUrl}
                              onMouseEnter={(e) => {
                                handleCardPreview(item, e);
                              }}
                              onMouseLeave={() => {
                                setPreview();
                              }}
                              key={`cardID/${item.card.id}`}
                              alt="cardImage"
                              ref={cardRef}
                            />
                          ) : (
                            <div className="imageDeckPage" style={{ position: "relative" }}>
                              <div
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  textAlign: "center",
                                  zIndex: 2,
                                  top: "15px",
                                  fontSize: "1.2rem",
                                  fontFamily: "gilroy-bold",
                                }}
                              >
                                {item.card.name}
                              </div>
                              <img
                                style={{ background: "black", height: "350px", borderRadius: "15px" }}
                                src="https://i.redd.it/qnnotlcehu731.jpg"
                                key={`cardID/${item.card.id}`}
                                alt="cardImage"
                                ref={cardRef}
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                );
            })}
            {sideBoard.length > 0 && (
              <div>
                <h3 style={{ textAlign: "center" }}>SideBoard</h3>
                {sideBoard.map((item) => {
                  return (
                    <img
                      className="imageDeckPage"
                      src={item.card.imageUrl}
                      style={{ marginBottom: "-315px", width: "250px", borderRadius: "15px" }}
                      onMouseEnter={(e) => {
                        handleCardPreview(item, e);
                      }}
                      onMouseLeave={() => {
                        setPreview();
                      }}
                      key={`cardSideBoard/${item.card.id}`}
                      alt="cardImage"
                      ref={cardRef}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="RightSideDeckPage">
        {deckInfo.commander.length > 0 && deckInfo.commander[0] ? (
          <h3
            style={{ position: "absolute", top: 0, left: "50%", transform: "translateX(-50%)", top: "2rem", fontFamily: "gilroy-bold", color: "var(--pink)" }}
          >
            Commander: {deckInfo.commander[0].card.name}
          </h3>
        ) : null}
        {deckInfo.companion.length > 0 && deckInfo.companion[0] ? (
          <h3
            style={{ position: "absolute", top: 0, left: "50%", transform: "translateX(-50%)", top: "2rem", fontFamily: "gilroy-bold", color: "var(--pink)" }}
          >
            Companion: {deckInfo.companion[0].card.name}
          </h3>
        ) : null}
        <div className="rightDeckCardInfo">
          <h3>Deck Name: {deckInfo.deckName}</h3>
          <h3>Archetype: {deckInfo.deckType.toUpperCase()}</h3>
          <h3>Deck Size: {deckSize.deck}</h3>
          <h3>SideBoard Size: {deckSize.sideBoard}</h3>
          <h3>Format: {deckInfo.format}</h3>
        </div>

        <section className="DisplayCards">
          <div className="rightDeckCardPage">{id && <Doughnut dataProp={deckOptions.deckColors} />}</div>
          <div className="rightDeckCardPage">{id && <BarChart name="Mana Curve" dataProp={deckOptions.manaCurve} />}</div>
          <div className="rightDeckCardPage">{id && <RadarChart name="Types" dataProp={deckOptions.cardsType} />}</div>
          <div className="rightDeckCardEdit">
            <Link to={`/addDeck/${id}`}>
              <BtnContinuo
                style={{
                  width: "170px",
                  height: "170px",
                  fontSize: "2rem",
                }}
                classe="button-deck-add"
              >
                Editar
              </BtnContinuo>
            </Link>
          </div>
        </section>
      </div>
      {preview && <img className="cardPreview" style={{ top: previewPosition.y, left: previewPosition.x }} src={preview} alt="previewImage" />}
    </div>
  );
}

export default EditDeck;
