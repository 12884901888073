/* eslint-disable import/no-anonymous-default-export */
import firebase from "firebase/compat/app";
import "firebase/firebase-firestore-compat";
import "firebase/firebase-auth-compat";
import firebaseConfig from "./firebaseConfig";
import api from "./api";

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();

export default {
  signIn: async (email, password) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  },
  addUser: async (u) => {
    await db.collection("users").doc(u._id).set(
      {
        nickname: u.nickname,
        // image: u.img && u.img.profile && u.img.profile,
      },
      { merge: true }
    );
  },
  addNewChat: async (user, user2) => {
    let newChat = await db.collection("chat").add({
      messages: [],
      users: [user._id, user2._id],
    });
    db.collection("users")
      .doc(user._id)
      .update({
        chats: firebase.firestore.FieldValue.arrayUnion({
          chatId: newChat.id,
          title: user2.nickname,
          with: user2._id,
        }),
      });
    db.collection("users")
      .doc(user2._id)
      .update({
        chats: firebase.firestore.FieldValue.arrayUnion({
          chatId: newChat.id,
          title: user.nickname,
          with: user._id,
        }),
      });
  },
  onChatList: async (userId, setChatList) => {
    return db
      .collection("users")
      .doc(userId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let data = doc.data();
          if (data.chats) {
            setChatList(data.chats);
          }
        }
      });
  },
  onChatContent: (chatId, setList, setUsers) => {
    return db
      .collection("chat")
      .doc(chatId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let data = doc.data();
          setList(data.messages);
          setUsers(data.users);
        }
      });
  },
  sendMessage: (chatId, userId, type, body, users) => {
    let now = new Date();
    db.collection("chat")
      .doc(chatId)
      .update({
        messages: firebase.firestore.FieldValue.arrayUnion({
          type,
          author: userId,
          body,
          date: now,
        }),
      });
    users.map(async (user) => {
      let u = await db.collection("users").doc(user).get();
      let uData = u.data();
      if (uData.chats) {
        let chats = [...uData.chats];
        chats.map((item) => {
          if (item.chatId === chatId) {
            item.lastMessage = body;
            item.lastMessageDate = now;
          }
        });
        await db.collection("users").doc(user).update({
          chats,
        });
      }
    });
  },

  // =====================  Tournament  ===================== //

  addEvo: async (id, event) => {
    db.collection("event").doc(id).set(event);
  },
  addTourney: async (id) => {
    await db
      .collection("tourney")
      .doc(id)
      .set({
        standings: [],
        round1: { winner: [], looser: [] },
        round2: { winner: [], looser: [] },
        round3: { winner: [], looser: [] },
        currentRound: 1,
        roundResults: {},
        messages: [],
        finished: false,
      });
  },
  setTourneyFinished: async (tourneyId) => {
    await db.collection("tourney").doc(tourneyId).update({ finished: true });
  },
  addStandings: async (tourneyId, body) => {
    await db.collection("tourney").doc(tourneyId).update({ standings: body });
  },
  setRoundUpdate: async (tourneyId, round) => {
    await db.collection("tourney").doc(tourneyId).update({ currentRound: round });
  },
  getStandings: async (id) => {
    const getTourney = await db.collection("tourney").doc(id).get();
    return getTourney.data();
  },
  getTourney: async (id) => {
    let tourney = await db.collection("tourney").doc(id).get();
    return tourney.data();
  },
  getTourneyOnSnapshot: async (id, setTourney, setStandings, setMessages) => {
    await db
      .collection("tourney")
      .doc(id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          setTourney(data);
          setStandings(data.standings);
          setMessages(data.messages);
        }
      });
  },
  setRoundWinner: async (tourneyId, round, winner, looser) => {
    const getTourney = await db.collection("tourney").doc(tourneyId).get();
    const data = getTourney.data();
    const win = [...data[`round${round}`].winner];
    const loss = [...data[`round${round}`].looser];
    await db
      .collection("tourney")
      .doc(tourneyId)
      .update({
        [`round${round}`]: {
          winner: win !== undefined ? [...win, winner] : [winner],
          looser: loss !== undefined ? [...loss, looser] : [looser],
        },
      });
  },
  createRoundInstance: async (id, user1, user2, tournamentId) => {
    await db
      .collection("event")
      .doc(tournamentId + id)
      .set({
        users: [user1, user2],
        messages: [],
        confirmed: false,
        results: { playerTwoWins: 0, playerOneWins: 0 },
        confirm: { player1: false, player2: false },
        roundResults: { r1: 0, r2: 0, r3: 0 },
      });
  },
  onRoundInstance: async (roundId, setRoundInstance) => {
    await db
      .collection("event")
      .doc(roundId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let data = doc.data();
          if (data) {
            setRoundInstance(data);
          }
        }
      });
  },
  addRoundResult: async (roundId, body) => {
    await db.collection("event").doc(roundId).update({ roundResults: body });
  },
  sendRoundResult: async (roundId, body) => {
    await db.collection("event").doc(roundId).update({ results: body });
  },
  sendConfirmResult: async (roundId, body) => {
    await db.collection("event").doc(roundId).update({ confirm: body });
  },
  sendConfirmedResult: async (roundId, body, results, tournamentId) => {
    const getTourney = await db.collection("tourney").doc(tournamentId).get();
    const data = getTourney.data();
    await db.collection("event").doc(roundId).update({ confirmed: body, results: results });
    await db
      .collection("tourney")
      .doc(tournamentId)
      .update({
        roundResults: { ...data.roundResults, [roundId]: results },
      });
  },
  getRound: async (roundId) => {
    const round = await db.collection("event").doc(roundId).get();
    return round.data();
  },

  // ===================== tournament chat ===================== //

  onTournamentChatContent: (roundId, setList, setResults, setConfirm, setConfirmed, setRoundResults) => {
    return db
      .collection("event")
      .doc(roundId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let data = doc.data();
          setList(data.messages);
          setResults(data.results);
          setConfirm(data.confirm);
          setConfirmed(data.confirmed);
          setRoundResults(data.roundResults);
        }
      });
  },
  sendRoundMessage: (roundId, userId, body) => {
    let now = new Date();
    db.collection("event")
      .doc(roundId)
      .update({
        messages: firebase.firestore.FieldValue.arrayUnion({
          author: userId,
          body,
          date: now,
        }),
      });
  },
  sendWaitRoomMessage: (tourneyId, userId, body) => {
    let now = new Date();
    db.collection("tourney")
      .doc(tourneyId)
      .update({
        messages: firebase.firestore.FieldValue.arrayUnion({
          author: userId,
          body,
          date: now,
        }),
      });
  },
  setConfirmedResult: async (round, player1, player2) => {
    const confirmApi = await api.post(`tournament/confirmed/${round}`, {
      tournament: {},
    });
    if (confirmApi) {
      db.collection("tourney");
    }
  },
};
