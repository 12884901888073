import { Dialog } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import AddDeck from "../../components/cardInport";

function InputTextBox({ open, setOpen, fetchUser, handleCloseInput, getUserDeckConfirm, fetchTournament, searchFormat }) {
  const textArea = useRef();
  const [confirmed, setConfirmed] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState();

  useEffect(() => {
    const getClipboard = async () => {
      const text = await navigator.clipboard.readText();
      textArea.current.value = text;
    };
    getClipboard();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setConfirmed(false);
    if (getUserDeckConfirm) {
      getUserDeckConfirm();
    }
    if (fetchTournament) {
      fetchTournament();
    }
    if (handleCloseInput) {
      handleCloseInput();
    }
  };

  const handleConfirm = () => {
    setTextAreaValue(textArea.current.value);
    setConfirmed(true);
  };

  if (confirmed)
    return <AddDeck open={confirmed} setOpen={setOpen} fetchUser={fetchUser} handleClose={handleClose} value={textAreaValue} searchFormat={searchFormat} />;

  return (
    <Dialog
      open={open}
      style={{ padding: "10px" }}
      onClose={handleClose}
      PaperProps={{ style: { background: "transparent" } }}
      BackdropProps={{ style: { background: "rgba(0,0,0,0.9)" } }}
    >
      <textarea
        rows="25"
        cols="50"
        style={{ fontFamily: "gilroy-bold", background: "transparent", resize: "none", borderRadius: "10px", padding: "2px" }}
        ref={textArea}
      />
      {/* <textarea id="txtid" name="txtname" rows="4" cols="50" maxlength="200">
        A nice day is a nice day. Lao Tseu
      </textarea> */}
      <div style={{ display: "flex", padding: "5px" }}>
        <button className="default-input" style={{ width: "50%", margin: "2px" }} onClick={handleClose}>
          Cancel
        </button>
        <button className="default-input" style={{ width: "50%", margin: "2px" }} onClick={handleConfirm}>
          Confirmar
        </button>
      </div>
    </Dialog>
  );
}

export default InputTextBox;
