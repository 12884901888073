export default function twoDecimals(number) {
  if (number) {
    const stringNumber = number.toString();
    // console.log(number);
    if (stringNumber.includes(".")) {
      const getDecimals = stringNumber.split(".");
      return getDecimals[0] + "." + getDecimals[1].substring(2, 0);
    } else {
      return number;
    }
  }
}
