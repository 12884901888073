export default {
  corte4: {
    jogadores: [
      {
        name: "teste1",
        stakers: [
          {
            name: "teste1",
            value: 400,
            days: 100,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste2", value: 300, days: 20, reward: { qnt: 0, time: 0 } },
          { name: "teste3", value: 10, days: 5, reward: { qnt: 0, time: 0 } },
          {
            name: "teste4",
            value: 500,
            days: 190,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
        ],
        reward: 0,
      },
      {
        name: "teste2",
        stakers: [
          {
            name: "teste4",
            value: 500,
            days: 190,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
          { name: "teste6", value: 15, days: 50, reward: { qnt: 0, time: 0 } },
          { name: "teste7", value: 10, days: 43, reward: { qnt: 0, time: 0 } },
          { name: "teste8", value: 120, days: 65, reward: { qnt: 0, time: 0 } },
          {
            name: "teste9",
            value: 400,
            days: 300,
            reward: { qnt: 0, time: 0 },
          },
          {
            name: "teste10",
            value: 6000,
            days: 10,
            reward: { qnt: 0, time: 0 },
          },
        ],

        reward: 0,
      },
      {
        name: "teste3",
        stakers: [
          {
            name: "teste1",
            value: 400,
            days: 100,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste2", value: 300, days: 20, reward: { qnt: 0, time: 0 } },
        ],

        reward: 0,
      },
      {
        name: "teste4",
        stakers: [
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
          { name: "teste6", value: 15, days: 50, reward: { qnt: 0, time: 0 } },
          { name: "teste7", value: 10, days: 43, reward: { qnt: 0, time: 0 } },
          { name: "teste8", value: 120, days: 65, reward: { qnt: 0, time: 0 } },
          {
            name: "teste9",
            value: 400,
            days: 300,
            reward: { qnt: 0, time: 0 },
          },
        ],

        reward: 0,
      },
    ],
  },
  corte8: {
    jogadores: [
      {
        name: "teste1",
        stakers: [
          {
            name: "teste1",
            value: 400,
            days: 100,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste2", value: 300, days: 20, reward: { qnt: 0, time: 0 } },
          { name: "teste3", value: 10, days: 5, reward: { qnt: 0, time: 0 } },
          {
            name: "teste4",
            value: 500,
            days: 190,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
          { name: "teste6", value: 15, days: 50, reward: { qnt: 0, time: 0 } },
          { name: "teste7", value: 10, days: 43, reward: { qnt: 0, time: 0 } },
          { name: "teste8", value: 120, days: 65, reward: { qnt: 0, time: 0 } },
          {
            name: "teste9",
            value: 400,
            days: 300,
            reward: { qnt: 0, time: 0 },
          },
          {
            name: "teste10",
            value: 6000,
            days: 10,
            reward: { qnt: 0, time: 0 },
          },
        ],

        reward: 0,
      },
      {
        name: "teste2",
        stakers: [
          {
            name: "teste1",
            value: 400,
            days: 100,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste2", value: 300, days: 20, reward: { qnt: 0, time: 0 } },
          { name: "teste3", value: 10, days: 5, reward: { qnt: 0, time: 0 } },
          {
            name: "teste4",
            value: 500,
            days: 190,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
          { name: "teste6", value: 15, days: 50, reward: { qnt: 0, time: 0 } },
          { name: "teste7", value: 10, days: 43, reward: { qnt: 0, time: 0 } },
          { name: "teste8", value: 120, days: 65, reward: { qnt: 0, time: 0 } },
          {
            name: "teste9",
            value: 400,
            days: 300,
            reward: { qnt: 0, time: 0 },
          },
          {
            name: "teste10",
            value: 6000,
            days: 10,
            reward: { qnt: 0, time: 0 },
          },
        ],

        reward: 0,
      },
      {
        name: "teste3",
        stakers: [
          {
            name: "teste1",
            value: 400,
            days: 100,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste2", value: 300, days: 20, reward: { qnt: 0, time: 0 } },
          { name: "teste3", value: 10, days: 5, reward: { qnt: 0, time: 0 } },
          {
            name: "teste4",
            value: 500,
            days: 190,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
          { name: "teste6", value: 15, days: 50, reward: { qnt: 0, time: 0 } },
          { name: "teste7", value: 10, days: 43, reward: { qnt: 0, time: 0 } },
          { name: "teste8", value: 120, days: 65, reward: { qnt: 0, time: 0 } },
          {
            name: "teste9",
            value: 400,
            days: 300,
            reward: { qnt: 0, time: 0 },
          },
          {
            name: "teste10",
            value: 6000,
            days: 10,
            reward: { qnt: 0, time: 0 },
          },
        ],

        reward: 0,
      },
      {
        name: "teste4",
        stakers: [
          {
            name: "teste1",
            value: 400,
            days: 100,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste2", value: 300, days: 20, reward: { qnt: 0, time: 0 } },
          { name: "teste3", value: 10, days: 5, reward: { qnt: 0, time: 0 } },
          {
            name: "teste4",
            value: 500,
            days: 190,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
          { name: "teste6", value: 15, days: 50, reward: { qnt: 0, time: 0 } },
          { name: "teste7", value: 10, days: 43, reward: { qnt: 0, time: 0 } },
          { name: "teste8", value: 120, days: 65, reward: { qnt: 0, time: 0 } },
          {
            name: "teste9",
            value: 400,
            days: 300,
            reward: { qnt: 0, time: 0 },
          },
          {
            name: "teste10",
            value: 6000,
            days: 10,
            reward: { qnt: 0, time: 0 },
          },
        ],

        reward: 0,
      },
      {
        name: "teste5",
        stakers: [
          {
            name: "teste1",
            value: 400,
            days: 100,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste2", value: 300, days: 20, reward: { qnt: 0, time: 0 } },
          { name: "teste3", value: 10, days: 5, reward: { qnt: 0, time: 0 } },
          {
            name: "teste4",
            value: 500,
            days: 190,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
          { name: "teste6", value: 15, days: 50, reward: { qnt: 0, time: 0 } },
          { name: "teste7", value: 10, days: 43, reward: { qnt: 0, time: 0 } },
          { name: "teste8", value: 120, days: 65, reward: { qnt: 0, time: 0 } },
          {
            name: "teste9",
            value: 400,
            days: 300,
            reward: { qnt: 0, time: 0 },
          },
          {
            name: "teste10",
            value: 6000,
            days: 10,
            reward: { qnt: 0, time: 0 },
          },
        ],

        reward: 0,
      },
      {
        name: "teste6",
        stakers: [
          {
            name: "teste1",
            value: 400,
            days: 100,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste2", value: 300, days: 20, reward: { qnt: 0, time: 0 } },
          { name: "teste3", value: 10, days: 5, reward: { qnt: 0, time: 0 } },
          {
            name: "teste4",
            value: 500,
            days: 190,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
          { name: "teste6", value: 15, days: 50, reward: { qnt: 0, time: 0 } },
          { name: "teste7", value: 10, days: 43, reward: { qnt: 0, time: 0 } },
          { name: "teste8", value: 120, days: 65, reward: { qnt: 0, time: 0 } },
          {
            name: "teste9",
            value: 400,
            days: 300,
            reward: { qnt: 0, time: 0 },
          },
          {
            name: "teste10",
            value: 6000,
            days: 10,
            reward: { qnt: 0, time: 0 },
          },
        ],

        reward: 0,
      },
      {
        name: "teste7",
        stakers: [
          {
            name: "teste1",
            value: 400,
            days: 100,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste2", value: 300, days: 20, reward: { qnt: 0, time: 0 } },
          { name: "teste3", value: 10, days: 5, reward: { qnt: 0, time: 0 } },
          {
            name: "teste4",
            value: 500,
            days: 190,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
          { name: "teste6", value: 15, days: 50, reward: { qnt: 0, time: 0 } },
          { name: "teste7", value: 10, days: 43, reward: { qnt: 0, time: 0 } },
          { name: "teste8", value: 120, days: 65, reward: { qnt: 0, time: 0 } },
          {
            name: "teste9",
            value: 400,
            days: 300,
            reward: { qnt: 0, time: 0 },
          },
          {
            name: "teste10",
            value: 6000,
            days: 10,
            reward: { qnt: 0, time: 0 },
          },
        ],

        reward: 0,
      },
      {
        name: "teste8",
        stakers: [
          {
            name: "teste1",
            value: 400,
            days: 100,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste2", value: 300, days: 20, reward: { qnt: 0, time: 0 } },
          { name: "teste3", value: 10, days: 5, reward: { qnt: 0, time: 0 } },
          {
            name: "teste4",
            value: 500,
            days: 190,
            reward: { qnt: 0, time: 0 },
          },
          { name: "teste5", value: 2000, days: 1, reward: { qnt: 0, time: 0 } },
          { name: "teste6", value: 15, days: 50, reward: { qnt: 0, time: 0 } },
          { name: "teste7", value: 10, days: 43, reward: { qnt: 0, time: 0 } },
          { name: "teste8", value: 120, days: 65, reward: { qnt: 0, time: 0 } },
          {
            name: "teste9",
            value: 400,
            days: 300,
            reward: { qnt: 0, time: 0 },
          },
          {
            name: "teste10",
            value: 6000,
            days: 10,
            reward: { qnt: 0, time: 0 },
          },
        ],

        reward: 0,
      },
    ],
  },
};
