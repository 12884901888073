import { Dialog } from "@material-ui/core";
import Table from "../../components/table/table2";
import React, { useEffect } from "react";
import { useLocalDB } from "../../context/LocalDB";

function ModalList({ open, setOpen, data, text, fetchAllUser, getStakedPlayers }) {
  const { lang } = useLocalDB();
  const coldata = [
    { col: lang === "pt" ? "Nome" : "Name", id: "name", wd: "50%" },
    { col: lang === "pt" ? "Nickname" : "Nickname", id: "nickname", wd: "45%" },
    { col: lang === "pt" ? "Stake" : "Stake", id: "stake", wd: "5%" },
    // { col: text.tableData[5], id: "unstake", wd: "5%" },
  ];
  useEffect(() => {
    fetchAllUser();
  }, []);
  return (
    <Dialog
      open={open}
      onClose={setOpen}
      maxWidth="100%"
      PaperProps={{ style: { background: "transparent", width: "60%", minWidth: "500px", height: "80vh", minHeight: "500px" } }}
      BackdropProps={{ style: { background: "rgba(0,0,0,0.9)" } }}
    >
      <div>
        <Table
          col={coldata}
          sx={{ width: "100%", height: "70vh", minHeight: "500px" }}
          data={data}
          getStakedPlayers={getStakedPlayers}
          modal={true}
          fetchAllUser={fetchAllUser}
        />
      </div>
    </Dialog>
  );
}

export default ModalList;
