import { useEffect, useState } from "react";
import { CircularProgress, Dialog } from "@material-ui/core";
import { useWallet } from "../../context/walletContext";
import { useStore } from "../../context/storeContext";
import LoadingPage from "../../components/modais/loadingPage";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
import axios from "axios";
import CreateAccount from "./finalizationForm";
import NumberToWei from "../../utils/toWei";
import Suggestion from "../../components/Suggestion";

function DepositEq9({ open, setOpen, getDeposit, setConfirmDepositModal }) {
  const { user } = useStore();
  const [pixLoading, setPixLoading] = useState(false);
  const [pix, setPix] = useState(false);
  const [pixImg, setPixImg] = useState();
  const [pixConfirm, setPixConfirm] = useState(false);
  const { setAlert, setAlertMsg, setSeverity, lang } = useLocalDB();
  const { getBalanceOf } = useWallet();
  const [eq9Value, setEq9Value] = useState(0);
  const [openForm, setOpenForm] = useState(false);

  const language = {
    pt: [
      "COMPRAR EQ9",
      "DEPOSITAR",
      "GERANDO PIX...",
      "FINALIZAR",
      "Pagamento Concluído com Sucesso!",
      "CONFIRMANDO PAGAMENTO! POR FAVOR, NÃO SAIA DA TELA.",
      "Clique na imagem para copiar.",
    ],
    en: [
      "BUY EQ9",
      "DEPOSIT",
      "GENERATING PIX...",
      "FINISH",
      "Payment Successfully Completed!",
      "CONFIRMING PAYMENT! PLEASE DO NOT LEAVE THE SCREEN.",
      "Click on the image to copy.",
    ],
  };

  const text = language[lang];

  useEffect(() => {
    if (open) {
      setOpenForm(true);
      setPixLoading(false);
      setPix(false);
      setPixImg();
      setPixConfirm(false);
      setEq9Value(0);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setOpenForm(false);
    setPixConfirm(true);
    setTimeout(() => {
      getBalanceOf();
    }, 5000);
  };

  const getValue = (value) => {
    if (value) {
      const Value = value.toString();
      if (Value.includes(".")) {
        const valueSplited = Value.split(".");
        if (valueSplited[1].length > 2) return valueSplited[0] + "." + valueSplited[1].slice(0, 2);
        if (valueSplited[1].length === 1) return valueSplited[0] + "." + valueSplited[1] + "0";
      } else {
        return value + ".00";
      }
    }
  };

  const handleValueChange = (e) => {
    const { value } = e.target;
    setEq9Value(value);
  };

  const handleCopyQrValue = () => {
    navigator.clipboard.writeText(pix);
    setAlert(true);
    setAlertMsg("Successfuly Copied!");
    setSeverity("success");
  };

  // ====================== // ======================== //

  const handlePixGenerate = async () => {
    if (!pixConfirm) {
      if (eq9Value > 0) {
        setPixLoading(true);
        const deposit = await getDeposit(NumberToWei(eq9Value)).catch((error) => {
          setPixLoading(false);
          setPixConfirm(false);
          setPix(false);
        });
        if (deposit) {
          setPixLoading(false);
          setAlert(true);
          setPix(false);
          setAlertMsg(lang === "pt" ? "DEPÓSITO CONCLUIDO COM SUCESSO!" : "DEPOSIT SUCCESSFULLY COMPLETED");
          setSeverity("success");
          getBalanceOf();
          setPixImg();
          setPixConfirm(false);
          setOpenForm(false);
          handleClose();
        }
      } else {
        setAlert(true);
        setAlertMsg(lang === "pt" ? "INSIRA UM VALOR VALIDO PARA CONTINUAR" : "NEED A VALID VALUE TO CONTINUE");
        setSeverity("error");
        setPixLoading(false);
        setPixConfirm(false);
        setPix(false);
      }
    } else {
      setPixConfirm(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        !pixLoading && handleClose();
      }}
      PaperProps={{ style: { background: "none", padding: "5px", maxWidth: "500px", borderRadius: "15px" } }}
      BackdropProps={{ style: { backdropFilter: "blur(10px)" } }}
    >
      {pix ? (
        pixConfirm && (
          <section
            style={{
              width: "450px",
              height: "300px",
              background: "rgba(0,0,0,0.7)",
              zIndex: "100",
              borderRadius: "15px",
              padding: "1rem",
              zIndex: "300",
            }}
          >
            <div
              className="default-input"
              style={{
                height: "210px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "1rem",
                fontSize: "1rem",
                fontFamily: "gilroy-bold",
              }}
            >
              <h1 style={{ fontFamily: "gilroy-bold", color: "var(--lightblue)", fontSize: "1rem" }}>
                {lang ? `${eq9Value}EQ9 depositado na plataforma` : `${eq9Value}EQ9 deposited on platform`}
              </h1>
            </div>
            <button
              className="default-input"
              onClick={() => {
                setPixLoading(false);
                setPixConfirm(false);
                setPix(false);
                setPixImg();
                handleClose();
              }}
              style={{ background: "transparent", color: "var(--lightblue)", border: "2px var(--lightblue) solid" }}
            >
              {lang ? `CLIQUE AQUI PARA SAIR` : `CLICK HERE TO LEAVE`}
            </button>
          </section>
        )
      ) : pixLoading ? (
        <section
          style={{
            width: "450px",
            height: "300px",
            background: "rgba(0,0,0,0.7)",
            zIndex: "100",
            borderRadius: "10px",
            padding: "1rem",
            zIndex: "300",
          }}
        >
          <div
            className="default-input"
            style={{
              height: "210px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "1rem",
              fontSize: "1rem",
              fontFamily: "gilroy-bold",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
          <button className="default-input" style={{ background: "transparent" }}>
            {lang === "pt" ? `DEPOSITANDO ${eq9Value}EQ9` : `DEPOSITING ${eq9Value}EQ9`}
          </button>
        </section>
      ) : (
        <>
          <h1
            style={{
              textAlign: "center",
              color: "var(--pink)",
              position: "absolute",
              top: "3.5rem",
              left: "50%",
              transform: "translate(-50%,-50%)",
              width: "100%",
              zIndex: 1000,
            }}
          >
            {lang === "pt" ? "DEPOSITAR EQ9" : "DEPOSIT EQ9"}
          </h1>
          <section
            style={{
              width: "450px",
              height: "300px",
              display: open ? "block" : "none",
              background: "rgba(0,0,0,0.7)",
              zIndex: "100",
              borderRadius: "10px",
              zIndex: "300",
              display: "flex",
              marginTop: "10px",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "150px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem",
              }}
            >
              <p style={{ padding: "5px", color: "var(--lightblue)", marginTop: "3rem", marginBottom: "-10px", fontSize: "1rem", fontFamily: "gilroy" }}>
                {user?.wallets?.default?.address}
              </p>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <label style={{ padding: "2rem", fontSize: "2rem", fontFamily: "gilroy-bold", color: "var(--pink)", marginLeft: "-0.8rem" }}>EQ9</label>
                <input
                  type="number"
                  style={{
                    padding: "10px",
                    margin: "1rem",
                    fontSize: "1rem",
                    textAlign: "right",
                    width: "100%",
                    height: "3rem",
                    background: "none",
                    border: "none",
                    color: "var(--pink)",
                    outline: "none",
                  }}
                  name="eq9Value"
                  value={eq9Value}
                  min={0}
                  onChange={handleValueChange}
                  placeholder="EQ9"
                />
              </div>
            </div>
            <p
              style={{ fontSize: ".8rem", width: "350px", height: "40px", margin: "2px", textAlign: "center", color: "var(--pink)", cursor: "pointer" }}
              onClick={() => setConfirmDepositModal(true)}
            >
              {lang === "pt" ? "VALIDAR DEPÓSITO" : "CONFIRM DEPOSIT"}
              <Suggestion
                value={
                  lang === "pt"
                    ? "Você pode confirmar um depósito pelo Id de transação da metamask."
                    : "Here, you can confirm deposit from your Metamask transactional ID"
                }
              />
            </p>
            <button
              className="default-input"
              style={{ background: "transparent", width: "85%", cursor: "pointer" }}
              onClick={() => {
                handlePixGenerate();
              }}
            >
              {text[1]}
            </button>
          </section>
        </>
      )}
    </Dialog>
  );
}

export default DepositEq9;
