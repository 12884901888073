export default {
  apiKey: "AIzaSyAuo5lFw-Tai3yr4t_ckafJt0yttHzqL_A",
  authDomain: "equals-318a3.firebaseapp.com",
  projectId: "equals-318a3",
  storageBucket: "equals-318a3.appspot.com",
  messagingSenderId: "601821976462",
  appId: "1:601821976462:web:b875a3aa4156ac6aeef6df",
  measurementId: "G-RRPN3NL2WY",
};

// REACT_APP_apiKey=AIzaSyBM__6FCw9eGyS4WHZq8zRmh3PVX1lyfwg
// REACT_APP_authDomain=equalschat.firebaseapp.com
// REACT_APP_projectId=equalschat
// REACT_APP_storageBucket=equalschat.appspot.com
// REACT_APP_messagingSenderId=842490736878
// REACT_APP_appId=1:842490736878:web:45882816c47a3bee9683ff
// REACT_APP_measurementId=G-BBRZEM6N0W

// REACT_APP_FIREBASEUSER=equals9production@gmail.com
// REACT_APP_FIREBASEPASS=123153

// export default {
//   apiKey: process.env.REACT_APP_apiKey,
//   authDomain: process.env.REACT_APP_authDomain,
//   projectId: process.env.REACT_APP_projectId,
//   storageBucket: process.env.REACT_APP_storageBucket,
//   messagingSenderId: process.env.REACT_APP_messagingSenderId,
//   appId: process.env.REACT_APP_appId,
//   measurementId: process.env.REACT_APP_measurementId,
// };
