import React, { createContext, useState, useContext } from "react";

const LocalDBContext = createContext();

export default function LocalDBProvider({ children }) {
  const [loginNav, setLoginNav] = useState();
  const [leftNav, setLeftNav] = useState();
  const [alert, setAlert] = useState();
  const [alertMsg, setAlertMsg] = useState("");
  const [userLocalData, setUserLocalData] = useState(1);
  const [access, setAccess] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [wallet, setWallet] = useState();
  const [btnDialog, setBtnDialog] = useState(false);
  const [btnSideNav, setBtnSideNav] = useState(false);
  const [dialogMsg, setDialogMsg] = useState({
    title: "teste",
    content: "teste2",
  });
  const [allPlayers, setAllPlayers] = useState();
  const [player, setPlayer] = useState();
  const [severity, setSeverity] = useState();
  const [addTournament, setAddTournament] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [profileEdit, setProfileEdit] = useState(false);
  const [modalPlayerProfile, setModalPlayerProfile] = useState(false);
  const [startEvent, setStartEvent] = useState(false);
  const [tableModal, setTableModal] = useState(false);
  const [stakersTable, setStakersTable] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [tournamentID, setTournamentID] = useState();
  const [eq9, setEq9] = useState(0);
  const [installed, setInstalled] = useState(false);
  const [lang, setLang] = useState("en");
  const [mobile, setMobile] = useState(false);
  const [volume, setVolume] = useState(1);
  const [login, setLogin] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [tournamentStartNotifications, setTournamentStartNotification] = useState([]);
  const [pop, setPop] = useState(false);

  return (
    <LocalDBContext.Provider
      value={{
        loginNav,
        setLoginNav,
        leftNav,
        setLeftNav,
        alert,
        setAlert,
        alertMsg,
        setAlertMsg,
        userLocalData,
        setUserLocalData,
        access,
        setAccess,
        pageLoading,
        setPageLoading,
        wallet,
        setWallet,
        btnDialog,
        setBtnDialog,
        btnSideNav,
        setBtnSideNav,
        dialogMsg,
        setDialogMsg,
        allPlayers,
        setAllPlayers,
        player,
        setPlayer,
        severity,
        setSeverity,
        addTournament,
        setAddTournament,
        editModal,
        setEditModal,
        profileEdit,
        setProfileEdit,
        modalPlayerProfile,
        setModalPlayerProfile,
        startEvent,
        setStartEvent,
        tableModal,
        setTableModal,
        stakersTable,
        setStakersTable,
        cartItems,
        setCartItems,
        tournamentID,
        setTournamentID,
        eq9,
        setEq9,
        installed,
        setInstalled,
        lang,
        setLang,
        mobile,
        setMobile,
        volume,
        setVolume,
        login,
        setLogin,
        messageAlert,
        setMessageAlert,
        tournamentStartNotifications,
        setTournamentStartNotification,
        pop,
        setPop,
      }}
    >
      {children}
    </LocalDBContext.Provider>
  );
}

export function useLocalDB() {
  const context = useContext(LocalDBContext);
  if (!context) throw new Error("useImport precisa de um Arquivo importado");
  return context;
}
