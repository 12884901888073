import { useState, useEffect, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { motion } from "framer-motion";
import { useLocalDB } from "../../context/LocalDB";
import List from "../../components/list/EventsList";
import { Link } from "@material-ui/core";
import { useStore } from "../../context/storeContext";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Profile from "../../components/profile/EvoProfile";
import api from "../../services/api";
import Resizer from "../../components/imgResizer";
import language from "../../assets/language/text";
import sounds from "../../assets/sounds";
import AddTournamentMobile from "./addTournament";
import AddTournament from "../../components/dialog/addTournament";

import { Redirect } from "react-router-dom";
import decode from "jwt-decode";
import Pageloading from "../../components/pageloading/pageloading";

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};
const pageTransition = { type: "tween", easy: "circInOut", duration: 0.4 };
const click = sounds.btn;
const slide = sounds.slideCard;
const hoverCreateEvent = sounds.EventCreate;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box style={{ padding: 0 }} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Evo() {
  const [yourTournament, setYourTournament] = useState();
  const { setAlert, setAlertMsg, setSeverity, lang, setUserLocalData, addTournament, setAddTournament, mobile } = useLocalDB();
  const [productsList, setProductsList] = useState([]);
  const [value, setValue] = useState(0);
  const { user, setUser, evo, setEvo, token } = useStore();
  const [playerSearch, setPlayerSearch] = useState();
  const [playersTable, setPlayersTable] = useState([]);
  const [player, setPlayer] = useState();
  const InvitePlayerInput = useRef(null);
  const RulesInput = useRef(null);
  const MailInput = useRef(null);
  const text = language[lang].evo_page;

  const fetchEvo = async () => {
    const userTokenDecoded = decode(token);
    if (userTokenDecoded.evo) {
      const getEvoData = await api.get(`evos/${userTokenDecoded.evo._id}`).catch(console.log);
      setEvo(getEvoData.data);
    }
  };

  async function fetchUser() {
    setUserLocalData({ access: 3 });
    const getUser = await api.get(`users/${user._id}`).catch(console.log);
    return setUser(getUser.data);
  }

  const fetchPlayers = async (item) => {
    const getAllPlayers = await api.get(`users`).catch(console.log);
    const filterSearch = getAllPlayers.data.filter((player) => {
      const pToLower = (palavra, item) => {
        if (palavra) {
          return palavra.toLowerCase().includes(item);
        }
      };
      if (pToLower(player.name, item) || pToLower(player.nickname, item)) {
        return true;
      }
    });
    setPlayersTable(filterSearch);
  };

  useEffect(() => {
    if (!user) {
      fetchUser();
    }
    if (!evo) {
      fetchEvo();
    }
    if (!yourTournament) {
      fetchEvents();
    }
  }, []);

  const fetchEvents = async () => {
    if (evo) {
      const fetchTournament = await api.get(`tournaments/find-by-evo/${evo._id}`).catch(console.log);
      return setYourTournament(fetchTournament.data);
    }
  };

  // useEffect(() => {
  //   if (addTournament === false) {
  //     fetchEvents();
  //   }
  // }, [addTournament]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleDell(e, item, index) {
    e.preventDefault();
    const rulesReturn = [...evo[item]];
    rulesReturn.splice(index, 1);
    const getDell = await api
      .patch(`evos`, {
        [item]: rulesReturn,
      })
      .catch(() => {
        setAlert(true);
        setAlertMsg("Erro ao atualizar as regras");
        setSeverity("error");
      });
    if (getDell) {
      setAlert(true);
      setAlertMsg("Regras Atualizadas");
      setSeverity("success");
      fetchEvo();
    }
  }

  const handleSubmitValue = async (e, item) => {
    e.preventDefault();
    let itemSubmit = item === "rules" ? RulesInput : MailInput;
    let textValue = item === "rules" ? itemSubmit.current.value.toUpperCase() : itemSubmit.current.value.toUpperCase();
    if (textValue.length !== 0) {
      const putData = await api
        .patch(`evos`, {
          [item]: [...evo[item], textValue],
        })
        .catch(() => {
          setAlert(true);
          setAlertMsg("Erro ao atualizar a regra");
          setSeverity("error");
        });
      if (putData) {
        fetchEvo();
        itemSubmit.current.value = "";
        setAlert(true);
        setAlertMsg("Regra Atualizada");
        setSeverity("success");
      }
    }
  };

  if (!evo) return <Pageloading />;
  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="page-evo">
      <div className="page-evo-bg" />
      <div className="page-evo-left">
        <div className="page-evo-left-profile">
          <Profile />
        </div>
        <div className="page-evo-left-menuTab">
          <AppBar
            position="static"
            className="menuOrganizer"
            style={{
              background: "transparent",
              width: "100%",
              alignItems: "space-between",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              style={{
                background: "transparent",
                width: "100%",
                alignItems: "space-between",
              }}
            >
              <Tab
                label={text[1]}
                {...a11yProps(0)}
                style={{ width: "33%" }}
                onClick={() => {
                  click.currentTime = 0;
                  click.play();
                }}
              />
              <Tab
                label={text[2]}
                {...a11yProps(1)}
                style={{ width: "33%" }}
                onClick={() => {
                  click.currentTime = 0;
                  click.play();
                }}
              />
              <Tab
                label="TORNEIOS"
                {...a11yProps(2)}
                style={{ width: "33%" }}
                onClick={() => {
                  click.currentTime = 0;
                  click.play();
                }}
              />
              {/* <Tab
              label={text[4]}
              {...a11yProps(3)}
              style={{ width: "25%" }}
              onClick={() => {
                click.currentTime = 0;
                click.play();
              }}
            /> */}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <form style={{ padding: "1rem " }} className="default-input-i" onSubmit={(e) => handleSubmitValue(e, "rules")}>
              <input type="text" placeholder="ADD RULES" ref={RulesInput} />
              <i style={{ paddingRight: "1rem" }} className="fas fa-plus fa-lg" onClick={(e) => handleSubmitValue(e, "rules")} />
            </form>
            <ul
              style={{
                height: "30vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {evo.rules &&
                evo.rules.map((item, index) => {
                  return (
                    <li
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "1rem",
                        fontSize: "clamp(.8rem, 1.5vw, 2rem)",
                      }}
                    >
                      {item}
                      <button style={{ background: "none", border: "none" }} onClick={(e) => handleDell(e, "rules", index)}>
                        <i
                          className="far fa-trash-alt fa-lg"
                          style={{
                            color: "var(--pink)",
                            cursor: "pointer",
                          }}
                        ></i>
                      </button>
                    </li>
                  );
                })}
            </ul>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <form style={{ padding: "1rem" }} className="default-input-i" onClick={(e) => handleSubmitValue(e, "emails")}>
              <input type="email" placeholder="ADD E-MAIL ADDRESS" ref={MailInput} />
              <i style={{ paddingRight: "1rem" }} className="fas fa-search fa-lg" onClick={(e) => handleSubmitValue(e, "emails")} />
            </form>
            <ul
              style={{
                height: "30vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {evo.emails &&
                evo.emails.map((item, index) => {
                  return (
                    <li
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "1rem",
                        fontSize: "clamp(.8rem, 1.5vw, 2rem)",
                      }}
                    >
                      {item}
                      <button style={{ background: "none", border: "none" }} onClick={(e) => handleDell(e, "emails", index)}>
                        <i className="far fa-trash-alt fa-lg" style={{ color: "var(--pink)", cursor: "pointer" }}></i>
                      </button>
                    </li>
                  );
                })}
            </ul>
          </TabPanel>
          <TabPanel style={{ overflow: "auto" }} value={value} index={2}>
            <List evento={yourTournament} />
          </TabPanel>
        </div>
        {addTournament && <AddTournament fetchEvents={fetchEvents} />}
      </div>
    </motion.div>
  );
}

export default Evo;
