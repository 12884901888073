import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";
import { useLocalDB } from "../../context/LocalDB";
import List from "../../components/list/EventsList";
import { useStore } from "../../context/storeContext";
import Profile from "../../components/profile/EvoProfile";
import api from "../../services/api";
import sounds from "../../assets/sounds";
import AddTournament from "../../components/dialog/addTournament";
import { Redirect } from "react-router-dom";
import decode from "jwt-decode";
import AbasEvo from "./AbasEvo";

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};
const pageTransition = { type: "tween", easy: "circInOut", duration: 0.4 };

function Organizer() {
  const [yourTournament, setYourTournament] = useState();
  const { setAlert, setAlertMsg, setSeverity, setUserLocalData } = useLocalDB();
  const { user, setUser, evo, setEvo, token } = useStore();
  const [addTournament, setAddTournament] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [amountTestPlayers, setAmountTestPlayers] = useState(4);
  const torneioTesteName = useRef(null);

  const fetchEvo = async () => {
    const userTokenDecoded = decode(token);
    if (userTokenDecoded.evo) {
      const getEvoData = await api.get(`evos/${userTokenDecoded.evo._id}`).catch(console.log);
      setEvo(getEvoData.data);
    }
  };

  const handleClone = (evento) => {
    setSelectedEvent(evento);
    setAddTournament(true);
  };

  const fetchUser = async () => {
    setUserLocalData({ access: 3 });
    const getUser = await api.get(`users/${user._id}`).catch(console.log);
    return setUser(getUser.data);
  };

  const fetchEvents = async () => {
    if (evo) {
      const fetchTournament = await api.get(`tournaments/list/evo/${evo._id}`, { params: { page: 0, size: 30 } }).catch(console.log);
      if (fetchTournament) {
        return setYourTournament(fetchTournament.data);
      }
    }
  };

  const fetchAllEvents = async (page, size, setLoading) => {
    const fetchTournament = await api.get(`tournaments/list/evo/${evo._id}`, { params: { page: page ? page : 0, size: size ? size : 20 } }).catch(console.log);
    setYourTournament([...yourTournament, ...fetchTournament.data]);
    setLoading && setLoading(false);
  };

  useEffect(() => {
    if (!user) {
      fetchUser();
    }
    if (!evo) {
      fetchEvo();
    }
    if (!yourTournament) {
      fetchEvents();
    }
  }, []);

  const CreateTestTournament = async () => {
    const createTournamentWithPlayers = await api
      .post(`/tournaments/dev`, {
        name: torneioTesteName.current.value,
        startDate: "Mon Oct 11 2021 19:16:06 GMT-0300 (Brasilia Standard Time)",
        amountPlayers: +amountTestPlayers,
        img: evo.img,
        rules: ["regra1", "regra2"],
        mail: ["email1", "email2"],
        description: "torneio de teste",
        subscription: "43000000000000000000",
        hour: "19:00",
        game: {
          name: "MAGIC",
          format: "MODERN",
          needsDeck: true,
          typeOf: "ARENA",
        },
        reward: {
          typeOf: "EQ9",
        },
        tourneyConfig: {
          format: "swiss",
          gameType: "magic",
          playoffs: null,
          cutLimit: 8,
          bestOf: 3,
          winValue: 3,
          drawValue: 1,
          lossValue: 0,
          // tiebreakers: ["magic-tcg"],
        },
      })
      .catch((error) => {
        setAlert(true);
        setAlertMsg(error.response.data.message);
        setSeverity("error");
      });
    if (createTournamentWithPlayers) {
      fetchEvents();
      setAlert(true);
      setAlertMsg("Criado com sucesso!");
      setSeverity("success");
    }
  };

  if (!evo) return <Redirect from="/organizer" to="/" />;

  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="page-evo">
      {process.env.REACT_APP_APP_ENV === "testnet" && (
        <div style={{ position: "absolute", top: "1rem", left: "50%", transform: "translateX(-50%)", zIndex: 1000 }}>
          <input type="text" className="default-input" placeholder="nome" style={{ height: "3rem", width: "200px", margin: "5px" }} ref={torneioTesteName} />
          <input
            type="number"
            className="default-input"
            style={{ height: "3rem", width: "70px", textAlign: "center", margin: "5px" }}
            value={amountTestPlayers}
            onChange={(e) => setAmountTestPlayers(e.target.value)}
          />
          <button className="default-input" style={{ height: "3rem", width: "200px", fontSize: "1rem", margin: "5px" }} onClick={CreateTestTournament}>
            Criar Torneio Teste
          </button>
        </div>
      )}
      <span className="page-evo-bg" />
      <div className="page-evo-left">
        <div className="page-evo-left-profile">
          <Profile nickname={user.nickname} name={user.name} score={user.score} age={user.age} img={user.img} setAddTournament={setAddTournament} />
        </div>
        <div className="page-evo-left-menuTab">
          <AbasEvo />
        </div>
      </div>
      <div className="page-evo-right">
        <List evento={yourTournament} fetchEvents={fetchEvents} handleClone={handleClone} fetchAllEvents={fetchAllEvents} />
      </div>
      {addTournament && <AddTournament fetchEvents={fetchEvents} open={addTournament} setOpen={setAddTournament} evento={selectedEvent} />}
    </motion.div>
  );
}

export default Organizer;
