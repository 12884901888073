import React from "react";
import sounds from "../../assets/sounds";

const click = sounds.btn;

const Btn = (props) => {
  return (
    <button
      {...props}
      onClick={() => {
        click.currentTime = 0;
        click.play();
        props.onClick && props.onClick();
      }}
    >
      {props.children}
    </button>
  );
};

export default Btn;
