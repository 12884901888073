import React, { useState } from "react";
import UIInput from "../UI/Input/input";
import UICheckbox from "../UI/Checkbox/Checkbox";
import UIButton from "../UI/Button/Button";
import Faddress from "./Faddress";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { CircularProgress } from "@material-ui/core";
import { useStore } from "../../context/storeContext";
import { useLocalDB } from "../../context/LocalDB";
import { useFetchData } from "../../context/userContext";
import { calculateAge } from "../../utils/calculateAge";

const getData = (data) => {
  const myDate = new Date(data);
  return myDate.toISOString().substring(0, 10);
};

function SingUp({ children, user, setClose, setInputsValue }, className) {
  const [inputsFaltantes, setInputsFaltantes] = useState([]);
  const { UpdateUser } = useFetchData();
  const [loading, setLoading] = useState(false);
  const { setAlert, setAlertMsg, setSeverity } = useLocalDB();
  const initialState = {
    name: user.name,
    cpf: user.cpf,
    birthDate: user.birthDate ? calculateAge(user.birthDate) : "new Date()",
    address: user.address,
    addressNumber: user.addressNumber,
    complement: user.complement,
    zipCode: user.zipCode,
    district: user.district,
    city: user.city,
    state: user.state,
    country: user.country,
  };

  const [inputs, setInputs] = useState(initialState);

  const getRegistered = async (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      if (inputs.password !== inputs.confirmPassword) {
        setAlert(true);
        setAlertMsg("Senha não confere!");
        setSeverity("error");
        setLoading(false);
        return;
      }
      const validateInputs = Object.keys(inputs).filter((item) => {
        if (item != "complement" && !inputs[item]) return true;
      });
      if (validateInputs.length > 0) {
        setInputsFaltantes(validateInputs);
        setAlert(true);
        setAlertMsg("Preencha todos os itens do formulári!");
        setSeverity("error");
        setLoading(false);
        return;
      }
      const FormSignUp = {
        name: inputs.name,
        cpf: inputs.cpf?.toString(),
        birthDate: new Date(inputs.birthDate),
        address: inputs.address,
        addressNumber: inputs.addressNumber,
        complement: inputs.complement,
        zipCode: inputs.zipCode,
        district: inputs.district,
        city: inputs.city,
        state: inputs.state,
        country: inputs.country,
      };
      const getSignUp = await api.put(`users/payment-data`, FormSignUp).catch(() => {
        setAlertMsg("Usuário existênte");
        setSeverity("error");
        setLoading(false);
      });
      if (getSignUp) {
        setAlert(true);
        setAlertMsg("Usuário atualizado com sucesso!");
        setSeverity("success");
        setLoading(false);
        setInputsValue(FormSignUp);
        UpdateUser();
        setClose();
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  return (
    <form
      className={`ui-form ${className}`}
      // noValidate
      onSubmit={getRegistered}
    >
      <div className="ui-form-grid">
        <UIInput maxLength="120" value={inputs.name} name="name" id="1" placeholder="Nome completo" onChange={handleChange} />
        <UIInput maxLength="11" id="2" placeholder="CPF" name="cpf" value={inputs.cpf} onChange={handleChange} />
        <UIInput id="3" placeholder="Nascimento" defaultValue={inputs.birthDate} type="date" name="birthDate" onChange={handleChange} />
      </div>
      <Faddress className="ui-form-address" handleChange={handleChange} inputs={inputs} onChange={handleChange} />

      <div className="ui-form-submit-container">
        {/* <UIButton style={{ width: "90%", height: "6vh", minHeight: "35px" }} component={Link} to="/index" className="ui-button--colorful-green ui-button--icon"> */}
        <button style={{ width: "90%", height: "6vh", minHeight: "35px" }} onClick={getRegistered} className="default-input">
          {!loading ? "Confirmar" : <CircularProgress />}
        </button>
      </div>
      {children}
    </form>
  );
}

export default SingUp;
