import { useEffect, useState } from "react";
import { useLocalDB } from "../../context/LocalDB";
import WinnerCard from "../../pages/room/WinnerCard";
import "./topPlayers.scss";

function Index({ data }) {
  const { setPlayer, setModalPlayerProfile } = useLocalDB();
  const [topPlayers, setTopPlayers] = useState();

  useEffect(() => {
    if (data) {
      const getPlayers = data.filter((item, index) => index <= 3);
      setTopPlayers(getPlayers);
    }
  }, [data]);

  return (
    <div className="topPlayers">
      {topPlayers?.map((item, index) => {
        return (
          <div
            className="topPlayers__item"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: index === 1 || index === 3 ? "row-reverse" : "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            key={`topPlayers${index}`}
            onClick={() => {
              setPlayer(item.user);
              setModalPlayerProfile(true);
            }}
          >
            <WinnerCard data={item} side={index != 1 && index != 2 ? 1 : 2} style={{ transform: "scaleX(0.51) scaleY(0.49)", marginTop: "-0.8rem" }} />
            <div className="topPlayers__item__description" style={{ width: "70%", textAlign: index === 1 || index === 3 ? "right" : "left" }}>
              <p>#{data.indexOf(item) + 1} LUGAR</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Index;
