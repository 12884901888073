import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sound from "../../assets/sounds/";
import Pageloading from "../../components/pageloading/pageloading";
import { useLocalDB } from "../../context/LocalDB";
import api from "../../services/api";
import WinnerCard from "./WinnerCard";
import NumberFromWei from "../../utils/fromWei";
import { setTimeout } from "timers";

const WinnerPage = ({ tournament, winner, standings }) => {
  const [playerWinner, setPlayerWinner] = useState();
  const [player2Winner, setPlayer2Winner] = useState();
  const [player3Winner, setPlayer3Winner] = useState();
  const [player4Winner, setPlayer4Winner] = useState();
  const [distribution, setDistribution] = useState();
  const { lang } = useLocalDB();

  useEffect(() => {
    if (tournament) {
      const getTournamentDistributionData = async () => {
        const getDistribution = await api.get(`/distributions/tournament/${tournament._id}`).catch(() => {
          setTimeout(() => {
            getTournamentDistributionData();
          }, 5000);
        });
        if (getDistribution) {
          setDistribution(getDistribution.data);
          setPlayerWinner(getDistribution.data.winners[0]);
          setPlayer2Winner(getDistribution.data.winners[1]);
          setPlayer3Winner(getDistribution.data.winners[2]);
          setPlayer4Winner(getDistribution.data.winners[3]);
        } else {
          setPlayerWinner(standings[0]);
          setPlayer2Winner(standings[1]);
          setPlayer3Winner(standings[2]);
          setPlayer4Winner(standings[3]);
        }
      };
      getTournamentDistributionData();
    }
  }, [tournament]);

  if (!winner) return <Pageloading />;

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "80%",
        height: "100%",
      }}
    >
      <h1 style={{ fontFamily: "gilroy-bold", color: "var(--lightgreen)" }}>WINNERS</h1>
      {playerWinner && (
        <div style={{ position: "absolute", left: "50%", top: "0%", transform: "scale(0.8) translateX(-60%)" }}>
          <h1 style={{ textAlign: "center" }}>1 {lang === "pt" ? "Lugar" : "Place"}</h1>
          <div>
            <WinnerCard data={playerWinner} side={1} />
          </div>
        </div>
      )}
      {player2Winner && (
        <div style={{ position: "absolute", left: "50%", top: "20%", marginLeft: "15%", transform: "scale(0.7)  translateX(-60%)" }}>
          <h1 style={{ textAlign: "center" }}>2 {lang === "pt" ? "Lugar" : "Place"}</h1>
          <div>
            <WinnerCard data={player2Winner} side={2} />
          </div>
        </div>
      )}
      {player3Winner && (
        <div style={{ position: "absolute", left: "50%", top: "35%", marginLeft: "-15%", transform: "scale(0.6)  translateX(-60%)" }}>
          <h1 style={{ textAlign: "center" }}>3 {lang === "pt" ? "Lugar" : "Place"}</h1>
          <div>
            <WinnerCard data={player3Winner} side={1} />
          </div>
        </div>
      )}
      {player4Winner && (
        <div style={{ position: "absolute", left: "50%", top: "55%", transform: "scale(0.5)  translateX(-60%)" }}>
          <h1 style={{ textAlign: "center" }}>4 {lang === "pt" ? "Lugar" : "Place"}</h1>
          <div>
            <WinnerCard data={player4Winner} side={2} />
          </div>
        </div>
      )}
    </div>
  );
};

export default WinnerPage;
