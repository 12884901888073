import React from "react";
import UIInput from "../UI/Input/input";

const Faddress = ({ handleChange, className, inputs }) => {
  return (
    <div className={`faddress ${className}`}>
      <UIInput id="5" placeholder="Logradouro" name="address" value={inputs.address} onChange={handleChange} />
      <UIInput min={0} type="number" id="6" placeholder="n.º" name="addressNumber" value={inputs.addressNumber} onChange={handleChange} />
      <UIInput id="7" placeholder="Complemento (opcional)" name="complement" value={inputs.complement} onChange={handleChange} />
      <UIInput maxlength="10" id="4" placeholder="CEP" name="zipCode" value={inputs.zipCode} onChange={handleChange} />
      <UIInput id="8" placeholder="Bairro" name="district" value={inputs.district} onChange={handleChange} />
      <UIInput id="9" placeholder="Cidade" name="city" value={inputs.city} onChange={handleChange} />
      <UIInput id="10" placeholder="UF" name="state" value={inputs.state} onChange={handleChange} />
      <UIInput id="11" placeholder="País" name="country" value={inputs.country} onChange={handleChange} />
    </div>
  );
};

Faddress.defaultProps = {
  className: "",
};

export default Faddress;
