import { useState, useEffect } from "react";

import { Route, Redirect, useLocation, useParams, Switch, useHistory } from "react-router-dom";
import Welcome from "../../pages/welcome";
import Alert from "../../components/alert/alert";
import Login from "../../pages/login";
import Player from "../../pages/player";
import Event from "../../pages/eventos";
import Staker from "../../pages/staker";
import Organizer from "../../pages/EVO";
import BadegsPage from "../../pages/badges";
import Cards from "../cardInport/index";
import Ranking from "../../pages/ranking/index.js";
import GraphicStaker from "../../pages/Graficos/stakersgraphic";
import MenuApp from "../navbar/MenuApp";
import Wallet from "../../pages/wallet/";
import SideMenu from "../navbar/SideNav";
import DialogBox from "../dialog/AddPlayer";
import HallOfFame from "../../pages/HallOfFame";
import Store from "../../pages/store";
import Tournaments from "../../pages/Tournaments/";
import MeusStakers from "../../pages/meusStakers";
import ConnectWallet from "../../pages/wallet/connect";
import DeckAdd from "../../pages/deckAdd";
import TourneyAdmin from "../../pages/EVO/tourneyAdmin";
import Room from "../../pages/room";
import RankingProfile from "../dialog/rankingProfile";
import { useStore } from "../../context/storeContext";
import PageMessage from "../messages";
import StartEvent from "../dialog/StartEvent";
import FriendMsg from "../messages/Friends/FriendMsg";
import Staked from "../dialog/Staked";
import teste from "../../pages/teste";
import Solicitation from "../../pages/Solicitation";
import addDeck from "../../pages/deckAdd";
import AudioController from "../Controller";
import StandingsRoom from "../../pages/room/standingsRoom";
import WinnerPage from "../../pages/room/winnerPage.js";
import EditDeck from "../../pages/deckAdd/EditDeck";
import LoadingPage from "../pageloading/pageloading";
import MobileRoutes from "./MobileRoutes";
import Social from "./social";
import RoomEditDeck from "../../pages/room/editDeck/EditDeck";

// import SideMenu from "../../mobile/navbar/SideNav";
import { useLocalDB } from "../../context/LocalDB";
import TourneyMsgs from "../messages/tourneyMsgs";
import ParticlesPage from "../Particles";
import DeckView from "../../pages/deckAdd/DeckView";
import OnDemand from "../../pages/onDemand";

function RotasTemporarias() {
  const { user, evo } = useStore();
  const [width, setWidth] = useState();
  const { userLocalData, setUserLocalData, mobile } = useLocalDB();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => (!evo ? setUserLocalData({ access: 1 }) : setUserLocalData({ access: 3 })), []);

  if (!user) return <LoadingPage />;
  if (mobile) return <MobileRoutes />;

  if (evo) {
    return (
      <>
        {/* {CheckAccess(user ? user.access : 0)} */}
        <ParticlesPage />
        <MenuApp />
        <SideMenu />
        <TourneyMsgs />
        <AudioController />
        {/* <PageMessage /> */}
        {/* <FriendMsg /> */}
        {/* <Redirect from="/" to="/organizer" /> */}
        <div className="App" history={history}>
          <Switch history={history}>
            <Route path="/privacy-policy" component={"politica de privacidade"} />
            <Route path="/" exact={true} component={Welcome} />
            <Route path="/player" component={Player} />
            <Route path="/staker" component={Staker} />
            <Route path="/badges/:id" component={BadegsPage} />
            <Route path="/organizer" component={Organizer} />
            <Route path="/event/:id" component={Event} />
            <Route path="/halloffame" component={HallOfFame} />
            <Route path="/store" component={Store} />
            <Route path="/ranking" component={Ranking} />
            <Route path="/room/:id" component={Room} />
            <Route
              path="/twitch/:id"
              component={(e) => {
                Social("twitch.tv");
              }}
            />
            {/* <Route path='/ranking/:id' component={RankingId} /> */}
            <Route path="/graphic/staker" component={GraphicStaker} />
            <Route path="/wallet" component={Wallet} />
            <Route path="/meusstakers" component={MeusStakers} />
            <Route path="/tournaments" component={Tournaments} />
            <Route path="/walletConnect" component={ConnectWallet} />
            <Route path="/deck" component={DeckAdd} />
            <Route path="/tourneyAdm/:id" component={TourneyAdmin} />
            {/* <Route path="/teste" component={teste} /> */}
            <Route path="/cardinport" component={Cards} />
            <Route path="/evosolicitation" component={Solicitation} />
            <Route path="/addDeck" exact={true} component={addDeck} />
            <Route path="/addDeck/:id" component={addDeck} />
            <Route path="/standingsRoom/:id" component={StandingsRoom} />
            <Route path="/winner" component={WinnerPage} />
            <Route path="/editDeck/:id" component={EditDeck} />
            <Route path="/deckView/:id" component={DeckView} />
            <Route path="/room/editDeck/:id" component={RoomEditDeck} />
            <Route path="/onDemand" component={OnDemand} />
          </Switch>
        </div>
      </>
    );
  } else {
    return (
      <>
        {/* {CheckAccess(user ? user.access : 0)} */}
        <ParticlesPage />
        <MenuApp />
        <SideMenu />
        <RankingProfile />
        <PageMessage />
        <TourneyMsgs />
        <AudioController />
        {/* <FriendMsg /> */}
        {/* <Redirect from="*" to="/player" /> */}
        <div className="App" history={history}>
          {/* <Switch location={location} key={location.pathname}> */}
          <Switch history={history}>
            <Route path="/privacy-policy" component={"politica de privacidade"} />
            <Route path="/" exact={true} component={Welcome} />
            <Route path="/player" component={Player} />
            <Route path="/staker" component={Staker} />
            <Route path="/badges/:id" component={BadegsPage} />
            <Route path="/organizer" component={Organizer} />
            <Route path="/event/:id" component={Event} />
            <Route path="/halloffame" component={HallOfFame} />
            <Route path="/store" component={Store} />
            <Route path="/ranking" component={Ranking} />
            <Route path="/room/:id" component={Room} />
            {/* <Route path='/ranking/:id' component={RankingId} /> */}
            <Route path="/graphic/staker" component={GraphicStaker} />
            <Route path="/wallet" component={Wallet} />
            <Route path="/meusstakers" component={MeusStakers} />
            <Route path="/tournaments" component={Tournaments} />
            <Route path="/walletConnect" component={ConnectWallet} />
            <Route path="/deck" component={DeckAdd} />
            <Route path="/tourneyAdm/:id" component={TourneyAdmin} />
            <Route path="/teste" component={teste} />
            <Route path="/cardinport" component={Cards} />
            <Route path="/evosolicitation" component={Solicitation} />
            <Route path="/addDeck" exact={true} component={addDeck} />
            <Route path="/addDeck/:id" component={addDeck} />
            <Route path="/standingsRoom/:id" component={StandingsRoom} />
            <Route path="/winner" component={WinnerPage} />
            <Route path="/editDeck/:id" component={EditDeck} />
            <Route path="/deckView/:id" component={DeckView} />
            <Route path="room/editDeck/:id" component={RoomEditDeck} />
            <Route path="/onDemand" component={OnDemand} />
          </Switch>
        </div>
      </>
    );
  }
}

export default RotasTemporarias;
