import React, { useState, useEffect } from "react";
import "./messages.scss";
import { Link } from "react-router-dom";
import { useStore } from "../../context/storeContext";
import { useFetch } from "../../hooks/useFetch";
import language from "../../assets/language/text";
import { useLocalDB } from "../../context/LocalDB";
import LoadingPage from "../pageloading/pageloading";
import { Snackbar } from "@material-ui/core";
import { useSocket } from "../../context/webSocket";

const initialState = {
  height: "80px",
  degrade: "linear-gradient(black, transparent 80%)",
};
function TourneyMsgs() {
  const [degrade, setDegrade] = useState(initialState.degrade);
  const { lang, messageAlert, setMessageAlert, tournamentStartNotifications, setTournamentStartNotification } = useLocalDB();
  const [height, setHeight] = useState(initialState.height);
  const [tournamentCreationNotifications, setTournamentCreationNotifications] = useState([]);
  const { socket } = useSocket();
  const [opacity, setOpacity] = useState(1);
  const [open, setOpen] = useState(false);
  const [cursor, setCursor] = useState();
  const [message, setMessage] = useState([]);
  const text = language[lang].messages;
  const { user } = useStore();

  useEffect(() => {
    setMessageAlert(true);
    socket.off("tournamentStartNotifications").on("tournamentStartNotifications", setTournamentStartNotification);
    socket.off("tournamentCreationNotifications").on("tournamentCreationNotifications", setTournamentCreationNotifications);
  }, []);

  useEffect(() => {
    if (tournamentStartNotifications.length > 0) {
      setMessage(tournamentStartNotifications);
      handleOpen();
      setOpen(true);
      setHeight("50px");
      setCursor();
      setOpacity(0.4);
      // console.log("torneio startou");
    }
  }, [tournamentStartNotifications]);

  useEffect(() => {
    if (tournamentCreationNotifications.length > 0) {
      setMessage(tournamentCreationNotifications);
      handleOpen();
      setOpen(true);
      setHeight("50px");
      setCursor();
      setOpacity(0.4);
      // console.log("torneio foi criado");
    }
  }, [tournamentCreationNotifications]);

  const handleOpen = () => {
    if (!open) {
      setOpen(true);
      setHeight("200px");
      setCursor();
      setOpacity(0.4);
      setDegrade("linear-gradient(black, transparent 90%)");
    } else {
      setOpen(false);
      setHeight(initialState.height);
      setCursor("pointer");
      setDegrade(initialState.degrade);
      setOpacity(1);
    }
  };

  return (
    <Snackbar className="topCenter" open={open} autoHideDuration={10000} onClose={() => setOpen(false)}>
      <div className="pageMessage" style={{ height: height, cursor: cursor }} onClick={() => handleOpen()}>
        <span
          className="degradeMsg"
          style={{
            opacity: opacity,
            background: degrade,
            pointerEvents: "none",
          }}
        />
        <div className="msgBlock">
          <div
            style={{
              height: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            {message.length > 0
              ? message.map((item, index) => {
                  return (
                    <p>
                      {open ? (
                        <Link to={item.path}>
                          <span>{item.origin}</span> <span>{item.msg}</span>
                          <span> {text[0]}</span>
                        </Link>
                      ) : (
                        <p>
                          <span>{item.origin}:</span> <span>{item.msg}</span>
                        </p>
                      )}
                    </p>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </Snackbar>
  );
}

export default TourneyMsgs;
