import React, { useState, useEffect } from "react";
import api from "../../services/api";

function Solicitation() {
  const [listSolic, setListSolic] = useState([]);
  const [listEvo, setListEvo] = useState([]);

  //   useEffect(() => {
  //     api.get('user')
  //   }, [])
  return (
    <div>
      {/* Tabela para adicao de Solicitacoes */}
      <table>
        {listSolic.map((item, index) => {
          return <tr key={index}>{item}</tr>;
        })}
      </table>
      <div></div>
    </div>
  );
}

export default Solicitation;
