import React, { useEffect, useRef, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import { useLocalDB } from "../../context/LocalDB";
import { Link } from "react-router-dom";
import { useStore } from "../../context/storeContext";
import useStorage from "../../utils/useStorage";
import eq9Config from "../../services/eq9Config";
import language from "../../assets/language/text";
import BtnSide from "../../components/buttons/BtnSide";
import api from "../../services/api";
import sound from "../../assets/sounds";
import { useWallet } from "../../context/walletContext";

const continuousSound = sound.btnContinuous;
const click = sound.btn;
const screenPass = sound.screenPass;

export default function TemporaryDrawer() {
  const { btnSideNav, setBtnSideNav, userLocalData, setUserLocalData, setAddTournament, lang, setAlert, setAlertMsg, setSeverity, mobile } = useLocalDB();
  const { user, setUser } = useStore();
  const { eq9 } = useWallet();
  const chatscrollref = useRef(null);
  const [messages, setMessages] = useState([]);
  const [accessBtn, setAccessBtn] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [token, setToken, remove] = useStorage("token");
  const text = language[lang].sideNav;
  const textAccess = language[lang].w_page;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setUserLocalData({ access: 3 }), []);

  useEffect(() => {
    if (messages) {
      if (chatscrollref.current !== null) {
        if (chatscrollref.current.scrollHeight > chatscrollref.current.offsetHeight) {
          chatscrollref.current.scrollTop = chatscrollref.current.scrollHeight - chatscrollref.current.offsetHeight;
        }
      }
    }
  }, [chatscrollref]);

  useEffect(() => {
    if (user.message) {
      setMessages(user.message);
    }
  }, [btnSideNav]);

  const handleClose = () => {
    setBtnSideNav(false);
    sound.slide.pause();
    sound.slide.currentTime = 0;
    sound.slide.play();
  };

  const handleChange = async (access) => {
    const accessType = { 1: "Player", 2: "Staker", 3: "Organizador" };
    setUserLocalData({ access: access });
    // const fetchUser = await api.get(`users/${user._id}`);
    // setUser(fetchUser.data);
    setAlert(true);
    setAlertMsg(`Entrando como perfil de ${accessType[access]}`);
    setSeverity("success");
  };

  const userAccess = { 1: "PLAYER", 2: "STAKER", 3: "ORGANIZADOR" };

  return (
    <div>
      {/* <React.Fragment key={"left"}> */}
      <Drawer
        anchor={"left"}
        open={btnSideNav}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            // position: "relative",
          },
        }}
      >
        <div className={`container-side-navbar ${userLocalData ? userAccess[userLocalData.access] : userAccess[1]}`}>
          {user && (
            <>
              <nav className="navBar__side">
                <div className="infoUser__nav">
                  <h2>
                    {text[0]} {user.nickname}
                  </h2>
                  <div className="btnsUser__Nav">
                    <Link to={mobile ? "/mobile" : "/"} className="myDataBtn__sideNav" onClick={handleClose}>
                      {text[1]}
                    </Link>
                    |
                    <button
                      className="exitBtn_sideNav"
                      onClick={() => {
                        remove();
                        setTimeout(() => {
                          document.location.reload(true);
                        }, 300);
                      }}
                    >
                      {text[2]}
                    </button>
                  </div>
                </div>
                <div className="wallet__nav">
                  <div className="wallet__nav-center">
                    <i class="fas fa-coins fa-lg"></i>
                    <div className="infoWallet">
                      <span className="wallet__nav-saldo">{text[3]}</span>
                      {eq9 ? (
                        // carteira ? (
                        <Link to="/wallet" className="wallet__nav-coins">
                          {/* {carteira} EQ9 */}
                          {Math.round(eq9 / 100000000).toFixed(1)} EQ9
                          {/* {user.wallet.value} EQ9 */}
                        </Link>
                      ) : // ) : null
                      null}
                    </div>
                  </div>
                </div>
              </nav>
              {user ? (
                <div className="container-buttons-sideNav">
                  {userLocalData ? (
                    userLocalData.access === 1 ? (
                      <>
                        <Link to="/tournaments" onClick={handleClose}>
                          <BtnSide>{text[5]}</BtnSide>
                        </Link>
                        <Link to="/meusstakers" onClick={handleClose}>
                          <BtnSide>{text[6]}</BtnSide>
                        </Link>
                      </>
                    ) : null
                  ) : null}
                  {userLocalData ? (
                    userLocalData.access === 3 ? (
                      <div className="buttons-box">
                        <Link
                          to={mobile ? "/mobile/evo" : "/organizer"}
                          onClick={() => {
                            setAddTournament(true);
                            handleClose();
                          }}
                        >
                          <BtnSide>{text[10]}</BtnSide>
                        </Link>
                      </div>
                    ) : null
                  ) : null}
                </div>
              ) : null}
              <div className="container-button-profiles">
                {accessBtn ? (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      alignItems: "flex-start",
                      width: "98%",
                      height: "180px",
                      paddingTop: "20px",
                      bottom: "12%",
                      justifyContent: "space-evenly",
                      overflow: "hidden",
                    }}
                  >
                    <Link
                      className="translate-y"
                      to="/player"
                      style={{ width: "30%" }}
                      onClick={() => {
                        screenPass.currentTime = 0;
                        handleChange(1);
                        setAccessBtn(false);
                        handleClose();
                        screenPass.play();
                        continuousSound.pause();
                        continuousSound.currentTime = 0;
                      }}
                      onMouseOver={() => setAccessBtn(true)}
                    >
                      <div className="w-button" style={{ width: "100%" }}>
                        <div
                          className="w-button-icon-player"
                          style={{
                            width: "80px",
                            height: "80px",
                            fontSize: "1rem",
                          }}
                        />
                        <p style={{ fontSize: "1rem", lineHeight: "1rem" }}>{textAccess.button[0]}</p>
                      </div>
                    </Link>
                    <Link
                      className="translate-y"
                      to="/staker"
                      style={{ width: "30%" }}
                      onClick={() => {
                        screenPass.currentTime = 0;
                        handleChange(2);
                        setAccessBtn(false);
                        handleClose();
                        screenPass.play();
                        continuousSound.pause();
                        continuousSound.currentTime = 0;
                      }}
                      onMouseOver={() => setAccessBtn(true)}
                    >
                      <div className="w-button delay" style={{ width: "100%" }}>
                        <div
                          className="w-button-icon-staker"
                          style={{
                            width: "80px",
                            height: "80px",
                            fontSize: "1rem",
                          }}
                        />
                        <p style={{ fontSize: "1rem", lineHeight: "1rem" }}>{textAccess.button[1]}</p>
                      </div>
                    </Link>
                    {user.evo.isEvo && (
                      <Link
                        className="translate-y"
                        to="/organizer"
                        style={{ width: "30%" }}
                        onClick={() => {
                          screenPass.currentTime = 0;
                          handleChange(3);
                          setAccessBtn(false);
                          handleClose();
                          screenPass.play();
                          continuousSound.pause();
                          continuousSound.currentTime = 0;
                        }}
                        onMouseOver={() => setAccessBtn(true)}
                      >
                        <div className="w-button button-evo" style={{ width: "100%" }}>
                          <div
                            className="w-button-icon-evo"
                            style={{
                              width: "80px",
                              height: "80px",
                              fontSize: "1rem",
                            }}
                          />
                          <p style={{ fontSize: ".8rem", lineHeight: "1rem" }}>{textAccess.button[2]}</p>
                        </div>
                      </Link>
                    )}
                  </div>
                ) : null}
                <Link to={mobile ? "/mobile/evo" : "/organizer"} className="button-profiles-sideNav" onClick={handleClose}>
                  <div className="button-profiles-sideNav-content-txt">
                    <p>{text[11]}</p>
                    <h4>{userAccess[3]}</h4>
                  </div>
                </Link>
              </div>
            </>
          )}
        </div>
      </Drawer>

      {/* </React.Fragment> */}
    </div>
  );
}
