import React from "react";
import "./ranking.css";
import Table from "../../components/table";
import TopPlayers from "../../components/topPlayers";
import { motion } from "framer-motion";

const coldata = [
  { col: "POS", wd: "5%" },
  { col: "JOGADOR", wd: "40%" },
  { col: "NICKNAME", wd: "20%" },
  { col: "GAMETAG", wd: "30%" },
  { col: "STAKERS", wd: "5%" },
];

const data = [
  {
    id: "1",
    name: "Felipe Moreira",
    nickname: "LOROTA",
    tag: "Equals CSS",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "2",
    name: "Bernardo Madeira",
    nickname: "BERNARDO",
    tag: "Equals BackEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "3",
    name: "Victor Santos",
    nickname: "IKIRA",
    tag: "Equals FrontEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "4",
    name: "Camila Cordeiro",
    nickname: "CKCORDEIRO",
    tag: "Equals Diretoria",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "5",
    name: "Felipe Moreira",
    nickname: "LOROTA",
    tag: "Equals CSS",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "6",
    name: "Bernardo Madeira",
    nickname: "BERNARDO",
    tag: "Equals BackEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "7",
    name: "Victor Santos",
    nickname: "IKIRA",
    tag: "Equals FrontEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "8",
    name: "Camila Cordeiro",
    nickname: "CKCORDEIRO",
    tag: "Equals Diretoria",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "9",
    name: "Felipe Moreira",
    nickname: "LOROTA",
    tag: "Equals CSS",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "10",
    name: "Bernardo Madeira",
    nickname: "BERNARDO",
    tag: "Equals BackEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "11",
    name: "Victor Santos",
    nickname: "IKIRA",
    tag: "Equals FrontEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "12",
    name: "Camila Cordeiro",
    nickname: "CKCORDEIRO",
    tag: "Equals Diretoria",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "13",
    name: "Felipe Moreira",
    nickname: "LOROTA",
    tag: "Equals CSS",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "14",
    name: "Bernardo Madeira",
    nickname: "BERNARDO",
    tag: "Equals BackEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "15",
    name: "Victor Santos",
    nickname: "IKIRA",
    tag: "Equals FrontEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "16",
    name: "Camila Cordeiro",
    nickname: "CKCORDEIRO",
    tag: "Equals Diretoria",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "17",
    name: "Felipe Moreira",
    nickname: "LOROTA",
    tag: "Equals CSS",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "18",
    name: "Bernardo Madeira",
    nickname: "BERNARDO",
    tag: "Equals BackEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "19",
    name: "Victor Santos",
    nickname: "IKIRA",
    tag: "Equals FrontEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "20",
    name: "Camila Cordeiro",
    nickname: "CKCORDEIRO",
    tag: "Equals Diretoria",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "21",
    name: "Felipe Moreira",
    nickname: "LOROTA",
    tag: "Equals CSS",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "22",
    name: "Bernardo Madeira",
    nickname: "BERNARDO",
    tag: "Equals BackEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "23",
    name: "Victor Santos",
    nickname: "IKIRA",
    tag: "Equals FrontEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "24",
    name: "Camila Cordeiro",
    nickname: "CKCORDEIRO",
    tag: "Equals Diretoria",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "25",
    name: "Felipe Moreira",
    nickname: "LOROTA",
    tag: "Equals CSS",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "26",
    name: "Bernardo Madeira",
    nickname: "BERNARDO",
    tag: "Equals BackEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "27",
    name: "Victor Santos",
    nickname: "IKIRA",
    tag: "Equals FrontEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "28",
    name: "Camila Cordeiro",
    nickname: "CKCORDEIRO",
    tag: "Equals Diretoria",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "29",
    name: "Felipe Moreira",
    nickname: "LOROTA",
    tag: "Equals CSS",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "30",
    name: "Bernardo Madeira",
    nickname: "BERNARDO",
    tag: "Equals BackEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "31",
    name: "Victor Santos",
    nickname: "IKIRA",
    tag: "Equals FrontEnd",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
  {
    id: "32",
    name: "Camila Cordeiro",
    nickname: "CKCORDEIRO",
    tag: "Equals Diretoria",
    score: { w: 10, l: 20, d: 5 },
    stakers: 20,
  },
];

function Index() {
  const pageVariants = {
    initial: { opacity: 0 },
    in: { opacity: 1 },
    out: { opacity: 0 },
  };
  const pageTransition = { type: "tween", easy: "circInOut", duration: 0.4 };
  return (
    <motion.div exit="out" animate="in" initial="initial" variants={pageVariants} transition={pageTransition} className="rankingPage">
      <div className="containerRankingLeft">
        <div className="rankingBanner">
          <div className="banner">
            <h1>SEASON ONE / 2021</h1>
          </div>
          <span className="descriptionBanner">
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using
            Lorem Ipsum is that it has.
          </span>
        </div>
        <div className="rankingProfile">
          <div className="rankingProfileContent">
            <TopPlayers data={data} />
          </div>
        </div>
      </div>
      <div className="containerRankingRight">
        <div className="tableRanking">
          <Table col={coldata} data={data} />
        </div>
      </div>
    </motion.div>
  );
}

export default Index;
