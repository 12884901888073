import { useEffect, useRef } from "react";

function Chat({ chatList, chatInputRef, handleSubmitMsg, user }) {
  const chatscrollref = useRef(null);
  useEffect(() => {
    if (chatList) {
      if (
        chatscrollref.current.scrollHeight > chatscrollref.current.offsetHeight
      ) {
        chatscrollref.current.scrollTop =
          chatscrollref.current.scrollHeight -
          chatscrollref.current.offsetHeight;
      }
    }
  }, [chatList]);
  if (!chatList) return null;
  return (
    <>
      <div
        className="room-page-mobile-chat_chat-container-messages"
        ref={chatscrollref}
      >
        {chatList.map((item) => {
          return (
            <div key={item.body}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  paddingRight: "5px",
                  flexDirection: "row",
                  justifyContent:
                    item.author === user.nickname ? "flex-start" : "flex-end",
                }}
              >
                {item.author !== user.nickname ? (
                  <div className="chat-box-vs animate__animated animate__fadeInRight animate__faster">
                    <div style={{ color: "rgb(200,0,200)" }}>{item.author}</div>
                    <div style={{ whiteSpace: "wrap", wordWrap: "break-word" }}>
                      {item.body}
                    </div>
                  </div>
                ) : (
                  <div className="chat-box-author animate__animated animate__fadeInLeft animate__faster">
                    <div
                      style={{
                        whiteSpace: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {item.body}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <form onSubmit={handleSubmitMsg}>
        <input
          type="text"
          placeholder="Digite aqui..."
          className="room-page-mobile-chat_chat-container-input"
          ref={chatInputRef}
        />
      </form>
    </>
  );
}

export default Chat;
