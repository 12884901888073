import React from "react";
import { Dialog } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

function LoadingPage() {
  return (
    <Dialog
      open={true}
      className="dialog"
      //   onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <CircularProgress variant="inderteminated" />
    </Dialog>
  );
}

export default LoadingPage;
